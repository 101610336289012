import React from 'react';
import { loadModules } from 'esri-loader';

export class ContactMap extends React.Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
      
    }

    componentDidMount() {
        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules(['esri/Map', 'esri/views/MapView', "esri/layers/GraphicsLayer", "esri/Graphic"], { css: true })
            .then(([ArcGISMap, MapView, GraphicsLayer, Graphic]) => {
                const map = new ArcGISMap({
                    basemap: 'hybrid'
                });

                this.view = new MapView({
                    container: this.mapRef.current,
                    map: map,
                    center: [44.801023, 41.698584],
                    zoom: 15
                });

                this.view.ui._removeComponents(["attribution"]);
                this.view.ui.move("zoom", "bottom-right");

                var graphicsLayer = new GraphicsLayer();

                var point = {
                    type: "point",
                    longitude: 44.801023,
                    latitude: 41.698584
                };

                var contenttxt = "<div style='background-color:#F6BD60;font-family:FiraGoBold;font-size:15px;line-height: 21px;' >" +
                   "<div>თბილისი 0105</div><div style='margin-top:5px;'>თაბუკაშვილის ქ.#5</div>"+
                    "</div>";

                var symbol = {
                    type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                    url: "/mapicons/mapPin.png",
                    width: "42px",
                    height: "42px"
                };
                var pointGraphic = new Graphic({
                    geometry: point,
                    symbol: symbol,
                    popupTemplate: {
                        title: "",
                        content: contenttxt,
                        actions:[]
                    }
                });
                graphicsLayer.graphics.add(pointGraphic);
                map.add(graphicsLayer);

               
                //graphicsLayer
                //    .when(function () {
                //        return graphicsLayer.queryExtent();
                //    })
                //    .then(function (response) {
                //        this.view.goTo(response.extent);
                //    });
            });
    }

    componentWillUnmount() {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }
    }

    render() {
        return (
            <div style={{ paddingBottom: '1px' }} className="contactMap webmap" ref={this.mapRef} >
                {this.props.children}
            </div>
        );
    }
}