import React, { Component } from 'react';

import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';

export class Recaptcha extends Component {


    render() {


        return (
            <div >

                <GoogleReCaptchaProvider reCaptchaKey="6LeBq_sUAAAAAHChOzJQukyOLQhQO18pzHHdC1rr">
                    <GoogleReCaptcha />
                </GoogleReCaptchaProvider>

            </div>
        );
    }

}
