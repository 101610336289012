import React from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import './CustomToggle.css';
import './reactTree.css';



function searchPredicate(node, searchTerm) {
    // return node.customData && node.customData.toLower().indexOf(searchTerm) >= 0
    if (searchTerm !== '' && node.label.includes(searchTerm)) node.expanded = true;
    else node.expanded = false;

    return node.label.includes(searchTerm)
}

export class CustomTreeSelect extends React.Component {
    selectedItems = [];

    render() {


        //const createItems = (item, children) => {
        //    var i = {
        //        value: item.id, label: item.label,
        //        expanded: false,
        //        checked: this.props.selectedOption != null ? this.props.selectedOption.indexOf(item.id) > -1 : false
        //    };
        //    if (children != null) {
        //        var itch = [];
        //        children.map(it => itch.push(createItems(it, it.children)))
        //        i.children = itch
        //    }
        //    return i;
        //}

        const createItems = (item, children,isChecked) => {
            var i = {
                value: item.id, label: item.label,
                expanded: false,
                checked: isChecked
            };
            if (children != null) {
                var itch = [];
                children.map(it => itch.push(createItems(it, it.children, isChecked || (this.props.selectedOption != null ? this.props.selectedOption.indexOf(it.id) > -1 : false))))
                i.children = itch
            }
            i.tempchild = i.children
            return i;
        }

        var options = [];
        this.props.items.map(item => options.push(createItems(item, item.children, this.props.selectedOption != null ? this.props.selectedOption.indexOf(item.id) > -1 : false))
            // options.push({ value: item.id, label: item.label, checked: this.props.selectedOption!=null? this.props.selectedOption.indexOf(item.id) > -1:false, children: item.children })
        );


        return (

            <DropdownTreeSelect
                data={options}
                searchPredicate={searchPredicate}
                onChange={this.props.onChange}
                className="mdl-demo searchDivRowInput"
                onBlur={this.props.onBlur}
                //mode="hierarchical"
                texts={{ placeholder: ' ', noMatches: ' ' }}
                //keepTreeOnSearch={true}
                //keepChildrenOnSearch={true}
               
            />
        );
    }
}
