import React from 'react';


class ClearSearch extends React.Component {

    clearFilter() {
        sessionStorage.setItem('locationFilter', 'off');
        sessionStorage.removeItem('locationFilter');

        this.props.filterResulthandle('');

        sessionStorage.clear();
        window.history.pushState({}, document.title, window.location.pathname);
        var data;
        switch (this.props.type) {
            case "immovable":
                data = {
                    id: '', name: '', eventStartDate: '', eventEndDate: '', docStartDate: '', docEndDate: '',
                    variety: [], status: [], category: [], period: [], function: [], eventType: [], documentType: [],sizuste:[],
                    country: [], region: [], municipality: [], settlement: [], street: [],
                    tempselectedPeriods: [], tempselectedFunction: [], tempselectedEventType: [], tempselectedDocumentType: [], linkedObj: {}, fullText: ''
                };
                break;
            case "movable":
                data = {
                    id: '', name: '',
                    variety: [], status: [], category: [], period: [], function: [],
                    locationName: '',
                    country: [], region: [], municipality: [], settlement: [], street: [],
                    oldcountry: [], oldregion: [], oldmunicipality: [], oldsettlement: [], oldstreet: [],
                    author: '', tempselectedPeriods: [], tempselectedFunction: [], linkedObj: {}, fullText: ''
                };
                break;
            case "wallart":
                data = {
                    id: '', name: '', period: [],
                    araidentificirebuli:'',
                    iconografia: [],
                    ciuriZalni: [],
                    diakvnebi: [],
                    gamosaxuleba: [],
                    mamebi: [],
                    mociqulni: [],
                    saeroPirebi: [],
                    sqemebi: [],
                    scenebi: [],
                    wmindanebi: [],
                    winaswarmetyvelebi: [],
                    ornamenti: [],
                    tempselectedPeriods: [],
                    tempselectedsaeroPirebi: [],
                    tempselectedscenebi: [], linkedObj: {}, fullText: ''
                };
                break;
            case "stoneengrave":
                data = {
                    id: '', name: '', period: [],
                    araidentificirebuli: '',
                    geometrornamenti: [],
                    ciuriZalni: [],
                    cxovelebi: [],
                    gamosaxuleba: [],
                    mamebi: [],
                    mociqulni: [],
                    saeroPirebi: [],
                    frinvelebi: [],
                    scenebi: [],
                    wmindanebi: [],
                    winaswarmetyvelebi: [],
                    ornamenti: [],
                    tempselectedPeriods: [],
                    tempselectedsaeroPirebi: [],
                    tempselectedscenebi: [], linkedObj: {}, fullText: ''
                };
                break;
            case "zone":
                data = {
                    id: '', name: '',
                    variety: [],
                    region: [], municipality: [], settlement: [], linkedObj: {}, fullText: ''
                };
                break;
            case "museum":
                data = {
                    id: '', name: '',
                    region: [], municipality: [], settlement: [], street: [], linkedObj: {}, fullText: ''
                };
                break;
            case "event":
                data = {
                    id: '', name: '', org: '', eventStartDate: '', eventEndDate: '',
                    eventType: [], eventStatus: [],
                    country: [], region: [], municipality: [], settlement: [], street: [],
                    tempselectedEventType: [], linkedObj: {}, fullText: ''
                };
                break;
            case "document":
                data = {
                    id: '', name: '', org: '', docStartDate: '', docEndDate: '',
                    documentType: [], tempselectedDocumentType: [], linkedObj: {}, fullText: ''
                };
                break;
            default:
                return "";
        }
        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));
        this.props.updatedataState(data);
        this.props.detailSearch.current.searchData(data);
    }

    loopChild(item, tempList) {
        tempList.push(item.id);
        if (item.children != null && item.children.length > 0) {
            item.children.map(i => this.loopChild(i, tempList))
        }
    }

    GetParentItem(id, classificators) {
        var node;
        classificators.some(currentNode => node = this.getNodeById(currentNode, id));
        return node;
    }

    GetItemLabel(id, classificators) {
        var node;
        classificators.some(currentNode => node = this.getNodeById(currentNode, id));
        return node.label;
    }

    getNodeById(currentNode, id) {
        var node;
        if (currentNode.id === id) {
            return currentNode;
        }
        currentNode.children.some(child => node = this.getNodeById(child, id));
        return node;
    }

    getParentNodes(idList, classificators) {
        var tempList = [];
        idList.map(id => this.loopChild(this.GetParentItem(id, classificators), tempList));
        return tempList;
    }


    clearFilterFromData(el) {
        
        var fieldType = el.target.getAttribute('fieldtype');
        var fieldValue = el.target.getAttribute('fieldvalue');

        var data = this.props.data;
        var index;
        var temp;
        switch (this.props.type) {
            case "immovable":
                switch (fieldType) {
                    case 'linkedObj':
                        data.linkedObj = {};
                        window.history.pushState({}, document.title, window.location.pathname);
                        sessionStorage.removeItem('objType');
                        sessionStorage.removeItem('objID');
                        break;
                    case 'fullText':
                        window.history.pushState({}, document.title, window.location.pathname);
                        data.fullText = '';
                        break;
                    case 'id':
                        data.id = 0;
                        break;
                    case 'eventStartDate':
                        data.eventStartDate = 0;
                        break;
                    case 'eventEndDate':
                        data.eventEndDate = 0;
                        break;
                    case 'docStartDate':
                        data.docStartDate = 0;
                        break;
                    case 'docEndDate':
                        data.docEndDate = 0;
                        break;
                    case 'name':
                        data.name = '';
                        break;
                    case 'variety':
                        index = data.variety.indexOf(parseInt(fieldValue));
                        data.variety.splice(index, 1)
                        break;
                    case 'period':
                        index = data.period.indexOf(parseInt(fieldValue));
                        data.period.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.period, this.props.classificator.period);
                        data.tempselectedPeriods = temp;
                        break;
                    case 'function':
                        index = data.function.indexOf(parseInt(fieldValue));
                        data.function.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.function, this.props.classificator.function);
                        data.tempselectedFunction = temp;
                        break;
                    case 'documentType':
                        index = data.documentType.indexOf(parseInt(fieldValue));
                        data.documentType.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.documentType, this.props.classificator.documentType);
                        data.tempselectedDocumentType = temp;
                        break;
                    case 'eventType':
                        index = data.eventType.indexOf(parseInt(fieldValue));
                        data.eventType.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.eventType, this.props.classificator.eventType);
                        data.tempselectedEventType = temp;
                        break;
                    case 'status':
                        index = data.status.indexOf(parseInt(fieldValue));
                        data.status.splice(index, 1)
                        break;
                    case 'category':
                        index = data.category.indexOf(parseInt(fieldValue));
                        data.category.splice(index, 1)
                        break;
                    case 'worldHeritage':
                        index = data.worldHeritage.indexOf(parseInt(fieldValue));
                        data.worldHeritage.splice(index, 1)
                        break;
                    case 'sizuste':
                        index = data.sizuste.indexOf(parseInt(fieldValue));
                        data.sizuste.splice(index, 1)
                        break;
                    case 'country':
                        index = data.country.indexOf(fieldValue);
                        data.country.splice(index, 1)
                        break;
                    case 'region':
                        index = data.region.indexOf(fieldValue);
                        data.region.splice(index, 1)
                        break;
                    case 'municipality':
                        index = data.municipality.indexOf(fieldValue);
                        data.municipality.splice(index, 1)
                        break;
                    case 'settlement':
                        index = data.settlement.indexOf(fieldValue);
                        data.settlement.splice(index, 1)
                        break;
                    case 'street':
                        index = data.street.indexOf(fieldValue);
                        data.street.splice(index, 1)
                        break;
                    case 'locationFilter':
                        this.props.locationClick()
                        break;
                    default:
                        break;
                    case 'dazianebisXarisxi':
                        index = data.dazianebisXarisxi.indexOf(parseInt(fieldValue));
                        data.dazianebisXarisxi.splice(index, 1)
                        break;
                    case 'dazianebisTipi':
                        index = data.dazianebisTipi.indexOf(parseInt(fieldValue));
                        data.dazianebisTipi.splice(index, 1)
                        break;
                    case 'ganmcxadebeli':
                        data.ganmcxadebeli = '';
                        break;
                    case 'gancxadebisMizaniArsebuli':
                        data.gancxadebisMizaniArsebuli = false;
                        break;
                    case 'gancxadebisMizaniMosalodneli':
                        data.gancxadebisMizaniMosalodneli = false;
                        break;
                }

                this.props.updatedataState(data);
                sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));

                this.props.detailSearch.current.searchData(data);
                break;
            case "movable":
                switch (fieldType) {
                    case 'linkedObj':
                        data.linkedObj = {};
                        window.history.pushState({}, document.title, window.location.pathname);
                        sessionStorage.removeItem('objType');
                        sessionStorage.removeItem('objID');
                        break;
                    case 'fullText':
                        window.history.pushState({}, document.title, window.location.pathname);
                        data.fullText = '';
                        break;
                    case 'id':
                        data.id = 0;
                        break;
                    case 'author':
                        data.author = '';
                        break;
                    case 'locationName':
                        data.locationName = '';
                        break;
                    case 'name':
                        data.name = '';
                        break;
                    case 'variety':
                        index = data.variety.indexOf(parseInt(fieldValue));
                        data.variety.splice(index, 1)
                        break;
                    case 'period':
                        index = data.period.indexOf(parseInt(fieldValue));
                        data.period.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.period, this.props.classificator.period);
                        data.tempselectedPeriods = temp;
                        break;
                    case 'function':
                        index = data.function.indexOf(parseInt(fieldValue));
                        data.function.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.function, this.props.classificator.function);
                        data.tempselectedFunction = temp;
                        break;
                    case 'status':
                        index = data.status.indexOf(parseInt(fieldValue));
                        data.status.splice(index, 1)
                        break;
                    case 'category':
                        index = data.category.indexOf(parseInt(fieldValue));
                        data.category.splice(index, 1)
                        break;
                    case 'country':
                        index = data.country.indexOf(fieldValue);
                        data.country.splice(index, 1)
                        break;
                    case 'region':
                        index = data.region.indexOf(fieldValue);
                        data.region.splice(index, 1)
                        break;
                    case 'municipality':
                        index = data.municipality.indexOf(fieldValue);
                        data.municipality.splice(index, 1)
                        break;
                    case 'settlement':
                        index = data.settlement.indexOf(fieldValue);
                        data.settlement.splice(index, 1)
                        break;
                    case 'street':
                        index = data.street.indexOf(fieldValue);
                        data.street.splice(index, 1)
                        break;
                    case 'oldcountry':
                        index = data.oldcountry.indexOf(fieldValue);
                        data.oldcountry.splice(index, 1)
                        break;
                    case 'oldregion':
                        index = data.oldregion.indexOf(fieldValue);
                        data.oldregion.splice(index, 1)
                        break;
                    case 'oldmunicipality':
                        index = data.oldmunicipality.indexOf(fieldValue);
                        data.oldmunicipality.splice(index, 1)
                        break;
                    case 'oldsettlement':
                        index = data.oldsettlement.indexOf(fieldValue);
                        data.oldsettlement.splice(index, 1)
                        break;
                    case 'oldstreet':
                        index = data.oldstreet.indexOf(fieldValue);
                        data.oldstreet.splice(index, 1)
                        break;

                    default:
                        break;
                }

                this.props.updatedataState(data);
                sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));

                this.props.detailSearch.current.searchData(data);
                break;
            case "document":
                switch (fieldType) {
                    case 'linkedObj':
                        data.linkedObj = {};
                        window.history.pushState({}, document.title, window.location.pathname);
                        sessionStorage.removeItem('objType');
                        sessionStorage.removeItem('objID');
                        break;
                    case 'fullText':
                        window.history.pushState({}, document.title, window.location.pathname);
                        data.fullText = '';
                        break;
                    case 'id':
                        data.id = 0;
                        break;
                    case 'docStartDate':
                        data.docStartDate = 0;
                        break;
                    case 'docEndDate':
                        data.docEndDate = 0;
                        break;
                    case 'name':
                        data.name = '';
                        break;
                    case 'org':
                        data.org = '';
                        break;
                    case 'documentType':
                        index = data.documentType.indexOf(parseInt(fieldValue));
                        data.documentType.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.documentType, this.props.classificator.type);
                        data.tempselectedDocumentType = temp;
                        break;
                    default:
                        break;
                }

                this.props.updatedataState(data);
                sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));

                this.props.detailSearch.current.searchData(data);
                break;
            case "event":
                switch (fieldType) {
                    case 'linkedObj':
                        data.linkedObj = {};
                        window.history.pushState({}, document.title, window.location.pathname);
                        sessionStorage.removeItem('objType');
                        sessionStorage.removeItem('objID');
                        break;
                    case 'fullText':
                        window.history.pushState({}, document.title, window.location.pathname);
                        data.fullText = '';
                        break;
                    case 'id':
                        data.id = 0;
                        break;
                    case 'eventStartDate':
                        data.eventStartDate = 0;
                        break;
                    case 'eventEndDate':
                        data.eventEndDate = 0;
                        break;
                    case 'name':
                        data.name = '';
                        break;
                    case 'org':
                        data.org = '';
                        break;
                    case 'eventType':
                        index = data.eventType.indexOf(parseInt(fieldValue));
                        data.eventType.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.eventType, this.props.classificator.eventType);
                        data.tempselectedEventType = temp;
                        break;
                    case 'eventStatus':
                        index = data.eventStatus.indexOf(parseInt(fieldValue));
                        data.eventStatus.splice(index, 1);                                         
                        break;
                    case 'country':
                        index = data.country.indexOf(fieldValue);
                        data.country.splice(index, 1)
                        break;
                    case 'region':
                        index = data.region.indexOf(fieldValue);
                        data.region.splice(index, 1)
                        break;
                    case 'municipality':
                        index = data.municipality.indexOf(fieldValue);
                        data.municipality.splice(index, 1)
                        break;
                    case 'settlement':
                        index = data.settlement.indexOf(fieldValue);
                        data.settlement.splice(index, 1)
                        break;
                    case 'street':
                        index = data.street.indexOf(fieldValue);
                        data.street.splice(index, 1)
                        break;

                    default:
                        break;
                }

                this.props.updatedataState(data);
                sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));

                this.props.detailSearch.current.searchData(data);
                break;
            case "museum":
                switch (fieldType) {
                    case 'linkedObj':
                        data.linkedObj = {};
                        window.history.pushState({}, document.title, window.location.pathname);
                        sessionStorage.removeItem('objType');
                        sessionStorage.removeItem('objID');
                        break;
                    case 'fullText':
                        window.history.pushState({}, document.title, window.location.pathname);
                        data.fullText = '';
                        break;
                    case 'id':
                        data.id = 0;
                        break;
                    case 'name':
                        data.name = '';
                        break;
                    case 'region':
                        index = data.region.indexOf(fieldValue);
                        data.region.splice(index, 1)
                        break;
                    case 'municipality':
                        index = data.municipality.indexOf(fieldValue);
                        data.municipality.splice(index, 1)
                        break;
                    case 'settlement':
                        index = data.settlement.indexOf(fieldValue);
                        data.settlement.splice(index, 1)
                        break;
                    case 'street':
                        index = data.street.indexOf(fieldValue);
                        data.street.splice(index, 1)
                        break;
                    case 'locationFilter':
                        this.props.locationClick()
                        break;
                    default:
                        break;
                }

                this.props.updatedataState(data);
                sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));

                this.props.detailSearch.current.searchData(data);
                break;
            case "zone":
                switch (fieldType) {
                    case 'linkedObj':
                        data.linkedObj = {};
                        window.history.pushState({}, document.title, window.location.pathname);
                        sessionStorage.removeItem('objType');
                        sessionStorage.removeItem('objID');
                        break;
                    case 'fullText':
                        window.history.pushState({}, document.title, window.location.pathname);
                        data.fullText = '';
                        break;
                    case 'id':
                        data.id = 0;
                        break;
                    case 'name':
                        data.name = '';
                        break;
                    case 'variety':
                        index = data.variety.indexOf(parseInt(fieldValue));
                        data.variety.splice(index, 1)
                        break;
                    case 'region':
                        index = data.region.indexOf(fieldValue);
                        data.region.splice(index, 1)
                        break;
                    case 'municipality':
                        index = data.municipality.indexOf(fieldValue);
                        data.municipality.splice(index, 1)
                        break;
                    case 'settlement':
                        index = data.settlement.indexOf(fieldValue);
                        data.settlement.splice(index, 1)
                        break;
                    default:
                        break;
                }

                this.props.updatedataState(data);
                sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));

                this.props.detailSearch.current.searchData(data);
                break;
            case "wallart":
                switch (fieldType) {
                    case 'linkedObj':
                        data.linkedObj = {};
                        window.history.pushState({}, document.title, window.location.pathname);
                        sessionStorage.removeItem('objType');
                        sessionStorage.removeItem('objID');
                        break;
                    case 'fullText':
                        window.history.pushState({}, document.title, window.location.pathname);
                        data.fullText = '';
                        break;
                    case 'id':
                        data.id = 0;
                        break;
                    case 'name':
                        data.name = '';
                        break;
                    case 'period':
                        index = data.period.indexOf(parseInt(fieldValue));
                        data.period.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.period, this.props.classificator.period);
                        data.tempselectedPeriods = temp;
                        break;
                    case 'araidentificirebuli':
                        data.araidentificirebuli = ''
                        break;
                    case 'iconografia':
                        index = data.iconografia.indexOf(parseInt(fieldValue));
                        data.iconografia.splice(index, 1)
                        break;
                    case 'ciuriZalni':
                        index = data.ciuriZalni.indexOf(parseInt(fieldValue));
                        data.ciuriZalni.splice(index, 1)
                        break;
                    case 'diakvnebi':
                        index = data.diakvnebi.indexOf(parseInt(fieldValue));
                        data.diakvnebi.splice(index, 1)
                        break;
                    case 'gamosaxuleba':
                        index = data.gamosaxuleba.indexOf(parseInt(fieldValue));
                        data.gamosaxuleba.splice(index, 1)
                        break;
                    case 'mamebi':
                        index = data.mamebi.indexOf(parseInt(fieldValue));
                        data.mamebi.splice(index, 1)
                        break;
                    case 'mociqulni':
                        index = data.mociqulni.indexOf(parseInt(fieldValue));
                        data.mociqulni.splice(index, 1)
                        break;
                    case 'saeroPirebi':
                        index = data.saeroPirebi.indexOf(parseInt(fieldValue));
                        data.saeroPirebi.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.saeroPirebi, this.props.classificator.saeroPirebi);
                        data.tempselectedsaeroPirebi = temp;
                        break;
                    case 'sqemebi':
                        index = data.sqemebi.indexOf(parseInt(fieldValue));
                        data.sqemebi.splice(index, 1)
                        break;
                    case 'scenebi':
                        index = data.scenebi.indexOf(parseInt(fieldValue));
                        data.scenebi.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.scenebi, this.props.classificator.scenebi);
                        data.tempselectedscenebi = temp;
                        break;
                    case 'wmindanebi':
                        index = data.wmindanebi.indexOf(parseInt(fieldValue));
                        data.wmindanebi.splice(index, 1)
                        break;
                    case 'winaswarmetyvelebi':
                        index = data.winaswarmetyvelebi.indexOf(parseInt(fieldValue));
                        data.winaswarmetyvelebi.splice(index, 1)
                        break;
                    case 'ornamenti':
                        index = data.ornamenti.indexOf(parseInt(fieldValue));
                        data.ornamenti.splice(index, 1)
                        break;
                    default:
                        break;
                }

                this.props.updatedataState(data);
                sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));


                this.props.detailSearch.current.searchData(data);
                break;
            case "stoneengrave":
                switch (fieldType) {
                    case 'linkedObj':
                        data.linkedObj = {};
                        window.history.pushState({}, document.title, window.location.pathname);
                        sessionStorage.removeItem('objType');
                        sessionStorage.removeItem('objID');
                        break;
                    case 'fullText':
                        window.history.pushState({}, document.title, window.location.pathname);
                        data.fullText = '';
                        break;
                    case 'id':
                        data.id = 0;
                        break;
                    case 'name':
                        data.name = '';
                        break;
                    case 'period':
                        index = data.period.indexOf(parseInt(fieldValue));
                        data.period.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.period, this.props.classificator.period);
                        data.tempselectedPeriods = temp;
                        break;
                    case 'araidentificirebuli':
                        data.araidentificirebuli = '';
                        break;
                    case 'geometrornamenti':
                        index = data.geometrornamenti.indexOf(parseInt(fieldValue));
                        data.geometrornamenti.splice(index, 1)
                        break;
                    case 'ciuriZalni':
                        index = data.ciuriZalni.indexOf(parseInt(fieldValue));
                        data.ciuriZalni.splice(index, 1)
                        break;
                    case 'cxovelebi':
                        index = data.cxovelebi.indexOf(parseInt(fieldValue));
                        data.cxovelebi.splice(index, 1)
                        break;
                    case 'gamosaxuleba':
                        index = data.gamosaxuleba.indexOf(parseInt(fieldValue));
                        data.gamosaxuleba.splice(index, 1)
                        break;
                    case 'mamebi':
                        index = data.mamebi.indexOf(parseInt(fieldValue));
                        data.mamebi.splice(index, 1)
                        break;
                    case 'mociqulni':
                        index = data.mociqulni.indexOf(parseInt(fieldValue));
                        data.mociqulni.splice(index, 1)
                        break;
                    case 'saeroPirebi':
                        index = data.saeroPirebi.indexOf(parseInt(fieldValue));
                        data.saeroPirebi.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.saeroPirebi, this.props.classificator.saeroPirebi);
                        data.tempselectedsaeroPirebi = temp;
                        break;
                    case 'frinvelebi':
                        index = data.frinvelebi.indexOf(parseInt(fieldValue));
                        data.frinvelebi.splice(index, 1)
                        break;
                    case 'scenebi':
                        index = data.scenebi.indexOf(parseInt(fieldValue));
                        data.scenebi.splice(index, 1);
                        temp = [];
                        temp = this.getParentNodes(data.scenebi, this.props.classificator.scenebi);
                        data.tempselectedscenebi = temp;
                        break;
                    case 'wmindanebi':
                        index = data.wmindanebi.indexOf(parseInt(fieldValue));
                        data.wmindanebi.splice(index, 1)
                        break;
                    case 'winaswarmetyvelebi':
                        index = data.winaswarmetyvelebi.indexOf(parseInt(fieldValue));
                        data.winaswarmetyvelebi.splice(index, 1)
                        break;
                    case 'ornamenti':
                        index = data.ornamenti.indexOf(parseInt(fieldValue));
                        data.ornamenti.splice(index, 1)
                        break;
                    default:
                        break;
                }

                this.props.updatedataState(data);
                sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));


                this.props.detailSearch.current.searchData(data);
                break;
            default:
                break;
        }



    }

    getTypeText(val) {
        switch (val) {
            case "immovable":
            case "userAlert":
            case "immovableCategory":
            case "immovableStatus":
                return "უძრავი ძეგლი";
            case "movable":
            case "movableCategory":
            case "movableStatus":
                return "მოძრავი ძეგლი";
            case "wallart":
                return "კედლის მხატვრობა";
            case "wallartpainting":
                return "კედლის მხატვრობა";
            case "stoneengrave":
                return "ქვაზე კვეთილობა";
            case "stoneengravemembership":
                return "ქვაზე კვეთილობა";
            case "zone":
                return "ზოგადი დამცავი ზონა";
            case "museum":
                return "მუზეუმ-ნაკრძალი/მუზეუმი";
            case "event":
                return "ქმედება";
            case "document":
                return "დოკუმენტაცია";
            default:
                return "";

        }

    }

    GetFilterData() {
        var data = this.props.data;
        switch (this.props.type) {
            case "immovable":
              
                if (this.props.data != null && this.props.data.id != null) {
                    if (sessionStorage.getItem('filterResult') !== ''
                        || (data.linkedObj.type && data.linkedObj.type !== '' && data.linkedObj.objID !== '')
                        || (data.fullText && data.fullText !== '')
                        || (sessionStorage.getItem('locationFilter') != null && sessionStorage.getItem('locationFilter') === 'on')
                    ) {
                        return (
                            <div className="filterProps">
                                <div>
                                    {data.linkedObj && data.linkedObj.type != null && data.linkedObj.type !== '' && data.linkedObj.objID !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='linkedObj' fieldvalue={data.linkedObj.type + ';' + data.linkedObj.objID} onClick={this.clearFilterFromData.bind(this)}>
                                            {this.getTypeText(data.linkedObj.type) + ' - ' + sessionStorage.getItem('objName')}
                                        </button>
                                        : <></>
                                    }
                                    {data.fullText && data.fullText !== ''  ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='fullText' fieldvalue={data.fullText} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.fullText}
                                        </button>
                                        : <></>
                                    }
                                    {data.id && data.id !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='id' fieldvalue={data.id} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.id}
                                        </button>
                                        : <></>
                                    }
                                    {data.name &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='name' fieldvalue={data.name} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.name}
                                        </button>
                                    }
                                    {data.variety &&
                                        data.variety.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='variety' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.variety.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.period &&
                                        data.period.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='period' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.period)}
                                            </button>
                                        )
                                    }
                                    {data.function &&
                                        data.function.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='function' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.function)}
                                            </button>
                                        )
                                    }
                                    {data.status &&
                                        data.status.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='status' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.status.filter(x => x.id === item)[0].name}
                                            </button>
                                        )
                                    }
                                    {data.category &&
                                        data.category.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='category' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.category.filter(x => x.id === item)[0].name}
                                            </button>
                                        )
                                    }
                                    {data.worldHeritage &&
                                        data.worldHeritage.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='worldHeritage' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.worldHeritage.filter(x => x.id === item)[0].name}
                                            </button>
                                        )
                                    }
                                    {data.country &&
                                        data.country.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='country' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.region &&
                                        data.region.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='region' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.municipality &&
                                        data.municipality.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='municipality' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.settlement &&
                                        data.settlement.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='settlement' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.street &&
                                        data.street.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='street' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.sizuste &&
                                        data.sizuste.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='sizuste' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.sizuste.filter(x => x.id === item)[0].name}
                                            </button>
                                        )
                                    }
                                    {data.eventType &&
                                        data.eventType.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='eventType' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.eventType)}
                                            </button>
                                        )
                                    }
                                    {data.eventStartDate && data.eventStartDate !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='eventStartDate' fieldvalue={data.eventStartDate} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.eventStartDate}
                                        </button>
                                        : <></>
                                    }
                                    {data.eventEndDate && data.eventEndDate !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='eventEndDate' fieldvalue={data.eventEndDate} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.eventEndDate}
                                        </button>
                                        : <></>
                                    }
                                    {data.documentType &&
                                        data.documentType.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='documentType' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.documentType)}
                                            </button>
                                        )
                                    }
                                    {data.docStartDate && data.docStartDate !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='docStartDate' fieldvalue={data.docStartDate} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.docStartDate}
                                        </button>
                                        : <></>
                                    }
                                    {data.docEndDate && data.docEndDate !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='docEndDate' fieldvalue={data.docEndDate} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.docEndDate}
                                        </button>
                                        : <></>
                                    }
                                    {sessionStorage.getItem('locationFilter')!=null && sessionStorage.getItem('locationFilter') === 'on' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='locationFilter' fieldvalue={''} onClick={this.clearFilterFromData.bind(this)}>
                                            {"ჩეთან ახლოს - " + (sessionStorage.getItem('locationRadius') == null ? 5 : sessionStorage.getItem('locationRadius')) +"კმ."}
                                        </button>
                                        : <></>
                                    }

                                    {data.ganmcxadebeli &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='ganmcxadebeli' fieldvalue={data.ganmcxadebeli} onClick={this.clearFilterFromData.bind(this)}>
                                        {data.ganmcxadebeli}
                                        </button>
                                    }
                                    {data.dazianebisXarisxi &&
                                        data.dazianebisXarisxi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='dazianebisXarisxi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.dazianebisXarisxi.filter(x => x.id === item)[0].name}
                                            </button>
                                        )
                                    }
                                    {data.dazianebisTipi &&
                                        data.dazianebisTipi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='dazianebisTipi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.dazianebisTipi.filter(x => x.id === item)[0].name}
                                            </button>
                                        )
                                    }

                                    {data.gancxadebisMizaniArsebuli && data.gancxadebisMizaniArsebuli !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='gancxadebisMizaniArsebuli' fieldvalue={data.gancxadebisMizaniArsebuli} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.gancxadebisMizaniArsebuli}
                                        </button>
                                        : <></>
                                    }
                                    {data.gancxadebisMizaniMosalodneli && data.gancxadebisMizaniMosalodneli !== 0 ?
                                        <button className='filterpropsButton icon-cancel' gancxadebisMizaniMosalodneli='id' fieldvalue={data.gancxadebisMizaniMosalodneli} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.gancxadebisMizaniMosalodneli}
                                        </button>
                                        : <></>
                                    }
                                    

                                </div>
                                <div><button className="linkButton" onClick={this.clearFilter.bind(this)}>ფილტრის გაუქმება</button></div>
                            </div>

                        );
                    }
                }
                break;
            case "movable":
                if (this.props.data != null && this.props.data.id != null) {
                    if (sessionStorage.getItem('filterResult') !== ''
                        || (data.linkedObj.type && data.linkedObj.type !== '' && data.linkedObj.objID !== '')
                        || (data.fullText && data.fullText !== '')) {
                        return (
                            <div className="filterProps">
                                <div>
                                    {data.linkedObj && data.linkedObj.type != null && data.linkedObj.type !== '' && data.linkedObj.objID !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='linkedObj' fieldvalue={data.linkedObj.type + ';' + data.linkedObj.objID} onClick={this.clearFilterFromData.bind(this)}>
                                            {this.getTypeText(data.linkedObj.type) + ' - ' + sessionStorage.getItem('objName')}
                                        </button>
                                        : <></>
                                    }
                                    {data.fullText && data.fullText !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='fullText' fieldvalue={data.fullText} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.fullText}
                                        </button>
                                        : <></>
                                    }
                                    {data.id && data.id !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='id' fieldvalue={data.id} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.id}
                                        </button>
                                        : <></>
                                    }
                                    {data.name &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='name' fieldvalue={data.name} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.name}
                                        </button>
                                    }
                                    {data.variety &&
                                        data.variety.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='variety' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.variety.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.period &&
                                        data.period.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='period' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.period)}
                                            </button>
                                        )
                                    }
                                    {data.function &&
                                        data.function.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='function' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.function)}
                                            </button>
                                        )
                                    }
                                    {data.status &&
                                        data.status.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='status' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.status.filter(x => x.id === item)[0].name}
                                            </button>
                                        )
                                    }
                                    {data.category &&
                                        data.category.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='category' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.category.filter(x => x.id === item)[0].name}
                                            </button>
                                        )
                                    }
                                    {data.author &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='author' fieldvalue={data.author} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.author}
                                        </button>
                                    }
                                    {data.locationName &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='locationName' fieldvalue={data.locationName} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.locationName}
                                        </button>
                                    }
                                    {data.country &&
                                        data.country.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='country' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.region &&
                                        data.region.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='region' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.municipality &&
                                        data.municipality.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='municipality' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.settlement &&
                                        data.settlement.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='settlement' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.street &&
                                        data.street.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='street' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.oldcountry &&
                                        data.oldcountry.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='oldcountry' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.oldregion &&
                                        data.oldregion.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='oldregion' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.oldmunicipality &&
                                        data.oldmunicipality.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='oldmunicipality' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.oldsettlement &&
                                        data.oldsettlement.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='oldsettlement' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.oldstreet &&
                                        data.oldstreet.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='oldstreet' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                </div>
                                <div><button className="linkButton" onClick={this.clearFilter.bind(this)}>ფილტრის გაუქმება</button></div>
                            </div>

                        );
                    }
                }
                break;
            case "document":
                if (this.props.data != null && this.props.data.id != null) {
                    if (sessionStorage.getItem('filterResult') !== ''
                        || (data.linkedObj.type && data.linkedObj.type !== '' && data.linkedObj.objID !== '')
                        || (data.fullText && data.fullText !== '')) {
                        return (
                            <div className="filterProps">
                                <div>  
                                    {data.linkedObj && data.linkedObj.type != null && data.linkedObj.type !== '' && data.linkedObj.objID !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='linkedObj' fieldvalue={data.linkedObj.type + ';' + data.linkedObj.objID} onClick={this.clearFilterFromData.bind(this)}>
                                            {this.getTypeText(data.linkedObj.type) + ' - ' + sessionStorage.getItem('objName')}
                                        </button>
                                        : <></>
                                    }
                                    {data.fullText && data.fullText !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='fullText' fieldvalue={data.fullText} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.fullText}
                                        </button>
                                        : <></>
                                    }
                                    {data.id && data.id !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='id' fieldvalue={data.id} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.id}
                                        </button>
                                        : <></>
                                    }
                                    {data.name &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='name' fieldvalue={data.name} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.name}
                                        </button>
                                    }
                                    {data.documentType &&
                                        data.documentType.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='documentType' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.type)}
                                            </button>
                                        )
                                    }
                                    {data.docStartDate && data.docStartDate !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='docStartDate' fieldvalue={data.docStartDate} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.docStartDate}
                                        </button>
                                        : <></>
                                    }
                                    {data.docEndDate && data.docEndDate !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='docEndDate' fieldvalue={data.docEndDate} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.docEndDate}
                                        </button>
                                        : <></>
                                    }
                                    {data.org &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='org' fieldvalue={data.org} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.org}
                                        </button>
                                    }
                                </div>
                                <div><button className="linkButton" onClick={this.clearFilter.bind(this)}>ფილტრის გაუქმება</button></div>
                            </div>

                        );
                    }
                }
                break;
            case "event":
                if (this.props.data != null && this.props.data.id != null) {

                    if (sessionStorage.getItem('filterResult') !== ''
                        || (data.linkedObj.type && data.linkedObj.type !== '' && data.linkedObj.objID !== '')
                        || (data.fullText && data.fullText !== '')) {
                        return (
                            <div className="filterProps">
                                <div>
                                    {data.linkedObj && data.linkedObj.type != null && data.linkedObj.type !== '' && data.linkedObj.objID !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='linkedObj' fieldvalue={data.linkedObj.type + ';' + data.linkedObj.objID} onClick={this.clearFilterFromData.bind(this)}>
                                            {this.getTypeText(data.linkedObj.type) + ' - ' + sessionStorage.getItem('objName')}
                                        </button>
                                        : <></>
                                    }
                                    {data.fullText && data.fullText !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='fullText' fieldvalue={data.fullText} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.fullText}
                                        </button>
                                        : <></>
                                    }
                                    {data.id && data.id !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='id' fieldvalue={data.id} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.id}
                                        </button>
                                        : <></>
                                    }
                                    {data.name &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='name' fieldvalue={data.name} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.name}
                                        </button>
                                    }
                                    {data.eventType &&
                                        data.eventType.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='eventType' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.type)}
                                            </button>
                                        )
                                    }
                                    {data.eventStatus &&
                                        data.eventStatus.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='eventStatus' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.eventStatus.filter(x => x.id === item)[0].name}
                                            </button>
                                        )
                                    }
                                    
                                    {data.eventStartDate && data.eventStartDate !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='eventStartDate' fieldvalue={data.eventStartDate} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.eventStartDate}
                                        </button>
                                        : <></>
                                    }
                                    {data.eventEndDate && data.eventEndDate !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='eventEndDate' fieldvalue={data.eventEndDate} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.eventEndDate}
                                        </button>
                                        : <></>
                                    }
                                    {data.org &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='org' fieldvalue={data.org} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.org}
                                        </button>
                                    }
                                    {data.country &&
                                        data.country.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='country' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.region &&
                                        data.region.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='region' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.municipality &&
                                        data.municipality.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='municipality' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.settlement &&
                                        data.settlement.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='settlement' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.street &&
                                        data.street.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='street' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                </div>
                                <div><button className="linkButton" onClick={this.clearFilter.bind(this)}>ფილტრის გაუქმება</button></div>
                            </div>

                        );
                    }
                }
                break;
            case "museum":
                if (this.props.data != null && this.props.data.id != null) {
                    if (sessionStorage.getItem('filterResult') !== ''
                        || (data.linkedObj.type && data.linkedObj.type !== '' && data.linkedObj.objID !== '')
                        || (data.fullText && data.fullText !== '')
                        || (sessionStorage.getItem('locationFilter') != null && sessionStorage.getItem('locationFilter') === 'on')
                    ) {
                        return (
                            <div className="filterProps">
                                <div>
                                    {data.linkedObj && data.linkedObj.type != null && data.linkedObj.type !== '' && data.linkedObj.objID !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='linkedObj' fieldvalue={data.linkedObj.type + ';' + data.linkedObj.objID} onClick={this.clearFilterFromData.bind(this)}>
                                            {this.getTypeText(data.linkedObj.type) + ' - ' + sessionStorage.getItem('objName')}
                                        </button>
                                        : <></>
                                    }
                                    {data.fullText && data.fullText !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='fullText' fieldvalue={data.fullText} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.fullText}
                                        </button>
                                        : <></>
                                    }
                                    {data.id && data.id !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='id' fieldvalue={data.id} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.id}
                                        </button>
                                        : <></>
                                    }
                                    {data.name &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='name' fieldvalue={data.name} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.name}
                                        </button>
                                    }
                                    {data.region &&
                                        data.region.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='region' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.municipality &&
                                        data.municipality.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='municipality' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.settlement &&
                                        data.settlement.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='settlement' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.street &&
                                        data.street.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='street' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {sessionStorage.getItem('locationFilter') != null && sessionStorage.getItem('locationFilter') === 'on' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='locationFilter' fieldvalue={''} onClick={this.clearFilterFromData.bind(this)}>
                                            {"ჩეთან ახლოს - " + (sessionStorage.getItem('locationRadius') == null ? 5 : sessionStorage.getItem('locationRadius')) + "კმ."}
                                        </button>
                                        : <></>
                                    }
                                </div>
                                <div><button className="linkButton" onClick={this.clearFilter.bind(this)}>ფილტრის გაუქმება</button></div>
                            </div>

                        );
                    }
                }
                break;
            case "zone":
                if (this.props.data != null && this.props.data.id != null) {
                    if (sessionStorage.getItem('filterResult') !== ''
                        || (data.linkedObj.type && data.linkedObj.type !== '' && data.linkedObj.objID !== '')
                        || (data.fullText && data.fullText !== '')) {
                        return (
                            <div className="filterProps">
                                <div>
                                    {data.linkedObj && data.linkedObj.type != null && data.linkedObj.type !== '' && data.linkedObj.objID !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='linkedObj' fieldvalue={data.linkedObj.type + ';' + data.linkedObj.objID} onClick={this.clearFilterFromData.bind(this)}>
                                            {this.getTypeText(data.linkedObj.type) + ' - ' + sessionStorage.getItem('objName')}
                                        </button>
                                        : <></>
                                    }
                                    {data.fullText && data.fullText !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='fullText' fieldvalue={data.fullText} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.fullText}
                                        </button>
                                        : <></>
                                    }
                                    {data.id && data.id !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='id' fieldvalue={data.id} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.id}
                                        </button>
                                        : <></>
                                    }
                                    {data.name &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='name' fieldvalue={data.name} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.name}
                                        </button>
                                    }
                                    {data.variety &&
                                        data.variety.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='variety' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.type.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.region &&
                                        data.region.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='region' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.municipality &&
                                        data.municipality.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='municipality' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                    {data.settlement &&
                                        data.settlement.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='settlement' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {item}
                                            </button>
                                        )
                                    }
                                </div>
                                <div><button className="linkButton" onClick={this.clearFilter.bind(this)}>ფილტრის გაუქმება</button></div>
                            </div>

                        );
                    }
                }
                break;
            case "wallart":
                if (this.props.data != null && this.props.data.id != null) {
                    if (sessionStorage.getItem('filterResult') !== ''
                        || (data.linkedObj.type && data.linkedObj.type !== '' && data.linkedObj.objID !== '')
                        || (data.fullText && data.fullText !== '')) {
                        return (
                            <div className="filterProps">
                                <div>
                                    {data.linkedObj && data.linkedObj.type != null && data.linkedObj.type !== '' && data.linkedObj.objID !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='linkedObj' fieldvalue={data.linkedObj.type + ';' + data.linkedObj.objID} onClick={this.clearFilterFromData.bind(this)}>
                                            {this.getTypeText(data.linkedObj.type) + ' - ' + sessionStorage.getItem('objName')}
                                        </button>
                                        : <></>
                                    }
                                    {data.fullText && data.fullText !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='fullText' fieldvalue={data.fullText} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.fullText}
                                        </button>
                                        : <></>
                                    }
                                    {data.id && data.id !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='id' fieldvalue={data.id} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.id}
                                        </button>
                                        : <></>
                                    }
                                    {data.name &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='name' fieldvalue={data.name} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.name}
                                        </button>
                                    }
                                    {data.period &&
                                        data.period.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='period' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.period)}
                                            </button>
                                        )
                                    }
                                    {data.araidentificirebuli && data.araidentificirebuli !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='araidentificirebuli' fieldvalue={data.araidentificirebuli} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.araidentificirebuli}
                                        </button>
                                        : <></>                                       
                                    }
                                    {data.iconografia &&
                                        data.iconografia.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='iconografia' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.iconografia.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.ciuriZalni &&
                                        data.ciuriZalni.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='ciuriZalni' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.ciuriZalni.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.diakvnebi &&
                                        data.diakvnebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='diakvnebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.diakvnebi.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.gamosaxuleba &&
                                        data.gamosaxuleba.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='gamosaxuleba' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.gamosaxuleba.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.mamebi &&
                                        data.mamebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='mamebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.mamebi.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.mociqulni &&
                                        data.mociqulni.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='mociqulni' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.mociqulni.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.saeroPirebi &&
                                        data.saeroPirebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='saeroPirebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.saeroPirebi)}
                                            </button>
                                        )
                                    }
                                    {data.scenebi &&
                                        data.scenebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='scenebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.scenebi)}
                                            </button>
                                        )
                                    }
                                    {data.sqemebi &&
                                        data.sqemebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='sqemebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.sqemebi.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.wmindanebi &&
                                        data.wmindanebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='wmindanebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.wmindanebi.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.winaswarmetyvelebi &&
                                        data.winaswarmetyvelebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='winaswarmetyvelebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.winaswarmetyvelebi.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.ornamenti &&
                                        data.ornamenti.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='ornamenti' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.ornamenti.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                </div>
                                <div><button className="linkButton" onClick={this.clearFilter.bind(this)}>ფილტრის გაუქმება</button></div>
                            </div>

                        );
                    }
                }
                break;
            case "stoneengrave":
                if (this.props.data != null && this.props.data.id != null) {
                    if (sessionStorage.getItem('filterResult') !== ''
                        || (data.linkedObj.type && data.linkedObj.type !== '' && data.linkedObj.objID !== '')
                        || (data.fullText && data.fullText !== '')) {
                        return (
                            <div className="filterProps">
                                <div>
                                    {data.linkedObj && data.linkedObj.type != null && data.linkedObj.type !== '' && data.linkedObj.objID !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='linkedObj' fieldvalue={data.linkedObj.type + ';' + data.linkedObj.objID} onClick={this.clearFilterFromData.bind(this)}>
                                            {this.getTypeText(data.linkedObj.type) + ' - ' + sessionStorage.getItem('objName')}
                                        </button>
                                        : <></>
                                    }
                                    {data.fullText && data.fullText !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='fullText' fieldvalue={data.fullText} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.fullText}
                                        </button>
                                        : <></>
                                    }
                                    {data.id && data.id !== 0 ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='id' fieldvalue={data.id} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.id}
                                        </button>
                                        : <></>
                                    }
                                    {data.name &&
                                        <button className='filterpropsButton icon-cancel' fieldtype='name' fieldvalue={data.name} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.name}
                                        </button>
                                    }
                                    {data.period &&
                                        data.period.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='period' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.period)}
                                            </button>
                                        )
                                    }
                                    {data.araidentificirebuli && data.araidentificirebuli !== '' ?
                                        <button className='filterpropsButton icon-cancel' fieldtype='araidentificirebuli' fieldvalue={data.araidentificirebuli} onClick={this.clearFilterFromData.bind(this)}>
                                            {data.araidentificirebuli}
                                        </button>
                                        : <></>
                                    }
                                    {data.geometrornamenti &&
                                        data.geometrornamenti.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='geometrornamenti' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.geometrornamenti.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.ciuriZalni &&
                                        data.ciuriZalni.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='ciuriZalni' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.ciuriZalni.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.cxovelebi &&
                                        data.cxovelebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='cxovelebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.cxovelebi.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.gamosaxuleba &&
                                        data.gamosaxuleba.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='gamosaxuleba' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.gamosaxuleba.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.mamebi &&
                                        data.mamebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='mamebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.mamebi.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.mociqulni &&
                                        data.mociqulni.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='mociqulni' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.mociqulni.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }

                                    {data.saeroPirebi &&
                                        data.saeroPirebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='saeroPirebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.saeroPirebi)}
                                            </button>
                                        )
                                    }
                                    {data.scenebi &&
                                        data.scenebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='scenebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.GetItemLabel(item, this.props.classificator.scenebi)}
                                            </button>
                                        )
                                    }
                                    {data.frinvelebi &&
                                        data.frinvelebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='frinvelebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.frinvelebi.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.wmindanebi &&
                                        data.wmindanebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='wmindanebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.wmindanebi.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.winaswarmetyvelebi &&
                                        data.winaswarmetyvelebi.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='winaswarmetyvelebi' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.winaswarmetyvelebi.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                    {data.ornamenti &&
                                        data.ornamenti.map((item, i) =>
                                            <button key={i} className='filterpropsButton icon-cancel' fieldtype='ornamenti' fieldvalue={item} onClick={this.clearFilterFromData.bind(this)}>
                                                {this.props.classificator.ornamenti.filter(x => x.id === item)[0].label}
                                            </button>
                                        )
                                    }
                                </div>
                                <div><button className="linkButton" onClick={this.clearFilter.bind(this)}>ფილტრის გაუქმება</button></div>
                            </div>

                        );
                    }
                }
                break;
            default:
                break;
        }
    }

    render() {


        return (

            <div>
                {
                    this.GetFilterData()
                }
            </div>

        );
    }
}

export { ClearSearch };