import { BehaviorSubject } from 'rxjs';

import { authHeader, handleResponse } from '../_helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    register,
    registerGancxadebisAdmini,
    registerShemvsebi,
    updateprofile,
    resetPassword,
    updatePassword,
    addobject,
    getObject,
    getUserAlert,
    addUserAlertobject,
    AddUserAlertMail,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
    };

    return fetch(`api/Users/Authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });

}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}

function register(user) {

    var fmData = new FormData();
    fmData.append('citizenship', user.citizenship);
    fmData.append('firstName', user.firstName);
    fmData.append('lastName', user.lastName);
    fmData.append('username', user.username);
    fmData.append('personalNum', user.personalNum);
    fmData.append('password', user.password);
    fmData.append('passwordrepeat', user.passwordrepeat);
    fmData.append('photo', user.photo);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
        },
        body: fmData
    };

    return fetch(`api/Users/Register`, requestOptions)
        .then(handleResponse)
        .then(message => {

            //localStorage.setItem('currentUser', JSON.stringify(user));
            //currentUserSubject.next(user);

            return message;
        });
}

function registerGancxadebisAdmini(object) {

    var fmData = new FormData();
    for (var i = 0; i < object.friends.length; i++) {
        if (object.friends[i] !== "") {
            fmData.append('friends[' + i + '].id', object.friends[i].id);
            fmData.append('friends[' + i + '].firstName', object.friends[i].firstName);
            fmData.append('friends[' + i + '].lastName', object.friends[i].lastName);
            fmData.append('friends[' + i + '].userName', object.friends[i].userName);
            fmData.append('friends[' + i + '].password', object.friends[i].password);
            fmData.append('friends[' + i + '].email', object.friends[i].email);
        }
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
        },
        body: fmData
    };

    return fetch(`api/Users/RegisterGancxadebisAdmini`, requestOptions)
        .then(handleResponse)
        .then(message => {

            //localStorage.setItem('currentUser', JSON.stringify(user));
            //currentUserSubject.next(user);

            return message;
        });
}
function registerShemvsebi(object) {

    var fmData = new FormData();
    for (var i = 0; i < object.friends.length; i++) {
        if (object.friends[i] !== "") {
            fmData.append('friends[' + i + '].id', object.friends[i].id);
            fmData.append('friends[' + i + '].firstName', object.friends[i].firstName);
            fmData.append('friends[' + i + '].lastName', object.friends[i].lastName);
            fmData.append('friends[' + i + '].userName', object.friends[i].userName);
            fmData.append('friends[' + i + '].password', object.friends[i].password);
            fmData.append('friends[' + i + '].email', object.friends[i].email);
            fmData.append('friends[' + i + '].personalNum', object.friends[i].personalNum);
            fmData.append('friends[' + i + '].phoneNumber', object.friends[i].phoneNumber);
        }
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
        },
        body: fmData
    };

    return fetch(`api/Users/registerShemvsebi`, requestOptions)
        .then(handleResponse)
        .then(message => {

            //localStorage.setItem('currentUser', JSON.stringify(user));
            //currentUserSubject.next(user);

            return message;
        });
}
function updateprofile(user) {

    var fmData = new FormData();
    fmData.append('password', user.password);
    fmData.append('passwordrepeat', user.passwordrepeat);
    fmData.append('photo', user.photo);
    fmData.append('photoChanged', user.photoChanged);

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),        
        body: fmData
    };

    return fetch(`api/Users/UpdateUser`, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.error === "") {
                alert('მონაცემები განახლებულია');
            }
            localStorage.setItem('currentUser', JSON.stringify(result.user));
            currentUserSubject.next(result.user);

            return result.error;
        });
}

function resetPassword(email) {
   
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(email)
    };

    return fetch(`api/Users/PasswordReset`, requestOptions)
        .then(handleResponse)
        .then(message => {
            return message;
        }).catch(function (message) {
            return "error:"+message;
        });
}

function updatePassword(email,code,data) {
    var fmData = new FormData();   
    fmData.append('password', data.password);
    fmData.append('passwordrepeat', data.passwordrepeat);
    
    const requestOptions = {
        method: 'POST',
        body: fmData
    };

    return fetch(`api/Users/UpdatePassword?email=${email}&code=${code}`, requestOptions)
        .then(handleResponse)
        .then(message => {
            alert('მონაცემები განახლებულია');
            return message;
        });
}

function addobject(object, x, y) {

    var fmData = new FormData();
    fmData.append('id', object.id);
    fmData.append('name', object.name);
    fmData.append('region', object.region);
    fmData.append('municipality', object.municipality);
    fmData.append('settlement', object.settlement);
    fmData.append('street', object.street);
    fmData.append('streetNum', object.streetNum);
    fmData.append('description', object.description);
    //fmData.append('photos', object.photos.filter(photo => photo !== ""));

    for (var i = 0; i < object.photos.length; i++) {
        if (object.photos[i] !== "") {
            fmData.append('photos', object.photos[i]);
        }
    }

    for ( i = 0; i < object.images.length; i++) {
        if (object.images[i] !== "") {
            fmData.append('images', object.images[i]);
        }
    }
    fmData.append('photo', object.photos[0]);
    fmData.append('x', x);
    fmData.append('y', y);

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: fmData
    };

   
    //const requestOptions = { method: 'GET', headers: authHeader() };
    //const response = await fetch(type, requestOptions);

    return fetch(`api/Users/AddObject`, requestOptions)
        .then(handleResponse)
        .then(message => {
            return message;
        });
}

function addUserAlertobject(object) {

    var fmData = new FormData();
    /*fmData.append('id', object.id);*/
    fmData.append('ObjectId', object.ObjectId);
    fmData.append('ObjectName', object.ObjectName);
    fmData.append('PhotoAddress', object.PhotoAddress);
    fmData.append('StatementReason', object.StatementReason);
    fmData.append('Comment', object.Comment);
    fmData.append('DangerDesc', object.DangerDesc);
    fmData.append('DamageLevel', object.DamageLevel);
    fmData.append('DamageType', object.DamageType);
    fmData.append('CommentCauseFactor', object.CommentCauseFactor);
    fmData.append('DamageReason', object.DamageReason);
    fmData.append('ExpectedDanger', object.ExpectedDanger);
    fmData.append('Recomendation', object.Recomendation);
    fmData.append('CommentDetaluri', object.CommentDetaluri);
    fmData.append('NameFirstLast', object.NameFirstLast);
    fmData.append('Email', object.Email);
    fmData.append('MobileNumber', object.MobileNumber);
    fmData.append('PersonalNum', object.PersonalNum);
    
    for (var i = 0; i < object.UserAlertDazianebaLoc.length; i++) {
        fmData.append('UserAlertDazianebaLoc', object.UserAlertDazianebaLoc[i]);
    }
    for (var i = 0; i < object.UserAlertZemoqmedebaGaremo.length; i++) {
        fmData.append('UserAlertZemoqmedebaGaremo', object.UserAlertZemoqmedebaGaremo[i]);
    }
    for (var i = 0; i < object.UserAlertZemoqmedebaAdamiani.length; i++) {
        fmData.append('UserAlertZemoqmedebaAdamiani', object.UserAlertZemoqmedebaAdamiani[i]);
    }
    for (var i = 0; i < object.UserAlertZemoqmedebaSxva.length; i++) {
        fmData.append('UserAlertZemoqmedebaSxva', object.UserAlertZemoqmedebaSxva[i]);
    }
    //fmData.append('photos', object.photos.filter(photo => photo !== ""));

    for (var i = 0; i < object.FotoDokumentacia.length; i++) {
        if (object.FotoDokumentacia[i] !== "") {
            fmData.append('FotoDokumentacia', object.FotoDokumentacia[i]);
            //fmData.append('FotoDokumentacia', object.FotoDokumentacia[i].File);
        }
    }

    //for (i = 0; i < object.images.length; i++) {
    //    if (object.images[i] !== "") {
    //        fmData.append('images', object.images[i]);
    //    }
    //}
    //fmData.append('photo', object.photos[0]);
    //fmData.append('x', x);
    //fmData.append('y', y);

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: fmData
    };


    //const requestOptions = { method: 'GET', headers: authHeader() };
    //const response = await fetch(type, requestOptions);

    return fetch(`api/UserAlert/AddUserAlertObject`, requestOptions)
        .then(handleResponse)
        .then(message => {
            return message;
        });
}

function AddUserAlertMail(object) {

    var fmData = new FormData();
    for (var i = 0; i < object.alertMails.length; i++) {
        if (object.alertMails[i] !== "") {
            fmData.append('alertMails[' + i + '].label', object.alertMails[i].label);
        }
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
        },
        body: fmData
    };

    return fetch(`api/Users/AddUserAlertMail`, requestOptions)
        .then(handleResponse)
        .then(message => {

            //localStorage.setItem('currentUser', JSON.stringify(user));
            //currentUserSubject.next(user);

            return message;
        });
}




function getObject(id) {
    var type = `api/Users/GetObject?id=${id}`;

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(type, requestOptions)
        .then(handleResponse)
        .then(object => {
            return object;
        });
}

function getUserAlert(id) {
    var type = `api/Users/GetUserAlert?id=${id}`;

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(type, requestOptions)
        .then(handleResponse)
        .then(object => {
            return object;
        });
}
