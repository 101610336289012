import React, { Component } from 'react';
import './Object.css';
import { Row, Col, Accordion, Card } from 'react-bootstrap'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { CustomToggle } from '../custom/CustomToggle'
import { ObjectListForLinks } from '../ObjectListForLinks';
import { EventObjectListForLinksTable } from '../EventObjectListForLinksTable';
import { FullDescModal } from './FullDescModal';
import PulseLoader from "react-spinners/PulseLoader";
import Avatar from 'react-avatar';
import { Footer } from '../Footer';
import ReactTooltip from "react-tooltip";

import { authHeader, handleResponse } from '../../_helpers';
import { authenticationService } from '../../_services';

import { AddToCollection } from '../AddToCollection';
import { Share } from '../Share';
export class DocumentObject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            isAdmin: false,
            favorite: false,
            document: {},
            loading: true
        };
    }
    componentDidMount() {
        var el = document.querySelector('.searchComponentButton');
        el.textContent = "დოკუმენტაცია";

        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x, isAdmin: x && x.role === "Admin"
        }));



        this.loadPage();
    }

    componentDidUpdate() {

        window.FB = null;
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/ka_GE/sdk.js#xfbml=1&autoLogAppEvents=1&version=v8.0&appId=1607074352807965";
            js.nonce = "q90Xb87m";

            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const id = parseInt(params.get('id'));
        if (id !== null) {
            var type = `api/Document/GetDocumentObject?id=${id}`;

            if (this.state.currentUser != null) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                const response = await fetch(type, requestOptions);
                const data = await response.json();
                this.setState({ document: data, loading: false, favorite: data.isFavorite });
            }
            else {

                const response = await fetch(type);
                const data = await response.json();
                this.setState({ document: data, loading: false, favorite: data.isFavorite });
            }

        }
    }

    toogleFullDesc() {
        if (document.getElementsByClassName('DescTextFull')[0].classList.contains('displayNone')) {
            document.getElementsByClassName('DescTextFull')[0].classList.remove('displayNone');
            document.getElementsByClassName('DescText')[0].classList.add('displayNone');
        }
        else {
            document.getElementsByClassName('DescTextFull')[0].classList.add('displayNone');
            document.getElementsByClassName('DescText')[0].classList.remove('displayNone');
        }

    }

    static document = DocumentObject.name;

    addToFavorite(id) {
        if (this.state.favorite === false) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            fetch(`api/Users/AddToFvorite?folderID=0&objectType=document&objectID=${id}`, requestOptions)
                .then(data => {
                    this.setState({ favorite: true });
                });
        }
        else {
            var result = window.confirm("გსურთ წაშლა?");
            if (result) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                return fetch(`api/Users/DeleteFromFvorite?objectType=document&objectID=${id}`, requestOptions)
                    .then(data => {
                        this.setState({ favorite: false });
                    });
            }
        }
    }
    hadleFavoriteStatus(value) {
        this.setState({ favorite: value });
    }

    download = () => {
        fetch(`api/document/Download?ids=${document.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
            function (myBlob) {
                myBlob.blob().then(
                    function (res) {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(res);
                        link.download = `documents.zip`;
                        link.click();
                    }
                );
            });


    }


    render() {
        const document = this.state.document;
        var str = document.docFile;
        var regex = /<iframe.*?src='(.*?)'/;
        var src = (regex.exec(str) != null && regex.exec(str).length) > 0 ? regex.exec(str)[1] : document.docFile;



        return (
            <div className="immObjDiv">
                {document && document.id > 0 ?
                    <div>
                        <div className="containerDiv">
                            <Row className="rowPadding">
                                <Col md={5}>
                                    <div style={{ height: '100%', maxHeight: '500px', minHeight: '250px' }}>
                                        {
                                            (document.docTypeID === 129 || document.docTypeID === 132) ?

                                                <iframe title="temp"
                                                    className={`${(document.docFile === '' || (document.docFile.indexOf('.jpg') < 0 && document.docFile.indexOf('.png') < 0)) ? 'ImageDivDocument' : 'ImageDivDocumentFull'}`}
                                                    src={src}
                                                    frameBorder='0'
                                                    allowFullScreen
                                                >
                                                </iframe>

                                                :
                                                document.isImage === true?
                                                    <img alt="" src={document.docFile} className={'ImageDivDocumentFull'}></img>
                                                    : <div className="ImageDivDocument"></div>
                                        }



                                    </div>
                                </Col>
                                <Col md={7}>

                                    <div style={{ display: "inline-flex" }}>
                                        <div className="ObjectType">დოკუმენტაცია1</div>
                                        <div className="ObjectDownload">
                                            <a style={{ marginRight: "10px" }} href={document.docFile} rel="noopener noreferrer" download="test.doc"  target="_blank"
                                                className={` ${(document.docFile === "" || document.docFile === null) ? "not-active" : ""}`} onClick={(document.docFile === "" || document.docFile === null) ? (event) => event.preventDefault() : null}>  <img width="16" style={{ marginTop: '-5px', marginRight: '15px' }}
                                                alt="" src="images/downloadRed.svg" />{' '}</a>
                                        </div>
                                    </div>
                                    <div className="ObjectMainHeader" style={{ marginBottom: '20px' }}>
                                        <span className="ObjectMainHeaderSpan">{document.objectName} - #{document.id}</span>
                                        <Share header="გაზიარება" customClass="objectShare" />
                                        {this.state.currentUser && this.state.isAdmin === false &&
                                            //<div style={{ float: 'right', cursor: 'pointer' }}><img width="20" alt="" src={this.state.favorite ? "/images/heartfill.svg" : "/images/heart.svg"} style={{ marginRight: '10px' }} onClick={() => this.addToFavorite(immovable.id)} />{' '}</div>
                                            <AddToCollection favorite={this.state.favorite} objID={document.id} setStatus={this.hadleFavoriteStatus.bind(this)}></AddToCollection>
                                        }
                                    </div>

                                    <div><span className="headerText">დოკუმენტის ტიპი: </span>{document.docType}</div>
                                    <div><span className="headerText">დოკუმენტის ნომერი: </span>{document.docNum}</div>
                                    <div><span className="headerText">ფაილის ტიპი/ფორმატი: </span>{document.docFormat}</div>
                                    <div><span className="headerText">ფაილის ზომა: </span>{document.docSize}</div>
                                    {document.docTypeID === 61 ? <div><span className="headerText">ანოტაცია: </span>{document.docAnotation}</div> : <></>}
                                    <div><span className="headerText">გვერდების რაოდენობა: </span>{document.pageCount}</div>

                                    <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '20px' }} />
                                    <div className="headerText">დოკუმენტთან დაკავშირებული კომენტარი/ანოტაცია</div>
                                    <div className="DescText">
                                        {document.comment}
                                        <div onClick={this.toogleFullDesc} className="toggleFullDesc">
                                            <svg className="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 01.708 0L8 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 01.708 0L8 8.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="DescTextFull displayNone">{document.commentFull}
                                        <div onClick={this.toogleFullDesc} style={{ textAlign: "center", padding: "20px" }}>
                                            <svg className="bi bi-chevron-double-up" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M7.646 2.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 3.707 2.354 9.354a.5.5 0 11-.708-.708l6-6z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M7.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div style={{ display: "inline-flex" }}>
                                        <FullDescModal className="FullDescModal" buttonDesc="დეტალური აღწერა" objname={document.objectName} disabled={(document.commentFull === '' || document.commentFull == null)}>
                                            <div className="ObjectHeader">მოკლე დახასიათება</div>
                                            {document.commentFull}
                                        </FullDescModal>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="containerDiv">
                            <div className="accardionPadding ObjectHeader">{document.linksDisplay ? 'დაკავშირებული კომპონენტები' : ''}</div>
                            {document.zoneLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1">ზოგადი დამცავი ზონა {document.zoneLinksCount}</CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={document.id} type="zone" objType="document" idList={document.zoneLinks} objName={document.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {document.immovableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">უძრავი ძეგლი/ობიექტი {document.immovableLinksCount} </span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={document.id} type="immovable" objType="document" idList={document.immovableLinks} objName={document.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {document.movableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მოძრავი ძეგლი/ობიექტი {document.movableLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={document.id} type="movable" objType="document" idList={document.movableLinks} objName={document.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {document.museumLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მუზეუმ-ნაკრძალი/მუზეუმი {document.museumLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={document.id} type="museum" objType="document" idList={document.museumLinks} objName={document.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {document.wallLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">კედლის მხატვრობა {document.wallLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={document.id} type="wallart" objType="document" idList={document.wallLinks} objName={document.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {document.stoneLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">ქვაზე კვეთილობა {document.stoneLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={document.id} type="stoneengrave" objType="document" idList={document.stoneLinks} objName={document.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {
                                document.eventLinkExist ?
                                    <Row className="accardionPadding">
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Card.Header className="TogglePanelHeader">
                                                    <CustomToggle eventKey="1"><span className="headerText">ქმედება {document.eventLinksCount}</span></CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body className="accardionBodyPadding">
                                                        {
                                                            <EventObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={document.id} type="event"
                                                                objType="document" idList={document.eventLinks} objName={document.objectName} />
                                                        }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Row>
                                    : <></>
                            }
                        </div>

                        <Footer>
                            <div className="containerDiv" style={{ paddingTop: '40px', paddingLeft: '20px' }}>
                                <Row >
                                    <Col md={5}>
                                        <div style={{ fontFamily: 'HelveticaBold', fontSize: '16px' }}>ინფორმაციის შემდგენელი</div>
                                        <div style={{ marginBottom: '20px' }}>თარიღი: <span className="headerText">{document.cardCreationDate}</span></div>
                                        <div style={{ display: 'inline-flex' }}>
                                            <Avatar title={document.cardCreator[0].name} src={document.cardCreator[0].photo} size={50} round="50px" />
                                            <span className="avatarText" data-tip data-for="allAuthors">{document.cardCreator.length > 1 ? `${document.cardCreator[0].name} \n\r(+ ${document.cardCreator.length - 1} ავტორი)`
                                                : document.cardCreator[0].name}</span>
                                        </div>
                                        {document.cardCreator.length > 1 ?
                                            <ReactTooltip className="customZIndex" id="allAuthors" effect='solid' backgroundColor="#FFFFFF" textColor="#000000" place="bottom" border={true} offset={{ top: 70, left: -35 }}>
                                                <span>
                                                    <ul className="periodList">{
                                                        document.cardCreator.map((item, i) => {
                                                            return <div style={{ marginTop: '5px' }} key={i}> <Avatar title={item.name} src={item.photo} size={30} round="30px" />
                                                                <span style={{ marginLeft: '10px' }}>{item.name}</span></div>
                                                                ;
                                                        })
                                                    }</ul>
                                                </span>
                                            </ReactTooltip>
                                            : <></>
                                        }
                                    </Col>
                                    <Col md={7}>
                                        <p style={{ borderBottom: '1px solid #ECECEC' }} className="facebookCOmmentP" />
                                        <div className="fb-comments" data-href={window.location.href} data-numposts="5" data-width="" data-mobile="true"></div>
                                    </Col>
                                </Row>
                            </div>
                        </Footer>
                    </div>
                    : <div style={{
                        marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center'
                    }}>
                        <PulseLoader
                            color={"#E57959"}
                            loading={true}
                        />
                    </div>

                }
            </div>
        );
    }

}