import React, { Component } from 'react';
import './Object.css';
import { Row, Col, Accordion, Card } from 'react-bootstrap'
import { WebMapView } from '../WebMapView';

import { CustomToggle } from '../custom/CustomToggle'
import { ObjectListForLinks } from '../ObjectListForLinks';

import { FullDescModal } from './FullDescModal';
import { DocObjectListForLinksTable } from '../DocObjectListForLinksTable';
import { EventObjectListForLinksTable } from '../EventObjectListForLinksTable';
import { Gallery } from './Gallery';
import PulseLoader from "react-spinners/PulseLoader";
import Avatar from 'react-avatar';
import { Footer } from '../Footer';
import ReactTooltip from "react-tooltip";

import { authHeader, handleResponse } from '../../_helpers';
import { authenticationService } from '../../_services';

import { AddToCollection } from '../AddToCollection';
import { Share } from '../Share';

export class WallArtObject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            isAdmin: false,
            favorite: false,
            wallart: {},
            objectID: null,
            objToZoom: null,
            fullScreeenIcon: "esri-icon-zoom-out-fixed",
            mapClass: '',
            maptype: '',
            loading: true
        };

        this.fullScreenMode = this.fullScreenMode.bind(this)
    }
    componentDidMount() {
        var el = document.querySelector('.searchComponentButton');
        el.textContent = "კედლის მხატვრობა";

        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x, isAdmin: x && x.role === "Admin"
        }));
        this.loadPage();
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const id = parseInt(params.get('id'));
        if (id !== null) {
            var type = `api/Wallart/GetWallArtObject?id=${id}`;
            
            if (this.state.currentUser != null) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                const response = await fetch(type, requestOptions);
                const data = await response.json();
                this.setState({ wallart: data, loading: false, favorite: data.isFavorite, maptype: data.curLocId !== "" ? data.curLocType : data.oldLocType,  objectID: data.curLocId });
            }
            else {
                const response = await fetch(type);
                const data = await response.json();
                this.setState({ wallart: data, loading: false, favorite: data.isFavorite, maptype: data.curLocId !== "" ? data.curLocType : data.oldLocType,  objectID: data.curLocId  });
            }
        }
    }

    toogleFullDesc() {
        if (document.getElementsByClassName('DescTextFull')[0].classList.contains('displayNone')) {
            document.getElementsByClassName('DescTextFull')[0].classList.remove('displayNone');
            document.getElementsByClassName('DescText')[0].classList.add('displayNone');
        }
        else {
            document.getElementsByClassName('DescTextFull')[0].classList.add('displayNone');
            document.getElementsByClassName('DescText')[0].classList.remove('displayNone');
        }

    }

    

    toogleMapActiveLink(val) {
        document.getElementsByClassName('mapLink')[0].classList.remove('active');
        document.getElementsByClassName('mapLink')[1].classList.remove('active');
        document.getElementsByClassName('mapLink')[val].classList.add('active');

        if (val === 0)
            this.setState({
                "maptype": this.state.wallart.curLocType, "mapObjectID": this.state.wallart.curLocId
            })
        else this.setState({
            "maptype": this.state.wallart.oldLocType, "mapObjectID": this.state.wallart.oldLocId
        })


        document.getElementsByClassName('mapCoord')[0].classList.add('displayNone');
        document.getElementsByClassName('mapCoord')[1].classList.add('displayNone');
        document.getElementsByClassName('mapCoord')[val].classList.remove('displayNone');
    }
    static displayName = WallArtObject.name;

    addToFavorite(id) {
        if (this.state.favorite === false) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            fetch(`api/Users/AddToFvorite?folderID=0&objectType=wallart&objectID=${id}`, requestOptions)
                .then(data => {
                    this.setState({ favorite: true });
                });
        }
        else {
            var result = window.confirm("გსურთ წაშლა?");
            if (result) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                return fetch(`api/Users/DeleteFromFvorite?objectType=wallart&objectID=${id}`, requestOptions)
                    .then(data => {
                        this.setState({ favorite: false });
                    });
            }
        }
    }
    hadleFavoriteStatus(value) {
        this.setState({ favorite: value });
    }

    fullScreenMode() {
        if (this.state.mapClass === '') {
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-in-fixed', mapClass: 'objectFullScreentMap', objectID: null, objToZoom: this.state.wallart.curLocId })
        }
        else {

            document.getElementById('toolbarDiv').classList.add('displayNone');
            document.getElementById('menuDiv').classList.add('displayNone')
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-out-fixed', mapClass: '', objectID: this.state.wallart.curLocId, objToZoom: null })
        }
    }

    render() {
        const wallart = this.state.wallart;

        window.FB = null;
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/ka_GE/sdk.js#xfbml=1&autoLogAppEvents=1&version=v8.0&appId=1607074352807965";
            js.nonce = "q90Xb87m";

            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        let images = [];
        if (wallart.photoIds != null) {
            wallart.photoIds.map(item =>
                images.push({
                    original: item,
                    thumbnail: item
                })
            );
        }
        else {
            images.push({
                original: '/wallart.svg',
                thumbnail: '/wallart.svg'
            });
        }



        return (
            <div className="immObjDiv">
                {wallart && wallart.id > 0 ?
                    <div>
                        <div className="containerDiv">
                            <Row className="rowPadding">
                            <Col md={5}>
                                <Gallery photo={wallart.photoIds} video={wallart.videoIds} vid3D={wallart._3dobjects} mainphoto={wallart.photoAddress} type="wallart" authors={wallart.docAuthors}/>
                                </Col>
                                <Col md={7}>
                                  
                                    <div className="ObjectType">კედლის მხატვრობა</div>
                                    <div className="ObjectMainHeader" style={{ marginBottom: '20px' }}>
                                        
                                        <span className="ObjectMainHeaderSpan">{wallart.objectName} - #{wallart.id}</span>
                                        <Share header="გაზიარება" customClass="objectShare" />
                                        {this.state.currentUser && this.state.isAdmin === false &&
                                            //<div style={{ float: 'right', cursor: 'pointer' }}><img width="20" alt="" src={this.state.favorite ? "/images/heartfill.svg" : "/images/heart.svg"} style={{ marginRight: '10px' }} onClick={() => this.addToFavorite(immovable.id)} />{' '}</div>
                                            <AddToCollection favorite={this.state.favorite} objID={wallart.id} setStatus={this.hadleFavoriteStatus.bind(this)}></AddToCollection>
                                        }
                                    </div>

                                    <div><span className="headerText">პერიოდი: </span>
                                        <ul className="periodList">
                                            {
                                                wallart.period.split(';').map((item, i) => {
                                                    return <li key={i}>{item}</li>;
                                                })
                                            }</ul></div>
                                    <div><span className="headerText">თარიღი: </span>{wallart.date}</div>

                                    <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '20px' }} />
                                    <div className="DescText">
                                        {wallart.description}
                                        <div onClick={this.toogleFullDesc} className="toggleFullDesc">
                                            <svg className="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 01.708 0L8 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 01.708 0L8 8.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="DescTextFull displayNone">{wallart.descriptionFull}
                                        <div onClick={this.toogleFullDesc} style={{ textAlign: "center", padding: "20px" }}>
                                            <svg className="bi bi-chevron-double-up" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M7.646 2.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 3.707 2.354 9.354a.5.5 0 11-.708-.708l6-6z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M7.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div style={{ display: "inline-flex" }}>
                                    <FullDescModal className="FullDescModal" buttonDesc="დეტალური აღწერა" objname={wallart.objectName} disabled={(document.descriptionFull === '')}>
                                        <div className="PopupHeader">მოკლე დახასიათება</div>
                                            {wallart.descriptionFull}
                                        </FullDescModal>
                                    </div>
                                </Col>
                        </Row>
                        <div className="ObjectHeaderNoTop" style={{ marginLeft: '35px', marginTop: '20px' }}>ადგილმდებარეობა</div>
                            <Row style={{ padding: '20px 20px 0 20px', width: '100%' }} className="ObjectMapRow">
                            <Col md={5}>
                                <div style={{ display: 'inline-flex', width: '100%'}}>
                                        <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                        <div>
                                            <div>ამჟამინდელი:</div>
                                            {wallart.country !== '' ? <div>ქვეყანა: <span className="headerText">{wallart.country}</span></div> : <></>}
                                            {wallart.region !== '' ? <div>რეგიონი: <span className="headerText">{wallart.region}</span></div> : <></>}
                                            {wallart.municipality !== '' ? <div>მუნიციპალიტეტი: <span className="headerText">{wallart.municipality}</span></div> : <></>}
                                            {wallart.settlement !== '' ? <div>დასახლებული პუნქტი: <span className="headerText">{wallart.settlement}</span></div> : <></>}
                                            {wallart.street !== '' ? <div>მისამართი: <span className="headerText">{wallart.street}</span></div> : <></>}
                                        </div>
                                    </div>

                                    {(wallart.oldcountry !== '' || wallart.oldregion !== '' || wallart.oldmunicipality !== '' || wallart.oldsettlement !== '' || wallart.oldstreet !== '') ?
                                        <div style={{ display: 'inline-flex', marginTop: '20px', width: '100%' }}>
                                            <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                            <div>
                                                <div>წარმომავლობა:</div>
                                                {wallart.oldcountry !== '' ? <div>ქვეყანა: <span className="headerText">{wallart.oldcountry}</span></div> : <></>}
                                                {wallart.oldregion !== '' ? <div>რეგიონი: <span className="headerText">{wallart.oldregion}</span></div> : <></>}
                                                {wallart.oldmunicipality !== '' ? <div>მუნიციპალიტეტი: <span className="headerText">{wallart.oldmunicipality}</span></div> : <></>}
                                                {wallart.oldsettlement !== '' ? <div>დასახლებული პუნქტი: <span className="headerText">{wallart.oldsettlement}</span></div> : <></>}
                                                {wallart.oldstreet !== '' ? <div>მისამართი: <span className="headerText">{wallart.oldstreet}</span></div> : <></>}
                                            </div>
                                        </div>
                                        : <></>
                                    }
                                </Col>
                            <Col md={7} style={{ minHeight: '300px' }} className="ObjectMap movableMap">
                                    <div style={{ display: "inline-block", margin: '0 10px 10px 0px' }}>
                                        <button className={wallart.curLocId !== "" ? 'mapLink active' : 'mapLink not-active'} onClick={() => this.toogleMapActiveLink(0)} disabled={(wallart.curLocId === "")}>ამჟამინდელი</button>
                                        <button className={wallart.curLocId !== "" ? 'mapLink' : wallart.oldLocId !== "" ? 'mapLink active' : 'mapLink not-active'} onClick={() => this.toogleMapActiveLink(1)} style={{ marginLeft: "20px" }} disabled={(wallart.oldLocId === "")}>წარმომავლობა</button>
                                    </div>
                                    <WebMapView type={this.state.maptype} objectID={this.state.objectID} objToZoom={this.state.objToZoom} right="30px" fullScreeenIcon={this.state.fullScreeenIcon} fullClick={this.fullScreenMode.bind(this)} mapClass={this.state.mapClass} />
                                </Col>
                            </Row>
                            <Row>
                            <Col md={5}>

                                </Col>
                            <Col md={7}>

                                    <Row className={wallart.curLocId !== "" ? 'mapCoord' : 'mapCoord displayNone'}>
                                        <Col md={5} style={{ marginBottom: '20px' }}>
                                            <div className="icon-waves">
                                                სიმაღლე ზღვის დონიდან: {wallart.elevetion}მ.
                                        </div>
                                        </Col>
                                        <Col md={6} style={{ marginBottom: '20px' }}>
                                            <div className="icon-direction-outline">კოორდინატები {wallart.sizuste === 'სწორია' ? <img style={{ width: '17px', height: '17px' }} alt="" src="/images/OK.svg" /> : <></>}
                                            </div>
                                            {wallart.sizuste === 'სწორია' ?
                                                <div>
                                                    <span className="headerText">X: </span>{wallart.x}
                                                    <span className="headerText"> Y: </span>{wallart.y}
                                                </div> :
                                                <div>
                                                    კოორდინატები დასაზუსტებელია
                                                </div>
                                            }
                                        </Col>
                                    </Row>

                                    <Row className={wallart.curLocId !== "" ? 'mapCoord displayNone' : 'mapCoord'}>
                                        <Col md={5} style={{ marginBottom: '20px' }}>
                                            <div className="icon-waves">
                                                სიმაღლე ზღვის დონიდან: {wallart.elevetionOld}მ.
                                        </div>
                                        </Col>
                                        <Col md={6} style={{ marginBottom: '20px' }}>
                                            <div className="icon-direction-outline">კოორდინატები {wallart.sizusteOld === 'სწორია' ? <img style={{ width: '17px', height: '17px' }} alt="" src="/images/OK.svg" /> : <></>}
                                            </div>
                                            {wallart.sizusteOld === 'სწორია' ?
                                                <div>
                                                    <span className="headerText">X: </span>{wallart.xOld}
                                                    <span className="headerText"> Y: </span>{wallart.yOld}
                                                </div> :
                                                <div>
                                                    კოორდინატები დასაზუსტებელია
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div className="containerDiv">
                            {wallart.shemadgeneliLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="1">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey="1" isOpen="true"><span className="headerText">კედლის მხატვრობის შემადგენელი მოხატულობები</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={wallart.id} type="wallartPainting" objType="wallart" curobjID="0" objName={wallart.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                    </div>
                    {
                        wallart.linksDisplay ? <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '80px' }} /> : <></>

                    }
                    <div className="containerDiv">
                        <div className="accardionPadding ObjectHeaderNoTop">{wallart.linksDisplay ? 'დაკავშირებული კომპონენტები' : ''}</div>

                            {wallart.immovableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">უძრავი ძეგლი/ობიექტი {wallart.immovableLinksCount} </span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={wallart.id} type="immovable" objType="wallart" idList={wallart.immovableLinks} objName={wallart.objectName}/>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {
                                wallart.eventLinkExist ?
                                    <Row className="accardionPadding">
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Card.Header className="TogglePanelHeader">
                                                    <CustomToggle eventKey="1"><span className="headerText">ქმედება {wallart.eventLinksCount}</span></CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body className="accardionBodyPadding">
                                                        {
                                                            <EventObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={wallart.id} type="event"
                                                                objType="wallart" idList={wallart.eventLinks} objName={wallart.objectName} />
                                                        }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Row>
                                    : <></>
                            }
                            {wallart.docLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">დოკუმენტაცია {wallart.docLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <DocObjectListForLinksTable caption={{ type: "დოკუმენტის ტიპი", docNum: "ავტორი" }} objID={wallart.id} type="document"
                                                        objType="wallart" idList={wallart.docLinks} objName={wallart.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        </div>

                        <Footer>
                        <div className="containerDiv" style={{ paddingTop: '40px', paddingLeft: '20px' }}>
                            <Row >
                                <Col md={5}>
                                    <div style={{ fontFamily: 'HelveticaBold', fontSize: '16px' }}>ინფორმაციის შემდგენელი</div>
                                    <div style={{ marginBottom: '20px' }}>თარიღი: <span className="headerText">{wallart.cardCreationDate}</span></div>
                                    <div style={{ display: 'inline-flex' }}>
                                        <Avatar title={wallart.cardCreator[0].name} src={wallart.cardCreator[0].photo} size={50} round="50px" />
                                        <span className="avatarText" data-tip data-for="allAuthors">{wallart.cardCreator.length > 1 ? `${wallart.cardCreator[0].name} \n\r(+ ${wallart.cardCreator.length - 1} ავტორი)`
                                            : wallart.cardCreator[0].name}</span>
                                    </div>
                                    {wallart.cardCreator.length > 1 ?
                                        <ReactTooltip className="customZIndex" id="allAuthors" effect='solid' backgroundColor="#FFFFFF" textColor="#000000" place="bottom" border={true} offset={{ top: 70, left: -35 }}>
                                            <span>
                                                <ul className="periodList">{
                                                    wallart.cardCreator.map((item, i) => {
                                                        return <div style={{ marginTop: '5px' }} key={i}> <Avatar title={item.name} src={item.photo} size={30} round="30px" />
                                                            <span style={{ marginLeft: '10px' }}>{item.name}</span></div>
                                                            ;
                                                    })
                                                }</ul>
                                            </span>
                                        </ReactTooltip>
                                        : <></>
                                    }
                                </Col>
                                    <Col md={7}>
                                        <p style={{ borderBottom: '1px solid #ECECEC' }} className="facebookCOmmentP" />
                                        <div className="fb-comments" data-href={window.location.href} data-numposts="5" data-width="" data-mobile="true"></div>
                                </Col>
                            </Row>
                        </div>
                        </Footer>
                    </div>
                     : <div style={{
                    marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center'
                }}>
                    <PulseLoader
                        color={"#E57959"}
                        loading={true}
                    />
                </div>
                }
            </div>
        );
    }

}