import React, { Component } from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import { authenticationService } from '../../_services';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { Row, Col, Modal, Card } from 'react-bootstrap';
import PropTypes from "prop-types";
import './MeilebisMartva.css';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { authHeader, handleResponse } from '../../_helpers';
const Schema = Yup.object().shape({
    friends: Yup.array()
        .of(
            Yup.object().shape({
                firstName: Yup.string().min(11, 'too short').required('Required'), // these constraints take precedence
                lastName: Yup.string().min(1, 'cmon').required('Required'), // these constraints take precedence
            })
        )
        .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
    //.min(3, 'Minimum of 3 friends'),
});
export class MeilebisMartvaModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            error: '',
            damxmareNags: [],
            sportTypes: [],
            show: false,
            objId: '7',
            UserAlertMail: [], //ბაზის ლუქაფი კონსტრუქციის ტიპი
        };
        //this.incrementCounter = this.incrementCounter.bind(this);
    }
    componentDidMount() {
        this.loadPage();
    }
    async loadPage() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        let initialUserAlertMail = [];
        fetch('api/Users/getUserAlertMail', requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {
                initialUserAlertMail = data;
                // console.log(initialSportTypes);
                this.setState({
                    UserAlertMail: initialUserAlertMail,
                });
            });
    }
    cancelDamxmareModal = () => {
        this.setState({ show: false })
    }
    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <Modal
                dialogClassName="modalAdminebisMartva"
                /*size="lg"*/
                show={true}
                backdrop="static"
                centered>
                <Modal.Header>
                    <Modal.Title>მეილების მართვა</Modal.Title>

                </Modal.Header>
                <Modal.Body>

                    <Formik
                        initialValues={{
                            Objectid: 0,
                            BuildingName: '',
                            Kons_Tipi: '',
                            alertMails: [""]
                        }}

                        // validationSchema={Schema}
                        onSubmit={({ alertMails }, { setStatus, setSubmitting, setFieldValue }) => {
                            setStatus();
                            authenticationService.AddUserAlertMail({ alertMails })
                                .then(
                                    user => {
                                        setStatus(user);
                                        setFieldValue('alertMails', [""])
                                        this.loadPage();
                                    },
                                    error => {
                                        setSubmitting(false);
                                        setStatus(error);
                                    }
                                );
                        }}
                    >

                        {({ values, errors, status, touched, isSubmitting, setFieldValue }) => {
                            useEffect(() => {
                                const id = this.props.ObjId;
                                if (id) {
                                    setFieldValue('ObjectId', id.objectId)
                                    setFieldValue('Dasaxeleba', id.dasaxeleba)
                                    setFieldValue('Danishnuleba', id.danishnuleba)
                                    setFieldValue('OtaxebisRaodenoba', id.otaxebisRaodenoba)
                                    setFieldValue('SasargebloFartobi', id.sasargebloFartobi)
                                    setFieldValue('SartulebisRaodenoba', id.sartulebisRaodenoba)
                                }


                            }, []);

                            return (

                                <Form>
                                    {status &&
                                        <div className={'alert alert-danger'}>{status}</div>
                                    }

                                    <div className="content">{this.props.children}</div>
                                    <Typeahead
                                        allowNew
                                        id="custom-selections-example"
                                        multiple
                                        newSelectionPrefix="დაამატეთ ახალი: "
                                        defaultSelected={this.state.UserAlertMail.slice(0, this.state.UserAlertMail.length)}
                                        options={[]}
                                        placeholder="ჩაწერეთ..."
                                        onChange={(selected) => {
                                            setFieldValue(`alertMails`, selected)
                                        }}
                                    />

                                    <button style={{ "float": "right" }} type="submit" className="toggle-button-martva"
                                    >
                                        შენახვა
                                        </button>
                                    <button style={{ "float": "right", "background": "white", "border": "none", "marginTop": '1%', "color": "#696D6F" }}
                                        data-value="cllose" type="button" className="toggle-button"
                                        onClick={this.props.cancelMeilebiModal}
                                    >
                                        დახურვა
                                        </button>


                                </Form>

                            );
                        }}



                    </Formik>
                </Modal.Body>
            </Modal >
        );
    }
}
MeilebisMartvaModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

export default MeilebisMartvaModal;