import React from 'react';

class ConfirmEmail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false
        }
    }
    componentDidMount() {
        this.load();
    }
    async load() {
        const params = new URLSearchParams(window.location.search);
        const userId = params.get('userId') || '';
        const code = params.get('code') || '';

        const response = await fetch(`api/Users/ConfirmEmail?userId=${userId}&code=${code}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        if (data === true) {
           //this.setState({ redirect: data });
            this.props.history.push('/login')

        }

    }
    render() {
        return (
            //this.state.redirect === true?
            
            //    <Redirect to='/login' />
            //    :

                    <div className="immListDiv" style={{
                        backgroundColor: 'white'
                    }}>

                        <div style={{
                            backgroundColor: 'white', width: '100%', height: '100%', padding: '30px', textAlign: 'center'
                        }}>



                        </div>
                    </div>

                    
        )
    }
}

export { ConfirmEmail }; 