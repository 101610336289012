import React, { Component } from 'react';
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
//import './Home.css';



const cardText = {
    width: 'auto'
}

export class ObjectListHorizontal extends Component {
    static displayName = ObjectListHorizontal.name;

    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true };

        this.GetObjectData(this.props.value, this.props.count);

    }

    static drawImovableList(forecasts, className) {
        return (
            <CardGroup className="horizontalCrdStyle">
                {forecasts.map(forecast =>
                    <a key={forecast.id} href={'../' + forecast.linkPath} className="mainCard">
                        <Card className="cardHorizonal">
                            <div className={`${forecast.photoAddress === '' ? className : 'imgDivObjListHorizontal'} cardImg`}>
                                {
                                    forecast.photoAddress == null || forecast.photoAddress === '' ?
                                        <></> :
                                        <img variant="top" src={forecast.photoAddress} alt="" style={{ width: '100%', height: '100%' }} />
                                }   

                                
                            </div>
                            <Card.Body>
                                <Card.Text style={cardText} className="card-link">
                                    {forecast.objectName} - #{forecast.id}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </a>
                )}
            </CardGroup>
        );
    }


    render() {

        return (

            this.state.loading
                ? <p><em>Loading...</em></p>
                : ObjectListHorizontal.drawImovableList(this.state.forecasts, this.props.imgClassName)

        );
    }

    async GetObjectData(value, count) {
        var type;
        switch (value) {
            case "immovable":
                type = 'api/Immovable/GetImmovables?count=' + count;
                break;
            case "movable":
                type = 'api/Movable/GetMovables?count=' + count;
                break;
            case "zone":
                type = 'api/SafetyZone/GetSafetyZone?count=' + count;
                break;
            case "wall":
                type = 'api/WallArt/GetWallArt?count=' + count;
                break;
            case "stone":
                type = 'api/StoneEngrave/GetStoneEngrave?count=' + count;
                break;
            case "document":
                type = 'api/Document/GetDocument?count=' + count;
                break;
            default: type = '';
                break;

        }

        const response = await fetch(type);
        const data = await response.json();
        this.setState({ forecasts: data, loading: false });

    }
}
