import React from 'react';
import Card from 'react-bootstrap/Card'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import ReactTooltip from "react-tooltip";


class DocObjectListForLinksTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pager: {},
            pageOfItems: [],
            loading: true,
            idsArray: ""

        };
        this.downloadJpg = this.downloadJpg.bind(this);
    }

    componentDidMount() {
        this.loadPage(1);
    }

    componentDidUpdate() {

        // this.loadPage(1);

    }
    
    async downloadJpg(ids) {
        fetch(`api/Immovable/Download?ids=${ids}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
            function (myBlob) {
                myBlob.blob().then(
                    function (res) {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(res);
                        const lastSlashIndex = link.href.lastIndexOf('/');
                        const lastPart = link.href.slice(lastSlashIndex + 1);
                        link.download = lastPart + `.jpg`;
                        //link.download = ids + `.jpg`;
                        link.click();
                    }
                );
            });


    }


    async loadPage(page) {
        var type;
        switch (this.props.type) {
            case "event":
                type = `api/Event/GetEventList?count=10&page=${page}`;
                break;
            case "document":
                type = `api/Document/GetDocumentList?count=10&page=${page}`;
                break;
            default: type = '';
                break;
        }


        if (page !== this.state.pager.currentPage) {
            const response = await fetch(type, {
                method: "POST",
                body: JSON.stringify(this.props.idList),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            this.setState({ pager: data, pageOfItems: data.aaData, loading: false });
        }
    }

    goToObject(link) {
        window.location = "../" + link;
    }

    drawImovableList(forecasts) {
        return (
            <Table className="docTable">
                <Tbody>
                    {
                        forecasts.map(forecast =>
                            <Tr key={forecast.id} className="mainCard">
                                <Td style={{ width: '35%', paddingBottom: '15px' }} className="colWidth">
                                    <a href={forecast.linkPath}>
                                        <Card key={forecast.id} className="cardHorizonalDocument">
                                           
                                            <div className={"imgDivDocument"}>
                                                {
                                                    forecast.photoAddress == null || forecast.photoAddress === '' ?
                                                        <></> :
                                                        <img alt="" variant="top" src={forecast.photoAddress} className="docImg" />
                                                }
                                            </div>
                                            <Card.Body className="doc_card-body">
                                                <div className="doc_card-text card-link">{forecast.objectName}</div>
                                                <div className="objectTypeList">{forecast.id}</div>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </Td>
                                <Td style={{ width: '40%' }} className="colWidth">
                                    <a href={forecast.linkPath}>
                                        <div className="docContent">
                                            <div style={{ width: '50%' }}>{forecast.variety}</div>
                                            <div style={{ width: '50%' }}>{forecast.period} </div>
                                        </div>
                                    </a>
                                </Td>

                                <Td style={{ width: '25%' }} className="colWidth">
                                    <a href={forecast.linkPath}>
                                        <div className="DocAuthorDiv"><div className='authorCaption'>ავტორი: </div><div style={{ textAlign: 'left' }} data-tip data-for={"sadFace_" + forecast.id}> {forecast.author}</div></div>
                                        {forecast.authorAll !== "" ?
                                            <ReactTooltip id={"sadFace_" + forecast.id} backgroundColor="#E57959" effect='solid'>
                                                <span>
                                                    <ul className="periodList">{
                                                        forecast.authorAll.split(';').map((item, i) => {
                                                            return <li key={i}>{item}</li>;
                                                        })
                                                    }</ul>
                                                </span>
                                            </ReactTooltip>
                                            : <></>
                                        }
                                    </a>
                                </Td>
                                <Td><img
                                    width="16"
                                    alt=""
                                    src="images/downloadRed.svg"
                                    onClick={() => this.downloadJpg(forecast.id)} // Pass forecast id to downloadJpg function
                                    style={{
                                        cursor: 'pointer',
                                        float: 'right',
                                        marginTop: '5px',
                                        marginRight: '15px'
                                    }}
                                /></Td>
                                
                            </Tr>
                        )
                    }

                </Tbody>
            </Table>

        );
    }

    goToObjLink(e) {
        //e.preventDefault();
        //var path = "";
        //switch (this.props.type) {
        //    case "immovable":
        //        path = "/objects/immovable";
        //        break;
        //    case "movable":
        //        path = "/objects/movable";
        //        break;
        //    case "zone":
        //        path = "/objects/zone";
        //        break;
        //    case "museum":
        //        path = "/objects/museum";
        //        break;
        //    case "wallart":
        //        path = "/objects/wallart";
        //        break;
        //    case "stoneengrave":
        //        path = "/objects/stoneengrave";
        //        break;
        //    case "event":
        //        path = "/objects/event";
        //        break;
        //    case "document":
        //        path = "/list/document";
        //        break;

        //    default: path = '';
        //        break;
        //}
        //sessionStorage.setItem('objType', this.props.objType);
        //sessionStorage.setItem('objID', this.props.objID);
        //sessionStorage.setItem('isLink', "true");
        //sessionStorage.setItem('filterResult', this.props.idList);
        //window.location.href = path;
    }

    render() {
        const { pager, pageOfItems } = this.state;
        return (
            <div className="card m-3">
                <div className="card-body">
                    {
                        this.state.loading
                            ? <p><em>Loading...</em></p>
                            : this.drawImovableList(pageOfItems)
                    }
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    {pager.pages && pager.pages.length > 0 &&
                        <a target="_blank" href={(this.props.type === "document" ? "/list/" : "/objects/") + this.props.type + "?objID=" + this.props.objID + "&type=" + this.props.objType + "&objName=" + this.props.objName} className="AllCompText" onClick={this.goToObjLink.bind(this)}>სრული სია ({pager.iTotalRecords.toLocaleString()})</a>
                    }
                </div>
            </div>
        );
    }
}

export { DocObjectListForLinksTable };