import React from 'react';
import { Modal, Accordion, Card } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import './Search.css';
import { CustomToggle } from '../custom/CustomToggle'
import { CustomTreeSelect } from '../custom/CustomTreeSelect';
import { CustomCreatableMultipleSelect } from '../custom/CustomCreatableMultipleSelect';
import Autosuggest from 'react-autosuggest';


const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = suggestion => (
    <div>
        {suggestion}
    </div>
);

export class WallDetailSearchModal extends React.Component {
    selectedPeriods = [];
   // selectedaraidentificirebuli = [];
    selectediconografia = [];
    selectedciuriZalni = [];
    selecteddiakvnebi = [];
    selectedgamosaxuleba = [];
    selectedmamebi = [];
    selectedmociqulni = [];
    selectedsaeroPirebi = [];
    selectedsqemebi = [];
    selectedscenebi = [];
    selectedwmindanebi = [];
    selectedwinaswarmetyvelebi = [];
    selectedornamenti = [];

    tempselectedPeriods = [];
    tempselectedsaeroPirebi = [];
    tempselectedscenebi = [];
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data:
            {
                id: '', name: '', period: [],
                araidentificirebuli: '',
                iconografia: [],
                ciuriZalni: [],
                diakvnebi: [],
                gamosaxuleba: [],
                mamebi: [],
                mociqulni: [],
                saeroPirebi: [],
                sqemebi: [],
                scenebi: [],
                wmindanebi: [],
                winaswarmetyvelebi: [],
                ornamenti: [],
                tempselectedPeriods: [],
                tempselectedsaeroPirebi: [],
                tempselectedscenebi: []
                , linkedObj: {}, fullText: ''

            },
            suggestions: []
        };

        this.handleClearClick = this.handleClearClick.bind(this);
    }


    handleClose = () => this.setState({ show: false });
    handleShow = () => {
        this.setState({ show: true })

        if (sessionStorage.getItem("filterData") != null && sessionStorage.getItem("filterData") !== '') {
            var data = JSON.parse(JSON.parse(sessionStorage.getItem("filterData")));
            this.tempselectedPeriods = data.tempselectedPeriods;
            this.tempselectedsaeroPirebi = data.tempselectedsaeroPirebi;
            this.tempselectedscenebi = data.tempselectedscenebi;

            this.selectedPeriods = data.period;
           // this.selectedaraidentificirebuli = data.araidentificirebuli;
            this.selectediconografia = data.iconografia;
            this.selectedciuriZalni = data.ciuriZalni;
            this.selecteddiakvnebi = data.diakvnebi;
            this.selectedgamosaxuleba = data.gamosaxuleba;
            this.selectedmamebi = data.mamebi;
            this.selectedmociqulni = data.mociqulni;
            this.selectedsaeroPirebi = data.saeroPirebi;
            this.selectedsqemebi = data.sqemebi;
            this.selectedscenebi = data.scenebi;
            this.selectedwmindanebi = data.wmindanebi;
            this.selectedwinaswarmetyvelebi = data.winaswarmetyvelebi;
            this.selectedornamenti = data.ornamenti;

            this.setState({ data: data })
        }

    };

    onChange(event) {
        var data = this.state.data;
        switch (event.target.name) {
            case "id":
                data.id = parseInt(event.target.value) || ''
                break;
            case "araidentificirebuli":
                data.araidentificirebuli = event.target.value
                break;
            default:
                break;
        }

        this.setState({ data: data });
    }
    handleSubmit(e) {
        e.preventDefault();
        this.prepareSubmit();
    }

    prepareSubmit() {

        var data = this.state.data;
        data.period = this.selectedPeriods;
      //  data.araidentificirebuli = this.selectedaraidentificirebuli;
        data.iconografia = this.selectediconografia;
        data.ciuriZalni = this.selectedciuriZalni;
        data.diakvnebi = this.selecteddiakvnebi;
        data.gamosaxuleba = this.selectedgamosaxuleba;
        data.mamebi = this.selectedmamebi;
        data.mociqulni = this.selectedmociqulni;
        data.saeroPirebi = this.selectedsaeroPirebi;
        data.sqemebi = this.selectedsqemebi;
        data.scenebi = this.selectedscenebi;
        data.wmindanebi = this.selectedwmindanebi;
        data.winaswarmetyvelebi = this.selectedwinaswarmetyvelebi;
        data.ornamenti = this.selectedornamenti;

        data.tempselectedPeriods = this.tempselectedPeriods;
        data.tempselectedsaeroPirebi = this.tempselectedsaeroPirebi;
        data.tempselectedscenebi = this.tempselectedscenebi;

        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));


        this.searchData(data);
    }
    checkData(data) {
        if (data.id !== 0 || data.name !== '' || data.period.length > 0
            || data.araidentificirebuli !== ''
            || data.iconografia.length > 0
            || data.ciuriZalni.length > 0
            || data.diakvnebi.length > 0
            || data.gamosaxuleba.length > 0
            || data.mamebi.length > 0
            || data.mociqulni.length > 0
            || data.saeroPirebi.length > 0
            || data.sqemebi.length > 0
            || data.scenebi.length > 0
            || data.wmindanebi.length > 0
            || data.winaswarmetyvelebi.length > 0
            || data.ornamenti.length > 0

        ) {
            return true;
        }
        return false
    }

    searchData(data) {
        if (data.id === '') data.id = 0;
        if (this.checkData(data)) {
            fetch('api/WallArt', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response) => {
                this.props.filterResulthandle(response);
                this.handleClose();
            })
        }
        else {
            this.props.filterResulthandle('');
            this.handleClose();
        }
    }
    handleClearData() {
        var data = {
            id: '', name: '', period: [],
            araidentificirebuli: '',
            iconografia: [],
            ciuriZalni: [],
            diakvnebi: [],
            gamosaxuleba: [],
            mamebi: [],
            mociqulni: [],
            saeroPirebi: [],
            sqemebi: [],
            scenebi: [],
            wmindanebi: [],
            winaswarmetyvelebi: [],
            ornamenti: [],
            tempselectedPeriods: [],
            tempselectedsaeroPirebi: [],
            tempselectedscenebi: [], linkedObj: {}, fullText: ''
        };
        this.setState({ data: data, show: false })
        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));
        this.props.filterResulthandle('');
    }

    loopChild(item, tempList) {
        tempList.push(item.value);
        if (item.tempchild != null && item.tempchild.length > 0) {
            item.tempchild.map(i => this.loopChild(i, tempList))
        }
    }


    changePeriodHandle(currentNode, selectedNodes) {
        this.selectedPeriods = [];
        this.tempselectedPeriods = [];

        if (selectedNodes != null) {
            selectedNodes.forEach(item => {
                this.selectedPeriods.push(item.value)
                this.loopChild(item, this.tempselectedPeriods)
            });
        }
    }

    //changeaidentificirebuliHandle(items) {
    //    var data = this.state.data;
    //    data.araidentificirebuli = [];
    //    if (items != null) {
    //        items.map(item =>
    //            data.araidentificirebuli.push(item.label)
    //        );
    //    }
    //    this.setState({ data: data });
    //}

    changeornamentiHandle(currentNode, selectedNodes) {
        this.selectedornamenti = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectedornamenti.push(item.value)
            );
        }
    }
    changeciuriZalniHandle(currentNode, selectedNodes) {
        this.selectedciuriZalni = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectedciuriZalni.push(item.value)
            );
        }
    }
    changediakvnebiHandle(currentNode, selectedNodes) {
        this.selecteddiakvnebi = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selecteddiakvnebi.push(item.value)
            );
        }
    }
    changegamosaxulebaHandle(currentNode, selectedNodes) {
        this.selectedgamosaxuleba = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectedgamosaxuleba.push(item.value)
            );
        }
    }

    changeiconografiaHandle(currentNode, selectedNodes) {
        this.selectediconografia = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectediconografia.push(item.value)
            );
        }
    }
    changemamebiHandle(currentNode, selectedNodes) {
        this.selectedmamebi = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectedmamebi.push(item.value)
            );
        }
    }
    changemociqulniHandle(currentNode, selectedNodes) {
        this.selectedmociqulni = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectedmociqulni.push(item.value)
            );
        }
    }
    changesaeroPirebiHandle(currentNode, selectedNodes) {
        this.selectedsaeroPirebi = [];
        this.tempselectedsaeroPirebi = [];

        if (selectedNodes != null) {
            selectedNodes.forEach(item => {
                this.selectedsaeroPirebi.push(item.value)
                this.loopChild(item, this.tempselectedsaeroPirebi)
            });
        }
    }
    changescenebiHandle(currentNode, selectedNodes) {
        this.selectedscenebi = [];
        this.tempselectedscenebi = [];

        if (selectedNodes != null) {
            selectedNodes.forEach(item => {
                this.selectedscenebi.push(item.value)
                this.loopChild(item, this.tempselectedscenebi)
            });
        }
    }

    changesqemebiHandle(currentNode, selectedNodes) {
        this.selectedsqemebi = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectedsqemebi.push(item.value)
            );
        }
    }
    changewinaswarmetyvelebiHandle(currentNode, selectedNodes) {
        this.selectedwinaswarmetyvelebi = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectedwinaswarmetyvelebi.push(item.value)
            );
        }
    }
    changewmindanebiHandle(currentNode, selectedNodes) {
        this.selectedwmindanebi = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectedwmindanebi.push(item.value)
            );
        }
    }
    onKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.prepareSubmit();
        }
    }
    onNameChange = (event, { newValue }) => {
        var data = this.state.data;
        data.name = newValue;
        this.setState({
            data: data
        });
    };
    handleClearClick() {
        var data = this.state.data;
        data.name = '';
        this.setState({
            data: data
        });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                suggestions: []
            }) :

            fetch('api/AutoComplete/Getrecord?value=' + value + "&component=wallart")
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        suggestions: data
                    })
                })
                .catch(error => console.error(error));
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };



    render() {
        const suggestions = this.state.suggestions;
        const value = this.state.data.name

        const inputProps = {
            value,
            onChange: this.onNameChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        let clearButton;
        if (value.length >= 1) {
            clearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleClearClick}
                />
            );
        }

        var repertuarOpen = (
            this.state.data.araidentificirebuli.length > 0
            || this.state.data.iconografia.length > 0
            || this.state.data.ciuriZalni.length > 0
            || this.state.data.diakvnebi.length > 0
            || this.state.data.gamosaxuleba.length > 0
            || this.state.data.mamebi.length > 0
            || this.state.data.mociqulni.length > 0
            || this.state.data.saeroPirebi.length > 0
            || this.state.data.sqemebi.length > 0
            || this.state.data.scenebi.length > 0
            || this.state.data.wmindanebi.length > 0
            || this.state.data.winaswarmetyvelebi.length > 0
            || this.state.data.ornamenti.length > 0);

        return (
            <>
                <div className="showModal">
                    <button onClick={this.handleShow} className="MainButton icon-equalizer" >{this.props.children}</button>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose} className="SearchDialog">
                    <Modal.Header closeButton className="searchCloseModal">
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit.bind(this)} method="POST" style={{ height: '100%', paddingTop: '0px' }}>
                            <div className="searchDivContent">
                                <div className="FullGalleryHeader">სარეგისტრაციო ნომერი</div>
                                <input type="number" className="form-control searchDivRowInput" name="id" id="id" value={this.state.data.id} onChange={this.onChange.bind(this)} autoComplete="off" />
                                <div className="FullGalleryHeader searchDivRow">სახელწოდება</div>
                                <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        renderSuggestion={renderSuggestion}
                                        getSuggestionValue={getSuggestionValue}
                                        inputProps={inputProps} />
                                    <div className="close-icon-container">
                                        {clearButton}
                                    </div>
                                </div>
                                <div className="FullGalleryHeader searchDivRow" >პერიოდი</div>
                                <CustomTreeSelect items={this.props.classificators.period} onChange={this.changePeriodHandle.bind(this)} selectedOption={this.state.data.period} />

                                <Accordion defaultActiveKey={repertuarOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                                    <Card className="modelCard">
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey="1" className="FullGalleryHeader" isOpen={repertuarOpen}>მოხატულობის რეპერტუარი</CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                {this.props.classificators.sqemebi && this.props.classificators.sqemebi.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >აბსიდალური სქემები</div>
                                                        <CustomTreeSelect items={this.props.classificators.sqemebi} onChange={this.changesqemebiHandle.bind(this)} selectedOption={this.state.data.sqemebi} />
                                                    </div>
                                                }
                                                {this.props.classificators.scenebi && this.props.classificators.scenebi.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >სცენები</div>
                                                        <CustomTreeSelect items={this.props.classificators.scenebi} onChange={this.changescenebiHandle.bind(this)} selectedOption={this.state.data.scenebi} />
                                                    </div>
                                                }
                                                {this.props.classificators.iconografia && this.props.classificators.iconografia.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >მაცხოვრის იკონოგრაფიული ტიპები</div>
                                                        <CustomTreeSelect items={this.props.classificators.iconografia} onChange={this.changeiconografiaHandle.bind(this)} selectedOption={this.state.data.iconografia} />
                                                    </div>
                                                }
                                                {this.props.classificators.ciuriZalni && this.props.classificators.ciuriZalni.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >ციური ძალნი</div>
                                                        <CustomTreeSelect items={this.props.classificators.ciuriZalni} onChange={this.changeciuriZalniHandle.bind(this)} selectedOption={this.state.data.ciuriZalni} />
                                                    </div>
                                                }
                                                {this.props.classificators.winaswarmetyvelebi && this.props.classificators.winaswarmetyvelebi.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >წინასწარმეტყველები</div>
                                                        <CustomTreeSelect items={this.props.classificators.winaswarmetyvelebi} onChange={this.changewinaswarmetyvelebiHandle.bind(this)} selectedOption={this.state.data.winaswarmetyvelebi} />
                                                    </div>
                                                }
                                                {this.props.classificators.mociqulni && this.props.classificators.mociqulni.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >მოციქულნი</div>
                                                        <CustomTreeSelect items={this.props.classificators.mociqulni} onChange={this.changemociqulniHandle.bind(this)} selectedOption={this.state.data.mociqulni} />
                                                    </div>
                                                }
                                                {this.props.classificators.mamebi && this.props.classificators.mamebi.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >ეკლესიის მამები</div>
                                                        <CustomTreeSelect items={this.props.classificators.mamebi} onChange={this.changemamebiHandle.bind(this)} selectedOption={this.state.data.mamebi} />
                                                    </div>
                                                }
                                                {this.props.classificators.diakvnebi && this.props.classificators.diakvnebi.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >დიაკვნები</div>
                                                        <CustomTreeSelect items={this.props.classificators.diakvnebi} onChange={this.changediakvnebiHandle.bind(this)} selectedOption={this.state.data.diakvnebi} />
                                                    </div>
                                                }
                                                {this.props.classificators.wmindanebi && this.props.classificators.wmindanebi.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >წმინდანები</div>
                                                        <CustomTreeSelect items={this.props.classificators.wmindanebi} onChange={this.changewmindanebiHandle.bind(this)} selectedOption={this.state.data.wmindanebi} />
                                                    </div>
                                                }
                                                {this.props.classificators.gamosaxuleba && this.props.classificators.gamosaxuleba.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >სიმბოლური გამოსახულებები</div>
                                                        <CustomTreeSelect items={this.props.classificators.gamosaxuleba} onChange={this.changegamosaxulebaHandle.bind(this)} selectedOption={this.state.data.gamosaxuleba} />
                                                    </div>
                                                }
                                                {this.props.classificators.saeroPirebi && this.props.classificators.saeroPirebi.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >საერო პირები</div>
                                                        <CustomTreeSelect items={this.props.classificators.saeroPirebi} onChange={this.changesaeroPirebiHandle.bind(this)} selectedOption={this.state.data.saeroPirebi} />
                                                    </div>
                                                }
                                                {this.props.classificators.ornamenti && this.props.classificators.ornamenti.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >ორნამენტები</div>
                                                        <CustomTreeSelect items={this.props.classificators.ornamenti} onChange={this.changeornamentiHandle.bind(this)} selectedOption={this.state.data.ornamenti} />
                                                    </div>
                                                }
                                                {
                                                    this.props.classificators.araidentificirebuli && this.props.classificators.araidentificirebuli.length > 0 &&
                                                    <div>
                                                        <div className="searchDivRow" >არაიდენტიფიცირებული</div>
                                                        <input type="text" className="form-control searchDivRowInput" name="araidentificirebuli" id="araidentificirebuli" value={this.state.data.araidentificirebuli} onChange={this.onChange.bind(this)} autoComplete="off" />

                                                    </div>
                                                }
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="searchDiv">
                                <div onClick={this.handleClearData.bind(this)} className="NoFillSearchButton">გაუქმება</div>
                                <button type="submit" className="MainButton">ძიება</button>
                            </div>
                        </Form>
                    </Modal.Body>

                </Modal>
            </>
        );
    }
}

