import React from 'react';
import './User.css';
import './Radio.css';
import { authenticationService } from '../_services';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import SiteRulesModal from '../components/SiteRulesModal';
function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            var el = document.getElementById('photoView');
            el.setAttribute('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
    }
}

const Schema = Yup.object().shape({
    firstName: Yup.string().required('შეიყვანეთ სახელი'),
    lastName: Yup.string().required('შეიყვანეთ გვარი'),
    username: Yup.string().required('შეიყვანეთ ელ.ფოსტა'),
    password: Yup.string().required('შეიყვანეთ პაროლი'),
    personalNum: Yup.string().required('შეიყვანეთ პირადი ნომერი').matches(/^[0-9]+$/, "უნდა იყოს მხოლოდ რიცხვები").min(11, 'მინიმუმ 11 სიმბოლო'),
    //passwordrepeat: Yup.string().when("password", {
    //    is: val => (val && val.length > 0 ? true : false),
    //    then: Yup.string().oneOf(
    //        [Yup.ref("password")],
    //        "პაროლი არ ემთხვევა"
    //    )
    //}),
    passwordrepeat: Yup.string().oneOf([Yup.ref('password')], 'პაროლი არ ემთხვევა').required('გაიმეორეთ პაროლი')
});

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showDamObj: true
        };

        // redirect to home if already logged in
        if (authenticationService.currentUserValue) {
            this.props.history.push('/');
        }
        this.cancelDamxmareModal = this.cancelDamxmareModal.bind(this)
    }
    showDamObjModal = (e, index) => {
        this.setState({
            showDamObj: !this.state.showDamObj
        })
    };
    async cancelDamxmareModal() {
        await this.setState({
            showDamObj: false
        })
    }
    render() {
        return (
            <div className="immListDiv" style={{
                backgroundColor: 'white'
            }}>

                <div style={{ backgroundColor: 'white', width: '100%', height: '100%', padding: '30px' }}>

                    <div style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="boldHeade" style={{ padding: '30px' }}>
                            სისტემაში რეგისტრაცია
                        </div>

                        <Formik
                            initialValues={{
                                photo: null,
                                citizenship: 'საქართველო',
                                firstName: '',
                                lastName: '',
                                username: '',
                                personalNum: '',
                                password: '',
                                passwordrepeat: ''
                            }}
                            validationSchema={Schema}
                            onSubmit={({ photo, citizenship, firstName, lastName, username, personalNum, password, passwordrepeat }, { setStatus, setSubmitting }) => {
                                setStatus();
                                authenticationService.register({ photo, citizenship, firstName, lastName, username, personalNum, password, passwordrepeat })
                                    .then(
                                        user => {
                                            const { from } = this.props.location.state || { from: { pathname: "/Verification" } };
                                            this.props.history.push(from);
                                        },
                                        error => {
                                            setSubmitting(false);
                                            setStatus(error);
                                        }
                                    );
                            }}
                            render={({ values, errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form >
                                    {status &&
                                        <div className={'alert alert-danger'}>{status}</div>
                                    }
                                    <SiteRulesModal
                                        onClose={this.showDamObjModal}
                                        show={this.state.showDamObj}
                                        cancelDamxmareModal={this.cancelDamxmareModal}
                                    >
                                    </SiteRulesModal>
                                    <div className="form-group" style={{ textAlign: "center" }}>
                                        <div style={{ display: "inline-flex" }}>
                                            <div className="userImageDiv" onClick={() => {
                                                if (values.photo == null || values.photo === "") {
                                                    document.getElementById("photo").click()
                                                }
                                            }}>
                                                <img alt="" src={values.photo} style={{ width: '100%', height: values.photo != null && values.photo !== "" ? '100%' : '0' }} id="photoView" />{' '}

                                                <input id="photo" name="photo" type="file" accept="image/*" style={{ display: 'none' }}
                                                    onChange={(event) => {
                                                        setFieldValue("photo", event.currentTarget.files[0]);
                                                        readURL(event.currentTarget);
                                                    }}
                                                />
                                            </div>
                                            <div id="addUser" className={values.photo != null && values.photo !== "" ? "userremoveImage" : "useraddImage"}
                                                onClick={() => {
                                                    if (values.photo != null && values.photo !== "") {
                                                        setFieldValue("photo", null);
                                                    }
                                                    else {
                                                        document.getElementById("photo").click()
                                                    }


                                                }}></div>
                                        </div>
                                    </div>
                                    <div className="form-group" style={{ padding: '0.375rem 0.75rem' }}>მოქალაქეობა</div>

                                    <Field component="div" name="citizenship" className="form-group" style={{ display: "flex", padding: '0.375rem 0.75rem' }}>
                                        <label className="container">საქართველო
                                                 <input
                                                type="radio"
                                                id="citizenshipGeo"
                                                defaultChecked={true}
                                                name="citizenship"
                                                value="საქართველო"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="container">სხვა
                                                <input
                                                type="radio"
                                                id="citizenshipOther"
                                                name="citizenship"
                                                value="სხვა"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </Field>

                                    <div className="form-group" style={{ display: "flex", flexWrap: 'wrap' }}>
                                        <div style={{ width: '50%' }}>
                                            <Field autoComplete="off" name="firstName" type="text" className={'form-control contactText' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} placeholder="სახელი" />
                                            <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <Field autoComplete="off" name="lastName" type="text" className={'form-control contactText' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} placeholder="გვარი" />
                                            <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <Field autoComplete="new-email" name="username" type="email" className={'form-control contactText' + (errors.username && touched.username ? ' is-invalid' : '')} placeholder="ელ.ფოსტა" />
                                        <ErrorMessage name="username" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <Field autoComplete="off" name="personalNum" type="text" className={'form-control contactText' + (errors.personalNum && touched.personalNum ? ' is-invalid' : '')} placeholder="პირადი ნომერი" />
                                        <ErrorMessage name="personalNum" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <Field autoComplete="new-password" name="password" type="password" className={'form-control contactText' + (errors.password && touched.password ? ' is-invalid' : '')} placeholder="პაროლი" />
                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <Field autoComplete="new-password" name="passwordrepeat" type="password" className={'form-control contactText' + (errors.passwordrepeat && touched.passwordrepeat ? ' is-invalid' : '')} placeholder="გაიმეორეთ პაროლი" />
                                        <ErrorMessage name="passwordrepeat" component="div" className="invalid-feedback" />
                                    </div>
                                    <div style={{ padding: '0.375rem 0.75rem' }}>
                                        <button type="submit" className="MainButton" disabled={isSubmitting}>რეგისტრაცია</button>
                                        {isSubmitting &&
                                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        }
                                    </div>

                                </Form>
                            )}
                        />
                    </div>

                </div>
            </div>

        )
    }
}

export { RegisterPage };