import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';

import { AboutSite } from './components/AboutSite';
import { AboutUs } from './components/AboutUs';
import { SiteRules } from './components/SiteRules';
import { Contact } from './components/Contact';
import { Saxelmdzgvanelo } from './components/User/Saxelmdzgvanelo';
import { testName } from './components/User/testName';

import './custom.css'
import './components/Home.css';
import './components/Card.css';
import './components/Main.css';
import { ImmovableList } from './components/ImmovableList';

import { DocumentList } from './components/DocumentList';

import { ImmovableObject } from './components/Immovable/ImmovableObject';
import { MovableObject } from './components/Immovable/MovableObject';
import { WallArtObject } from './components/Immovable/WallArtObject';
import { WallArtPainting } from './components/Immovable/WallArtPainting';
import { StoneEngraveObject } from './components/Immovable/StoneEngraveObject';
import { StoneEngraveMembership } from './components/Immovable/StoneEngraveMembership';
import { ZoneObject } from './components/Immovable/ZoneObject';
import { MuseumObject } from './components/Immovable/MuseumObject';
import { DocumentObject } from './components/Immovable/DocumentObject';
import { EventObject } from './components/Immovable/EventObject';

import { CheckEmail } from './LoginPage/CheckEmail';
import { Verification } from './LoginPage/Verification';
import { LoginPage } from './LoginPage/LoginPage';
import { RegisterPage } from './LoginPage/RegisterPage';
import { RecoverPassword } from './LoginPage/RecoverPassword';
import { ResetPassword } from './LoginPage/ResetPassword';
import { UserProfile } from './components/User/UserProfile';
import { AddObject } from './components/User/AddObject';
import { ViewObject } from './components/User/ViewObject';
import { UserList } from './components/User/UserList';
import { ConfirmEmail } from './LoginPage/ConfirmEmail';

//import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

//import { useClearCache } from "react-clear-cache";



//function ClearHistory() {
//    const { isLatestVersion, latestVersion, emptyCacheStorage } = useClearCache();

//    console.log(latestVersion)
//    if (!isLatestVersion) {
//        emptyCacheStorage();
//        localStorage.removeItem('APP_VERSION') 
//        window.location.reload()
//      //  localStorage.setItem('APP_VERSION', latestVersion);
//    }

//    return (
//        <></>

//    );
//}

export default class App extends Component {
    static displayName = App.name;



    render() {

        return (
            <Layout>
                {
                    // <Route exact path='/' component={Home} />
                    // <AuthorizeRoute exact path='/' component={Home} />
                    // <ClearHistory></ClearHistory>

                }

                <Route exact path='/login' component={LoginPage} />
                <Route exact path='/register' component={RegisterPage} />
                <Route exact path='/ResetPassword' component={ResetPassword} />
                <Route exact path='/Verification' component={Verification} />
                <Route exact path='/Confirmation' component={CheckEmail} />


                <Route exact path='/ConfirmEmail' component={ConfirmEmail} />

                <Route exact path='/recovery' component={RecoverPassword} />
                <Route exact path='/userprofile/:type' component={UserProfile} />
                <Route exact path='/addobject' component={AddObject} />
                <Route exact path='/viewobject' component={ViewObject} />

                <Route exact path='/AdminUsers' component={UserList} />

                <Route exact path='/' component={Home} />
                <Route exact path='/about' component={AboutSite} />
                <Route exact path='/aboutus' component={AboutUs} />
                <Route exact path='/siterules' component={SiteRules} />
                <Route exact path='/contact' component={Contact} />
                <Route exact path='/manual' component={Saxelmdzgvanelo} />

                <Route exact path='/test' component={testName} />
                

                <Route exact path='/objects/:type' component={ImmovableList} />
                <Route exact path='/objects/immovable/immovableObject' component={ImmovableObject} />
                <Route exact path='/list/document' component={DocumentList} />

                <Route exact path='/objects/movable/movableObject' component={MovableObject} />

                <Route exact path='/objects/wallart/wallartObject' component={WallArtObject} />
                <Route exact path='/objects/wallart/wallartPaintingObject' component={WallArtPainting} />
                <Route exact path='/objects/stoneengrave/stoneengraveObject' component={StoneEngraveObject} />
                <Route exact path='/objects/stoneengrave/stoneengraveMembershipObject' component={StoneEngraveMembership} />
                <Route exact path='/objects/zone/zoneobject' component={ZoneObject} />
                <Route exact path='/objects/museum/museumObject' component={MuseumObject} />
                <Route exact path='/list/document/documentObject' component={DocumentObject} />
                <Route exact path='/objects/event/eventObject' component={EventObject} />


                {

                    //<AuthorizeRoute exact path='/' component={Home} />
                    //<AuthorizeRoute exact path='/about' component={AboutSite} />
                    //<AuthorizeRoute exact path='/aboutus' component={AboutUs} />
                    //<AuthorizeRoute exact path='/contact' component={Contact} />

                    //<AuthorizeRoute exact path='/objects/:type' component={ImmovableList} />
                    //<AuthorizeRoute exact path='/objects/immovable/immovableObject' component={ImmovableObject} />
                    //<AuthorizeRoute exact path='/list/document' component={DocumentList} />

                    //<AuthorizeRoute exact path='/objects/movable/movableObject' component={MovableObject} />
                    //<AuthorizeRoute exact path='/objects/wallart/wallartObject' component={WallArtObject} />
                    //<AuthorizeRoute exact path='/objects/wallart/wallartPaintingObject' component={WallArtPainting} />
                    //<AuthorizeRoute exact path='/objects/stoneengrave/stoneengraveObject' component={StoneEngraveObject} />
                    //<AuthorizeRoute exact path='/objects/stoneengrave/stoneengraveMembershipObject' component={StoneEngraveMembership} />
                    //<AuthorizeRoute exact path='/objects/zone/zoneobject' component={ZoneObject} />
                    //<AuthorizeRoute exact path='/objects/museum/museumObject' component={MuseumObject} />
                    //<AuthorizeRoute exact path='/list/document/documentObject' component={DocumentObject} />
                    //<AuthorizeRoute exact path='/objects/event/eventObject' component={EventObject} />

                }
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />

            </Layout>
        );
    }
}
