import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table'
//import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Card from 'react-bootstrap/Card'
import '../CheckBox.css';
import { SearchTemplate } from '../../components/SearchTemplate';
import ReactTooltip from "react-tooltip";
import { Footer } from '../Footer';
import { authHeader, handleResponse } from '../../_helpers';
import PulseLoader from "react-spinners/PulseLoader";
import AdminebisMartvaModal from './AdminebisMartvaModal';
import ShemvsebebisMartvaModal from './ShemvsebebisMartvaModal';
import MeilebisMartvaModal from './MeilebisMartvaModal';
import { UserListDetailSearchModal } from './UserListDetailSearchModal';
class UserListVIew extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pager: {},
            pageOfItems: [],
            loading: true,
            checkedList: [],
            reload: false,
            showDamObj: false,
            showMail: false,
            damxmareNagebobaID: '',
            elmntIndex: '',
            newSearch: false,
            type: this.props.type
        };
        this.cancelDamxmareModal = this.cancelDamxmareModal.bind(this)
        this.cancelMeilebiModal = this.cancelMeilebiModal.bind(this)
        this.cancelShemvsebiModal = this.cancelShemvsebiModal.bind(this)
        this.GetSearch = this.GetSearch.bind(this); //dziebistvis
        this.filterResulthandle = this.filterResulthandle.bind(this);
        this.detailSearch = React.createRef();

    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if (sessionStorage.getItem('sessionType') !== "userList") {
            sessionStorage.removeItem('filterData');
            sessionStorage.setItem('sessionType', "userList");
        }
        this.loadPage();
    }


    componentDidUpdate() {
        this.loadPage();
    }


    async loadPage() {

        const params = new URLSearchParams(window.location.search);
        const page = parseInt(params.get('page')) || 1;
        //var res = sessionStorage.getItem('filterResult') || '';
        var res = JSON.parse(JSON.parse(sessionStorage.getItem("filterData")));
        var searchMail = '', searchName= '';
        if (res != null) {
            searchMail = res.email;
            searchName = res.name;
        }
        

        var type = `api/Users/GetUserList?Email=${searchMail}&Name=${searchName}&count=10&page=${page}`;
        //var type = `api/Users/GetUserList?count=10&page=${page}`;

        if (page !== this.state.pager.currentPage || this.state.reload === true || this.state.newSearch === true) {

            const requestOptions = { method: 'GET', headers: authHeader() };
            //const requestOptions = {
            //    method: 'GET',
            //    headers: authHeader(),
            //    //headers: {
            //    //    'Accept': 'application/json',
            //    //    'Content-Type': 'application/json'
            //    //},
            //    //body: JSON.stringify(res)
            //};
            const response = await fetch(type, requestOptions);


            const data = await response.json();
            console.log(data);
            this.setState({ pager: data, pageOfItems: data.aaData, newSearch: false,loading: false, reload: false });
        }
    }

    handleChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { pageOfItems, allChecked } = prevState;
            if (itemName === "checkAll") {
                allChecked = checked;
                pageOfItems = pageOfItems.map(item => ({ ...item, isChecked: checked }));
            } else {
                pageOfItems = pageOfItems.map(item =>
                    `${item.period}` === itemName ? { ...item, isChecked: checked } : item
                );
                allChecked = pageOfItems.every(item => item.isChecked);
            }
            return { pageOfItems, allChecked };
        });
    };


    drawImovableList(forecasts, className) {

        return (
            <Table className="docTable">
                <Tbody>
                    {
                        forecasts.map(forecast =>
                            <Tr key={forecast.period} className="mainCard">
                                <Td style={{ width: '25%', paddingBottom: '15px' }} className="colWidth">
                                    <a href={"/objects/userObject?id=" + forecast.period}>
                                        <Card key={forecast.period} className="cardHorizonalDocument">
                                            <div className="checkbox checkbox-success checkboxlist checkboxlistTable checkInCard"
                                                style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                                <input
                                                    id={"check_" + forecast.period}
                                                    type="checkbox"
                                                    name={forecast.period}
                                                    value={forecast.period}
                                                    checked={forecast.isChecked}
                                                    onChange={this.handleChange}
                                                />
                                                <label htmlFor={"check_" + forecast.period}></label>
                                            </div>
                                            <div className={className}>
                                                {
                                                    forecast.photoAddress == null || forecast.photoAddress === '' ?
                                                        <></> :
                                                        <img alt="" variant="top" src={forecast.photoAddress} className="docImg" />
                                                }

                                            </div>
                                            <Card.Body className="doc_card-body">
                                                <div className="doc_card-text card-link">{forecast.objectName}</div>
                                                <div className="doc_card-text card-link">({forecast.mapID} ობიექტი)</div>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </Td>
                                <Td style={{ width: '12%' }} className="colWidth">
                                    <div>{forecast.statementCount} განცხადება</div>
                                </Td>
                                {/*<Td style={{ width: '12%' }} className="colWidth">*/}
                                {/*    <div>{forecast.mapType}</div>*/}
                                {/*</Td>*/}
                                <Td style={{ width: '12%' }} className="colWidth">
                                    <div>{forecast.variety}</div>
                                </Td>
                                <Td style={{ width: '12%' }} className="colWidth">
                                    <div>{forecast.address}</div>
                                </Td>
                                <Td style={{ width: '12%' }} className="colWidth">
                                    <div >{forecast.linkPath}</div>
                                </Td>
                                <Td style={{ width: '12%' }} className="colWidth">
                                    <div >{forecast.objType}</div>
                                </Td>
                                <Td style={{ width: '12%' }} className="colWidth">
                                    <div>{forecast.author}</div>
                                </Td>
                                <Td>
                                    <div className="checkbox checkbox-success checkboxlist checkboxlistTable checkInTable"
                                        style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                        <input
                                            id={"check_" + forecast.period}
                                            type="checkbox"
                                            name={forecast.period}
                                            value={forecast.period}
                                            checked={forecast.isChecked}
                                            onChange={this.handleChange}
                                        />
                                        <label htmlFor={"check_" + forecast.period}></label>
                                    </div>
                                </Td>
                            </Tr>
                        )
                    }

                </Tbody>
            </Table>

        );
    }





    updatedataState(data) {
        this.setState({ data: data });
    }

    delete = () => {
        var idsArray = this.state.pageOfItems.map(function (v) {
            return v.isChecked ? v.period : '';
        });
        var ids = idsArray.filter(item => item !== '').join(',');

        var result = window.confirm("გსურთ წაშლა?");
        if (result) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            return fetch(`api/Users/DeleteUser?ids=${ids}`, requestOptions)
                .then(data => {
                    this.setState({ reload: true });
                    this.loadPage();
                });
        }

    }

    block = () => {
        var idsArray = this.state.pageOfItems.map(function (v) {
            return v.isChecked ? v.period : '';
        });
        var ids = idsArray.filter(item => item !== '').join(',');

        var result = window.confirm("გსურთ დაბლოკვა ?");
        if (result) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            return fetch(`api/Users/BlockUser?ids=${ids}`, requestOptions)
                .then(data => {
                    this.setState({ reload: true });
                    this.loadPage();
                });
        }

    }
    showDamObjModal = (e, index) => {
        this.setState({
            showDamObj: !this.state.showDamObj
        })
    };
    showShemvsebiModal = (e, index) => {
        this.setState({
            showShemvsebi: !this.state.showShemvsebi
        })
    };
    showMeilebiModal = (e, index) => {
        this.setState({
            showMail: !this.state.showMail
        })
    };
    async cancelDamxmareModal() {
        await this.setState({
            showDamObj: false
        })
        this.setState({ reload: true });
        this.loadPage();
    }
    async cancelShemvsebiModal() {
        await this.setState({
            showShemvsebi: false
        })
        this.setState({ reload: true });
        this.loadPage();
    }
    async cancelMeilebiModal() {
        await this.setState({
            showMail: false
        })
    }
    //dziebistvis
    filterResulthandle(result) {
        window.history.pushState({}, document.title, window.location.pathname)
        sessionStorage.setItem('filterResult', result);
        this.setState({
            newSearch: true, loading: true
        });
        //this.loadPage();
    }
    GetSearch(isMob) {
        switch (this.state.type) {
            case "userList":
                if (isMob === 1) return <UserListDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} />;
                return <UserListDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} >
                    <span className="FilterButtonText">ფილტრი</span></UserListDetailSearchModal>;
            default:
                return "";
        }
    }

    render() {
        const { pager, pageOfItems } = this.state;
        return (
            <div className="card text-center containerDiv">

                <div className="ButtonsMenu">

                    <div className="TotalButtons">
                        <button className="SecondaryButtonMartva" style={{ marginRight: "10px" }} onClick={e => {
                            this.showDamObjModal(e);
                        }}> <img style={{ "marginRight": "12px", "width": "11px" }} src="images/vectoradminebi.png"></img>ადმინების მართვა</button>
                        <AdminebisMartvaModal
                            ObjId={this.state.damxmareNagebobaID}
                            index={this.state.elmntIndex}
                            onClose={this.showDamObjModal}
                            show={this.state.showDamObj}
                            cancelDamxmareModal={this.cancelDamxmareModal}
                        >
                        </AdminebisMartvaModal>
                        <button className="SecondaryButtonMartva" style={{ marginRight: "10px" }} onClick={e => {
                            this.showShemvsebiModal(e);
                        }}> <img style={{ "marginRight": "12px", "width": "11px" }} src="images/vectoradminebi.png"></img>ფორმის შემვსებების მართვა</button>
                        <ShemvsebebisMartvaModal
                            ObjId={this.state.damxmareNagebobaID}
                            index={this.state.elmntIndex}
                            onClose={this.showShemvsebiModal}
                            show={this.state.showShemvsebi}
                            cancelShemvsebiModal={this.cancelShemvsebiModal}
                        >
                        </ShemvsebebisMartvaModal>

                        <button className="SecondaryButtonMartva" style={{ marginRight: "10px" }} onClick={e => {
                            this.showMeilebiModal(e);
                        }}> <img style={{ "marginRight": "12px", "width": "11px" }} src="images/vectormail.png"></img>მეილების მართვა</button>
                        <MeilebisMartvaModal
                            ObjId={this.state.damxmareNagebobaID}
                            index={this.state.elmntIndex}
                            onClose={this.showMeilebiModal}
                            show={this.state.showMail}
                            cancelMeilebiModal={this.cancelMeilebiModal}
                        >
                        </MeilebisMartvaModal>
                        <button className="SecondaryButtonOrange" style={{ marginRight: "10px" }} onClick={this.delete}>წაშლა</button>
                        <button className="SecondaryButtonOrange" style={{ marginRight: "10px" }} onClick={this.block}>დაბლოკვა</button>

                        <div className="MobileObjectSearch">
                            <SearchTemplate type={this.props.type}></SearchTemplate>
                            {this.GetSearch(1)}
                        </div>
                        <div className="TotalButtons">
                            {this.GetSearch()}
                        </div>

                    </div>
                </div>

                <div className="card-body">
                    {
                        this.state.loading
                            ? <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center' }}>
                                <PulseLoader
                                    color={"#E57959"}
                                    loading={true}
                                />
                            </div>
                            : this.drawImovableList(pageOfItems, this.props.imgClassName)
                    }


                    {pager.pages &&
                        <div className="TotalText">
                            {`${pager.currentPage * 10 - 9}-${pager.currentPage * 10 > pager.iTotalRecords ? pager.iTotalRecords : pager.currentPage * 10} ობიექტი `}
                            <span style={{ fontFamily: 'HelveticaBold' }}>{pager.iTotalRecords.toLocaleString()} - დან</span>
                        </div>
                    }
                </div>
                <div className="pb-0 pt-3 automargin docPages">
                    {pager.pages && pager.pages.length &&
                        <ul className="pagination mainPagination">
                            <li className={`page-item first-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=1` }} className="page-link">
                                    <svg className="bi bi-chevron-double-left" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M8.354 1.646a.5.5 0 010 .708L2.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z" clipRule="evenodd" />
                                        <path fillRule="evenodd" d="M12.354 1.646a.5.5 0 010 .708L6.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z" clipRule="evenodd" />
                                    </svg>
                                </Link>
                            </li>
                            <li className={`page-item previous-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=${pager.currentPage - 1}` }} className="page-link">
                                    <svg className="bi bi-chevron-left" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z" clipRule="evenodd" />
                                    </svg>
                                </Link>
                            </li>
                            {pager.pages.map(page =>
                                <li key={page} className={`page-item number-item ${pager.currentPage === page ? 'active' : ''}`}>
                                    <Link to={{ search: `?page=${page}` }} className="page-link">{page}</Link>
                                </li>
                            )}
                            <li className={`page-item next-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=${pager.currentPage + 1}` }} className="page-link">
                                    <svg className="bi bi-chevron-right" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />
                                    </svg>
                                </Link>
                            </li>
                            <li className={`page-item last-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=${pager.totalPages}` }} className="page-link">
                                    <svg className="bi bi-chevron-double-right" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L9.293 8 3.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />
                                        <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L13.293 8 7.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                    }
                </div>

                <div className="docFooter">
                    <div className="MobileObjectSearch">
                        <button className="MainButton" onClick={this.delete}>წაშლა</button>
                        <button className="MainButton" style={{ marginLeft: "10px" }} onClick={this.block}>დაბლოკვა</button>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export { UserListVIew };