import React, { Component } from 'react';
import './ImmovableList.css';
import { ContactMap } from './ContactMap';

import { Footer } from './Footer';
import { Row, Col } from 'react-bootstrap';
import { Recaptcha } from './Recaptcha';
export class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            disabled: true
        }

        this.handler = this.handler.bind(this)
    }


    onNameChange(event) {
        this.setState({ name: event.target.value })
        this.handler()
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value })
        this.handler()
    }

    onMessageChange(event) {
        this.setState({ message: event.target.value })
        this.handler()
    }

    setCustomValidity(event) {
        if (event.target.name === "name")
            event.target.setCustomValidity("შეიყვანეთ სახელი!");
        else if (event.target.name === "email")
            event.target.setCustomValidity("შეიყვანეთ ელ.ფოსტა!");
        else if (event.target.name === "message")
            event.target.setCustomValidity("შეიყვანეთ ტექსტი!");
    }

    removeCustomValidity(event) {
        event.target.setCustomValidity("");
        event.target.placeholder = '';
    }

    addPlaceHolder(event) {
        if (event.target.name === "name")
            event.target.placeholder = "სახელი";
        else if (event.target.name === "email")
            event.target.placeholder = "ელ.ფოსტა";
        else if (event.target.name === "message")
            event.target.placeholder = "ტექსტი...";
    }


    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            disabled: true
        });

        fetch('api/Email', {
            method: "POST",
            body: JSON.stringify(this.state),
            //body: `name=${this.state.name}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(
            (response) => (response.json())
        ).then((response) => {
            if (response === 'success') {
                alert("თქვენი წერილი გაგზავნილია");
                this.resetForm()
            } else if (response === 'fail') {
                alert("მოხდა შეცდომა.")
            }
        })
    }


    resetForm() {

        this.setState({ name: '', email: '', message: '' })
    }


    handler() {
        if (this.state.name !== '' && this.state.email !== '' && this.state.message.length > 10) {
            this.setState({
                disabled: false
            });
        }
        else {
            this.setState({
                disabled: true
            });
        }
    }

    static displayName = Contact.name;


    render() {

        return (
            <div className="immListDiv" style={{
                backgroundColor: 'white'
            }}>
                <Recaptcha />
                <Row style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                    <Col md={6}>
                        <div style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className="boldHeade" style={{ padding: '30px' }}>
                                კონტაქტი
                        </div>
                            <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST" >
                                <Row className="form-group">
                                    <Col style={{ marginTop: '1rem' }} md={6}>
                                        <input required type="text" className="form-control contactText" placeholder="სახელი" value={this.state.name} onChange={this.onNameChange.bind(this)}
                                            name="name" onInvalid={this.setCustomValidity.bind(this)} onInput={this.removeCustomValidity.bind(this)}
                                            onFocus={this.removeCustomValidity.bind(this)}
                                            onBlur={this.addPlaceHolder.bind(this)}
                                        />
                                    </Col>
                                    <Col style={{ marginTop: '1rem' }} md={6}>
                                        <input required type="email" className="form-control contactText" aria-describedby="emailHelp" placeholder="ელ.ფოსტა" value={this.state.email} onChange={this.onEmailChange.bind(this)}
                                            name="email" onInvalid={this.setCustomValidity.bind(this)}
                                            onInput={this.removeCustomValidity.bind(this)} onFocus={this.removeCustomValidity.bind(this)}
                                            onBlur={this.addPlaceHolder.bind(this)} />
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <Col style={{ marginTop: '1rem' }}>
                                        <textarea required className="form-control contactText" rows="5" placeholder="ტექსტი…" value={this.state.message} style={{ resize: 'none' }}
                                            onChange={this.onMessageChange.bind(this)} name="message" onInvalid={this.setCustomValidity.bind(this)}
                                            onFocus={this.removeCustomValidity.bind(this)}
                                            onBlur={this.addPlaceHolder.bind(this)} onInput={this.removeCustomValidity.bind(this)}></textarea>
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                    <Col style={{ marginTop: '1rem' }} md={6}>

                                    </Col>
                                    <Col style={{ marginTop: '1rem', textAlign: 'right' }} md={6}> <button type="submit" className="MainButton" style={{ width: '100%', maxWidth: '250px' }} disabled={this.state.disabled}>გაგზავნა</button></Col>
                                </Row>
                            </form>
                            <Row style={{ paddingTop: '30px' }}>
                                <Col style={{ marginTop: '1rem' }} md={12}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div style={{ marginRight: '50px' }}>
                                            <div style={{ fontSize: '13px' }}>ტელ.ნომერი</div>
                                            <div className="ObjectHeader">+995 32 932411</div>
                                        </div>
                                        <div>
                                            <div style={{ fontSize: '13px' }}>ელ.ფოსტა</div>
                                            <div className="ObjectHeader">sainformaciosistemebi@gmail.com</div>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <Row style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                                <Col style={{ fontSize: '13px' }}>
                                    დაგვიმეგობრდით
                                <div style={{ display: "iline-block", marginTop: '10px' }}>
                                        <a className="cardImg" href="https://www.facebook.com/georgianheritage/?eid=ARD0DbrURrrVbf8kBa2VsoajnLh0pGfPGFm0YtlVSNW23B2nHx1y5-ZYzfq341Aby3wVk1N57dlZiokS&timeline_context_item_type=intro_card_work&timeline_context_item_source=100000090270015&fref=tag" target="_blank" rel="noopener noreferrer"><img alt="" variant="top" src="/images/facebook.png" width="42" /></a>
                                        <a className="cardImg" href="http://twitter.com" target="_blank" rel="noopener noreferrer"><img alt="" variant="top" src="/images/twitter.png" width="42" style={{ marginLeft: '15px' }} /></a>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                                <small><span>ეს საიტი დაცულია reCAPTCHA-ს ინსტრუმენტით და მასზე ვრცელდება Google-ის </span>
                                    <a href="https://policies.google.com/privacy" style={{ color: "#007bff" }}>კონფიდენციალურობის პოლისი</a><span> და </span>
    <a href="https://policies.google.com/terms" style={{ color: "#007bff" }}>სერვისების წესები.</a>
                            </small>
                            </Row>
                        </div>

                    </Col>
                    <Col md={6} style={{ minHeight: '300px' }}>
                        <ContactMap />
                    </Col>
                </Row>
            </div>
        );
    }

}