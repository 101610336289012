import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { slide as Menu } from 'react-burger-menu'
import Avatar from 'react-avatar';

export class UserBurgerMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    componentDidMount() {
        this.setState({ show: false })
    }

    handleStateChange(state) {
        this.setState({ show: state.isOpen })
    }

    logout() {
        this.props.logout()
        this.closeMenu();
    }

    render() {

        return (

            <Menu right isOpen={this.state.show}
                customBurgerIcon={
                    //<div className="UserBtn"></div>                    
                    <Avatar title={this.props.currentUser.userName} src={this.props.photo} size={45} round="45px" />
                }
                onStateChange={(state) => this.handleStateChange(state)}
            >
                <div style={{ padding: '20px 0', fontSize: '16px' }}>
                    <span>
                        <Avatar title={this.props.currentUser.userName} src={this.props.photo} size={50} round="50px" />
                    </span>
                    <span className="avatarText" data-tip data-for="allAuthors">გამარჯობა, {this.props.currentUser.userName}</span>
                </div>
                {this.props.isAdmin !== true ?
                    <div style={{ textAlign: "left" }}>
                        <LinkContainer to="/userprofile/data?t=0" active={(window.location.href.indexOf("/userprofile/data") > -1) ? true : false}>
                            <Nav.Link onClick={() => this.closeMenu()}>
                                ჩემი პროფილი
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/userprofile/favorite?t=1" active={(window.location.href.indexOf("/userprofile/favorite") > -1) ? true : false}>
                            <Nav.Link onClick={() => this.closeMenu()}>
                                ფავორიტები
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/userprofile/userObjects?t=2" active={(window.location.href.indexOf("/userprofile/userObjects") > -1) ? true : false}>
                            <Nav.Link onClick={() => this.closeMenu()}>
                                მემკვიდრეობის დამატება
                            </Nav.Link>
                        </LinkContainer>
                    </div>
                    :
                    <div style={{ textAlign: "left" }}>
                        <LinkContainer to="/AdminUsers" active={(window.location.href.indexOf("/AdminUsers") > -1) ? true : false}>
                            <Nav.Link onClick={() => this.closeMenu()}>
                                მომხმარებლები                                
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/objects/userObject" active={(window.location.href.indexOf("/objects/userObject") > -1) ? true : false}>
                            <Nav.Link onClick={() => this.closeMenu()}>
                                დამატებული მემკვიდრეობა                                
                            </Nav.Link>
                        </LinkContainer>
                    </div>
                }

                <LinkContainer to="/#">
                    <Nav.Link onClick={() => this.logout()}>
                        გასვლა
                            </Nav.Link>
                </LinkContainer>


            </Menu>

        );
    }
}

