import React from 'react';
import ReactDOM from 'react-dom'
import { Modal, Accordion, Card } from 'react-bootstrap'
/*import { Form } from 'react-bootstrap'*/
import './QuickAlert.css';
import { CustomMultipleSelect } from '../custom/CustomMultipleSelect';
import { CustomToggle } from '../custom/CustomToggle'
import { CustomTreeSelect } from '../custom/CustomTreeSelect';
import { CustomCreatableMultipleSelect } from '../custom/CustomCreatableMultipleSelect';
import { CustomCreatableMultipleSelectSearch } from '../custom/CustomCreatableMultipleSelectSearch';
import Autosuggest from 'react-autosuggest';
import CheckboxTree from 'react-checkbox-tree-enhanced';
import 'react-checkbox-tree-enhanced/lib/react-checkbox-tree.css';
import Select from 'react-select'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone, { IDropzoneProps, ILayoutProps } from 'react-dropzone-uploader'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { NavMenu } from '../NavMenu';
import { authenticationService } from '../../_services';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
//import { faCoffee } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import autosize from "autosize";
import Jumbotron from 'react-bootstrap/Jumbotron'
import { UserAlertObjectListView } from '../User/UserAlertObjectListView'
import $ from 'jquery';
import EXIF from 'exif-js'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import portal from "./Portal";
const cardText = {
    width: 'auto',
    fontSize: '10px'
}
var faMyUncheckIcon = {
    prefix: "fas",
    iconName: "myUncheck",
    icon: [
        16,
        16,
        [],
        "cf0000",
        "M 11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H 5 z"
    ]
};

library.add(faMyUncheckIcon);
var faCheckIcon = {
    prefix: "fas",
    iconName: "myCheck",
    icon: [
        16,
        16,
        [],
        "cf0000",
        "M 2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022 z"
    ]
};

library.add(faCheckIcon);

var faExpandCloseIcon = {
    prefix: "fas",
    iconName: "myExpandClose",
    icon: [

        32,
        16,
        [],
        "cf0000",
        "M 6.5 11.55 c 0.78 -0.77 2.03 -0.77 2.81 0 c 0 0 10.21 10.21 10.21 10.21 C 20.3 22.54 20.3 23.79 19.52 24.57 C 18.72 25.37 17.47 25.37 16.69 24.6 C 16.69 24.6 6.48 14.38 6.48 14.38 C 5.7 13.61 5.7 12.35 6.48 11.58 z M 16.69 1.4 C 17.47 0.63 18.72 0.63 19.5 1.4 C 20.3 2.21 20.3 3.46 19.52 4.24 C 19.52 4.24 9.31 14.45 9.31 14.45 C 8.53 15.22 7.28 15.22 6.5 14.45 C 6.5 14.45 6.48 14.42 6.48 14.42 C 5.7 13.65 5.7 12.39 6.48 11.62 C 6.48 11.62 16.69 1.4 16.69 1.4 Z"
    ]
};

library.add(faExpandCloseIcon);


var faExpandOpenIcon = {
    prefix: "fas",
    iconName: "myExpandOpen",
    icon: [

        32,
        16,
        [],
        "cf0000",
        "M 6.5 11.55 c 0.78 -0.77 2.03 -0.77 2.81 0 c 0 0 10.21 10.21 10.21 10.21 C 20.3 22.54 20.3 23.79 19.52 24.57 C 18.72 25.37 17.47 25.37 16.69 24.6 C 16.69 24.6 6.48 14.38 6.48 14.38 C 5.7 13.61 5.7 12.35 6.48 11.58 z M 16.69 1.4 C 17.47 0.63 18.72 0.63 19.5 1.4 C 20.3 2.21 20.3 3.46 19.52 4.24 C 19.52 4.24 9.31 14.45 9.31 14.45 C 8.53 15.22 7.28 15.22 6.5 14.45 C 6.5 14.45 6.48 14.42 6.48 14.42 C 5.7 13.65 5.7 12.39 6.48 11.62 C 6.48 11.62 16.69 1.4 16.69 1.4 Z"
    ]
};

library.add(faExpandOpenIcon);


library.add(fas, faCoffee)
dom.watch();
const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = suggestion => (
    <div>
        {suggestion}
    </div>
);
//// add type defs to custom LayoutComponent prop to easily inspect props passed to injected components
//const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
//    return (
//        <div>
//            {previews}

//            <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

//            {files.length > 0 && submitButton}
//        </div>
//    )
//}

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

const nodesGaremo = [
    {
        value: 'ბუნებრივი გარემო-პირობები',
        label: 'ბუნებრივი გარემო-პირობები',
        children: [
            {
                value: 'ადგილობრივი პირობები',
                label: 'ადგილობრივი პირობები',
                children: [
                    {
                        value: 'ქარი',
                        label: 'ქარი',
                    },
                    {
                        value: 'ფარდობითი ტენიანობა',
                        label: 'ფარდობითი ტენიანობა',
                    },
                    {
                        value: 'ტემპერატურა',
                        label: 'ტემპერატურა',
                    },
                    {
                        value: 'რადიაცია/სინათლე',
                        label: 'რადიაცია/სინათლე',
                    },
                    {
                        value: 'მტვერი',
                        label: 'მტვერი',
                    },
                    {
                        value: 'წყალი',
                        label: 'წყალი',
                    },
                    {
                        value: 'მავნებლები',
                        label: 'მავნებლები',
                    },
                    {
                        value: 'მიკროორგანიზმები',
                        label: 'მიკროორგანიზმები',
                    },
                    {
                        value: 'ხე-მცენარეები',
                        label: 'ხე-მცენარეები',
                    },
                ],
            },
            {
                value: 'კლიმატის ცვლილება და ამინდის მკაცრი პირობები',
                label: 'კლიმატის ცვლილება და ამინდის მკაცრი პირობები',
                children: [
                    {
                        value: 'გრიგალი',
                        label: 'გრიგალი',
                    },
                    {
                        value: 'წყალდიდობა',
                        label: 'წყალდიდობა',
                    },
                    {
                        value: 'გვალვა',
                        label: 'გვალვა',
                    },
                    {
                        value: 'გაუდაბნოება',
                        label: 'გაუდაბნოება',
                    },
                    {
                        value: 'ოკეანის წყლების ცვლილებები',
                        label: 'ოკეანის წყლების ცვლილებები',
                    },
                    {
                        value: 'ტემპერატურის ცვლილება',
                        label: 'ტემპერატურის ცვლილება',
                    },
                    {
                        value: 'კლიმატური ცვლილებების სხვა ზემოქმედება',
                        label: 'კლიმატური ცვლილებების სხვა ზემოქმედება',
                    },
                ],
            },
            {
                value: 'მოულოდნელი ეკოლოგიური ან გეოლოგიური მოვლენები',
                label: 'მოულოდნელი ეკოლოგიური ან გეოლოგიური მოვლენები',
                children: [
                    {
                        value: 'ვულკანური ამოფრქვევა',
                        label: 'ვულკანური ამოფრქვევა',
                    },
                    {
                        value: 'მიწისძვრა',
                        label: 'მიწისძვრა',
                    },
                    {
                        value: 'ცუნამი/მოქცევისტალღა',
                        label: 'ცუნამი/მოქცევისტალღა',
                    },
                    {
                        value: 'ზვავი/მეწყერი',
                        label: 'ზვავი/მეწყერი',
                    },
                    {
                        value: 'ეროზია და მოშლამვა/დალექვა',
                        label: 'ეროზია და მოშლამვა/დალექვა',
                    },
                    {
                        value: 'ხანძარი',
                        label: 'ხანძარი',
                    },
                ],
            },
        ],
    }
];
const nodesAdamiani = [
    {
        value: 'ადამიანის საქმიანობა',
        label: 'ადამიანის საქმიანობა',
        children: [
            {
                value: 'ფიზიკური რესურსების მოპოვება',
                label: 'ფიზიკური რესურსების მოპოვება',

                children: [
                    {
                        value: 'სამთო-მომპოვებელი სამუშაოები',
                        label: 'სამთო-მომპოვებელი სამუშაოები',
                    },
                    {
                        value: 'საბადოების კარიერული წესით დამუშავება',
                        label: 'საბადოების კარიერული წესით დამუშავება',
                    },
                    {
                        value: 'ნავთობის და გაზის მოპოვება',
                        label: 'ნავთობის და გაზის მოპოვება',
                    },
                    {
                        value: 'წყლის რესურსების მოპოვება',
                        label: 'წყლის რესურსების მოპოვება',
                    },
                ],
            },
        ],
    },
    {
        value: 'მემკვიდრეობის განზრახ დაზიანება',
        label: 'მემკვიდრეობის განზრახ დაზიანება',
    },
    {
        value: 'მემკვიდრეობის შემთხვევითი დაზიანება',
        label: 'მემკვიდრეობის შემთხვევითი დაზიანება',
    },
    {
        value: 'უკანონო საქმიანობა',
        label: 'უკანონო საქმიანობა',
    },
    {
        value: 'სამხედრო წვრთნა',
        label: 'სამხედრო წვრთნა',
    },
    {
        value: 'ომი',
        label: 'ომი',
    },
    {
        value: 'ტერორიზმი',
        label: 'ტერორიზმი',
    },
    {
        value: 'სამოქალაქო მღელვარება',
        label: 'სამოქალაქო მღელვარება',
    },
];
const nodesInterieri = [
    {
        value: 'ექსტერიერი',
        label: 'ექსტერიერი',
        children: [
            {
                value: 'არქიტექტურული ელემენტი/ნაწილი (კარი, სარკმელი,აივანი და ა.შ.)',
                label: 'არქიტექტურული ელემენტი/ნაწილი (კარი, სარკმელი,აივანი და ა.შ.)',
            },
            {
                value: 'კონსტრუქციული ელემენტი/ნაწილი (საძირკველი,კედელი, სახურავი და ა.შ.)',
                label: 'კონსტრუქციული ელემენტი/ნაწილი (საძირკველი,კედელი, სახურავი და ა.შ.)',
            },

            {
                value: 'დეკორატიული ელემენტი (რელიეფი, ორნამენტი და ა.შ.)',
                label: 'დეკორატიული ელემენტი (რელიეფი, ორნამენტი და ა.შ.)',
            },
            {
                value: 'კედლის მხატვრობა/მოზაიკა',
                label: 'კედლის მხატვრობა/მოზაიკა',
            }
        ],
    },
    {

        value: 'ინტერიერი',
        label: 'ინტერიერი',
        children: [
            {
                value: 'არქიტექტურული ელემენტი/ ნაწილი(კარი, სარკმელი და.ა.შ.)',
                label: 'არქიტექტურული ელემენტი/ ნაწილი(კარი, სარკმელი და.ა.შ.)',


            },
            {
                value: 'კონსტრუქციული ელემენტი/ნაწილი (საძირკველი, კედელი და ა.შ.)',
                label: 'კონსტრუქციული ელემენტი/ნაწილი (საძირკველი, კედელი და ა.შ.)',
            }
            ,
            {
                value: 'დეკორატიული ელემენტი(რელიეფი, ორნამენტი და ა.შ.)',
                label: 'დეკორატიული ელემენტი(რელიეფი, ორნამენტი და ა.შ.)',
            }
            ,
            {
                value: 'კედლის მხატვრობა/ მოზაიკა',
                label: 'კედლის მხატვრობა/ მოზაიკა',
            }
        ],
    },
    {
        value: 'მიმდებარე არეალი (ეზო, ქუჩა, გზა და ა.შ.)',
        label: 'მიმდებარე არეალი (ეზო, ქუჩა, გზა და ა.შ.)',
    }
    ,
    {
        value: 'სხვა',
        label: 'სხვა',
    }

];
const nodesSxvaZemoqmedeba = [
    {
        value: 'სხვა ტიპის ზემოქმედება',
        label: 'სხვა ტიპის ზემოქმედება',
    }
];
const getUploadParams = () => {
    return { url: 'https://httpbin.org/post' }
}

const Schema = Yup.object().shape({
    StatementReason: Yup.string().required('შეავსეთ სავალდებულო ველი'),
    Comment: Yup.string().required('შეავსეთ სავალდებულო ველი'),
    DangerDesc: Yup.string().required('შეავსეთ სავალდებულო ველი'),
    UserAlertDazianebaLoc: Yup.string().required('აირჩიეთ ერთ-ერთი დაზიანების ადგილმდებარეობა'),
    fotoDokumentaciaRequired: Yup.string().required('შეავსეთ სავალდებულო ველი'),

    //NameFirstLast: Yup.string().required('შეავსეთ სავალდებულო ველი'),
    //Email: Yup.string().required('შეავსეთ სავალდებულო ველი'),
    //MobileNumber: Yup.string().required('შეავსეთ სავალდებულო ველი')
});
const SchemaNext = Yup.object().shape({
    NameFirstLast: Yup.string().required('შეავსეთ სავალდებულო ველი'),
    Email: Yup.string().required('შეავსეთ სავალდებულო ველი'),
    MobileNumber: Yup.string().required('შეავსეთ სავალდებულო ველი'),
    PersonalNum: Yup.string().required('შეავსეთ სავალდებულო ველი').matches(/^[0-9]+$/, "უნდა იყოს მხოლოდ რიცხვები").min(11, 'მინიმუმ 11 სიმბოლო')
});

export class QuickAlertModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            acceptText: "",
            currentUser: null,
            showModal: false,
            show: false,
            gagzavna: false,
            Schema: Schema,
            StatementReason: "",
            orangeButtonId: null,
            DazianebisXarisxi: [],
            DazianebisXarisxiValue: [], //რედაქტირების მნიშვნელობა დაზიანების ხარისხი
            DazianebisTipi: [],
            DazianebisTipiValue: [], //რედაქტირების მნიშვნელობა დაზიანების ტიპი
            FotoDokumentacia: []
            , ModalHeaderTitle: 'სწრაფი შეტყობინების ფორმა'
            , data:
            {
                id: '', name: '', eventStartDate: '', eventEndDate: '', docStartDate: '', docEndDate: '',
                variety: [], status: [], category: [], period: [], function: [], eventType: [], documentType: [], sizuste: [],
                country: [], region: [], municipality: [], settlement: [], street: [],
                tempselectedPeriods: [], tempselectedFunction: [], tempselectedEventType: [], tempselectedDocumentType: [], linkedObj: {}, fullText: ''

            },
            suggestions: []
            , clicked: {}
            , checked1: []
            , checked2: []
            , checked3: []
            , checked4: []
            , expanded1: []
            , expanded2: [
                "ბუნებრივი გარემო-პირობები"
            ]
            , expanded3: [
                "ადამიანის საქმიანობა"
            ]
            , expanded4: []
            , QuickAlertContent: "QuickAlertContentShow"
            , QuickAlertContentNext: "QuickAlertContentNextHide"
            , QuickAlertAccepted: 'QuickAlertAcceptedHide'
            , QuickAlertRejected: 'QuickAlertRejectedHide'
            , fieldSetDisabled: false
            , fade: false
            , disable: false
        };

        this.handleClearClick = this.handleClearClick.bind(this);
        this.setOrangeButton = this.setOrangeButton.bind(this);
        this.onCheck1 = this.onCheck1.bind(this);
        this.onCheck2 = this.onCheck2.bind(this);
        this.onCheck3 = this.onCheck3.bind(this);
        this.onCheck4 = this.onCheck4.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onExpand1 = this.onExpand1.bind(this);
        this.onExpand2 = this.onExpand2.bind(this);
        this.onExpand3 = this.onExpand3.bind(this);
        this.onExpand4 = this.onExpand4.bind(this);
        this.myInputRef = React.createRef();
        this.formikRef = React.createRef();
        this.modalRef = React.createRef();
    }
    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    };

    changeStyleGagzvna = () => {
        this.setState({ QuickAlertContent: "QuickAlertContentHide" }); /*სწრაფი შეტყობინების ფორმის დამალვა*/
        this.setState({ QuickAlertContentNext: "QuickAlertContentNextShow" }); /*პირადი ინფორმაციის დივის გამოჩენა*/
        this.setState({ QuickAlertAccepted: "QuickAlertAcceptedHide" }); /*შეტყობინება მიღებულია ფორმის დამალვა*/
    }
    changeStyleGagzvnaHide = () => {
        this.setState({ QuickAlertContent: "QuickAlertContentHide" }); /*სწრაფი შეტყობინების ფორმის დამალვა*/
        this.setState({ QuickAlertContentNext: "QuickAlertContentNextHide" }); /*პირადი ინფორმაციის დივის დამალვა*/
        this.setState({ QuickAlertAccepted: "QuickAlertAcceptedShow" }); /*შეტყობინება მიღებულია ფორმის გამოჩენა*/
    }
    changeStyleRejected = () => {

        if (this.state.QuickAlertContent == "QuickAlertContentShow") {
            this.setState({ QuickAlertContent: "QuickAlertContentHide", returnPage: "QuickAlertContent", QuickAlertRejected: "QuickAlertRejectedShow" }); /*სწრაფი შეტყობინების ფორმის დამალვა*/
            $(".ObjectNameDiv").css("display", "none");
        }
        else if (this.state.QuickAlertContentNext == "QuickAlertContentNextShow") {
            this.setState({ QuickAlertContentNext: "QuickAlertContentNextHide", returnPage: "QuickAlertContentNext", QuickAlertRejected: "QuickAlertRejectedShow" }); /*პირადი ინფორმაციის დივის დამალვა*/
            $(".ObjectNameDiv").css("display", "none");
        }
    }
    closeRejected = () => {

        if (this.state.returnPage == "QuickAlertContent") {
            this.setState({ QuickAlertContent: "QuickAlertContentShow", QuickAlertRejected: "QuickAlertRejectedHide" }); /*სწრაფი შეტყობინების ფორმის დამალვა*/
            $(".ObjectNameDiv").css("display", "block");
        }
        else if (this.state.returnPage == "QuickAlertContentNext") {
            this.setState({ QuickAlertContentNext: "   ", QuickAlertRejected: "QuickAlertRejectedHide" }); /*პირადი ინფორმაციის დივის გამოჩენა*/
        }
    }
    changeStyleQuickAlert = () => {

    }
    componentDidUpdate() {
        if (this.textAreaRef) {
            if (this.textAreaRef.style !== undefined)

                this.textAreaRef.style.height = this.textAreaRef.scrollHeight + "px";
        }
    }
    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x, isAdmin: x && x.role === "Admin", isAdminG: x && x.role === "AdminG",
        }));
        this.loadPage();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
        window.addEventListener('resize', () => {

            const doc = document.documentElement
            doc.style.setProperty('--app-height', `${window.innerHeight}px`)
        })
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
        /*        document.body.style.overflow = 'unset';*/
    }
    resize() {
        let currentHideNav = (window.innerWidth <= 760);
        if (currentHideNav !== this.state.hideNav) {
            {
                this.setState({ currentHideNav: currentHideNav });
                //if (currentHideNav) {
                //    //this.setState({ acceptText: "image/*;capture=camera" });
                //    this.setState({acceptText: '"image/*" capture' });
                //}
                //else {
                //    this.setState({ acceptText: "image/*" });
                //}
            }
        }
    }
    async loadPage() {
        let initialDazianebisXarisxi = [];
        fetch('api/UserAlert/getLkpDazianebisXarisxi')
            .then(response => {
                return response.json();
            }).then(data => {
                initialDazianebisXarisxi = data;
                this.setState({
                    DazianebisXarisxi: initialDazianebisXarisxi,
                });
            });

        let initialDazianebisTipi = [];
        fetch('api/UserAlert/getLkpDazianebisTipi')
            .then(response => {
                return response.json();
            }).then(data => {
                initialDazianebisTipi = data;
                this.setState({
                    DazianebisTipi: initialDazianebisTipi,
                });
            });
    }
    setOrangeButton(id) { //ფერის ცვლილება განცხადების მიზეზზე
        this.setState({ orangeButtonId: id });
    }
    handelModalShow = () => {
        document.body.style.overflow = 'hidden'
    }
    handleClose = () => {
        document.body.style.overflow = 'unset'
        enableBodyScroll(this.modalRef)
        this.setState({
            show: false
            , ModalHeaderTitle: 'სწრაფი შეტყობინების ფორმა'
            , QuickAlertContent: "QuickAlertContentShow"
            , QuickAlertContentNext: "QuickAlertContentNextHide"
            , QuickAlertAccepted: 'QuickAlertAcceptedHide'
            , gagzavna: false
            , Schema: Schema
            , StatementReason: ""
            , orangeButtonId: null
            , DazianebisXarisxi: []
            , DazianebisTipi: []
            , FotoDokumentacia: []
            , suggestions: []
            , expanded: []
            , clicked: {}
            , checked1: []
            , checked2: []
            , checked3: []
            , checked4: []
        });
        if (this.props.MapID) {
            this.props.hideOpacity(this.props.MapID);
            var sty = $("[mapid=" + this.props.MapID + "]");
            $("[mapid=" + this.props.MapID + "]")[0].style.removeProperty("opacity");
        }
    }
    hideBackdrop = () => {
        disableBodyScroll(this.modalRef)
        if (this.state.show) {
            document.body.style.overflow = 'hidden';
        }
        if (this.props.MapID) {
            this.props.showOpacity(this.props.MapID);

            //$("[mapid=" + this.props.MapID+ "]").css("opacity", 1);

            $(".modal-backdrop").css("opacity", 0);
        }


    }
    handleShowJumbotron = () => {
        this.setState({ showJumbotron: true })
    }
    handleShow = () => {
        this.setState({ show: true })
        var trueshowJumbotron = this.state.showJumbotron;
        if (trueshowJumbotron)
            this.setState({ showJumbotron: !trueshowJumbotron })


        if (sessionStorage.getItem("filterData") != null && sessionStorage.getItem("filterData") !== '') {
            var data = JSON.parse(JSON.parse(sessionStorage.getItem("filterData")));

            this.tempselectedPeriods = data.tempselectedPeriods;
            this.tempselectedFunction = data.tempselectedFunction;
            this.tempselectedDocumentType = data.tempselectedDocumentType;
            this.tempselectedEventType = data.tempselectedEventType;

            this.selectedPeriods = data.period;
            this.selectedFunction = data.function;
            this.selectedEventType = data.eventType;
            this.selectedDocumentType = data.documentType;
            this.selectedVarieties = data.variety;

            if (data.id === 0) data.id = '';
            this.setState({ data: data });
        }

    };

    onCheck1(checked1) {
        this.setState({ checked1 });
    }
    onCheck2(checked2) {
        this.setState({ checked2 });
    }
    onCheck3(checked3) {
        this.setState({ checked3 });
    }
    onCheck4(checked4) {
        this.setState({ checked4 });
    }
    onClick(clicked) {
        this.setState({ clicked });
    }

    onExpand1(expanded1) {
        this.setState({ expanded1 });
    }
    onExpand2(expanded2) {
        this.setState({ expanded2 });
    }
    onExpand3(expanded3) {
        this.setState({ expanded3 });
    }
    onExpand4(expanded4) {
        this.setState({ expanded4 });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.prepareSubmit();
    }

    prepareSubmit() {

    }
    removeImage = (id) => {
        var fotoImages = this.state.FotoDokumentacia;
        //var index = fotoImages.indexOf(id)
        //var arr = fotoImages.Splice(index, 1);
        var images = fotoImages.filter((item) => item !== id)
        if (images.length == 0) {
            this.formikRef.current.setFieldValue(
                "fotoDokumentaciaRequired",
                ''
            );
        }
        this.setState({ FotoDokumentacia: images })
    };


    searchData(data) {
        if (data.id === '') data.id = 0;
        if (data.eventStartDate === '') data.eventStartDate = 0;
        if (data.eventEndDate === '') data.eventEndDate = 0;
        if (data.docStartDate === '') data.docStartDate = 0;
        if (data.docEndDate === '') data.docEndDate = 0;

        if (this.checkData(data)) {
            fetch('api/Immovable', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response) => {
                this.props.filterResulthandle(response);
                this.handleClose();
            })
        }
        else {
            this.props.filterResulthandle('');
            this.handleClose();
        }
    }

    handleClearData() {
        var data = {
            id: '', name: '', eventStartDate: '', sizuste: [],
        };
        this.setState({ data: data, show: false })
        /*sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(daPhotoAddressta)));*/
        this.props.filterResulthandle('');
        sessionStorage.setItem('locationFilter', 'off');

    }


    handleClearClick() {
        var data = this.state.data;
        data.name = '';
        this.setState({
            data: data
        });
    }
    //image აკონვერტირებს file ად
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    render() {

        const InputDropzone = ({ accept, onFiles, files, getFilesFromEvent }) => {
            const text = files.length > 0 ? 'კიდევ დაამატეთ' : 'ფაილის არჩევა'
            const text2 = files.length > 0 ? '' : 'ან გადმოაგდეთ აქ'

            return (
                files.length > 0 ?
                    ''
                    :
                    this.state.currentHideNav == false ?
                        <div>
                            <label style={{ backgroundColor: '#E57959', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3, marginTop: 23 }}>
                                {text}
                                <input
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept={accept}
                                    multiple
                                    onChange={e => {
                                        getFilesFromEvent(e).then(chosenFiles => {
                                            onFiles(chosenFiles);
                                            var fotos = this.state.FotoDokumentacia;
                                            var newfotos = fotos.concat(chosenFiles);
                                            this.setState({ FotoDokumentacia: newfotos })
                                        })
                                    }}
                                />
                            </label>
                            <br />
                            <label style={{ color: '#AEB0B0', cursor: 'pointer', borderRadius: 3, fontWeight: 500, marginTop: 8 }}>
                                {text2}
                            </label>

                        </div>
                        :
                        <div style={{ display: "flex" }}>
                            <div className="captureCamera">
                                <label>
                                    <img src="/images/fi-sr-camera.png" />
                                    <input
                                        style={{ display: 'none' }}
                                        type="file"
                                        accept={accept}
                                        capture="user"
                                        multiple
                                        onChange={e => {
                                            getFilesFromEvent(e).then(chosenFiles => {
                                                onFiles(chosenFiles);
                                                var thisImage = new Image;
                                                var kkk = this;
                                                chosenFiles.map(file => {
                                                    //EXIF.getData(item, function () {
                                                    //    this.exifdata.Orientation = 2;
                                                    //    alert(this.exifdata.Orientation);
                                                    //});

                                                    EXIF.getData(file, function () {
                                                        var orientation = EXIF.getTag(this, "Orientation");
                                                        var can = document.createElement("canvas");
                                                        var ctx = can.getContext('2d');
                                                        var jjj = kkk.state.FotoDokumentacia;
                                                        thisImage.onload = function () {
                                                            can.width = thisImage.width;
                                                            can.height = thisImage.height;
                                                            ctx.save();
                                                            var width = can.width; var styleWidth = can.style.width;
                                                            var height = can.height; var styleHeight = can.style.height;
                                                            if (orientation) {
                                                                //if (orientation > 4) {
                                                                //    can.width = height; can.style.width = styleHeight;
                                                                //    can.height = width; can.style.height = styleWidth;
                                                                //}
                                                                //switch (orientation) {
                                                                //    case 2: ctx.translate(width, 0); ctx.scale(-1, 1); break;
                                                                //    case 3: ctx.translate(width, height); ctx.rotate(Math.PI); break;
                                                                //    case 4: ctx.translate(0, height); ctx.scale(1, -1); break;
                                                                //    case 5: ctx.rotate(0.5 * Math.PI); ctx.scale(1, -1); break;
                                                                //    case 6: ctx.rotate(0.5 * Math.PI); ctx.translate(0, -height); break;
                                                                //    case 7: ctx.rotate(0.5 * Math.PI); ctx.translate(width, -height); ctx.scale(-1, 1); break;
                                                                //    case 8: ctx.rotate(-0.5 * Math.PI); ctx.translate(-width, 0); break;
                                                                //}

                                                            }

                                                            ctx.drawImage(thisImage, 0, 0);
                                                            ctx.restore();
                                                            var dataURL = can.toDataURL();
                                                            var index = jjj.indexOf(file);
                                                            var file3 = kkk.dataURLtoFile(dataURL, file.name); //აბრუნებს file ს

                                                            var fotos2 = jjj
                                                            fotos2.splice(index, 1); // 2nd parameter means remove one item only
                                                            var newfotos2 = fotos2.concat(file3); // ვამატებთ არსებულ ფოტოების მასივში
                                                            kkk.setState({ FotoDokumentacia: newfotos2 }) //სთეითის განახლება

                                                            // at this point you can save the image away to your back-end using 'dataURL'
                                                        }

                                                        // now trigger the onload function by setting the src to your HTML5 file object (called 'file' here)
                                                        thisImage.src = URL.createObjectURL(file);

                                                    });

                                                })

                                                var fotos = this.state.FotoDokumentacia;
                                                var newfotos = fotos.concat(chosenFiles);
                                                this.setState({ FotoDokumentacia: newfotos })

                                            })

                                        }}
                                    />
                                </label>
                            </div>
                            <div className="captureCamera" style={{ marginLeft: "15px" }}>
                                <label>
                                    <img src="/images/fi-sr-camera-2.png" />
                                    <input
                                        style={{ display: 'none' }}
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        multiple
                                        onChange={e => {
                                            getFilesFromEvent(e).then(chosenFiles => {
                                                onFiles(chosenFiles);
                                                var fotos = this.state.FotoDokumentacia;
                                                var newfotos = fotos.concat(chosenFiles);
                                                this.setState({ FotoDokumentacia: newfotos })
                                            })
                                        }}
                                    />
                                </label>
                            </div>
                        </div >
            )
        }

        const { showModal } = this.state;
        let DazianebisXarisxi = this.state.DazianebisXarisxi;
        let dazianebisXarisxiItems = [];
        DazianebisXarisxi.map((item) =>
            /* <option key={item.name} value={item.id}>{item.name} </option>*/
            dazianebisXarisxiItems.push({ label: item.name, value: item.id })
        );

        let DazianebisTipi = this.state.DazianebisTipi;
        let dazianebisTipiItems = [];
        DazianebisTipi.map((item) =>
            /* <option key={item.name} value={item.id}>{item.name} </option> */
            dazianebisTipiItems.push({ label: item.name, value: item.id })
        );
        var eventOpen = (this.state.data.eventType.length > 0 || this.state.data.eventStartDate > 0 || this.state.data.eventEndDate > 0);
        const { checked1, checked2, checked3, checked4,
            expanded1, expanded2, expanded3, expanded4, clicked } = this.state;

        const handleChangeStatus = ({ meta, remove }, status) => {
            if (status === 'headers_received') {
                //var arr = this.state.FotoDokumentacia;
                //arr.push(meta)
                //this.setState({ FotoDokumentacia: arr });

                this.formikRef.current.setFieldValue(
                    "fotoDokumentaciaRequired",
                    'filled'
                );

                remove();
            }
            console.log(status, meta);
        }

        const handleSubmitDropzone = (files, allFiles) => {
            /*console.log(files.map(f => f.meta))
            allFiles.forEach(f => f.remove())*/
            //this.setState({ FotoDokumentacia: files });
        }
        const getFilesFromEvent = e => {
            return new Promise(resolve => {
                getDroppedOrSelectedFiles(e).then(chosenFiles => {
                    resolve(chosenFiles.map(f => f.fileObject))
                })
            })
        }

        return (
            <>
                {this.props.immovableAlertLinkGancxadebebi == true ?

                    <div onClick={this.handleShow}>
                        <td className="width60alertObjectCardTable" >
                            <Card.Title className="objectList_card-title alertcard" >{this.props.objectName}</Card.Title>
                            <div className="objectTypeList">{this.props.Id}</div>
                            <Card.Text className="alertList_object_card-text" dataid={this.props.id}>
                                <span className="dazianebaTextAlertObject">{this.props.statementReason}</span>
                                <br></br>
                                <span className="dazianebaTextAlertObject">{this.props.createDate} </span>

                            </Card.Text>
                        </td>
                    </div>: ""}
                {this.props.AdminG == "AdminG" ?
                    this.props.immovableAlertLink == true ?
                        <div onClick={this.handleShow}>
                            <Card key={this.props.forecast.id} className="cardHorizonal" style={{ hover: "pointer" }}>
                                <div className='imgDivObjListHorizontal'>
                                    {this.props.forecast.photoAddress == null || this.props.forecast.photoAddress === '' ?
                                        <></> :
                                        <img variant="top" src={this.props.forecast.photoAddress} alt="" style={{ width: '100%', height: '100%' }} />
                                    }
                                </div>
                                <Card.Body>
                                    <div className="cardObjHeader">{this.props.forecast.objectName.length > 30 ? this.props.forecast.objectName.substring(0, 30) + "..." : this.props.forecast.objectName}</div>
                                    <Card.Text style={cardText}>
                                        {this.props.forecast.variety}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        :
                        <div /*onClick={this.handleShow}*/ className="quickAlartBtn" >
                            <React.Fragment>
                                <button
                                    className="modal-toggle-button"
                                    onClick={this.toggleModal}
                                >
                                    {!showModal ? 'განცხადებები' : 'Close Modal'}
                                    <span style={{ marginLeft: "5px", "borderRadius": "10px", "color": "white", "background": "#6A8E7F", "padding": "3px" }}>{this.props.statementCount}</span>
                                </button>

                                {
                                    showModal ? (
                                        <portal className="my-modal-portal">
                                            <button
                                                className="modal-close"
                                                onClick={this.toggleModal}
                                            >
                                                <span style={{ fontSize: "1.6em", color: "#979797" }} aria-hidden="true">&times;</span>
                                            </button>
                                            <UserAlertObjectListView
                                                userAlertList={this.props.UserAlertList}
                                            >
                                            </UserAlertObjectListView>

                                            {/*<p>განცხადებები {this.props.UserAlertList.count} </p>*/}
                                            {/*{this.props.UserAlertList != null ?*/}
                                            {/*    this.props.UserAlertList.map((item) => {*/}

                                            {/*        return item.id*/}
                                            {/*    })*/}
                                            {/*    : ""*/}
                                            {/*}*/}
                                        </portal>
                                    ) : null
                                }

                            </React.Fragment>
                        </div>
                    :

                    this.props.immovableObject == true ?
                        <a>

                            <a className="PopUpUserAlertWeb">
                                <div onClick={this.handleShow} className="quickAlartBtnImmovable" >
                                    <img src="file-text.png">
                                    </img> სწრაფი შეტყობინების ფორმა</div>
                            </a>
                            <div className="PopUpUserAlertMobile">

                                <div onClick={this.handleShowJumbotron} className="quickAlartBtnImmovable" >
                                    <img src="file-text.png">
                                    </img> სწრაფი შეტყობინების ფორმა</div>


                                <Modal size="sm" backdrop="static" show={this.state.showJumbotron} onHide={this.handleClose} className="QuickAlertDialogPopUp"
                                >
                                    <Modal.Header closeButton className="searchCloseModal">
                                        <div className="PopUpModalTitleDiv">
                                            <div className="PopUpModalTitle">სწრაფი შეტყობინება</div>
                                        </div>

                                    </Modal.Header>
                                    <div className="alertModalTitleLine"> </div>
                                    <Modal.Body>

                                        <div className="PopUpModalBodyText">
                                            მსფოლიო მემკვიდრეობის ძეგლებზე დაზიანება/საფრთხის გამოვლენის შემთხვევაში ინფორმაციის დროულად მიწოდების სერვისი
                                            </div>
                                        <div className="manualPopUpButtonDiv">
                                            <button type="button" className="manualPopUpButton">
                                                <a href="/manual" onClick={this.quickAlertBtn}>
                                                    <span className="">სახელმძღვანელო</span>
                                                </a>
                                            </button>
                                        </div>
                                        <br />
                                        <div className="ganacxadiPopUpButtonDiv">
                                            <button type="button" className="ganacxadiPopUpButton">
                                                <a /*href="/objects/immovable" */ onClick={this.handleShow}>
                                                    <span className="">განაცხადი</span>
                                                </a>
                                            </button>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>

                        </a>


                        : this.props.immovableAlert == true ?
                            <div onClick={this.handleShow} className="quickAlartBtnAlerts" >
                                <img style={{ "marginRight": "12px", "width": "11px" }} src="images/Vector (1).png"></img> განცხადება</div>
                            : (this.state.currentUser == null ? "" : this.state.currentUser.role) == "AdminG" ?
                                <div /*onClick={this.handleShow}*/ className="quickAlartBtn" >
                                    <React.Fragment>
                                        <button
                                            className="modal-toggle-button"
                                            onClick={this.toggleModal}
                                        >
                                            {!showModal ? 'განცხადებები' : 'Close Modal'}
                                            <span style={{ marginLeft: "5px", "borderRadius": "10px", "color": "white", "background": "#6A8E7F", "padding": "3px" }}>{this.props.statementCount}</span>
                                        </button>

                                        {
                                            showModal ? (
                                                <portal className="my-modal-portal">
                                                    <button
                                                        className="modal-close"
                                                        onClick={this.toggleModal}
                                                    >
                                                        <span style={{ fontSize: "1.6em", color: "#979797" }} aria-hidden="true">&times;</span>
                                                    </button>
                                                    <UserAlertObjectListView
                                                        userAlertList={this.props.UserAlertList}
                                                        handleShow={this.handleShow}
                                                    >
                                                    </UserAlertObjectListView>

                                                    {/*<p>განცხადებები {this.props.UserAlertList.count} </p>*/}
                                                    {/*{this.props.UserAlertList != null ?*/}
                                                    {/*    this.props.UserAlertList.map((item) => {*/}

                                                    {/*        return item.id*/}
                                                    {/*    })*/}
                                                    {/*    : ""*/}
                                                    {/*}*/}
                                                </portal>
                                            ) : null
                                        }

                                    </React.Fragment>
                                </div>
                                :
                                <div onClick={this.handleShow} className="quickAlartBtn" >
                                    <img src="file-text-blue.png"></img> სწრაფი შეტყობინების ფორმა</div>


                }
                <Modal ref={this.modalRef} backdrop="static" show={this.state.show} onHide={this.handleClose} onShow={this.hideBackdrop} className="QuickAlertDialog"
                    onEntered={function () {
                        console.log("Modal is Shown");
                        autosize(this.myTextAreaRef);
                        autosize($(".CommentText"));
                    }}
                >
                    <NavMenu />
                    <Modal.Header closeButton className="searchCloseModal">

                        <div className="alertModalTitle">{this.state.ModalHeaderTitle}</div>
                        <a href="/manual">
                            <button className="manualButton"
                                type="button"
                            >
                                <img src="images/fi-br-interrogation.png"></img></button>
                        </a>
                    </Modal.Header>
                    <div className="alertModalTitleLine"> </div>
                    <Modal.Body>
                        <div className="ObjectNameDivBackground">
                            <div className="ObjectNameDiv"><img style={{ "borderRadius": "12px", "width": "80px" }} src={this.props.MainPhoto} ></img>

                                <span className="ObjectNameDivText">{this.props.ObjectName} - #{this.props.ObjectId} </span>

                            </div>
                        </div>
                        <Formik
                            innerRef={this.formikRef}
                            initialValues={{
                                ObjectId: 0,
                                ObjectName: '',
                                PhotoAddress: '',
                                FotoDokumentacia: [null],
                                images: [],
                                StatementReason: '',
                                UserAlertDazianebaLoc: '',
                                fotoDokumentaciaRequired: '',
                                Comment: '',
                                DangerDesc: '',
                                DamageLevel: '',
                                DamageType: '',
                                UserAlertZemoqmedebaGaremo: [],
                                UserAlertZemoqmedebaAdamiani: [],
                                UserAlertZemoqmedebaSxva: [],
                                CommentCauseFactor: '',
                                DamageReason: '',
                                ExpectedDanger: '',
                                Recomendation: '',
                                CommentDetaluri: '',
                                NameFirstLast: '',
                                Email: '',
                                MobileNumber: ''
                                , PersonalNum: ''
                            }}
                            /*initialTouched={{*/
                            /*    fotoDokumentaciaRequired: true,*/
                            /*}}*/
                            validationSchema={this.state.Schema}
                            onSubmit={({ ObjectId, ObjectName, PhotoAddress, FotoDokumentacia, images, StatementReason, Comment, UserAlertDazianebaLoc,
                                UserAlertZemoqmedebaGaremo, UserAlertZemoqmedebaAdamiani, UserAlertZemoqmedebaSxva
                                , DangerDesc, DamageLevel, DamageType, CommentCauseFactor, DamageReason, ExpectedDanger, Recomendation, CommentDetaluri
                                , NameFirstLast, Email, MobileNumber, PersonalNum },
                                {
                                    setStatus, setSubmitting }) => {
                                if (this.state.gagzavna) {
                                    setStatus();
                                    this.setState({ disable: true });
                                    /*this.changeStyleGagzvna*/
                                    UserAlertDazianebaLoc = this.state.checked1;
                                    UserAlertZemoqmedebaGaremo = this.state.checked2;
                                    UserAlertZemoqmedebaAdamiani = this.state.checked3;
                                    UserAlertZemoqmedebaSxva = this.state.checked4;
                                    FotoDokumentacia = this.state.FotoDokumentacia;
                                    ObjectId = this.props.ObjectId
                                    ObjectName = this.props.ObjectName;
                                    PhotoAddress = this.props.PhotoAddress;
                                    authenticationService.addUserAlertobject({
                                        ObjectId, ObjectName, PhotoAddress, FotoDokumentacia, images, StatementReason, Comment, UserAlertDazianebaLoc,
                                        UserAlertZemoqmedebaGaremo, UserAlertZemoqmedebaAdamiani, UserAlertZemoqmedebaSxva,
                                        DangerDesc, DamageLevel, DamageType, CommentCauseFactor, DamageReason, ExpectedDanger, Recomendation, CommentDetaluri
                                        , NameFirstLast, Email, MobileNumber, PersonalNum
                                    })
                                        .then(
                                            user => {
                                                //const { from } = this.props.location.state || { from: { pathname: "/UserProfile/userObjects" } };
                                                //this.props.history.push(from);
                                                this.changeStyleGagzvnaHide();
                                                this.setState({
                                                    ModalHeaderTitle: '',
                                                    disable: false

                                                });
                                            },
                                            error => {
                                                setSubmitting(false);
                                                setStatus(error);
                                                this.setState({
                                                    disable: false
                                                });
                                            }
                                        );
                                }
                                else {
                                    this.changeStyleGagzvna();
                                    //const node = ReactDOM.findDOMNode(this).getElementsByClassName('dzu-submitButton');
                                    //ReactDOM.findDOMNode(this.myInputRef.current).focus();
                                    ReactDOM.findDOMNode(this.myInputRef.current)
                                    const clickc = ReactDOM.findDOMNode(this.myInputRef.current);
                                    if (clickc != null) {
                                        clickc.click();
                                    }
                                    //node.click();
                                    this.setState({
                                        gagzavna: true,
                                        Schema: SchemaNext,
                                        ModalHeaderTitle: 'პირადი ინფორმაცია'
                                    });
                                }
                            }}
                        >

                            {({ values, errors, status, touched, isSubmitting, setFieldValue, validateForm, value }) => {

                                useEffect(() => {

                                    const params = new URLSearchParams(window.location.search);
                                    //const params = new URLSearchParams(window.location.pathname);
                                    var id = window.location.pathname.split('/').pop();
                                    var kkk = values;

                                    if (this.state.currentUser != null) {
                                        var curus = this.state.currentUser;
                                        setFieldValue('NameFirstLast', curus.userName);
                                        setFieldValue('Email', curus.email);
                                        setFieldValue('PersonalNum', curus.personalNum);
                                        setFieldValue('MobileNumber', curus.phoneNumber);
                                    }

                                    if ((typeof id !== "undefined" && id !== "immovableObject" && id !== "immovable") || this.props.immovableAlertLink == true || this.props.immovableAlertLinkGancxadebebi == true) {
                                        //const id = parseInt(params.get('id'));
                                        const id = this.props.id === undefined ? this.props.ObjectId : this.props.id
                                        authenticationService.getUserAlert(id).then(
                                            object => {
                                                this.setState({ fieldSetDisabled: true })
                                                setFieldValue('OrderId', object.orderId);
                                                setFieldValue('UserId', object.userId);
                                                setFieldValue('ObjectName', object.objectName);
                                                setFieldValue('PhotoAddress', object.photoAddress);
                                                setFieldValue('StatementReason', object.statementReason);

                                                if (object.statementReason == "არსებული დაზიანება") {
                                                    this.setOrangeButton(1);
                                                }
                                                else {
                                                    this.setOrangeButton(1);
                                                }
                                                this.setState({ checked1: object.userAlertDazianebaLoc });
                                                this.setState({ checked2: object.userAlertZemoqmedebaGaremo });
                                                this.setState({ checked3: object.userAlertZemoqmedebaAdamiani });
                                                this.setState({ checked4: object.UserAlertZemoqmedebaSxva });
                                                let DazianebisXarisxiValue = this.state.DazianebisXarisxiValue;
                                                DazianebisXarisxiValue.push({ label: object.damageLevel, value: object.damageLevel });
                                                this.setState({ DazianebisXarisxiValue: DazianebisXarisxiValue })

                                                let DazianebisTipiValue = this.state.DazianebisTipiValue;
                                                DazianebisTipiValue.push({ label: object.damageType, value: object.damageType });
                                                this.setState({ DazianebisTipiValue: DazianebisTipiValue })
                                                setFieldValue('DamageLocation', object.damageLocation);
                                                setFieldValue('Comment', object.comment);
                                                setFieldValue('DangerDesc', object.dangerDesc);
                                                setFieldValue('DamageLevel', object.damageLevel);
                                                setFieldValue('DamageType', object.damageType);
                                                setFieldValue('CommentCauseFactor', object.commentCauseFactor);
                                                setFieldValue('DamageReason', object.damageReason);
                                                setFieldValue('ExpectedDanger', object.expectedDanger);
                                                setFieldValue('Recomendation', object.recomendation);
                                                setFieldValue('Email', object.email);
                                                setFieldValue('MobileNumber', object.mobileNumber);
                                                setFieldValue('PersonalNum', object.personalNum);
                                                setFieldValue('CommentDetaluri', object.commentDetaluri);

                                                var arr = this.state.FotoDokumentacia;
                                                object.images.map((pic) => {
                                                    var i = 0;
                                                    var img = { id: i, previewUrl: pic };
                                                    arr.push(img);
                                                    i++;
                                                })
                                                this.setState({ FotoDokumentacia: arr });
                                            }
                                        )
                                    }



                                }, []);
                                return (
                                    <Form>
                                        <fieldset disabled={this.state.fieldSetDisabled}>
                                            <div className={this.state.QuickAlertContent}>
                                                <div className="alertModalTitleText">განცხადების მიზანი <span style={{ "color": "red" }}>*</span> </div>
                                                <div className="displayButtons">
                                                    <div>
                                                        <Field /*as="textarea"*/ autoComplete="off" className={this.state.orangeButtonId === 1 ? "button1 orange statemantReasonBtn" : "statemantReasonBtn"}
                                                            onClick={() => {
                                                                this.setOrangeButton(1);
                                                                setFieldValue('StatementReason', 'არსებული დაზიანება')
                                                            }} value="არსებული დაზიანება" type="button" useref="button" />
                                                        <Field /*as="textarea"*/ autoComplete="off" className={this.state.orangeButtonId === 2 ? "button2 orange statemantReasonBtn229" : "statemantReasonBtn229"}
                                                            onClick={() => {
                                                                this.setOrangeButton(2);
                                                                setFieldValue('StatementReason', 'მოსალოდნელი საფრთხე')
                                                            }} value="მოსალოდნელი საფრთხე" useref="button1" type="button" />

                                                        <Field autoComplete="off" name="StatementReason" type="text" className={'form-control displaynone' + (errors.StatementReason && touched.StatementReason ? ' is-invalid' : '')} />
                                                        <ErrorMessage name="StatementReason" component="div" className="invalid-feedback margin624 textalignleft" />
                                                    </div>
                                                </div>
                                                <div className="Rectangle165">
                                                    <div className="alertModalTitleText">დაზიანების ადგილმდებარეობა <span style={{ "color": "red" }}>*</span> </div>
                                                    <div>
                                                        <CheckboxTree
                                                            checked={checked1}
                                                            expanded={expanded1}
                                                            iconsClass="fa4"
                                                            nodes={nodesInterieri}
                                                            /*onCheck={this.onCheck1}*/
                                                            onCheck={checked => {

                                                                if (
                                                                    (checked.includes("არქიტექტურული ელემენტი/ნაწილი (კარი, სარკმელი,აივანი და ა.შ.)")
                                                                        || checked.includes("კონსტრუქციული ელემენტი/ნაწილი (საძირკველი,კედელი, სახურავი და ა.შ.)")
                                                                        || checked.includes("დეკორატიული ელემენტი (რელიეფი, ორნამენტი და ა.შ.)")
                                                                        || checked.includes("კედლის მხატვრობა/მოზაიკა")
                                                                    )
                                                                    && !checked.includes("ექსტერიერი")) {
                                                                    checked.push("ექსტერიერი");
                                                                }
                                                                if (
                                                                    (checked.includes("არქიტექტურული ელემენტი/ ნაწილი(კარი, სარკმელი და.ა.შ.)")
                                                                        || checked.includes("კონსტრუქციული ელემენტი/ნაწილი (საძირკველი, კედელი და ა.შ.)")
                                                                        || checked.includes("დეკორატიული ელემენტი(რელიეფი, ორნამენტი და ა.შ.)")
                                                                        || checked.includes("კედლის მხატვრობა/ მოზაიკა")
                                                                    )
                                                                    && !checked.includes("ინტერიერი")) {
                                                                    checked.push("ინტერიერი");
                                                                }
                                                                for (var i = 0; i < checked.length; i++) {
                                                                }
                                                                this.setState({ checked1: checked });
                                                                setFieldValue('UserAlertDazianebaLoc', checked)
                                                            }}
                                                            noCascade={true}
                                                            onClick={this.onClick}
                                                            onExpand={this.onExpand1}
                                                            expandOnClick={true}
                                                            icons={{
                                                                check: < FontAwesomeIcon color="#6A8E7F" icon="myCheck" />,
                                                                uncheck: < FontAwesomeIcon color="#CED4DA" icon="myUncheck" />,
                                                                halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                                expandClose: < FontAwesomeIcon color="#575D61" rotation={180} icon="myExpandClose" />,
                                                                expandOpen: < FontAwesomeIcon color="#575D61" rotation={270} icon="myExpandOpen" />,
                                                                expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                                collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                                parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                                parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                                leaf: <span className="rct-icon rct-icon-leaf" />,
                                                            }}
                                                        />
                                                    </div>
                                                    <Field as="textarea" autoComplete="off" name="UserAlertDazianebaLoc" type="text" className={'form-control displaynone' + (errors.UserAlertDazianebaLoc && touched.UserAlertDazianebaLoc ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />

                                                    <ErrorMessage name="UserAlertDazianebaLoc" component="div" className="invalid-feedback margin624" />
                                                    <div className="alertModalTitleText">კომენტარი <span style={{ "color": "red" }}>*</span> </div>
                                                    <Field as="textarea" size="large" autoComplete="off" name="Comment" type="text" className={'form-control CommentText' + (errors.Comment && touched.Comment ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />
                                                    <ErrorMessage name="Comment" component="div" className="invalid-feedback margin624" />
                                                    <p style={{ "height": "32px" }}></p>
                                                </div>
                                                <div className="alertModalTitleText">დაზიანების/საფრთხის აღწერა <span style={{ "color": "red" }}>*</span> </div>
                                                <Field as="textarea" autoComplete="off" name="DangerDesc" type="text" className={'form-control CommentText' + (errors.DangerDesc && touched.DangerDesc ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />
                                                <ErrorMessage name="DangerDesc" component="div" className="invalid-feedback margin624" />
                                                <div className="alertModalTitleText">ფოტო დოკუმენტაცია <span style={{ "color": "red" }}>*</span> </div>

                                                <Dropzone
                                                    getUploadParams={getUploadParams}
                                                    onChangeStatus={
                                                        handleChangeStatus
                                                    }
                                                    inputContent="ან გადმოაგდეთ ფაილი აქ"
                                                    /*accept={this.state.acceptText}*/

                                                    accept="image/*"
                                                    InputComponent={InputDropzone}
                                                    onSubmit={handleSubmitDropzone}
                                                    /*inputWithFilesContent='add fff' 
                                                    submitButtonDisabled={true} 
                                                    LayoutComponent={Layout}*/
                                                    submitButtonContent={() => (
                                                        <button onClick={(e) => {
                                                            e.preventDefault();


                                                            console.log("SUBMIT!");
                                                        }} ref={this.myInputRef}
                                                            id="kuku" className="btn btn-secondary kuku" style={{ width: "100%" }}>Submit File</button>
                                                    )}

                                                    getFilesFromEvent={getFilesFromEvent}
                                                    styles={{
                                                        dropzone: { width: 500, maxHeight: 300 },
                                                        dropzoneActive: { borderColor: 'red' },
                                                    }}
                                                />
                                                <Field style={{ display: "none" }} as="textarea" autoComplete="off" name="fotoDokumentaciaRequired" type="text" className={'form-control CommentText' + (errors.fotoDokumentaciaRequired && touched.fotoDokumentaciaRequired ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />
                                                <ErrorMessage name="fotoDokumentaciaRequired" component="div" className="invalid-feedback margin624" />


                                                <div className="row">
                                                    {this.props.immovableAlert == true || this.props.immovableAlertLinkGancxadebebi == true || this.props.immovableAlertLink == true ?
                                                        this.state.FotoDokumentacia.map((pic) => {
                                                            return (
                                                                <div className="col-md-4">
                                                                    {/*<div className="col-md-3 col-md-offset-2" style={{ marginBottom: "16x", marginLeft: "40px" }}>*/}

                                                                    <img style={{ marginBottom: "16px" }}
                                                                        src={pic.previewUrl}
                                                                        width="130px"
                                                                        height="120px"
                                                                    />

                                                                    <span className="closeImgButton" onClick={
                                                                        () => this.removeImage(pic)

                                                                    }>&times;</span>
                                                                </div>
                                                            );
                                                        })
                                                        :
                                                        this.state.FotoDokumentacia.map((pic) => {
                                                            return (
                                                                <div className="col-md-4">
                                                                    {/*<div className="col-md-3 col-md-offset-2" style={{ marginBottom: "16x", marginLeft: "40px" }}>*/}

                                                                    <img style={{ marginBottom: "16px" }}
                                                                        src={URL.createObjectURL(pic)}
                                                                        width="130px"
                                                                        height="120px"
                                                                    />

                                                                    <span className="closeImgButton" onClick={
                                                                        () => this.removeImage(pic)

                                                                    }>&times;</span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>

                                                <div className="dzu-previewContainer" >
                                                </div>
                                                <div className="savaldebuloSesavsebi"><img src="/images/redsnowflake.png" /> სავალდებულო შესავსები</div>
                                                <Accordion defaultActiveKey={eventOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                                                    <Card className="modelCard">
                                                        <Card.Header className="TogglePanelHeader">
                                                            <CustomToggle eventKey="1" className="alertModalSubTitleText alertModalTitleTextDetaluri" isOpen={eventOpen}>დეტალური აღწერა</CustomToggle>
                                                        </Card.Header>
                                                        <Accordion.Collapse eventKey="1">
                                                            <Card.Body>

                                                                <div id='wrapper' className="wrapper">
                                                                    <div className="floatLeft50">
                                                                        <div className="alertModalTitleTextDazianeba">დაზიანების ხარისხი</div>
                                                                        <Select
                                                                            placeholder="აირჩიეთ"
                                                                            defaultValue={this.state.DazianebisXarisxiValue}
                                                                            onChange={
                                                                                (e) => setFieldValue(`DamageLevel`, e.label)
                                                                            }
                                                                            options={dazianebisXarisxiItems}
                                                                            className="SelectDazianeba"
                                                                        />
                                                                    </div>
                                                                    <div className="floatRight50">
                                                                        <div className="alertModalTitleTextDazianeba">დაზიანების ტიპი</div>
                                                                        <Select
                                                                            placeholder="აირჩიეთ"
                                                                            defaultValue={this.state.DazianebisTipiValue}
                                                                            onChange={
                                                                                (e) => setFieldValue(`DamageType`, e.label)
                                                                            }
                                                                            options={dazianebisTipiItems}
                                                                            className="SelectDazianeba"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="alertModalSubTitleDetaluriText">ფაქტორები რომლებიც ზემოქმედებს ობიექტზე/ძეგლზე</div>
                                                                <div className="rectangle144"></div>
                                                                <div className="CheckboxTreeDazianeba">
                                                                    <CheckboxTree
                                                                        checked={checked2}
                                                                        expanded={expanded2}
                                                                        iconsClass="fa4"
                                                                        nodes={nodesGaremo}
                                                                        /*onCheck={this.onCheck2}*/

                                                                        onCheck={checked => {
                                                                            if (
                                                                                (checked.includes("ადგილობრივი პირობები")
                                                                                    || checked.includes("კლიმატის ცვლილება და ამინდის მკაცრი პირობები")
                                                                                    || checked.includes("მოულოდნელი ეკოლოგიური ან გეოლოგიური მოვლენები")
                                                                                )
                                                                                && !checked.includes("ბუნებრივი გარემო-პირობები")) {
                                                                                checked.push("ბუნებრივი გარემო-პირობები");
                                                                            }
                                                                            if (
                                                                                (checked.includes("ქარი")
                                                                                    || checked.includes("ფარდობითი ტენიანობა")
                                                                                    || checked.includes("ტემპერატურა")
                                                                                    || checked.includes("რადიაცია/სინათლე")
                                                                                    || checked.includes("მტვერი")
                                                                                    || checked.includes("წყალი")
                                                                                    || checked.includes("მავნებლები")
                                                                                    || checked.includes("მიკროორგანიზმები")
                                                                                    || checked.includes("ხე-მცენარეები")
                                                                                )
                                                                                && !checked.includes("ადგილობრივი პირობები")) {
                                                                                checked.push("ადგილობრივი პირობები");
                                                                                if (
                                                                                    (checked.includes("ადგილობრივი პირობები")
                                                                                        || checked.includes("კლიმატის ცვლილება და ამინდის მკაცრი პირობები")
                                                                                        || checked.includes("მოულოდნელი ეკოლოგიური ან გეოლოგიური მოვლენები")
                                                                                    )
                                                                                    && !checked.includes("ბუნებრივი გარემო-პირობები")) {
                                                                                    checked.push("ბუნებრივი გარემო-პირობები");
                                                                                }
                                                                            }
                                                                            if (
                                                                                (checked.includes("გრიგალი")
                                                                                    || checked.includes("წყალდიდობა")
                                                                                    || checked.includes("გვალვა")
                                                                                    || checked.includes("გაუდაბნოება")
                                                                                    || checked.includes("ოკეანის წყლების ცვლილებები")
                                                                                    || checked.includes("ტემპერატურის ცვლილება")
                                                                                    || checked.includes("კლიმატური ცვლილებების სხვა ზემოქმედება")
                                                                                )
                                                                                && !checked.includes("კლიმატის ცვლილება და ამინდის მკაცრი პირობები")) {
                                                                                checked.push("კლიმატის ცვლილება და ამინდის მკაცრი პირობები");
                                                                                if (
                                                                                    (checked.includes("ადგილობრივი პირობები")
                                                                                        || checked.includes("კლიმატის ცვლილება და ამინდის მკაცრი პირობები")
                                                                                        || checked.includes("მოულოდნელი ეკოლოგიური ან გეოლოგიური მოვლენები")
                                                                                    )
                                                                                    && !checked.includes("ბუნებრივი გარემო-პირობები")) {
                                                                                    checked.push("ბუნებრივი გარემო-პირობები");
                                                                                }
                                                                            }
                                                                            if (
                                                                                (checked.includes("ვულკანური ამოფრქვევა")
                                                                                    || checked.includes("მიწისძვრა")
                                                                                    || checked.includes("ცუნამი/მოქცევისტალღა")
                                                                                    || checked.includes("ზვავი/მეწყერი")
                                                                                    || checked.includes("ეროზია და მოშლამვა/დალექვა")
                                                                                    || checked.includes("ხანძარი")
                                                                                )
                                                                                && !checked.includes("მოულოდნელი ეკოლოგიური ან გეოლოგიური მოვლენები")) {
                                                                                checked.push("მოულოდნელი ეკოლოგიური ან გეოლოგიური მოვლენები");
                                                                                if (
                                                                                    (checked.includes("ადგილობრივი პირობები")
                                                                                        || checked.includes("კლიმატის ცვლილება და ამინდის მკაცრი პირობები")
                                                                                        || checked.includes("მოულოდნელი ეკოლოგიური ან გეოლოგიური მოვლენები")
                                                                                    )
                                                                                    && !checked.includes("ბუნებრივი გარემო-პირობები")) {
                                                                                    checked.push("ბუნებრივი გარემო-პირობები");
                                                                                }
                                                                            }

                                                                            this.setState({ checked2: checked });

                                                                        }}
                                                                        noCascade={true}
                                                                        onClick={this.onClick}
                                                                        onExpand={this.onExpand2}
                                                                        expandOnClick={true}
                                                                        icons={{
                                                                            check: < FontAwesomeIcon color="#6A8E7F" icon="myCheck" />,
                                                                            uncheck: < FontAwesomeIcon color="#CED4DA" icon="myUncheck" />,
                                                                            halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                                            expandClose: < FontAwesomeIcon color="#575D61" rotation={180} icon="myExpandClose" />,
                                                                            expandOpen: < FontAwesomeIcon color="#575D61" rotation={270} icon="myExpandOpen" />,
                                                                            expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                                            collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                                            parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                                            parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                                            leaf: <span className="rct-icon rct-icon-leaf" />,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="CheckboxTreeDazianebaAdamiani">
                                                                    <CheckboxTree
                                                                        checked={checked3}
                                                                        expanded={expanded3}
                                                                        iconsClass="fa4"
                                                                        nodes={nodesAdamiani}
                                                                        /*onCheck={this.onCheck3}*/
                                                                        onCheck={checked => {
                                                                            if (
                                                                                (checked.includes("ფიზიკური რესურსების მოპოვება")
                                                                                )
                                                                                && !checked.includes("ადამიანის საქმიანობა")) {
                                                                                checked.push("ადამიანის საქმიანობა");
                                                                            }
                                                                            if (
                                                                                (checked.includes("სამთო-მომპოვებელი სამუშაოები")
                                                                                    || checked.includes("საბადოების კარიერული წესით დამუშავება")
                                                                                    || checked.includes("ნავთობის და გაზის მოპოვება")
                                                                                    || checked.includes("წყლის რესურსების მოპოვება")

                                                                                )
                                                                                && !checked.includes("ფიზიკური რესურსების მოპოვება")) {
                                                                                checked.push("ფიზიკური რესურსების მოპოვება");
                                                                                if (
                                                                                    (checked.includes("ფიზიკური რესურსების მოპოვება")
                                                                                    )
                                                                                    && !checked.includes("ადამიანის საქმიანობა")) {
                                                                                    checked.push("ადამიანის საქმიანობა");
                                                                                }
                                                                            }
                                                                            this.setState({ checked3: checked });
                                                                        }}
                                                                        noCascade={true}
                                                                        onClick={this.onClick}
                                                                        onExpand={this.onExpand3}
                                                                        expandOnClick={true}
                                                                        icons={{
                                                                            check: < FontAwesomeIcon color="#6A8E7F" icon="myCheck" />,
                                                                            uncheck: < FontAwesomeIcon color="#CED4DA" icon="myUncheck" />,
                                                                            halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                                            expandClose: < FontAwesomeIcon color="#575D61" rotation={180} icon="myExpandClose" />,
                                                                            expandOpen: < FontAwesomeIcon color="#575D61" rotation={270} icon="myExpandOpen" />,
                                                                            expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                                            collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                                            parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                                            parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                                            leaf: <span className="rct-icon rct-icon-leaf" />,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="CheckboxTreeDazianebaSxva">
                                                                    <CheckboxTree
                                                                        checked={checked4}
                                                                        expanded={expanded4}
                                                                        iconsClass="fa4"
                                                                        nodes={nodesSxvaZemoqmedeba}
                                                                        noCascade={true}
                                                                        onClick={this.onClick}
                                                                        onCheck={this.onCheck4}
                                                                        onExpand={this.onExpand4}
                                                                        expandOnClick={true}
                                                                        icons={{
                                                                            check: < FontAwesomeIcon color="#6A8E7F" icon="myCheck" />,
                                                                            uncheck: < FontAwesomeIcon color="#CED4DA" icon="myUncheck" />,
                                                                            halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                                            expandClose: < FontAwesomeIcon color="#575D61" rotation={180} icon="myExpandClose" />,
                                                                            expandOpen: < FontAwesomeIcon color="#575D61" rotation={270} icon="myExpandOpen" />,
                                                                            expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                                            collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                                            parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                                            parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                                            leaf: <span className="rct-icon rct-icon-leaf" />,
                                                                        }}
                                                                    />
                                                                </div>


                                                                <div className="alertModalTitleText">კომენტარი გამომწვევ ფაქტორებზე</div>
                                                                <Field as="textarea" autoComplete="off" name="CommentCauseFactor" type="text" className={'form-control CommentText' + (errors.CommentCauseFactor && touched.CommentCauseFactor ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />

                                                                <div className="alertModalSubTitleDetaluriText">დამატებითი ინფორმაცია</div>

                                                                <div style={{ "marginTop": "41px" }} className="alertModalTitleText">დაზიანების მიზეზი</div>
                                                                <Field as="textarea" autoComplete="off" name="DamageReason" type="text" className={'form-control CommentText' + (errors.DamageReason && touched.DamageReason ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />

                                                                <div className="alertModalTitleText">მოსალოდნელი საფრთხეები</div>
                                                                <Field as="textarea" autoComplete="off" name="ExpectedDanger" type="text" className={'form-control CommentText' + (errors.ExpectedDanger && touched.ExpectedDanger ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />

                                                                <div className="alertModalTitleText">რეკომენდაცია</div>
                                                                <Field as="textarea" autoComplete="off" name="Recomendation" type="text" className={'form-control CommentText' + (errors.Recomendation && touched.Recomendation ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />

                                                                <div className="alertModalTitleText">კომენტარი</div>
                                                                <Field as="textarea" autoComplete="off" name="CommentDetaluri" type="text" className={'form-control CommentText' + (errors.CommentDetaluri && touched.CommentDetaluri ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />

                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>



                                                <div className="searchDivQuick">
                                                    <button type="submit" className="MainButtonQuick"

                                                        onClick={() => {
                                                            validateForm().then(errors => {
                                                                if (Object.keys(errors).length > 0 || this.state.FotoDokumentacia.length < 1) {
                                                                    this.changeStyleRejected()
                                                                }
                                                                else {
                                                                    $(".ObjectNameDiv").css("display", "none");
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <span className="MainButtonQuickspan">შემდეგი</span>
                                                    </button>
                                                </div>

                                            </div>
                                            <div className={this.state.QuickAlertContentNext} >
                                                <div className="alertModalTitleText">სახელი, გვარი <span style={{ "color": "red" }}>*</span> </div>
                                                <Field /*as="textarea"*/ disabled={true} autoComplete="off" name="NameFirstLast" type="text" className={'form-control PiradiInfoText' + (errors.NameFirstLast && touched.NameFirstLast ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />
                                                <ErrorMessage name="NameFirstLast" component="div" className="invalid-feedback margin624" />


                                                <div className="alertModalTitleText">ელ.ფოსტა <span style={{ "color": "red" }}>*</span> </div>
                                                <Field disabled={true} /*as="textarea"*/ autoComplete="off" name="Email" type="text" className={'form-control PiradiInfoTextMail' + (errors.Email && touched.Email ? ' is-invalid' : '')} placeholder="მაგ: info@mail.com" />
                                                <ErrorMessage name="Email" component="div" className="invalid-feedback margin624" />


                                                <div className="alertModalTitleText">ტელეფონი <span style={{ "color": "red" }}>*</span> </div>
                                                <Field disabled={true} /*as="textarea"*/ autoComplete="off" name="MobileNumber" type="text" className={'form-control PiradiInfoTextNumber' + (errors.MobileNumber && touched.MobileNumber ? ' is-invalid' : '')} placeholder="მაგ: 5XX-XX-XX-XX" />
                                                <ErrorMessage name="MobileNumber" component="div" className="invalid-feedback margin624" />

                                                <div className="alertModalTitleText">პირადი ნომერი <span style={{ "color": "red" }}>*</span> </div>
                                                <Field disabled={true} /*as="textarea"*/ autoComplete="off" name="PersonalNum" type="text" className={'form-control PiradiInfoTextMail' + (errors.PersonalNum && touched.PersonalNum ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />
                                                <ErrorMessage name="PersonalNum" component="div" className="invalid-feedback margin624" />



                                                <div className="searchDivQuickGagzavna">
                                                    <button disabled={this.state.disable} type="submit" className="MainButtonQuickGagzavna"
                                                        onClick={() => {
                                                            validateForm().then(errors => {
                                                                if (Object.keys(errors).length > 0) {
                                                                    this.changeStyleRejected()
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <span className="MainButtonQuickspanGagzavna">გაგზავნა</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={this.state.QuickAlertAccepted} >
                                                <img className="CheckMarkCircle" src="checkmark-circle-2.png"></img>
                                                <div className="ShetyobinebaMigebulia">შეტყობინება მიღებულია</div>
                                                <div className="GmadlobtTanamshrolobistvis">გმადლობთ თანამშრომლობისთვის</div>

                                                {/*<div className="SistemashiRegistracia">*/}
                                                {/*    <button type="text" className="SistemashiRegistraciaText">*/}
                                                {/*        <a href="/register">ავტორიზაცია/რეგისტრაცია</a>*/}
                                                {/*    </button>*/}
                                                {/*</div>*/}
                                            </div>

                                            <div className={this.state.QuickAlertRejected} >
                                                <img className="CheckMarkCircle" src="/images/fi-br-exclamation.png"></img>
                                                <div className="GmadlobtTanamshrolobistvisRejected">განაცხადის გასაგზავნად <br />
                                                    გთხოვთ შეავსოთ სავალდებულო ველები </div>

                                                <div className="SistemashiRegistraciaRejected">
                                                    <button type="text" className="RejectedButtonClose"

                                                        onClick={() => {
                                                            this.closeRejected()
                                                        }}
                                                    >
                                                        დახურვა
                                                    </button>
                                                </div>
                                            </div>

                                        </fieldset>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Modal.Body>

                </Modal>
            </>
        );
    }
}

