import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button, Row, Col } from 'react-bootstrap'

function GetModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    return (
        <>
            <div onClick={handleShow} className="showModal">
                <Button id="mapButton">რუკის ნახვა</Button>{' '}
            </div>

            <Modal show={show} onHide={handleClose} className="categoryDialog">
                <Modal.Header closeButton>
                    <Modal.Title className="ModalHeader">აირჩიეთ რომელი კომპონენტის ობიექტების ნახვა გსურთ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        //<Row>
                        //    <Col md={4} className="categoryButton"><a href="/objects/immovable?view=map"><div className="categoryButtonText">უძრავი ძეგლი</div></a></Col>
                        //    <Col md={4} className="categoryButton"><a href="/objects/movable?view=map"><div className="categoryButtonText">მოძრავი ძეგლები</div></a></Col>
                        //    <Col md={4} className="categoryButton"><a href="/objects/wallart?view=map"><div className="categoryButtonText">კედლის მხატვრობა</div></a></Col>
                        //    <Col md={4} className="categoryButton"><a href="/objects/stoneengrave?view=map"><div className="categoryButtonText">ქვაზე კვეთილობა</div></a></Col>
                        //    <Col md={4} className="categoryButton"><a href="/objects/museum?view=map"><div className="categoryButtonText">მუზეუმ-ნაკრძალი/მუზეუმი</div></a></Col>
                        //    <Col md={4} className="categoryButton"><a href="/objects/zone?view=map"><div className="categoryButtonText">ზოგადი დამცავი ზონა</div></a></Col>
                        //</Row>
                    }

                    <div style={{ display: 'flex',flexWrap: 'wrap' }}>
                        <a className="categoryButton" href="/objects/immovable?view=map"><div className="categoryButtonText">უძრავი ძეგლი</div></a>
                        <a className="categoryButton" href="/objects/movable?view=map"><div className="categoryButtonText">მოძრავი ძეგლები</div></a>
                        <a className="categoryButton" href="/objects/wallart?view=map"><div className="categoryButtonText">კედლის მხატვრობა</div></a>
                        <a className="categoryButton" href="/objects/stoneengrave?view=map"><div className="categoryButtonText">ქვაზე კვეთილობა</div></a>
                        <a className="categoryButton" href="/objects/museum?view=map"><div className="categoryButtonText">მუზეუმ-ნაკრძალი/მუზეუმი</div></a>
                        <a className="categoryButton" href="/objects/zone?view=map"><div className="categoryButtonText">ზოგადი დამცავი ზონა</div></a>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    );
}


export class ChooseCategory extends React.Component {

    render() {

        return (
            <GetModal />

        );
    }
}