import React, { Component } from 'react';
import './Object.css';
import { Row, Col, Accordion, Card } from 'react-bootstrap'
import { WebMapView } from '../WebMapView';

import { CustomToggle } from '../custom/CustomToggle'
import { ObjectListForLinks } from '../ObjectListForLinks';

import { FullDescModal } from './FullDescModal';
import { DocObjectListForLinksTable } from '../DocObjectListForLinksTable';
import { EventObjectListForLinksTable } from '../EventObjectListForLinksTable';
import { Gallery } from './Gallery';
import PulseLoader from "react-spinners/PulseLoader";
import { Footer } from '../Footer';

import { AddToCollection } from '../AddToCollection';
import { Share } from '../Share';

export class StoneEngraveMembership extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stoneengravemembership: {},
            objectID: null,
            objToZoom: null,
            fullScreeenIcon: "esri-icon-zoom-out-fixed",
            mapClass: '',
            maptype: '',
            loading: true
        };

        this.fullScreenMode = this.fullScreenMode.bind(this)
    }
    componentDidMount() {
        var el = document.querySelector('.searchComponentButton');
        el.textContent = "ქვაზე კვეთილობა";
        this.loadPage();
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const id = parseInt(params.get('id'));
        if (id !== null) {
            var type = `api/Stoneengrave/GetStoneEngraveMembershipObject?id=${id}`;
            const response = await fetch(type);
            const data = await response.json();
            this.setState({ stoneengravemembership: data, loading: false, maptype: data.curLocId !== "" ? data.curLocType : data.oldLocType, objectID: data.curLocId });
        }
    }

    toogleFullDesc() {
        if (document.getElementsByClassName('DescTextFull')[0].classList.contains('displayNone')) {
            document.getElementsByClassName('DescTextFull')[0].classList.remove('displayNone');
            document.getElementsByClassName('DescText')[0].classList.add('displayNone');
        }
        else {
            document.getElementsByClassName('DescTextFull')[0].classList.add('displayNone');
            document.getElementsByClassName('DescText')[0].classList.remove('displayNone');
        }

    }

    toogleMapActiveLink(val) {
        document.getElementsByClassName('mapLink')[0].classList.remove('active');
        document.getElementsByClassName('mapLink')[1].classList.remove('active');
        document.getElementsByClassName('mapLink')[val].classList.add('active');

        if (val === 0)
            this.setState({
                "maptype": this.state.wallArtPainting.curLocType, "mapObjectID": this.state.wallArtPainting.curLocId
            })
        else this.setState({
            "maptype": this.state.wallArtPainting.oldLocType, "mapObjectID": this.state.wallArtPainting.oldLocId
        })


        document.getElementsByClassName('mapCoord')[0].classList.add('displayNone');
        document.getElementsByClassName('mapCoord')[1].classList.add('displayNone');
        document.getElementsByClassName('mapCoord')[val].classList.remove('displayNone');
    }

    static displayName = StoneEngraveMembership.name;

    fullScreenMode() {
        if (this.state.mapClass === '') {
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-in-fixed', mapClass: 'objectFullScreentMap', objectID: null, objToZoom: this.state.stoneengravemembership.curLocId })
        }
        else {

            document.getElementById('toolbarDiv').classList.add('displayNone');
            document.getElementById('menuDiv').classList.add('displayNone')
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-out-fixed', mapClass: '', objectID: this.state.stoneengravemembership.curLocId, objToZoom: null })
        }
    }

    render() {
        const stoneengravemembership = this.state.stoneengravemembership;

        window.FB = null;
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/ka_GE/sdk.js#xfbml=1&autoLogAppEvents=1&version=v8.0&appId=1607074352807965";
            js.nonce = "q90Xb87m";

            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));


        return (
            <div className="immObjDiv">
                {stoneengravemembership && stoneengravemembership.id > 0 ?
                    <div>
                        <div className="containerDiv">
                            <Row className="rowPadding">
                                <Col md={5}>
                                    <Gallery photo={stoneengravemembership.photoIds} video={stoneengravemembership.videoIds} vid3D={stoneengravemembership._3dobjects} mainphoto={stoneengravemembership.photoAddress} type="stoneengrave" authors={stoneengravemembership.docAuthors} />
                                </Col>
                                <Col md={7}>
                                    <div className="WallObjectType">ქვაზე კვეთილობის შემადგენელი კვეთილობა</div>
                                    <div className="ObjectMainHeader" style={{ marginBottom: '20px' }}>
                                        <span className="ObjectMainHeaderSpan">{stoneengravemembership.objectName} - #{stoneengravemembership.id}</span>
                                        <Share header="გაზიარება" customClass="objectShare" />
                                    </div>
                                    <div style={{ marginBottom: '20px' }}><span className="headerText" >ქვაზე კვეთილობა: </span> <a className="card-link" style={{ color: "black" }} href={'../stoneengrave/stoneengraveObject?id=' + stoneengravemembership.stoneengraveID}>{stoneengravemembership.stoneengrave}</a></div>
                                    <div><span className="headerText">ისტორიული/კულტურული პერიოდი: </span>
                                        <ul className="periodList">
                                            {
                                                stoneengravemembership.period.split(';').map((item, i) => {
                                                    return <li key={i}>{item}</li>;
                                                })
                                            }</ul></div>
                                    <div><span className="headerText">თარიღი: </span>{stoneengravemembership.date}</div>
                                    <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '20px' }} />
                                    <div className="DescText">
                                        {stoneengravemembership.description}
                                        <div onClick={this.toogleFullDesc} className="toggleFullDesc">
                                            <svg className="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 01.708 0L8 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 01.708 0L8 8.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="DescTextFull displayNone">{stoneengravemembership.descriptioncFull}
                                        <div onClick={this.toogleFullDesc} style={{ textAlign: "center", padding: "20px" }}>
                                            <svg className="bi bi-chevron-double-up" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M7.646 2.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 3.707 2.354 9.354a.5.5 0 11-.708-.708l6-6z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M7.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div style={{ display: "inline-flex" }}>
                                        <FullDescModal className="FullDescModal" buttonDesc="დეტალური აღწერა" objname={stoneengravemembership.objectName} >
                                            <div className="PopupHeader">შემკულობის/რელიეფების აღწერა</div>
                                            {stoneengravemembership.descriptionFull}
                                            <div className="PopupHeader">შემკულობის/რელიეფების ტოპოგრაფია</div>
                                            {stoneengravemembership.topografia}
                                            {(stoneengravemembership.scenebi || stoneengravemembership.ciuriZalni || stoneengravemembership.winaswarmetyvelebi
                                                || stoneengravemembership.mociqulni || stoneengravemembership.mamebi || stoneengravemembership.wmindanebi
                                                || stoneengravemembership.gamosaxuleba || stoneengravemembership.saeroPirebi || stoneengravemembership.ornamenti
                                                || stoneengravemembership.cxovelebi || stoneengravemembership.frinvelebi || stoneengravemembership.geometrornamenti || stoneengravemembership.araidentificirebuli) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Card>
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">ქვაზე კვეთილობის რეპერტუარი</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body>
                                                                    {
                                                                        stoneengravemembership.scenebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>სცენები</div><div className="headerText">{stoneengravemembership.scenebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.ciuriZalni !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>ციური ძალნი</div><div className="headerText">{stoneengravemembership.ciuriZalni}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.winaswarmetyvelebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>წინასწარმეტყველები </div><div className="headerText">{stoneengravemembership.winaswarmetyvelebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.mociqulni !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>მოციქულნი</div><div className="headerText">{stoneengravemembership.mociqulni}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.mamebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>ეკლესიის მამები </div><div className="headerText">{stoneengravemembership.mamebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.wmindanebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>წმინდანები </div><div className="headerText">{stoneengravemembership.wmindanebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.gamosaxuleba !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>სიმბოლური გამოსახულებები </div><div className="headerText">{stoneengravemembership.gamosaxuleba}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.saeroPirebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>საერო პირები </div><div className="headerText">{stoneengravemembership.saeroPirebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.ornamenti !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>მცენარეული გამოსახულებები-ორნამენტები </div><div className="headerText">{stoneengravemembership.ornamenti}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.cxovelebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>ზოომორფოლოგიური გამოსახულებები-ცხოველები </div><div className="headerText">{stoneengravemembership.cxovelebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.frinvelebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>ზოომორფოლოგიური გამოსახულებები-ფრინველები </div><div className="headerText">{stoneengravemembership.frinvelebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.geometrornamenti !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>გეომეტრიული ორნამენტები</div><div className="headerText">{stoneengravemembership.geometrornamenti}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.araidentificirebuli !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div> არაიდენტიფიცირებული </div><div className="headerText">{stoneengravemembership.araidentificirebuli}</div></div>
                                                                            : <></>
                                                                    }

                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {(stoneengravemembership.nageboba || stoneengravemembership.satavsi || stoneengravemembership.archForma || stoneengravemembership.archComponenti) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Card>
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">ქვაზე კვეთილობის განთავსების ადგილები</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {
                                                                        stoneengravemembership.nageboba ?
                                                                            <div style={{ marginBottom: '20px' }}><div>ნაგებობა </div><div className="headerText">{stoneengravemembership.nageboba}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.satavsi ?
                                                                            <div style={{ marginBottom: '20px' }}><div>სათავსი </div><div className="headerText">{stoneengravemembership.satavsi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.archForma ?
                                                                            <div style={{ marginBottom: '20px' }}><div>მცირე არქიტექტურული ფორმები </div><div className="headerText">{stoneengravemembership.archForma}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        stoneengravemembership.archComponenti ?
                                                                            <div style={{ marginBottom: '20px' }}><div>არქიტექტურული კომპონენტები </div><div className="headerText">{stoneengravemembership.archComponenti}</div></div>
                                                                            : <></>
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {stoneengravemembership.warwera ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Card>
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">წარწერა</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {
                                                                        stoneengravemembership.warwera.map((item, i) =>
                                                                            <Row key={i}>
                                                                                <Col>
                                                                                    <div>ტექსტი: </div><div className="headerText">{item.split(';')[0]}</div>
                                                                                    <div>ენა: </div><div className="headerText">{item.split(';')[1]}</div>
                                                                                    <div>თარიღი: </div><div className="headerText">{item.split(';')[2]}</div>
                                                                                    <p style={{ borderBottom: '1px solid #DBDBDB', marginTop: '20px' }} />
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                        </FullDescModal>
                                    </div>
                                </Col>
                            </Row>
                            <div className="ObjectHeaderNoTop" style={{ marginLeft: '35px', marginTop: '20px' }}>ადგილმდებარეობა</div>
                            <Row style={{ padding: '20px 20px 0 20px', width: '100%' }} className="ObjectMapRow">
                                <Col md={5}>
                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                        <div>
                                            <div>ამჟამინდელი:</div>
                                            {stoneengravemembership.country !== '' ? <div>ქვეყანა: <span className="headerText">{stoneengravemembership.country}</span></div> : <></>}
                                            {stoneengravemembership.region !== '' ? <div>რეგიონი: <span className="headerText">{stoneengravemembership.region}</span></div> : <></>}
                                            {stoneengravemembership.municipality !== '' ? <div>მუნიციპალიტეტი: <span className="headerText">{stoneengravemembership.municipality}</span></div> : <></>}
                                            {stoneengravemembership.settlement !== '' ? <div>დასახლებული პუნქტი: <span className="headerText">{stoneengravemembership.settlement}</span></div> : <></>}
                                            {stoneengravemembership.street !== '' ? <div>მისამართი: <span className="headerText">{stoneengravemembership.street}</span></div> : <></>}
                                        </div>
                                    </div>

                                    {(stoneengravemembership.oldcountry !== '' || stoneengravemembership.oldregion !== '' || stoneengravemembership.oldmunicipality !== '' || stoneengravemembership.oldsettlement !== '' || stoneengravemembership.oldstreet !== '') ?
                                        <div style={{ display: 'inline-flex', marginTop: '20px', width: '100%' }}>
                                            <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                            <div>
                                                <div>წარმომავლობა:</div>
                                                {stoneengravemembership.oldcountry !== '' ? <div>ქვეყანა: <span className="headerText">{stoneengravemembership.oldcountry}</span></div> : <></>}
                                                {stoneengravemembership.oldregion !== '' ? <div>რეგიონი: <span className="headerText">{stoneengravemembership.oldregion}</span></div> : <></>}
                                                {stoneengravemembership.oldmunicipality !== '' ? <div>მუნიციპალიტეტი: <span className="headerText">{stoneengravemembership.oldmunicipality}</span></div> : <></>}
                                                {stoneengravemembership.oldsettlement !== '' ? <div>დასახლებული პუნქტი: <span className="headerText">{stoneengravemembership.oldsettlement}</span></div> : <></>}
                                                {stoneengravemembership.oldstreet !== '' ? <div>მისამართი: <span className="headerText">{stoneengravemembership.oldstreet}</span></div> : <></>}
                                            </div>
                                        </div>
                                        : <></>
                                    }
                                </Col>
                                <Col md={7} style={{ minHeight: '250px' }} className="ObjectMap">
                                    <div style={{ display: "inline-block", margin: '0 10px 10px 0px' }}>
                                        <button className={stoneengravemembership.curLocId !== "" ? 'mapLink active' : 'mapLink not-active'} onClick={() => this.toogleMapActiveLink(0)} disabled={(stoneengravemembership.curLocId === "")}>ამჟამინდელი</button>
                                        <button className={stoneengravemembership.curLocId !== "" ? 'mapLink' : stoneengravemembership.oldLocId !== "" ? 'mapLink active' : 'mapLink not-active'} onClick={() => this.toogleMapActiveLink(1)} style={{ marginLeft: "20px" }} disabled={(stoneengravemembership.oldLocId === "")}>წარმომავლობა</button>
                                    </div>
                                    <WebMapView type={this.state.maptype} objectID={this.state.objectID} objToZoom={this.state.objToZoom} right="30px" fullScreeenIcon={this.state.fullScreeenIcon} fullClick={this.fullScreenMode.bind(this)} mapClass={this.state.mapClass} />

                                </Col>
                            </Row>
                            <Row>
                                <Col md={5}>

                                </Col>
                                <Col md={7}>
                                    <Row className={stoneengravemembership.curLocId !== "" ? 'mapCoord' : 'mapCoord displayNone'}>
                                        <Col md={5} style={{ marginBottom: '20px' }}>
                                            <div className="icon-waves">
                                                სიმაღლე ზღვის დონიდან: {stoneengravemembership.elevetion}მ.
                                        </div>
                                        </Col>
                                        <Col md={6} style={{ marginBottom: '20px' }}>
                                            <div className="icon-direction-outline">კოორდინატები {stoneengravemembership.sizuste === 'სწორია' ? <img style={{ width: '17px', height: '17px' }} alt="" src="/images/OK.svg" /> : <></>}
                                            </div>
                                            {stoneengravemembership.sizuste === 'სწორია' ?
                                                <div>
                                                    <span className="headerText">X: </span>{stoneengravemembership.x}
                                                    <span className="headerText"> Y: </span>{stoneengravemembership.y}
                                                </div> :
                                                <div>
                                                    კოორდინატები დასაზუსტებელია
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={stoneengravemembership.curLocId !== "" ? 'mapCoord displayNone' : 'mapCoord'}>
                                        <Col md={5} style={{ marginBottom: '20px' }}>
                                            <div className="icon-waves">
                                                სიმაღლე ზღვის დონიდან: {stoneengravemembership.elevetionOld}მ.
                                        </div>
                                        </Col>
                                        <Col md={6} style={{ marginBottom: '20px' }}>
                                            <div className="icon-direction-outline">კოორდინატები {stoneengravemembership.sizusteOld === 'სწორია' ? <img style={{ width: '17px', height: '17px' }} alt="" src="/images/OK.svg" /> : <></>}
                                            </div>
                                            {stoneengravemembership.sizusteOld === 'სწორია' ?
                                                <div>
                                                    <span className="headerText">X: </span>{stoneengravemembership.xOld}
                                                    <span className="headerText"> Y: </span>{stoneengravemembership.yOld}
                                                </div> :
                                                <div>
                                                    კოორდინატები დასაზუსტებელია
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="containerDiv">
                            {stoneengravemembership.shemadgeneliLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">ქვაზე კვეთილობის შემადგენელი კვეთილობა</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={stoneengravemembership.stoneengraveID} curobjID={stoneengravemembership.id} type="stoneengraveMembership" objType="stoneengrave" objName={stoneengravemembership.objectName}/>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        </div>
                        {
                            stoneengravemembership.linksDisplay ? <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '80px' }} /> : <></>

                        }
                        <div className="containerDiv">
                            <div className="accardionPadding ObjectHeaderNoTop">{stoneengravemembership.linksDisplay ? 'დაკავშირებული კომპონენტები' : ''}</div>
                            {stoneengravemembership.immovableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">უძრავი ძეგლი/ობიექტი</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={stoneengravemembership.id} type="immovable" objType="stoneengravemembership" idList={stoneengravemembership.immovableLinks} objName={stoneengravemembership.objectName}/>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {stoneengravemembership.movableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მოძრავი ძეგლი/ობიექტი</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={stoneengravemembership.id} type="movable" objType="stoneengravemembership" idList={stoneengravemembership.movableLinks} objName={stoneengravemembership.objectName}/>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {
                                stoneengravemembership.eventLinkExist ?
                                    <Row className="accardionPadding">
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Card.Header className="TogglePanelHeader">
                                                    <CustomToggle eventKey="1"><span className="headerText">ქმედება</span></CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body className="accardionBodyPadding">
                                                        {
                                                            <EventObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={stoneengravemembership.id} type="event" objType="stoneengravemembership" idList={stoneengravemembership.eventLinks} objName={stoneengravemembership.objectName}/>
                                                        }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Row>
                                    : <></>
                            }
                            {stoneengravemembership.docLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">დოკუმენტაცია</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <DocObjectListForLinksTable caption={{ type: "დოკუმენტის ტიპი", docNum: "ავტორი" }} objID={stoneengravemembership.id} type="document" objType="stoneengravemembership" idList={stoneengravemembership.docLinks} objName={stoneengravemembership.objectName}/>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        </div>

                        <Footer>
                            <div className="containerDiv" style={{ paddingTop: '40px', paddingLeft: '20px' }}>
                                <Row >
                                    <Col md={5}>

                                    </Col>
                                    <Col md={7}>
                                        <p style={{ borderBottom: '1px solid #ECECEC' }} className="facebookCOmmentP" />
                                        <div className="fb-comments" data-href={window.location.href} data-numposts="5" data-width="" data-mobile="true"></div>
                                    </Col>
                                </Row>
                            </div>
                        </Footer>
                    </div>
                    : <div style={{
                        marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center'
                    }}>
                        <PulseLoader
                            color={"#E57959"}
                            loading={true}
                        />
                    </div>
                }
            </div>
        );
    }

}