import React from 'react';
import { Modal, Accordion, Card } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import './Search.css';

import { CustomToggle } from '../custom/CustomToggle'
import { CustomTreeSelect } from '../custom/CustomTreeSelect';
import { CustomCreatableMultipleSelect } from '../custom/CustomCreatableMultipleSelect';
import { CustomCreatableMultipleSelectSearch } from '../custom/CustomCreatableMultipleSelectSearch';
import Autosuggest from 'react-autosuggest';


const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = suggestion => (
    <div>
        {suggestion}
    </div>
);

export class ZoneDetailSearchModal extends React.Component {
    selectedVarieties = [];

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data:
            {
                id: '', name: '',
                variety: [],
                region: [], municipality: [], settlement: [], linkedObj: {}, fullText: ''
            },
            suggestions: []
        };

        this.handleClearClick = this.handleClearClick.bind(this);
    }


    handleClose = () => this.setState({ show: false });
    handleShow = () => {
        this.setState({ show: true })

        if (sessionStorage.getItem("filterData") != null && sessionStorage.getItem("filterData") !== '') {
            var data = JSON.parse(JSON.parse(sessionStorage.getItem("filterData")));
            this.selectedVarieties = data.variety;
            this.setState({ data: data })
        }

    };

    onChange(event) {
        var data = this.state.data;
        switch (event.target.name) {
            case "id":
                data.id = parseInt(event.target.value) || ''
                break;
            default:
                break;
        }

        this.setState({ data: data });
    }
    handleSubmit(e) {
        e.preventDefault();

        this.prepareSubmit();

    }
    prepareSubmit() {
        var data = this.state.data;
        data.variety = this.selectedVarieties;

        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));


        this.searchData(data);
    }
    checkData(data) {
        if (data.id !== 0 || data.name !== '' || data.variety.length > 0 ||
            data.region.length > 0 || data.municipality.length > 0 || data.settlement.length > 0 ) {
            return true;
        }
        return false
    }

    searchData(data) {
        if (data.id === '') data.id = 0;

        if (this.checkData(data)) {
            fetch('api/SafetyZone', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response) => {
                this.props.filterResulthandle(response);
                this.handleClose();
            })
        }
        else {
            this.props.filterResulthandle('');
            this.handleClose();
        }
    }
    handleClearData() {
        var data = {
            id: '', name: '',
            variety: [],
            region: [], municipality: [], settlement: [], linkedObj: {}, fullText: ''
        };
        this.setState({ data: data, show: false })
        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));
        this.props.filterResulthandle('');
    }



    changeVarietyHandle(currentNode, selectedNodes) {
        this.selectedVarieties = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectedVarieties.push(item.value)
            );
        }
    }

    changeRegionHandle(items) {
        var data = this.state.data;
        data.region = [];
        if (items != null) {
            items.map(item =>
                data.region.push(item.label)
            );
        }
        this.setState({ data: data });
    }

    changeMunicipalityHandle(items) {
        var data = this.state.data;
        data.municipality = [];
        if (items != null) {
            items.map(item =>
                data.municipality.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeSettlementHandle(items) {
        var data = this.state.data;
        data.settlement = [];
        if (items != null) {
            items.map(item =>
                data.settlement.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    onKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.prepareSubmit();
        }
    }
    onNameChange = (event, { newValue }) => {
        var data = this.state.data;
        data.name = newValue;
        this.setState({
            data: data
        });
    };
    handleClearClick() {
        var data = this.state.data;
        data.name = '';
        this.setState({
            data: data
        });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                suggestions: []
            }) :

            fetch('api/AutoComplete/Getrecord?value=' + value + "&component=zone")
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        suggestions: data
                    })
                })
                .catch(error => console.error(error));
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };



    render() {
        const suggestions = this.state.suggestions;
        const value = this.state.data.name

        const inputProps = {
            value,
            onChange: this.onNameChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        let clearButton;
        if (value.length >= 1) {
            clearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleClearClick}
                />
            );
        }

        var locationOpen = (this.state.data.region.length > 0 || this.state.data.municipality.length > 0 || this.state.data.settlement.length > 0);


        return (
            <>
                <div className="showModal">
                    <button onClick={this.handleShow} className="MainButton icon-equalizer" >{this.props.children}</button>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose} className="SearchDialog">
                    <Modal.Header closeButton className="searchCloseModal">
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit.bind(this)} method="POST" style={{ height: '100%', paddingTop: '0px' }}>
                            <div className="searchDivContent">
                                <div className="FullGalleryHeader">სარეგისტრაციო ნომერი</div>
                                <input type="number" className="form-control searchDivRowInput" name="id" id="id" value={this.state.data.id} onChange={this.onChange.bind(this)} autoComplete="off" />
                                <div className="FullGalleryHeader searchDivRow">სახელწოდება</div>
                                <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        renderSuggestion={renderSuggestion}
                                        getSuggestionValue={getSuggestionValue}
                                        inputProps={inputProps} />
                                    <div className="close-icon-container">
                                        {clearButton}
                                    </div>
                                </div>
                                <div className="FullGalleryHeader searchDivRow" >ზოგადი დამცავი ზონის ტიპი</div>
                                <CustomTreeSelect items={this.props.classificators.type} onChange={this.changeVarietyHandle.bind(this)} selectedOption={this.state.data.variety} />

                                <Accordion defaultActiveKey={locationOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                                    <Card className="modelCard">
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey="1" className="FullGalleryHeader">ადგილმდებარეობა</CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <div className="searchDivRow" >რეგიონი</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={
                                                    this.props.classificators.region
                                                } changeHandle={this.changeRegionHandle.bind(this)} selectedOption={this.state.data.region} />
                                                <div className="searchDivRow" >რაიონი/მუნიციპალიტეტი</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={
                                                    this.props.classificators.municipality.filter(i =>
                                                        this.state.data.region.indexOf(i.parentName) > -1 || this.state.data.region.length === 0)
                                                } changeHandle={this.changeMunicipalityHandle.bind(this)} selectedOption={this.state.data.municipality} />
                                                <div className="searchDivRow" >დასახლებული პუნქტი</div>
                                                <CustomCreatableMultipleSelectSearch onKeyDown={this.onKeyPress.bind(this)} isDisabled={false} items={
                                                    this.props.classificators.settlement.filter(i =>
                                                        this.state.data.municipality.indexOf(i.parentName) > -1
                                                        || (this.state.data.municipality.length === 0))}
                                                    changeHandle={this.changeSettlementHandle.bind(this)} selectedOption={this.state.data.settlement} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="searchDiv">
                                <div onClick={this.handleClearData.bind(this)} className="NoFillSearchButton">გაუქმება</div>
                                <button type="submit" className="MainButton">ძიება</button>
                            </div>
                        </Form>
                    </Modal.Body>

                </Modal>
            </>
        );
    }
}

