import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button, Row, Col } from 'react-bootstrap'

export class FullDescModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };        
    }

    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });

    render() {

        return (
            <div className={this.props.className}>
                <div className="showModal">
                    <button onClick={this.handleShow} className="NoFillButton SecondaryButtonOrange" disabled={(this.props.disabled === false || this.props.disabled == null) ? false : true}>{this.props.buttonDesc}</button>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose} className="FullDescDialog">
                    <Modal.Header closeButton>
                        <Modal.Title className="ModalHeader" style={{ width: '100%', borderBottom: '1px solid #EDEDED' }}>{this.props.objname}</Modal.Title>                       
                    </Modal.Header>
                    <Modal.Body>
                        
                            {this.props.children}
                       
                    </Modal.Body>
                   
                </Modal>
            </div>
        );
    }
}

