import React from 'react';

class Verification extends React.Component {
   
    render() {
        return (
            <div className="immListDiv" style={{
                backgroundColor: 'white'
            }}>

                <div style={{
                    backgroundColor: 'white', width: '100%', height: '100%', padding: '30px', textAlign: 'center' }}>

                    მომხმარებელი წარმატებით დარეგისტრირდა, შეამოწმეთ ელ.ფოსტა

                </div>
            </div>

        )
    }
}

export { Verification }; 