import React, { Component } from 'react';
import { Row, Col, Accordion } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import { CustomToggle } from '../custom/CustomToggle'
import './Saxelmdzgvanelo.css';
import Carousel from 'react-multi-carousel';
export class Saxelmdzgvanelo extends Component {
    static displayName = Saxelmdzgvanelo.name;
    constructor(props) {
        super(props);
        this.state = {
            data: {
                eventStartDate: '', eventEndDate: '', eventType: []
            }
        }
    }


    render() {
        var eventOpen = (this.state.data.eventType.length > 0 || this.state.data.eventStartDate > 0 || this.state.data.eventEndDate > 0);
        return (
            <div>

                <div className="AboutUsMainDivManual" style={{ "maxWidth": "1128px", "margin": "0 auto" }}>

                    <div className="accardionPaddingManualHeadText boldHeadeManual" style={{
                        bottom: '0'
                    }}>
                        რა არის სწრაფი შეტყობინების ფორმა?
                 </div>
                    <div className="accardionPaddingManual">
                        ,,სწრაფი შეტყობინების ფორმა“ წარმოადგენს, გელათის მსოფლიო მემკვიდრეობის კომპლექსურ ძეგლზე და მის შემადგენელ კომპონენტებზე დაზიანება/საფრთხის გამოვლენის შემთხვევაში ინფორმაციის დროულად მიწოდების სერვისს. მარტივი, სტანდარტიზებული ფორმა შემუშავდა საერთაშორისო და ეროვნულ სტანდარტებზე დაფუძნებით, იგი შედგება ძირითადი - სავალდებულო და დეტალური - არასავალდებულო ნაწილებისაგან.                        <p />
                        <p />
                        ავტორიზირებულ მომხმარებელს შეუძლია შეავსოს განაცხადი, დაურთოს ძეგლის ფიზიკური მდგომარეობის ამსახველი ფოტო მასალა და დროულად მიაწოდოს ინფორმაცია შესაბამის სამსახურებს.
                                                <p />
                        „სწრაფი შეტყობინების ფორმა“ შემუშავდა - 2020-2021 წლის UNESCO-ს ,,მონაწილეობის პროგრამის“ ფარგლებში განხორციელებული პროექტის "საქართველოში მსოფლიო მემკვიდრეობის ძეგლების საკონსერვაციო მდგომარეობის კვლევის გაუმჯობესების მიზნით, შეფასების, ანგარიშგებისა და დოკუმენტაციის სისტემატიზაციის GIS ინსტრუმენტის შემუშავება" საფუძველზე.
                        </div>



                    {/*<div className="accardionPaddingManual">*/}

                    <div className="saxelmdzgvaneloDivManual">
                        <div className="whiteandtop">
                            <div className="accardionPaddingManualLeft boldHeadeManual" style={{
                                bottom: '0'
                            }}>
                                სახელმძღვანელო
                 </div>
                            <div className="saxelmdzgvaneloText">
                                „სწრაფი შეტყობინების ფორმა“ იყოფა „ძირითადი“ და „დეტალური აღწერის“ ნაწილებად.<br></br>
                            ფორმაში წარმოდგენილია შესავსები გრაფები და ჩაშლადი, კლასიფიცირებული მენიუები, სადაც შეიძლება მოინიშნოს ერთი ან რამდენიმე მნიშვნელობა.
                        <p />
                            ძირითად ნაწილში მოცემული მენიუები, შესავსები გრაფები და ფოტო/დოკუმენტის ატვირთვის ბლოკი სავალდებულოა, რომელიც აღნიშნულია წითელი ფიფქით “*”.
                            </div>
                        </div>
                    </div>
                    <div className="accardionPaddingBody boldHeadeManual hideDziritadiNawiliText" style={{
                        bottom: '0'
                    }}>
                        ძირითადი ნაწილი
                          </div>



                </div>
                <div className="ManualBodyWide">

                    <div className="AboutUsMainDivManual" style={{ "maxWidth": "1128px", "margin": "0 auto" }}>
                        <Row className="accardionPaddingBody aboutUsFooterManual">
                            <Col md={6} style={{ textAlign: 'center', paddingTop: '25px' }}>

                                <div className="GancxadebisMizaniFlex">
                                    <div className="GancxadebisMizani1">
                                        <span>
                                            <p className="DziriTadiNawiliTitle">განცხადების მიზანი</p>
                                            <p className="DziriTadiNawiliText"> „არსებული დაზიანების“ მონიშვნით, განცხადება ივსება ძეგლზე გამოვლენილ დაზიანებაზე; „მოსალოდნელი საფრთხის“ არჩევისას - ძეგლზე არსებულ რისკებზე.</p>
                                        </span>
                                        <img alt="" variant="top" src="/images/manual/MobileChecked1.png" className="MobileChecked1" />
                                    </div>

                                    <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1">
                                        <span>
                                            <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">დაზიანების/საფრთხის აღწერა</p>
                                            <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText"> ტექსტუალური ველია, რომელშიც თავისუფალი ტექსტის სახით იწერება, დაზიანების/საფრთხის აღწერა.</p>
                                        </span>
                                        <img alt="" variant="top" src="/images/manual/MobileMoreDetails2.png" className="MobileChecked1" />
                                    </div>

                                </div>
                            </Col>
                            <Col md={6} style={{ textAlign: 'center', paddingTop: '25px' }}>
                                <div className="GancxadebisMizaniFlex">
                                    <div className="GancxadebisMizani1">
                                        <span>
                                            <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">დაზიანების ადგილმდებარეობა</p>
                                            <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText"> კლასიფიცირებული მოსანიშნი მენიუა, სადაც ინიშნება ძეგლის რომელ მონაკვეთში ფიქსირდება დაზიანება/მოსალოდნელი საფრთხე. მას ერთვის კომენტარის გრაფა, რომელშიც უნდა აღიწეროს დაზიანების/მოსალოდნელი საფრთხის ზუსტი მდებარეობა.</p>
                                        </span>
                                        <img alt="" variant="top" src="/images/manual/MobileMoreDetails1.png" className="MobileChecked22" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div style={{ background: "#FBFBFB" }}>
                        <div className="maxWidthMargin">
                            <Row className="accardionPaddingBody aboutUsFooterManual" >
                                <Col md={5} style={{ paddingTop: '25px' }}>
                                    ძირითადი ფორმის შევსების ბოლო ეტაპზე, მომხმარებელი ვალდებულია ატვირთოს ფოტო მასალა, დაზიანება/საფრთხის საილუსტრაციოდ და სურვილისამებრ მიაბას დამატებითი დოკუმენტაცია.
                            <p />
                        მობილურ ვერსიაში - ფოტო მასალისა და დამატებითი დოკუმენტაციის ასატვირთად, განთავსებულია ორი განსხვავებული ღილაკი. ფოტოს ღილაკზე დაჭერით           ირთვება გადაღების რეჟიმი, რომლის მეშვეობითაც შესაძლებელია ფოტოს გადაღება, ან ალბომში გადასვლა და გასაგზავნი ფოტოების არჩევა. „დოკუმენტის ატვირთვის“ ღილაკზე            დაჭერით, კი შესაძლებელია ნებისმიერი ფორმატის დოკუმენტის მიბმა.
                            </Col>
                                <Col md={6} style={{ textAlign: 'center', paddingTop: '25px' }}>
                                    <a href="https://heritagesites.ge/ka" rel="noopener noreferrer" target="_blank">
                                        <img style={{ "marginRight": "31px" }} alt="" variant="top" src="/images/manual/foto1.png" className="" /></a>
                                    <a href="https://www.riksantikvaren.no/" rel="noopener noreferrer" target="_blank">
                                        <img alt="" variant="top" src="/images/manual/foto2.png" className="" /></a>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="AboutUsMainDivManual" style={{ "maxWidth": "1128px", "margin": "0 auto" }}>
                        <Row className="accardionPaddingBody aboutUsFooterManual">
                            <Col md={5} style={{ paddingTop: '25px' }}>
                                <div className="GancxadebisMizaniFlex">
                                    <div className="GancxadebisMizani1">
                                        <span>
                                            <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">ფოტოს ატვირთვა კომპიუტერით</p>
                                            <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText DziriTadiNawiliText75">ღილაკი - „ფაილის არჩევით”   ფოტო მასალა და ზოგადი დოკუმენტაცია იტვირთება.</p>
                                        </span>
                                        <img alt="" variant="top" src="/images/manual/foto3.png" className="MobileChecked2" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="accardionPaddingBody aboutUsFooterManual">
                            <Col md={5.5} className="ColMd55">
                                ღილაკზე „შემდეგ“ დაჭერისას, შემვსები გადადის განცხადების გაგზავნის საფეხურზე.
                            <p />
                        სავალდებულო ველების გამორჩენის შემთხვევაში გამოჩნდება „ფანჯარა“ ტექსტური გაფრთხილებით. ფანჯრის დახურვის შემდეგ, სავალდებულო ველები გაწითლდება და გაჩნდება მიმანიშნებელი წარწერები.
                            </Col>
                            <Col md={7} style={{ textAlign: 'center', paddingTop: '25px' }}>
                                <a href="https://heritagesites.ge/ka" rel="noopener noreferrer" target="_blank">
                                    <img style={{ "marginRight": "31px", "width":"270px" }} alt="" variant="top" src="/images/manual/notification1.png" className="" /></a>
                                <a href="https://www.riksantikvaren.no/" rel="noopener noreferrer" target="_blank">
                                    <img style={{ "width": "270px" }}  className="notification2Img" alt="" variant="top" src="/images/manual/notification2.png" /></a>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ background: 'white' }}>
                        <div className="AboutUsMainDivManualWhite" style={{ "maxWidth": "1128px", "margin": "0 auto" }}>
                            <div className="accardionPaddingBodyMargin boldHeadeManual" style={{
                                bottom: '0'
                            }}>
                                დეტალური აღწერა
                          </div>
                            <div className="detaluriAgweraText">
                                ძეგლზე დაზიანება/საფრთხის დეტალური აღწერის სურვილის შემთხვევაში, მომხმარებელს შეუძლია შეავსოს დეტალური აღწერის ბლოკში მოცემული ველები.
                            </div>
                            <p />

                            <Row className="accardionPaddingManual aboutUsFooterManual">
                                <Col md={6} style={{ textAlign: 'center', paddingTop: '25px' }}>

                                    <div className="GancxadebisMizaniFlex">
                                        <div className="GancxadebisMizani1Gray">
                                            <span>
                                                <p className="DziriTadiNawiliTitle">დაზიანების ხარისხი</p>
                                                <p className="DziriTadiNawiliText">მომხმარებელს შეუძლია აირჩიოს დაზიანება/საფრთხის ხარისხის კლასიფიკატორებიდან ერთ-ერთი. </p>
                                            </span>
                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera1.png" className="MobileChecked1" />
                                        </div>
                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                            <span>
                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">ფაქტორები რომლებიც ზემოქმედებს ობიექტზე/ძეგლზე</p>
                                                <p style={{ "paddingTop": "50px" }} className="DziriTadiNawiliText DziriTadiNawiliTitlePadding"> შედგება სამი ძირითადი მოსანიშნი კლასიფიკატორისგან, აქედან ორი - „ბუნებრივი გარემო-პირობები“ და „ადამიანის საქმიანობა“ ჩაშლადი ველია, ხოლო „სხვა ტიპის ზემოქმედება“ - მოსანიშნი. გარდა ამისა, მას ერთვის კომენტარის ჩასაწერი გრაფა, სადაც მომხმარებელი აღწერს გამომწვევ ფაქტორებს.</p>
                                            </span>
                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera3.png" className="MobileChecked3" />
                                        </div>
                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                            <span>
                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">რეკომენდაცია</p>
                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">ივსება იმ შემთხვევაში, თუ განმცხადებელს აქვს სურვილი, ჩაწეროს ინფორმაცია ჩასატარებელ სამუშაოებზე, განსახორციელებელ ღონისძიებებზე.</p>
                                            </span>
                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera5.png" className="MobileChecked5" />
                                        </div>
                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                            <span>
                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">კომენტარი</p>
                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">მომხმარებელს სურვილისამებრ შეუძლია დაამატოს ინფორმაცია.</p>
                                            </span>
                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera8.png" className="MobileChecked7" />
                                        </div>
                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                            <span>
                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">ინფორმაციული ფანჯარა</p>
                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">პირადი ინფორმაციის შევსების შემდეგ, ღილაკზე „გაგზავნა“ დაჭერით, გამოჩნდება ინფორმაციული ფანჯარა, რომ შეტყობინება მიღებულია</p>
                                            </span>
                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera9.png" className="MobileChecked9" />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} style={{ textAlign: 'center', paddingTop: '25px' }}>
                                    <div className="GancxadebisMizaniFlex">
                                        <div className="GancxadebisMizani1Gray">
                                            <span>
                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">დაზიანების ტიპი</p>
                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">ინფორმაციის ფლობის შემთხვევაში, მომხმარებელს შეუძლია აირჩიოს ერთი ან რამდენიმე დაზიანების ტიპი.</p>
                                            </span>
                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera2.png" className="MobileChecked2" />
                                        </div>
                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                            <span>
                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">დაზიანების მიზეზი</p>
                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">ინფორმაციის ქონის შემთხვევაში, განმცხადებელს შეუძლია ჩაწეროს, ზოგადი ფაქტორებით გამოწვეული დაზიანების კონკრეტული მიზეზები.</p>
                                            </span>
                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera4.png" className="MobileChecked4" />
                                        </div>
                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                            <span>
                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">მოსალოდნელი საფრთხეები</p>
                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">მომხმარებელს შეუძლია შეავსოს ძეგლზე რისკ-ფაქტორების გამოვლენისას.</p>
                                            </span>
                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera6.png" className="MobileChecked6" />
                                        </div>
                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                            <span>
                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">პირადი ინფორმაცია</p>
                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">ღილაკზე „შემდეგ“ დაჭერისას, განმცხადებელი გადადის პირადი ინფორმაციის „ფანჯარაზე“, რომელიც შეიცავს შემდეგ გრაფებს: სახელ-გვარს, ელ.ფოსტას, ტელეფონსა და პირად ნომერს.</p>
                                            </span>
                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera7.png" className="MobileChecked8" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>


                </div>
                <div className="ManualBodyMobile">
                    <Accordion defaultActiveKey={eventOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                        <Card className="modelCard">
                            <Card.Header className="TogglePanelHeader">
                                <CustomToggle eventKey="1" className="alertModalSubTitleText alertModalTitleTextDetaluri" isOpen={eventOpen}>ძირითადი ნაწილი</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <div className="AboutUsMainDivManual" style={{ "maxWidth": "1128px", "margin": "0 auto" }}>
                                        <Row className="accardionPaddingBody aboutUsFooterManual">
                                            <Col md={6} style={{ textAlign: 'center', paddingTop: '25px' }}>

                                                <div className="GancxadebisMizaniFlex">
                                                    <div className="GancxadebisMizani1">
                                                        <span>
                                                            <p className="DziriTadiNawiliTitle">განცხადების მიზანი</p>
                                                            <p className="DziriTadiNawiliText"> „არსებული დაზიანების“ მონიშვნით, განცხადება ივსება ძეგლზე გამოვლენილ დაზიანებაზე; „მოსალოდნელი საფრთხის“ არჩევისას - ძეგლზე არსებულ რისკებზე.</p>
                                                        </span>
                                                        <img alt="" variant="top" src="/images/manual/MobileChecked1.png" className="MobileChecked1" />
                                                    </div>

                                                    <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1">
                                                        <span>
                                                            <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">დაზიანების/საფრთხის აღწერა</p>
                                                            <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText"> ტექსტუალური ველია, რომელშიც თავისუფალი ტექსტის სახით იწერება, დაზიანების/საფრთხის აღწერა.</p>
                                                        </span>
                                                        <img alt="" variant="top" src="/images/manual/MobileMoreDetails2.png" className="MobileChecked1" />
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col md={6} style={{ textAlign: 'center', paddingTop: '25px' }}>
                                                <div className="GancxadebisMizaniFlex">
                                                    <div className="GancxadebisMizani1">
                                                        <span>
                                                            <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">დაზიანების ადგილმდებარეობა</p>
                                                            <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText"> კლასიფიცირებული მოსანიშნი მენიუა, სადაც ინიშნება ძეგლის რომელ მონაკვეთში ფიქსირდება დაზიანება/მოსალოდნელი საფრთხე. მას ერთვის კომენტარის გრაფა, რომელშიც უნდა აღიწეროს დაზიანების/მოსალოდნელი საფრთხის ზუსტი მდებარეობა.</p>
                                                        </span>
                                                        <img alt="" variant="top" src="/images/manual/MobileMoreDetails1.png" className="MobileChecked22" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{ background: "#FBFBFB" }}>
                                        <div className="maxWidthMargin">
                                            <Row className="accardionPaddingBody aboutUsFooterManual" >
                                                <Col md={5} style={{ paddingTop: '25px' }}>
                                                    ძირითადი ფორმის შევსების ბოლო ეტაპზე, მომხმარებელი ვალდებულია ატვირთოს ფოტო მასალა, დაზიანება/საფრთხის საილუსტრაციოდ და სურვილისამებრ მიაბას დამატებითი დოკუმენტაცია.
                            <p />
                        მობილურ ვერსიაში - ფოტო მასალისა და დამატებითი დოკუმენტაციის ასატვირთად, განთავსებულია ორი განსხვავებული ღილაკი. ფოტოს ღილაკზე დაჭერით           ირთვება გადაღების რეჟიმი, რომლის მეშვეობითაც შესაძლებელია ფოტოს გადაღება, ან ალბომში გადასვლა და გასაგზავნი ფოტოების არჩევა. „დოკუმენტის ატვირთვის“ ღილაკზე            დაჭერით, კი შესაძლებელია ნებისმიერი ფორმატის დოკუმენტის მიბმა.
                            </Col>
                                                <Col md={6} style={{ textAlign: 'center', paddingTop: '25px' }}>
                                                    <a href="https://heritagesites.ge/ka" rel="noopener noreferrer" target="_blank">
                                                        <img style={{ "marginRight": "31px" }} alt="" variant="top" src="/images/manual/foto1.png" className="" /></a>
                                                    <a href="https://www.riksantikvaren.no/" rel="noopener noreferrer" target="_blank">
                                                        <img alt="" variant="top" src="/images/manual/foto2.png" className="" /></a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="AboutUsMainDivManual" style={{ "maxWidth": "1128px", "margin": "0 auto" }}>
                                        <Row className="accardionPaddingBody aboutUsFooterManual">
                                            <Col md={5} style={{ paddingTop: '25px' }}>
                                                <div className="GancxadebisMizaniFlex">
                                                    <div className="GancxadebisMizani1">
                                                        <span>
                                                            <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">ფოტოს ატვირთვა კომპიუტერით</p>
                                                            <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText DziriTadiNawiliText75">ღილაკი - „ფაილის არჩევით”   ფოტო მასალა და ზოგადი დოკუმენტაცია იტვირთება.</p>
                                                        </span>
                                                        <img alt="" variant="top" src="/images/manual/foto3.png" className="MobileChecked2" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="accardionPaddingBody aboutUsFooterManual">
                                            <Col md={5.5} className="ColMd55">
                                                ღილაკზე „შემდეგ“ დაჭერისას, შემვსები გადადის განცხადების გაგზავნის საფეხურზე.
                            <p />
                        სავალდებულო ველების გამორჩენის შემთხვევაში გამოჩნდება „ფანჯარა“ ტექსტური გაფრთხილებით. ფანჯრის დახურვის შემდეგ, სავალდებულო ველები გაწითლდება და გაჩნდება მიმანიშნებელი წარწერები.
                            </Col>
                                            <Col md={7} style={{ textAlign: 'center', paddingTop: '25px' }}>
                                                <a href="https://heritagesites.ge/ka" rel="noopener noreferrer" target="_blank">
                                                    <img style={{ "marginRight": "31px", "width" : "270px" }} alt="" variant="top" src="/images/manual/notification1.png" className="" /></a>
                                                <a href="https://www.riksantikvaren.no/" rel="noopener noreferrer" target="_blank">
                                                    <img style={{ "width": "270px" }} className="notification2Img" alt="" variant="top" src="/images/manual/notification2.png" /></a>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <Accordion defaultActiveKey={eventOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                        <Card className="modelCard">
                            <Card.Header className="TogglePanelHeader">
                                <CustomToggle eventKey="1" className="alertModalSubTitleText alertModalTitleTextDetaluri" isOpen={eventOpen}>დეტალური აღწერა</CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>

                                    <div style={{ background: 'white' }}>
                                        <div className="AboutUsMainDivManualWhite" style={{ "maxWidth": "1128px", "margin": "0 auto" }}>
                                            <div className="accardionPaddingBodyMargin boldHeadeManual" style={{
                                                bottom: '0'
                                            }}>
                                                დეტალური აღწერა
                          </div>
                                            <div className="detaluriAgweraText">
                                                ძეგლზე დაზიანება/საფრთხის დეტალური აღწერის სურვილის შემთხვევაში, მომხმარებელს შეუძლია შეავსოს დეტალური აღწერის ბლოკში მოცემული ველები.
                            </div>
                                            <p />

                                            <Row className="accardionPaddingManual aboutUsFooterManual">
                                                <Col md={6} style={{ textAlign: 'center', paddingTop: '25px' }}>

                                                    <div className="GancxadebisMizaniFlex">
                                                        <div className="GancxadebisMizani1Gray">
                                                            <span>
                                                                <p className="DziriTadiNawiliTitle">დაზიანების ხარისხი</p>
                                                                <p className="DziriTadiNawiliText">მომხმარებელს შეუძლია აირჩიოს დაზიანება/საფრთხის ხარისხის კლასიფიკატორებიდან ერთ-ერთი. </p>
                                                            </span>
                                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera1.png" className="MobileChecked1" />
                                                        </div>
                                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                                            <span>
                                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">ფაქტორები რომლებიც ზემოქმედებს ობიექტზე/ძეგლზე</p>
                                                                <p style={{ "paddingTop": "50px" }} className="DziriTadiNawiliText DziriTadiNawiliTitlePadding"> შედგება სამი ძირითადი მოსანიშნი კლასიფიკატორისგან, აქედან ორი - „ბუნებრივი გარემო-პირობები“ და „ადამიანის საქმიანობა“ ჩაშლადი ველია, ხოლო „სხვა ტიპის ზემოქმედება“ - მოსანიშნი. გარდა ამისა, მას ერთვის კომენტარის ჩასაწერი გრაფა, სადაც მომხმარებელი აღწერს გამომწვევ ფაქტორებს.</p>
                                                            </span>
                                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera3.png" className="MobileChecked3" />
                                                        </div>
                                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                                            <span>
                                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">რეკომენდაცია</p>
                                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">ივსება იმ შემთხვევაში, თუ განმცხადებელს აქვს სურვილი, ჩაწეროს ინფორმაცია ჩასატარებელ სამუშაოებზე, განსახორციელებელ ღონისძიებებზე.</p>
                                                            </span>
                                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera5.png" className="MobileChecked5" />
                                                        </div>
                                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                                            <span>
                                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">კომენტარი</p>
                                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">მომხმარებელს სურვილისამებრ შეუძლია დაამატოს ინფორმაცია.</p>
                                                            </span>
                                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera8.png" className="MobileChecked7" />
                                                        </div>
                                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                                            <span>
                                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">ინფორმაციული ფანჯარა</p>
                                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">პირადი ინფორმაციის შევსების შემდეგ, ღილაკზე „გაგზავნა“ დაჭერით, გამოჩნდება ინფორმაციული ფანჯარა, რომ შეტყობინება მიღებულია</p>
                                                            </span>
                                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera9.png" className="MobileChecked9" />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={6} style={{ textAlign: 'center', paddingTop: '25px' }}>
                                                    <div className="GancxadebisMizaniFlex">
                                                        <div className="GancxadebisMizani1Gray">
                                                            <span>
                                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">დაზიანების ტიპი</p>
                                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">ინფორმაციის ფლობის შემთხვევაში, მომხმარებელს შეუძლია აირჩიოს ერთი ან რამდენიმე დაზიანების ტიპი.</p>
                                                            </span>
                                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera2.png" className="MobileChecked2" />
                                                        </div>
                                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                                            <span>
                                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">დაზიანების მიზეზი</p>
                                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">ინფორმაციის ქონის შემთხვევაში, განმცხადებელს შეუძლია ჩაწეროს, ზოგადი ფაქტორებით გამოწვეული დაზიანების კონკრეტული მიზეზები.</p>
                                                            </span>
                                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera4.png" className="MobileChecked4" />
                                                        </div>
                                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                                            <span>
                                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">მოსალოდნელი საფრთხეები</p>
                                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">მომხმარებელს შეუძლია შეავსოს ძეგლზე რისკ-ფაქტორების გამოვლენისას.</p>
                                                            </span>
                                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera6.png" className="MobileChecked6" />
                                                        </div>
                                                        <div style={{ "marginTop": "72px" }} className="GancxadebisMizani1Gray">
                                                            <span>
                                                                <p style={{ "textAlign": "left" }} className="DziriTadiNawiliTitle">პირადი ინფორმაცია</p>
                                                                <p style={{ "paddingTop": "16px" }} className="DziriTadiNawiliText">ღილაკზე „შემდეგ“ დაჭერისას, განმცხადებელი გადადის პირადი ინფორმაციის „ფანჯარაზე“, რომელიც შეიცავს შემდეგ გრაფებს: სახელ-გვარს, ელ.ფოსტას, ტელეფონსა და პირად ნომერს</p>
                                                            </span>
                                                            <img alt="" variant="top" src="/images/manual/DetaluriAgwera7.png" className="MobileChecked8" />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </div>
        );
    }

}
