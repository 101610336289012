import React from 'react';
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
//import './Home.css';
import { QuickAlertModal } from './User/QuickAlertModal';
import { authenticationService } from './../_services';


const cardText = {
    width: 'auto',
    fontSize: '10px'
}
class ObjectListForLinks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pager: {},
            pageOfItems: [],
            loading: true,
            currentUser: null
        };
    }

    componentDidMount() {
        this.loadPage(1);
        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x, isAdmin: x && x.role === "Admin", isAdminG: x && x.role === "AdminG",
        }));
    }

    componentDidUpdate() {

        // this.loadPage(1);

    }

    async loadPage(page) {
        var type;
        switch (this.props.type) {
            case "immovable":
                type = `api/Immovable/GetImmovableList?count=6&page=${page}`;
                break;
            case "userAlert":
                type = `api/Immovable/GetUserList?count=6&page=${page}`;
                break;
            case "movable":
                type = `api/Movable/GetMovableList?count=6&page=${page}`;
                break;
            case "zone":
                type = `api/SafetyZone/GetSafetyZoneList?count=6&page=${page}`;
                break;
            case "museum":
                type = `api/Museum/GetMuseumsList?count=6&page=${page}`;
                break;
            case "wallart":
                type = `api/WallArt/GetWallArtList?count=6&page=${page}`;
                break;
            case "wallartPainting":
                type = `api/WallArt/GetWallArtPaintingList?count=6&page=${page}&wallID=${this.props.objID}&curpaintID=${this.props.curobjID}`;
                break;
            case "stoneengrave":
                type = `api/StoneEngrave/GetStoneEngraveList?count=6&page=${page}`;
                break;
            case "stoneengraveMembership":
                type = `api/StoneEngrave/GetStoneEngraveMembershipList?count=6&page=${page}&stoneID=${this.props.objID}&curmemberID=${this.props.curobjID}`;
                break;
            default: type = '';
                break;
        }

        if (page !== this.state.pager.currentPage) {
            const response = await fetch(type, {
                method: "POST",
                body: JSON.stringify(this.props.idList),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            this.setState({ pager: data, pageOfItems: data.aaData, loading: false });
        }

    }

    drawImovableList(forecasts, className) {
        return (
            this.props.type != "userAlert" ?
                <CardGroup className="objCardGroup">
                    {forecasts.map(forecast =>
                        <a key={forecast.id} href={'../' + forecast.linkPath} className="mainCard">
                            <Card key={forecast.id} className="cardHorizonal">
                                <div className={`${forecast.photoAddress === '' ? className : 'imgDivObjListHorizontal'} cardImg`}>
                                    {forecast.photoAddress == null || forecast.photoAddress === '' ?
                                        <></> :
                                        <img variant="top" src={forecast.photoAddress} alt="" style={{ width: '100%', height: '100%' }} />
                                    }
                                </div>
                                <Card.Body>
                                    <div className="cardObjHeader">{forecast.objectName.length > 30 ? forecast.objectName.substring(0, 30) + "..." : forecast.objectName}</div>
                                    <Card.Text style={cardText}>
                                        {forecast.variety}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </a>
                    )}
                </CardGroup>
                :
                <CardGroup className="objCardGroup">
                    {forecasts.map(forecast =>

                        <QuickAlertModal ref={this.quickAlert}
                            AdminG={this.state.currentUser == null ? "" : this.state.currentUser.role}
                            filterResulthandle={this.filterResulthandle}
                            classificators={this.state.pager.classificator}
                            ObjectId={forecast.id}
                            ObjectName={forecast.objectName}
                            PhotoAddress={forecast.photoAddress}
                            immovableAlertLink={true}
                            id={forecast.id}
                            forecast={forecast}
                        >
                        </QuickAlertModal>

                    )}
                </CardGroup>
        );
    }

    goToObjLink(e) {
        //e.preventDefault();
        //var path = "";
        //switch (this.props.type) {
        //    case "immovable":
        //        path = "/objects/immovable";
        //        break;
        //    case "movable":
        //        path = "/objects/movable";
        //        break;
        //    case "zone":
        //        path = "/objects/zone";
        //        break;
        //    case "museum":
        //        path = "/objects/museum";
        //        break;
        //    case "wallart": case "wallartPainting":
        //        path = "/objects/wallart";
        //        break;            
        //    case "stoneengrave": case "stoneengraveMembership":
        //        path = "/objects/stoneengrave";
        //        break;
        //    case "event":
        //        path = "/objects/event";
        //        break;

        //    default: path = '';
        //        break;
        //}
        //sessionStorage.setItem('objType', this.props.objType);
        //sessionStorage.setItem('objID', this.props.objID);
        //sessionStorage.setItem('isLink', "true");
        //sessionStorage.setItem('filterResult', this.props.idList);
        //window.location.href = path;
    }

    getImgClassName() {
        var type = "";
        switch (this.props.type) {
            case "immovable":
                type = 'ImageDivImmovableSmall';
                break;
            case "movable":
                type = 'ImageDivMovableSmall';
                break;
            case "zone":
                type = 'ImageDivZoneSmall';
                break;
            case "museum":
                type = 'ImageDivMuseum';
                break;
            case "wallart":
                type = 'ImageDivWallArt';
                break;
            case "wallartPainting":
                type = 'ImageDivWallArt';
                break;
            case "stoneengrave":
                type = 'ImageDivStoneEngrave';
                break;
            case "stoneengraveMembership":
                type = 'ImageDivStoneEngrave';
                break;
            case "document":
                type = 'ImageDivDocumentSmall';
                break;
            case "event":
                type = 'ImageDivEventSmall';
                break;
            default: type = '';
                break;
        }

        return type;
    }

    render() {
        const { pager, pageOfItems } = this.state;
        return (
            <div className="card text-center m-3">
                <div className="card-body">
                    {
                        this.state.loading
                            ? <p><em>Loading...</em></p>
                            : this.drawImovableList(pageOfItems, this.getImgClassName())
                    }
                </div>
                <div>
                    {this.props.type != "userAlert" ?
                    pager.pages && pager.pages.length > 0 &&
                        <a rel="noopener noreferrer" target="_blank" href={"/objects/" + (this.props.type === "wallartPainting" || this.props.type === "stoneengraveMembership"
                        ? this.props.objType
                        : this.props.type) + "?objID=" + this.props.objID + "&type=" + this.props.objType + "&objName=" 
                        + this.props.objName} onClick={this.goToObjLink.bind(this)} className="AllCompText" >სრული სია ({pager.iTotalRecords.toLocaleString()})</a>
                    
                    :
                    pager.pages && pager.pages.length > 0 &&
                        <a rel="noopener noreferrer" target="_blank" href={"/objects/immovable" + "?objID=" + this.props.objID + "&type=userAlert" + "&objName="
                            + this.props.objName} onClick={this.goToObjLink.bind(this)} className="AllCompText" >სრული სია ({pager.iTotalRecords.toLocaleString()})</a>
                    }
                </div>
            </div>
        );
    }
}

export { ObjectListForLinks };