import React from 'react';
import CreatableSelect from 'react-select';
//import CreatableSelect from 'react-select/creatable';
export class CustomCreatableMultipleSelectSearch extends React.Component {
    //constructor(props) {
    //    super(props);
    //    this.state = {

    //    };
    //}


    formatCreateLabel = (inputValue) => {
        return (<span>{inputValue}</span>);
    };

    formatGroupLabel = data => (
        <div>
            <span>{data.label}</span>
        </div>
    );

    filterOption = (option, inputValue) => {
        if (inputValue.length >= 3) {
            if (option.data.name != null)
                return option.data.name.includes(inputValue);
        }
         return false;
    };

    render() {
        let res = Math.max.apply(Math, this.props.items.map(function (o) { return o.id; }));
        const increment = () => {
            res++;            
        };

        var options = [];
        this.props.items.map(item =>
            options.push({ value: item.id, label: item.name, name: item.tempName})
        );

        var selectedValues = [];
        selectedValues = this.props.selectedOption.map(item => {
            var t = {};
            
            if (this.props.items.filter(el => el.name === item).length > 0) {
                t.value = this.props.items.filter(el => el.name === item)[0].id
                t.label = this.props.items.filter(el => el.name === item)[0].name;
                t.name = this.props.items.filter(el => el.name === item)[0].tempName;
            }
            else {
                increment()               
                t.value = res;
                t.label = item;
                t.name = item;
            }
            return t;
        });
        const customStyles = {
            option: (styles, state) => ({
                ...styles,
                color: state.isSelected ? "#E57959" : styles.color,
                backgroundColor: state.isSelected ? "transparent" : styles.color,
                //borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
                "&:hover": {
                    color: "#E57959",
                    backgroundColor: "transparent"
                }
            }),
            control: (styles, state) => ({
                ...styles,
                boxShadow: 0,
                borderColor: state.isFocused ? "#ced4da" : "#ced4da",
                "&:hover": {
                    borderColor: state.isFocused ? "#ced4da" : "#ced4da"
                }
            })
        };
        return (
            <div className="searchDivRowInput">
                <CreatableSelect
                    isMulti
                    blurInputOnSelect={false}
                    onChange={this.props.changeHandle}
                    //onKeyDown={this.props.onKeyDown}
                    placeholder=""
                    isDisabled={this.props.isDisabled}
                    value={selectedValues}
                    options={options}
                    openMenuOnClick={false}
                    noOptionsMessage={() => " "}
                    isClearable={true}
                    formatCreateLabel={this.formatCreateLabel}
                    filterOption={this.filterOption}
                    styles={customStyles}
                />
                {
                    //(this.props.displayNum === true) ?
                    //    <input type="text" className="form-control contactText" name="buildingNum" id="buildingNum" value={this.props.buildingNum} onChange={this.props.buildingChange} disabled={this.props.isDisabled} />
                    //:<></>
                }
            </div>
        );
    }
}
