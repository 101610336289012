import React, { Component } from 'react';
import '../ImmovableList.css';

import { UserListVIew } from './UserListVIew';

export class UserList extends Component {
    componentDidMount() {
      
       
    }

    static displayName = UserList.name;

    render() {
        return (
            <div className="immListDiv" style={{ backgroundColor: 'white' }}>

                <UserListVIew type="userList" imgClassName="imgDivUser"/>

            </div>
        );
    }

}