import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Button, Row, Col, Accordion, Card } from 'react-bootstrap'
import { CustomToggle } from './custom/CustomToggle'
import './Legend.css';
import InputRange from 'react-input-range'
import "react-input-range/lib/css/index.css"

export class WebMapLegend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            archZoneValue: 0.5,
            landZoneValue: 0.5,
            regZoneValue: 0.5,
            istganZoneValue: 0.5,
            worldZoneValue: 0.5,
            visZoneValue: 0.5,
            physZoneValue: 0.5,
            immValue: 0.7,
            immPointValue: 1,
            musResValue: 0.7,
            museumValue: 1,
            archZoneCheck: this.props.archZoneCheck,
            landZoneCheck: this.props.landZoneCheck,
            regZoneCheck: this.props.regZoneCheck,
            istganZoneCheck: this.props.istganZoneCheck,
            worldZoneCheck: this.props.worldZoneCheck,
            visZoneCheck: this.props.visZoneCheck,
            physZoneCheck: this.props.physZoneCheck,
            immCheck: this.props.immCheck,
            immPointCheck: this.props.immPointCheck,
            musResCheck: this.props.musResCheck,
            museumCheck: this.props.museumCheck
        };

        this.changeLayerOpacity = this.changeLayerOpacity.bind(this);

    }

    static getDerivedStateFromProps(props, state) {
        if (state.show === true)
            return null;
        return {
            archZoneCheck: props.archZoneCheck,
            landZoneCheck: props.landZoneCheck,
            regZoneCheck: props.regZoneCheck,
            istganZoneCheck: props.istganZoneCheck,
            worldZoneCheck: props.worldZoneCheck,
            visZoneCheck: props.visZoneCheck,
            physZoneCheck: props.physZoneCheck,
            immCheck: props.immCheck,
            immPointCheck: props.immPointCheck,
            musResCheck: props.musResCheck,
            museumCheck: props.museumCheck
        }
        
        
    }

    handleClose = () => this.setState({ show: false });
    handleShow = () => {
        this.setState({ show: true });

        if (window.checkZoneVisiblity != null) {
            var value = window.checkZoneVisiblity();
            if (this.props.physZoneCheck !== value) {
                this.setState({ visZoneCheck: false, physZoneCheck: false });
            }
        }
    }

    changeLayerOpacity(type, value) {
        switch (type) {
            case "arch":
                this.setState({ archZoneValue: value });
                if (window.changeOpacity != null)
                    window.changeOpacity(type, value);
                break;
            case "land":
                this.setState({ landZoneValue: value });
                if (window.changeOpacity != null)
                    window.changeOpacity(type, value);
                break;
            case "reg":
                this.setState({ regZoneValue: value });
                if (window.changeOpacity != null)
                    window.changeOpacity(type, value);
                break;
            case "istgan":
                this.setState({ istganZoneValue: value });
                if (window.changeOpacity != null)
                    window.changeOpacity(type, value);
                break;
            case "world":
                this.setState({ worldZoneValue: value });
                if (window.changeOpacity != null)
                    window.changeOpacity(type, value);
                break;
            case "vis":
                this.setState({ visZoneValue: value });
                if (window.changeOpacity != null)
                    window.changeOpacity(type, value);
                break;
            case "phys":
                this.setState({ physZoneValue: value });
                if (window.changeOpacity != null)
                    window.changeOpacity(type, value);
                break;
            case "imm":
                this.setState({ immValue: value });
                if (window.changeOpacity != null)
                    window.changeOpacity(type, value);
                break;
            case "immPoint":
                this.setState({ immPointValue: value });
                if (window.changeOpacity != null)
                    window.changeOpacity(type, value);
                break;
            case "musRes":
                this.setState({ musResValue: value });
                if (window.changeOpacity != null)
                    window.changeOpacity(type, value);
                break;
            case "museum":
                this.setState({ museumValue: value });
                if (window.changeOpacity != null)
                    window.changeOpacity(type, value);
                break;
            default:
                break;
        }
    }

    handleCheckChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        switch (itemName) {
            case "arch":
                this.setState({ archZoneCheck: checked });
                if (window.changeVisibility != null)
                    window.changeVisibility(itemName, checked);
                break;
            case "land":
                this.setState({ landZoneCheck: checked });
                if (window.changeVisibility != null)
                    window.changeVisibility(itemName, checked);
                break;
            case "reg":
                this.setState({ regZoneCheck: checked });
                if (window.changeVisibility != null)
                    window.changeVisibility(itemName, checked);
                break;
            case "istgan":
                this.setState({ istganZoneCheck: checked });
                if (window.changeVisibility != null)
                    window.changeVisibility(itemName, checked);
                break;
            case "world":
                this.setState({ worldZoneCheck: checked });
                if (window.changeVisibility != null)
                    window.changeVisibility(itemName, checked);
                break;
            case "vis":
                this.setState({ visZoneCheck: checked });
                if (window.changeVisibility != null)
                    window.changeVisibility(itemName, checked);
                break;
            case "phys":
                this.setState({ physZoneCheck: checked });
                if (window.changeVisibility != null)
                    window.changeVisibility(itemName, checked);
                break;
            case "imm":
                this.setState({ immCheck: checked });
                if (window.changeVisibility != null)
                    window.changeVisibility(itemName, checked);
                break;
            case "immPoint":
                this.setState({ immPointCheck: checked });
                if (window.changeVisibility != null)
                    window.changeVisibility(itemName, checked);
                break;
            case "musRes":
                this.setState({ musResCheck: checked });
                if (window.changeVisibility != null)
                    window.changeVisibility(itemName, checked);
                break;
            case "museum":
                this.setState({ museumCheck: checked });
                if (window.changeVisibility != null)
                    window.changeVisibility(itemName, checked);
                break;
            default:
                break;
        }
    };

    changeBaseMap(value) {
        if (window.changeBaseMap != null) {
            window.changeBaseMap(value);
        }
    }
    render() {

        return (
            <div className="esri-widget--button">
                <div onClick={this.handleShow}>
                    <div className="esri-widget--button esricutomButton esri-icon-layers " data-tooltip="ლეგენდა"></div>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose} className={`SearchDialog ${this.props.legendClass}`}>
                    <Modal.Header closeButton>
                        <Modal.Title className="ModalHeader" style={{ width: '100%', borderBottom: '1px solid #EDEDED' }}>ლეგენდა</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="layerTitle">
                                <div className="checkbox checkbox-success checkboxlist"
                                    style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                    <input id="check_immPoint"
                                        type="checkbox"
                                        name="immPoint"
                                        checked={this.state.immPointCheck}
                                        onChange={this.handleCheckChange} />
                                    <label htmlFor={"check_immPoint"}>ძეგლი/ობიექტი</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <InputRange
                                    maxValue={1}
                                    minValue={0}
                                    step={0.1}
                                    value={this.state.immPointValue}
                                    onChange={value => this.changeLayerOpacity('immPoint', value)} />
                            </div>
                            <Accordion defaultActiveKey="0" className="accordionNoshadow" style={{ margin: '10px' }}>
                                <Card>
                                    <div className="LegendPanelHeader">
                                        <Accordion.Toggle eventKey="1">
                                            <div className="icon-down-open-big">სიმბოლიკა</div>
                                        </Accordion.Toggle>
                                    </div>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>

                                            <div><svg width="15px" height="15px"><rect height="15px" width="15px" style={{ fill: '#931928', strokeWidth: '2', stroke: 'black' }} /></svg><span style={{ marginLeft: '5px' }}>ობიექტები სტატუსის გარეშე</span></div>
                                            <div><svg width="15px" height="15px"><rect height="15px" width="15px" style={{ fill: '#ff69B4', strokeWidth: '2', stroke: 'black' }} /></svg><span style={{ marginLeft: '5px' }}>კულტურული მემკვიდრეობის ობიექტები</span></div>
                                            <div><svg width="15px" height="15px"><rect height="15px" width="15px" style={{ fill: '#dd49ab', strokeWidth: '2', stroke: 'black' }} /></svg><span style={{ marginLeft: '5px' }}>კულტურული მემკვიდრეობის ძეგლები</span></div>
                                            <div><svg width="15px" height="15px"><rect height="15px" width="15px" style={{ fill: '#ff00a9', strokeWidth: '2', stroke: 'black' }} /></svg><span style={{ marginLeft: '5px' }}>ეროვნული მნიშვნელობის ძეგლები</span></div>
                                            <div><svg width="15px" height="15px"><rect height="15px" width="15px" style={{ fill: '#f5a623', strokeWidth: '2', stroke: 'black' }} /></svg><span style={{ marginLeft: '5px' }}>მსოფლიო მემკვიდრეობის მნიშვნელობის ძეგლები</span></div>
                                            <br />
                                            <div><img src="/mapicons/1.png" style={{ height: '20px', width: '20px' }} alt="" /> არქეოლოგიური</div>
                                            <div><img src="/mapicons/2.png" style={{ height: '20px', width: '20px' }} alt="" /> არქიტექტურის</div>
                                            <div><img src="/mapicons/3.png" style={{ height: '20px', width: '20px' }} alt="" /> საინჟინრო</div>
                                            <div><img src="/mapicons/4.png" style={{ height: '20px', width: '20px' }} alt="" /> ქალაქთმშენებლობის (ურბანული)</div>
                                            <div><img src="/mapicons/5.png" style={{ height: '20px', width: '20px' }} alt="" /> საბაღე-საპარკო ხელოვნებისა და ლანდშაფტური არქიტექტურის</div>
                                            <div><img src="/mapicons/6.png" style={{ height: '20px', width: '20px' }} alt="" /> პალეოგრაფიული</div>
                                            <div><img src="/mapicons/7.png" style={{ height: '20px', width: '20px' }} alt="" /> მონუმენტური სახვითი ხელოვნების</div>
                                            <div><img src="/mapicons/8.png" style={{ height: '20px', width: '20px' }} alt="" /> მემორიალური</div>
                                            <div><img src="/mapicons/9.png" style={{ height: '20px', width: '20px' }} alt="" /> ეთნოგრაფიული</div>
                                            <div><img src="/mapicons/10.png" style={{ height: '20px', width: '20px' }} alt="" /> სახვითი ხელოვნების</div>
                                            <div><img src="/mapicons/11.png" style={{ height: '20px', width: '20px' }} alt="" /> მეცნიერების, ტექნიკის ან მრეწველობის განვითარებასთან დაკავშირებული</div>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                        </div>
                        <div>
                            <div className="layerTitle">
                                <div className="checkbox checkbox-success checkboxlist"
                                    style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                    <input id="check_imm"
                                        type="checkbox"
                                        name="imm"
                                        checked={this.state.immCheck}
                                        onChange={this.handleCheckChange} />
                                    <label htmlFor={"check_imm"}>ძეგლის/ობიექტის საზღვარი</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ height: '15px', width: '15px', backgroundColor: '#a0ece1' }} />
                                <InputRange
                                    maxValue={1}
                                    minValue={0}
                                    step={0.1}
                                    value={this.state.immValue}
                                    onChange={value => this.changeLayerOpacity('imm', value)} />
                            </div>
                        </div>

                        <div>
                            <div className="layerTitle">
                                <div className="checkbox checkbox-success checkboxlist"
                                    style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                    <input id="check_phys"
                                        type="checkbox"
                                        name="phys"
                                        checked={this.state.physZoneCheck}
                                        onChange={this.handleCheckChange} />
                                    <label htmlFor={"check_phys"}>ძეგლის ფიზიკური დაცვის არეალი</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ height: '15px', width: '15px', backgroundColor: '#004da8' }} />
                                <InputRange
                                    maxValue={1}
                                    minValue={0}
                                    step={0.1}
                                    value={this.state.physZoneValue}
                                    onChange={value => this.changeLayerOpacity('phys', value)} />
                            </div>
                        </div>
                        <div>
                            <div className="layerTitle">
                                <div className="checkbox checkbox-success checkboxlist"
                                    style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                    <input id="check_vis"
                                        type="checkbox"
                                        name="vis"
                                        checked={this.state.visZoneCheck}
                                        onChange={this.handleCheckChange} />
                                    <label htmlFor={"check_vis"}> ძეგლის ვიზუალური დაცვის არეალი</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ height: '15px', width: '15px', backgroundColor: '#96bac4' }} />
                                <InputRange
                                    maxValue={1}
                                    minValue={0}
                                    step={0.1}
                                    value={this.state.visZoneValue}
                                    onChange={value => this.changeLayerOpacity('vis', value)} />
                            </div>
                        </div>
                        <div>
                            <div className="layerTitle">
                                <div className="checkbox checkbox-success checkboxlist"
                                    style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                    <input id="check_world"
                                        type="checkbox"
                                        name="world"
                                        checked={this.state.worldZoneCheck}
                                        onChange={this.handleCheckChange} />
                                    <label htmlFor={"check_world"}> მსოფლიო მემკვიდრეობის ძეგლის ბუფერული ზონა</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ height: '15px', width: '15px', backgroundColor: '#d98c8c' }} />
                                <InputRange
                                    maxValue={1}
                                    minValue={0}
                                    step={0.1}
                                    value={this.state.worldZoneValue}
                                    onChange={value => this.changeLayerOpacity('world', value)} />
                            </div>
                        </div>
                        <div>
                            <div className="layerTitle">
                                <div className="checkbox checkbox-success checkboxlist"
                                    style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                    <input id="check_arch"
                                        type="checkbox"
                                        name="arch"
                                        checked={this.state.archZoneCheck}
                                        onChange={this.handleCheckChange} />
                                    <label htmlFor={"check_arch"}>არქეოლოგიური დაცვის ზონა</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ height: '15px', width: '15px', backgroundColor: '#f5dd6e' }} />
                                <InputRange
                                    maxValue={1}
                                    minValue={0}
                                    step={0.1}
                                    value={this.state.archZoneValue}
                                    onChange={value => this.changeLayerOpacity('arch', value)} />
                            </div>
                        </div>
                        <div>
                            <div className="layerTitle">
                                <div className="checkbox checkbox-success checkboxlist"
                                    style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                    <input id="check_land"
                                        type="checkbox"
                                        name="land"
                                        checked={this.state.landZoneCheck}
                                        onChange={this.handleCheckChange} />
                                    <label htmlFor={"check_land"}>ისტორიული ლანდშაფტის დაცვის ზონა</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ height: '15px', width: '15px', backgroundColor: '#61d274' }} />
                                <InputRange
                                    maxValue={1}
                                    minValue={0}
                                    step={0.1}
                                    value={this.state.landZoneValue}
                                    onChange={value => this.changeLayerOpacity('land', value)} />
                            </div>
                        </div>
                        <div>
                            <div className="layerTitle">
                                <div className="checkbox checkbox-success checkboxlist"
                                    style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                    <input id="check_reg"
                                        type="checkbox"
                                        name="reg"
                                        checked={this.state.regZoneCheck}
                                        onChange={this.handleCheckChange} />
                                    <label htmlFor={"check_reg"}>განაშენიანების რეგულირების ზონა</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ height: '15px', width: '15px', backgroundColor: '#a06e9c' }} />
                                <InputRange
                                    maxValue={1}
                                    minValue={0}
                                    step={0.1}
                                    value={this.state.regZoneValue}
                                    onChange={value => this.changeLayerOpacity('reg', value)} />
                            </div>
                        </div>
                        <div>
                            <div className="layerTitle">
                                <div className="checkbox checkbox-success checkboxlist"
                                    style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                    <input id="check_istgan"
                                        type="checkbox"
                                        name="istgan"
                                        checked={this.state.istganZoneCheck}
                                        onChange={this.handleCheckChange} />
                                    <label htmlFor={"check_istgan"}>ისტორიული განაშენიანების დაცვის ზონა</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ height: '15px', width: '15px', backgroundColor: '#f48752' }} />
                                <InputRange
                                    maxValue={1}
                                    minValue={0}
                                    step={0.1}
                                    value={this.state.istganZoneValue}
                                    onChange={value => this.changeLayerOpacity('istgan', value)} />
                            </div>
                        </div>



                        <div>
                            <div className="layerTitle">
                                <div className="checkbox checkbox-success checkboxlist"
                                    style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                    <input id="check_museum"
                                        type="checkbox"
                                        name="museum"
                                        checked={this.state.museumCheck}
                                        onChange={this.handleCheckChange} />
                                    <label htmlFor={"check_museum"}>მუზეუმი/მუზეუმ-ნაკრძალი</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div><img src="/mapicons/2.png" style={{ height: '20px', width: '20px' }} alt="" /></div>
                                <InputRange
                                    maxValue={1}
                                    minValue={0}
                                    step={0.1}
                                    value={this.state.museumValue}
                                    onChange={value => this.changeLayerOpacity('museum', value)} />
                            </div>
                        </div>
                        <div>
                            <div className="layerTitle">
                                <div className="checkbox checkbox-success checkboxlist"
                                    style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                    <input id="check_musRes"
                                        type="checkbox"
                                        name="musRes"
                                        checked={this.state.musResCheck}
                                        onChange={this.handleCheckChange} />
                                    <label htmlFor={"check_musRes"}>მუზეუმ-ნაკრძალის სამოქმედო არეალი</label>
                                </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ height: '15px', width: '15px', backgroundColor: '#c7c1e6' }} />
                                <InputRange
                                    maxValue={1}
                                    minValue={0}
                                    step={0.1}
                                    value={this.state.musResValue}
                                    onChange={value => this.changeLayerOpacity('musRes', value)} />
                            </div>
                        </div>

                        <div style={{ margin: '20px' }}>
                            <div className="ModalHeader">რუკები</div>
                            <ul style={{ display: 'inline-block', padding:'0' }}>
                                <li className="baseMap hybridImage" style={{ marginLeft: '5px' }} onClick={value => this.changeBaseMap('hybrid')} />
                                <li className="baseMap osmImage" style={{ marginLeft: '5px' }} onClick={value => this.changeBaseMap('napr')} title="NAPR - საბაზისო რუკა" />
                                <li className="baseMap orto_Georgia" style={{ marginLeft: '5px' }} onClick={value => this.changeBaseMap('orto_Georgia')} title="ორთოების ფენა - NAPR" />
                                <li className="baseMap orto_2016" style={{ marginLeft: '5px' }} onClick={value => this.changeBaseMap('orto_2016')} title="ორთო 2016 - NAPR" />
                                <li className="baseMap orto_2000" style={{ marginLeft: '5px' }} onClick={value => this.changeBaseMap('orto_2000')} title="ორთო 2000- NAPR" />
                                <li className="baseMap topo_25000" style={{ marginLeft: '5px' }} onClick={value => this.changeBaseMap('topo_25000')} title="ტოპო- 25000 - NAPR" />

                            </ul>
                        </div>
                    </Modal.Body>

                </Modal>
            </div >
        );
    }
}

