import React, { Component } from 'react';
import './SiteRules.css'
import { authenticationService } from '../_services';
import { Footer } from './Footer';
export class SiteRules extends Component {
    static displayName = SiteRules.name;
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null
        };

    }
    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x
        }));
    }
    render() {
        return (
            this.state.currentUser != null || this.props.fromRegister == true ?
                <div className="AboutUsMainDiv">

                    <div className="accardionPadding boldHeade" style={{
                        bottom: '0'
                    }}>
                        პორტალით სარგებლობის პირობები
                 </div>
                    <div className="accardionPadding SiteRulesText">
                        <ol>
                            <li>

                                წინამდებარე წესები წარმოადგენს იურიდიულ შეთანხმებას მხარეთა შორის
                                და მასზე ვრცელდება საქართველოს მოქმედი კანონმდებლობით დადგენილი
                                წესები. პორტალზე დარეგისტრირებით, ან რაიმე მასალის განთავსებით,
                                ასევე მონიშვნის ველზე თანხმობით, თქვენ ვალდებული ხართ იმოქმედოთ
                                ამ რეგულაციების ფარგლებში.
                            </li>
                            <p />
                            <li>
                                გთხოვთ ყურადღებით გაეცნოთ პორტალით სარგებლობის წესებს, რადგან
                                მასზე თანხმობის გამოხატვის შემთხვევაში თქვენს მიმართ ვრცელდება
                                შესაბამისი უფლებები და ვალდებულებები.
                                 </li>
                            <p />
                            <li> პორტალის ადმინისტრატორი უფლებამოსილია ნებისმიერ დროს
                            შეუზღუდოს მომხმარებელს პორტალთან წვდომა და გაუუქმოს
                            რეგისტრაცია.
                            </li>
                            <p />
                            <li> პორტალის ლოგოტიპი, დიზაინი და სხვა საცნობი ნიშნები წარმოადგენს
                            პორტალის ადმინისტრატორის საკუთრებას და დაცულია საქართველოს
                            კანონმდებლობით.
                        </li><p />

                            <li>  საავტორო, მომიჯნავე უფლების მფლობელისა და მონაცემთა ბაზის
                            დამამზადებლის უფლების ხელყოფა წარმოადგენს დასჯად ქმედებას და
                            საქართველოს სისხლის სამართლის კოდექსის 189-ე მუხლის საფუძველზე
                            ითვალისწინებს სისხლის სამართლებრივ პასუხისმგებლობას.
                        </li><p />
                            <li>  იმ შემთხვევაში, თუ პორტალზე რეგისტრაციისა და პორტალით სარგებლობის
                            რომელიმე წესი მიიჩნევა არაკანონიერად ან ბათილად, მისი დანარჩენი
                            ნაწილი რჩება ძალაში და ასეთ გაუქმებას არ აქვს გავლენა სხვა წესების
                            ნამდვილობაზე ან ძალაზე.
                        </li><p />
                            <li>  წინამდებარე პორტალის მოხმარების წესებზე ვრცელდება საქართველოს
                            კანონმდებლობა, აქედან გამომდინარე მხარეთა შორის წარმოქმნილი
                            ნებისმიერი დავა, რომელიც ვერ გადაწყდება მოლაპარაკების გზით
                            გადაწყდება საქართველოს საერთო სასამართლოების მეშვეობით. დავას
                            ტერიტორიული განსჯადობის მიხედვით განიხილავს თბილისის საქალაქო
                            სასამართლო.
                        </li><p />
                            <li>  პორტალზე რეგისტრაციის დროს მომხმარებელი ვალდებულია მიუთითოს
                            უტყუარი/ნამდვილი მონაცემები. მომხმარებელი ვალდებულია შეავსოს
                            ყველა სავალდებულო ველი, წინააღმდეგ შემთხვევაში პორტალზე ვერ
                            დარეგისტრირდება.
                        </li><p />
                            <li>  აკრძალულია მომხმარებლის სახელში ნებისმიერი შეურაცხმყოფელი/ბილწი
                            სიტყვის/ფრაზის მითითება.
                        </li><p />
                            <li> აკრძალულია სხვისი პერსონალური ინფორმაციის მითითება ან საკუთარ
                            პერსონალურ ინფორმაციაში მცდარი ან/და ცრუ მონაცემების დაფიქსირება.
                        </li><p />
                            <li> მომხმარებელი პორტალზე რეგისტრაციით თანხმობას აცხადებს, რომ
                            კომპანიამ გამოიყენოს და დაამუშაოს მომხმარებლის პერსონალური
                            მონაცემები, ასევე პორტალზე არსებული მომხმარებლის ნებისმიერი
                            მონაცემი, ასევე გადასცეს ასეთი მონაცემები მესამე პირებს საქართველოს
                            მოქმედი კანონმდებლობის, „პერსონალურ მონაცემთა დაცვის შესახებ“
                            საქართველოს კანონის შესაბამისად და ფარგლებში.
                        </li><p />
                            <li>  ადმინისტრატორის ინტერესშია პორტალის გამართული ფუნქციონირება,
                            მაგრამ ტექნიკური ხარვეზის შემთხვევაში ადმინისტრატორი არ აგებს პასუხს
                            პორტალით შეფერხებული სარგებლობის ფაქტის შემთხვევაში.
                        </li><p />
                            <li>  მომხმარებელს ეკრძალება პორტალის გამოყენება კანონსაწინააღმდეგო
                            მიზნებისთვის ან/და პორტალის გამოყენება სხვა პირთა უკანონო
                            მოქმედების შესრულებაში ან მონაწილეობაში დასახმარებლად.
                        </li><p />
                            <li>  მომხმარებელს ეკრძალება პორტალის გამოყენება სოციალური, რასობრივი,
                            ეროვნული ან რელიგიური სიძულვილისა და მტრობის გამომწვევი ან
                            უპირატესობის შემცველი პროპაგანდის გასაწევად.
                        </li><p />
                            <li>  აკრძალულია ცრუ ინფორმაციის შეგნებულად გავრცელება.
                        </li><p />
                            <li>  აკრძალულია პორტალის ფუნქციონირების დესტაბილიზაციისკენ
                            მიმართული ქმედებების განხორციელება, მათ შორის ადმინისტრატორის
                            სისტემებში არასანქცირებული შესვლა ან/და ნებისმიერი სხვა ქმედების
                            განხორციელება, რამაც შეიძლება ავნოს პორტალს. ზიანის შემთხვევაში
                            შესაბამისი პირის მიმართ შესაძლებელია დადგეს საქართველოს
                            კანონმდებლობით გათვალისწინებული პასუხისიმგებლობა.
                        </li><p />
                            <li> აკრძალულია ნებისმიერი გზით საქართველოს მოქმედი კანონმდებლობის
                            დარღვევა.
                        </li><p />
                            <li>  პორტალზე რეგისტრაციისა და პორტალით სარგებლობის პირობების
                            დარღვევის შემთხვევაში კომპანიას უფლება აქვს დაბლოკოს მომხმარებლის
                            IP მისამართი.
                        </li><p />
                            <li>  ადმინისტრატორს უფლება აქვს ნებისმიერ დროს წაშალოს მომხმარებლის
                            მიერ გამოქვეყნებული ნებისმიერი ინფორმაცია/მასალა.
                        </li><p />
                            <li>  ადმინისტრატორი უფლებამოსილია ცალმხრივად შეიტანოს
                            ცვლილება/დამატება წესებსა და პირობებში ან/და ცალმხრივად გააუქმოს
                            ისინი მომხმარებელთათვის წინასწარი შეტყობინების გარეშე. ცვლილებების
                            შეტანის შემთხვევაში კომპანია განაახლებს წინამდებარე წესებსა და
                            პირობებს და მიუთითებს ბოლო განახლების თარიღს. შეცვლილი წესები და
                            პირობები ძალაში შედის პორტალზე გამოქვეყნების შემდეგ. წესებსა და
                            პირობებში ცვლილებების შეტანის შემდეგ პორტალით სარგებლობის
                            გაგრძელება/სარგებლობა ავტომატურად ნიშნავს, რომ მომხმარებელი
                            დაეთანხმა ცვლილებებს;
                             </li>
                        </ol>
                    </div>
                   {/* <Footer></Footer>*/}
                </div>
                : <> </>

        );
    }

}
export default SiteRules;