import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'


function GetModal(props) {
  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    return (
        <>
            <div onClick={handleShow} className="showModal objectAbout">
                <div style={{ height: '30px', width: '30px' }}>
                    <svg className="bi bi-question-circle" width="1em" height="1em" viewBox="0 0 16 16" fill="#6A8E7F" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z" clipRule="evenodd" />
                        <path d="M5.25 6.033h1.32c0-.781.458-1.384 1.36-1.384.685 0 1.313.343 1.313 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.007.463h1.307v-.355c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.326 0-2.786.647-2.754 2.533zm1.562 5.516c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                    </svg>
                </div>               
            </div>
            {
                props.objectType === 'zone' ?
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className="ModalHeader">დამცავი ზონების კატეგორიები</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="ModalContent">
                            <div className="ModalBodyHeader">ისტორიული განაშენიანების დაცვის ზონა</div>
                            <div className="ModalBody">ისტორიული განაშენიანების დაცვის ზონად განისაზღვრება ტერიტორია, სადაც გამოვლენილია ძეგლებისა და კულტურული მემკვიდრეობის სხვა უძრავი ობიექტების დიდი კონცენტრაცია, ავთენტიკური სახით შენარჩუნებული ქუჩათა ქსელი, განაშენიანება, გეგმარებითი სტუქტურა და მორფოლოგია.</div>
                            <div className="ModalBodyHeader">განაშენიანების რეგულირების ზონა</div>
                            <div className="ModalBody">განაშენიანების რეგულირების ზონად განისაზღვრება ტერიტორია, სადაც ავთენტიკური სახით შემორჩენილია ისტორიული განაშენიანების, ქუჩათა ქსელის, გეგმარებითი სტრუქტურის ფრაგმენტები ან/და ცალკეული ძეგლები, კულტურული ღირებულების მქონე სხვა უძრავი ობიექტები, ფონური განაშენიანება. განაშენიანების რეგულირების ზონა შეიძლება ასევე იყოს კულტურული მემკვიდრეობის დამცავი სხვა ზონის ბუფერული ზონა.</div>
                            <div className="ModalBodyHeader">ისტორიული ლანდშაფტის დაცვის ზონა</div>
                            <div className="ModalBody">ისტორიული ლანდშაფტის დაცვის ზონად განისაზღვრება ისტორიული, კულტურული, ესთეტიკური ღირებულების მქონე ბუნებრივი, სასოფლო ან ურბანული ტერიტორია, რომლის ჩამოყალიბება ისტორიული განვითარების მანძილზე მთლიანად ან ნაწილობრივ ადამიანის საქმიანობის შედეგია ან რომელიც ცალკეული ძეგლების ისტორიულად ჩამოყალიბებული ბუნებრივი გარემოა.</div>
                            <div className="ModalBodyHeader">არქეოლოგიური დაცვის ზონა</div>
                            <div className="ModalBody">არქეოლოგიური დაცვის ზონად განისაზღვრება ტერიტორია, სადაც გამოვლენილი, დაფიქსირებული ან დაზვერილია კულტურული ფენები ან/და  არქეოლოგიური ობიექტები.</div>
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                    :
                <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className="ModalHeader">{props.headerText}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {props.children}
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}


export class AboutObject extends React.Component {
    render() {
        return (
            <GetModal objectType={this.props.objectType} headerText={this.props.headerText}>
                {this.props.children}
            </GetModal>

        );
    }
}