import React from 'react';

import { Card, Table } from "react-bootstrap";
import CardGroup from 'react-bootstrap/CardGroup';
import { format } from "date-fns";

import PulseLoader from "react-spinners/PulseLoader";
import { authenticationService } from '../../_services';
import { authHeader, handleResponse } from '../../_helpers';
import { QuickAlertModal } from '../User/QuickAlertModal';
import './userobjectlistviewStyle.css';

class UserAlertObjectListView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //currentUser: null,
            //isAdmin: false,
            //photo: null,
            pager: {},
            pageOfItems: [],
            loading: true,
            reload: false,
            type: this.props.type,
        };

    }


    drawImovableList(forecasts) {
        window.scrollTo(0, 0)
        var el = document.getElementsByClassName('immListDiv');
        if (el != null && el.length > 0) el[0].scrollTo(0, 0);

        return (
            <div>
                <div className="searchandSumDiv">
                    <div style={{ "float": "left" }}>  განცხადებები
                    <span style={{ marginLeft: "5px", "borderRadius": "10px", "color": "white", "background": "#6A8E7F", "padding": "2px" }}> {forecasts.length}</span>
                    </div>
                </div>
                <CardGroup>
                    {forecasts.map(forecast =>
                        <div key={+ forecast.id} dataid={forecast.id} >

                            <Card className="cardHorizonalObjectListView AlertObjectListView">
                                <div className="AlertObjectViewImgContainer">
                                    <div className={`${(forecast.photoAddress === '' || (forecast.photoAddress.indexOf('.jpg') < 0 && forecast.photoAddress.indexOf('.png') < 0)) ? 'imgDivImmovableObjectAlert' : 'imgDivObjListView'}`}>
                                        {
                                            forecast.photoAddress == null || forecast.photoAddress === '' ?
                                                <></> :
                                                <img className="list_card-alert-img" variant="top" src={forecast.photoAddress} alt="" />
                                        }


                                    </div>
                                </div>



                                <Card.Body className="alert_objectList_card-body">

                                    <Table className="alertCardTable">
                                        <tbody>
                                            <tr>
                                                {/*<div onClick={this.props.handleShow}>*/}
                                                {/*    <td className="width60alertObjectCardTable" >*/}
                                                {/*        <Card.Title className="objectList_card-title alertcard" >{forecast.objectName}</Card.Title>*/}
                                                {/*        <div className="objectTypeList">{forecast.Id}</div>*/}
                                                {/*        <Card.Text className="alertList_object_card-text" dataid={forecast.id}>*/}
                                                {/*            <span className="dazianebaTextAlertObject">{forecast.statementReason}</span>*/}
                                                {/*            <br></br>*/}
                                                {/*            <span className="dazianebaTextAlertObject">{forecast.createDate} </span>*/}
                                                            
                                                {/*        </Card.Text>*/}
                                                {/*    </td>*/}
                                                {/*</div>*/}
                                                <td style={{ "verticalAlign": "middle" }}>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <div >
                                                            {/*<div onClick={(e) => e.preventDefault()}>*/}
                                                            <QuickAlertModal ref={this.quickAlert}
                                                                filterResulthandle={this.filterResulthandle}
                                                                classificators={this.state.pager.classificator}
                                                                ObjectId={forecast.id}
                                                                ObjectName={forecast.objectName}
                                                                PhotoAddress={forecast.photoAddress}
                                                                id={forecast.id}
                                                                immovableAlertLinkGancxadebebi={true}
                                                                createDate={forecast.createDate}
                                                                statementReason={forecast.statementReason}
                                                            >
                                                            </QuickAlertModal>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </div>
                    )}
                </CardGroup>
            </div>
        );
    }


    render() {

        const params = new URLSearchParams(window.location.search);

        const { pager, pageOfItems } = this.state;
        return (
            <div className="card text-center">
                <div className="card-body">
                    {
                        this.drawImovableList(this.props.userAlertList)
                    }

                </div>

            </div>
        );
    }
}

export { UserAlertObjectListView };