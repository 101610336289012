import React, { Component } from 'react';
import './Object.css';
import { Row, Col, Accordion, Card } from 'react-bootstrap'
import { WebMapView } from '../WebMapView';

import { CustomToggle } from '../custom/CustomToggle'
import { ObjectListForLinks } from '../ObjectListForLinks';

import { FullDescModal } from './FullDescModal';
import { HistoryModal } from './HistoryModal';
import { DocObjectListForLinksTable } from '../DocObjectListForLinksTable';
import { EventObjectListForLinksTable } from '../EventObjectListForLinksTable';
import { Gallery } from './Gallery';

import Avatar from 'react-avatar';
import { Footer } from '../Footer';
import ReactTooltip from "react-tooltip";
import PulseLoader from "react-spinners/PulseLoader";

import { AddToCollection } from '../AddToCollection';
import { Share } from '../Share';

export class WallArtPainting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            wallArtPainting: {},
            objectID: null,
            objToZoom: null,
            fullScreeenIcon: "esri-icon-zoom-out-fixed",
            mapClass: '',
            maptype: '',
            loading: true
        };

        this.fullScreenMode = this.fullScreenMode.bind(this)
    }
    componentDidMount() {
        var el = document.querySelector('.searchComponentButton');
        el.textContent = "კედლის მხატვრობა";
        this.loadPage();
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const id = parseInt(params.get('id'));
        if (id !== null) {
            var type = `api/WallArt/GetWallArtPaintingObject?id=${id}`;
            const response = await fetch(type);
            const data = await response.json();
            this.setState({ wallArtPainting: data, loading: false, maptype: data.curLocId !== "" ? data.curLocType : data.oldLocType,  objectID: data.curLocId  });
        }
    }

    toogleFullDesc() {
        if (document.getElementsByClassName('DescTextFull')[0].classList.contains('displayNone')) {
            document.getElementsByClassName('DescTextFull')[0].classList.remove('displayNone');
            document.getElementsByClassName('DescText')[0].classList.add('displayNone');
        }
        else {
            document.getElementsByClassName('DescTextFull')[0].classList.add('displayNone');
            document.getElementsByClassName('DescText')[0].classList.remove('displayNone');
        }

    }

    toogleMapActiveLink(val) {
        document.getElementsByClassName('mapLink')[0].classList.remove('active');
        document.getElementsByClassName('mapLink')[1].classList.remove('active');
        document.getElementsByClassName('mapLink')[val].classList.add('active');

        if (val === 0)
            this.setState({
                "maptype": this.state.wallArtPainting.curLocType, "mapObjectID": this.state.wallArtPainting.curLocId
            })
        else this.setState({
            "maptype": this.state.wallArtPainting.oldLocType, "mapObjectID": this.state.wallArtPainting.oldLocId
        })


        document.getElementsByClassName('mapCoord')[0].classList.add('displayNone');
        document.getElementsByClassName('mapCoord')[1].classList.add('displayNone');
        document.getElementsByClassName('mapCoord')[val].classList.remove('displayNone');
    }
    static displayName = WallArtPainting.name;

    fullScreenMode() {
        if (this.state.mapClass === '') {
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-in-fixed', mapClass: 'objectFullScreentMap', objectID: null, objToZoom: this.state.wallArtPainting.curLocId })
        }
        else {

            document.getElementById('toolbarDiv').classList.add('displayNone');
            document.getElementById('menuDiv').classList.add('displayNone')
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-out-fixed', mapClass: '', objectID: this.state.WallArtPainting.curLocId, objToZoom: null })
        }
    }

    render() {
        const wallArtPainting = this.state.wallArtPainting;

        window.FB = null;
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/ka_GE/sdk.js#xfbml=1&autoLogAppEvents=1&version=v8.0&appId=1607074352807965";
            js.nonce = "q90Xb87m";

            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));


        return (
            <div className="immObjDiv">
                {wallArtPainting && wallArtPainting.id > 0 ?
                    <div>
                        <div className="containerDiv">
                            <Row className="rowPadding">
                                <Col md={5}>
                                    <Gallery photo={wallArtPainting.photoIds} video={wallArtPainting.videoIds} vid3D={wallArtPainting._3dobjects} mainphoto={wallArtPainting.photoAddress} type="wallart" authors={wallArtPainting.docAuthors} />
                                </Col>
                                <Col md={7}>
                                    <div className="WallObjectType">კედლის მხატვრობის შემადგენელი მოხატულობა</div>
                                    <div className="ObjectMainHeader" style={{ marginBottom: '20px' }}>
                                        <span className="ObjectMainHeaderSpan">{wallArtPainting.objectName} - #{wallArtPainting.id}</span>
                                        <Share header="გაზიარება" customClass="objectShare" />
                                    </div>
                                    <div style={{ marginBottom: '20px' }}><span className="headerText" >კედლის მხატვრობა: </span> <a className="card-link" style={{ color: "black" }} href={'../wallart/wallartObject?id=' + wallArtPainting.wallArtID}>{wallArtPainting.wallArt}</a></div>
                                    <div><span className="headerText">ისტორიული/კულტურული პერიოდი: </span>
                                        <ul className="periodList">
                                            {
                                                wallArtPainting.period.split(';').map((item, i) => {
                                                    return <li key={i}>{item}</li>;
                                                })
                                            }</ul></div>
                                    <div><span className="headerText">თარიღი: </span>{wallArtPainting.date}</div>
                                    <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '20px' }} />
                                    <div className="DescText">
                                        {wallArtPainting.description}
                                        <div onClick={this.toogleFullDesc} className="toggleFullDesc">
                                            <svg className="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 01.708 0L8 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 01.708 0L8 8.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="DescTextFull displayNone">{wallArtPainting.descriptionFull}
                                        <div onClick={this.toogleFullDesc} style={{ textAlign: "center", padding: "20px" }}>
                                            <svg className="bi bi-chevron-double-up" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M7.646 2.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 3.707 2.354 9.354a.5.5 0 11-.708-.708l6-6z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M7.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div style={{ display: "inline-flex" }}>
                                        <HistoryModal desc={wallArtPainting.istoriuliCnobebi} objname={wallArtPainting.objectName} />
                                        <FullDescModal className="FullDescModal" buttonDesc="დეტალური აღწერა" objname={wallArtPainting.objectName} >
                                            <div className="PopupHeader">მოხატულობის მოკლე მხატვრულ-ისტორიული დახასიათება</div>
                                            {wallArtPainting.descriptionFull}

                                            {(wallArtPainting.sqemebi || wallArtPainting.scenebi || wallArtPainting.iconografia
                                                || wallArtPainting.ciuriZalni || wallArtPainting.winaswarmetyvelebi || wallArtPainting.mociqulni
                                                || wallArtPainting.mamebi || wallArtPainting.diakvnebi || wallArtPainting.wmindanebi
                                                || wallArtPainting.gamosaxuleba || wallArtPainting.saeroPirebi || wallArtPainting.ornamenti || wallArtPainting.araidentificirebuli) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Card>
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">მოხატულობის რეპერტუარი</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {
                                                                        wallArtPainting.sqemebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>აბსიდალური სქემები</div><div className="headerText">{wallArtPainting.sqemebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.scenebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>სცენები</div><div className="headerText">{wallArtPainting.scenebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.iconografia !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}> <div>მაცხოვრის იკონოგრაფიული ტიპები </div><div className="headerText">{wallArtPainting.iconografia}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.ciuriZalni !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>ციური ძალნი</div><div className="headerText">{wallArtPainting.ciuriZalni}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.winaswarmetyvelebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>წინასწარმეტყველები </div><div className="headerText">{wallArtPainting.winaswarmetyvelebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.mociqulni !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>მოციქულნი </div><div className="headerText">{wallArtPainting.mociqulni}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.mamebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>ეკლესიის მამები </div><div className="headerText">{wallArtPainting.mamebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.diakvnebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>დიაკვნები </div><div className="headerText">{wallArtPainting.diakvnebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.wmindanebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>წმინდანები </div><div className="headerText">{wallArtPainting.wmindanebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.gamosaxuleba !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>სიმბოლური გამოსახულებები </div><div className="headerText">{wallArtPainting.gamosaxuleba}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.saeroPirebi !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>საერო პირები </div><div className="headerText">{wallArtPainting.saeroPirebi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.ornamenti !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div>ორნამენტები</div><div className="headerText">{wallArtPainting.ornamenti}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.araidentificirebuli !== "" ?
                                                                            <div style={{ marginBottom: '20px' }}><div> არაიდენტიფიცირებული </div><div className="headerText">{wallArtPainting.araidentificirebuli}</div></div>
                                                                            : <></>
                                                                    }

                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {(wallArtPainting.nageboba || wallArtPainting.satavsi || wallArtPainting.archForma || wallArtPainting.archComponenti) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Card>
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">მოხატულობის განთავსების ადგილები</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {
                                                                        wallArtPainting.nageboba ?
                                                                            <div style={{ marginBottom: '20px' }}><div>ნაგებობა </div><div className="headerText">{wallArtPainting.nageboba}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.satavsi ?
                                                                            <div style={{ marginBottom: '20px' }}><div>სათავსი </div><div className="headerText">{wallArtPainting.satavsi}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.archForma ?
                                                                            <div style={{ marginBottom: '20px' }}><div>მცირე არქიტექტურული ფორმები </div><div className="headerText">{wallArtPainting.archForma}</div></div>
                                                                            : <></>
                                                                    }
                                                                    {
                                                                        wallArtPainting.archComponenti ?
                                                                            <div style={{ marginBottom: '20px' }}><div>არქიტექტურული კომპონენტები </div><div className="headerText">{wallArtPainting.archComponenti}</div></div>
                                                                            : <></>
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {wallArtPainting.warwera ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Card>
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">წარწერა</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {
                                                                        wallArtPainting.warwera.map((item, i) =>
                                                                            <Row key={i}>
                                                                                <Col>
                                                                                    <div>ტექსტი: </div><div className="headerText">{item.split(';')[0]}</div>
                                                                                    <div>ენა: </div><div className="headerText">{item.split(';')[1]}</div>
                                                                                    <div>თარიღი: </div><div className="headerText">{item.split(';')[2]}</div>
                                                                                    <p style={{ borderBottom: '1px solid #DBDBDB', marginTop: '20px' }} />
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                        </FullDescModal>
                                    </div>
                                </Col>
                            </Row>
                            <div className="ObjectHeaderNoTop" style={{ marginLeft: '35px', marginTop: '20px' }}>ადგილმდებარეობა</div>
                            <Row style={{ padding: '20px 20px 0 20px', width: '100%' }} className="ObjectMapRow">
                                <Col md={5}>
                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                        <div>
                                            <div>ამჟამინდელი:</div>
                                            {wallArtPainting.country !== '' ? <div>ქვეყანა: <span className="headerText">{wallArtPainting.country}</span></div> : <></>}
                                            {wallArtPainting.region !== '' ? <div>რეგიონი: <span className="headerText">{wallArtPainting.region}</span></div> : <></>}
                                            {wallArtPainting.municipality !== '' ? <div>მუნიციპალიტეტი: <span className="headerText">{wallArtPainting.municipality}</span></div> : <></>}
                                            {wallArtPainting.settlement !== '' ? <div>დასახლებული პუნქტი: <span className="headerText">{wallArtPainting.settlement}</span></div> : <></>}
                                            {wallArtPainting.street !== '' ? <div>მისამართი: <span className="headerText">{wallArtPainting.street}</span></div> : <></>}
                                        </div>
                                    </div>

                                    {(wallArtPainting.oldcountry !== '' || wallArtPainting.oldregion !== '' || wallArtPainting.oldmunicipality !== '' || wallArtPainting.oldsettlement !== '' || wallArtPainting.oldstreet !== '') ?
                                        <div style={{ display: 'inline-flex', marginTop: '20px', width: '100%' }}>
                                            <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                            <div>
                                                <div>წარმომავლობა:</div>
                                                {wallArtPainting.oldcountry !== '' ? <div>ქვეყანა: <span className="headerText">{wallArtPainting.oldcountry}</span></div> : <></>}
                                                {wallArtPainting.oldregion !== '' ? <div>რეგიონი: <span className="headerText">{wallArtPainting.oldregion}</span></div> : <></>}
                                                {wallArtPainting.oldmunicipality !== '' ? <div>მუნიციპალიტეტი: <span className="headerText">{wallArtPainting.oldmunicipality}</span></div> : <></>}
                                                {wallArtPainting.oldsettlement !== '' ? <div>დასახლებული პუნქტი: <span className="headerText">{wallArtPainting.oldsettlement}</span></div> : <></>}
                                                {wallArtPainting.oldstreet !== '' ? <div>მისამართი: <span className="headerText">{wallArtPainting.oldstreet}</span></div> : <></>}
                                            </div>
                                        </div>
                                        : <></>
                                    }
                                </Col>
                                <Col md={7} style={{ minHeight: '250px' }} className="ObjectMap">
                                    <div style={{ display: "inline-block", margin: '0 10px 10px 0px' }}>
                                        <button className={wallArtPainting.curLocId !== "" ? 'mapLink active' : 'mapLink not-active'} onClick={() => this.toogleMapActiveLink(0)} disabled={(wallArtPainting.curLocId === "")}>ამჟამინდელი</button>
                                        <button className={wallArtPainting.curLocId !== "" ? 'mapLink' : wallArtPainting.oldLocId !== "" ? 'mapLink active' : 'mapLink not-active'} onClick={() => this.toogleMapActiveLink(1)} style={{ marginLeft: "20px" }} disabled={(wallArtPainting.oldLocId === "")}>წარმომავლობა</button>
                                    </div>
                                    <WebMapView type={this.state.maptype} objectID={this.state.objectID} objToZoom={this.state.objToZoom} right="30px" fullScreeenIcon={this.state.fullScreeenIcon} fullClick={this.fullScreenMode.bind(this)} mapClass={this.state.mapClass} />

                                </Col>
                            </Row>
                            <Row>
                                <Col md={5}>

                                </Col>
                                <Col md={7}>
                                    <Row className={wallArtPainting.curLocId !== "" ? 'mapCoord' : 'mapCoord displayNone'}>
                                        <Col md={5} style={{ marginBottom: '20px' }}>
                                            <div className="icon-waves">
                                                სიმაღლე ზღვის დონიდან: {wallArtPainting.elevetion}მ.
                                        </div>
                                        </Col>
                                        <Col md={6} style={{ marginBottom: '20px' }}>
                                            <div className="icon-direction-outline">კოორდინატები {wallArtPainting.sizuste === 'სწორია' ? <img style={{ width: '17px', height: '17px' }} alt="" src="/images/OK.svg" /> : <></>}
                                            </div>
                                            {wallArtPainting.sizuste === 'სწორია' ?
                                                <div>
                                                    <span className="headerText">X: </span>{wallArtPainting.x}
                                                    <span className="headerText"> Y: </span>{wallArtPainting.y}
                                                </div> :
                                                <div>
                                                    კოორდინატები დასაზუსტებელია
                                                </div>
                                            }
                                        </Col>
                                    </Row>

                                    <Row className={wallArtPainting.curLocId !== "" ? 'mapCoord displayNone' : 'mapCoord'}>
                                        <Col md={5} style={{ marginBottom: '20px' }}>
                                            <div className="icon-waves">
                                                სიმაღლე ზღვის დონიდან: {wallArtPainting.elevetionOld}მ.
                                        </div>
                                        </Col>
                                        <Col md={6} style={{ marginBottom: '20px' }}>
                                            <div className="icon-direction-outline">კოორდინატები {wallArtPainting.sizusteOld === 'სწორია' ? <img style={{ width: '17px', height: '17px' }} alt="" src="/images/OK.svg" /> : <></>}
                                            </div>
                                            {wallArtPainting.sizusteOld === 'სწორია' ?
                                                <div>
                                                    <span className="headerText">X: </span>{wallArtPainting.xOld}
                                                    <span className="headerText"> Y: </span>{wallArtPainting.yOld}
                                                </div> :
                                                <div>
                                                    კოორდინატები დასაზუსტებელია
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="containerDiv">
                            {wallArtPainting.shemadgeneliLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">კედლის მხატვრობის შემადგენელი მოხატულობები</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={wallArtPainting.wallArtID} curobjID={wallArtPainting.id} type="wallartPainting" objType="wallart" objName={wallArtPainting.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        </div>
                        {
                            wallArtPainting.linksDisplay ? <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '80px' }} /> : <></>

                        }
                        <div className="containerDiv">
                            <div className="accardionPadding ObjectHeaderNoTop">{wallArtPainting.linksDisplay ? 'დაკავშირებული კომპონენტები' : ''}</div>
                            {wallArtPainting.immovableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">უძრავი ძეგლი/ობიექტი</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={wallArtPainting.id} type="immovable" objType="wallartpainting" idList={wallArtPainting.immovableLinks} objName={wallArtPainting.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {wallArtPainting.movableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მოძრავი ძეგლი/ობიექტი</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={wallArtPainting.id} type="movable" objType="wallartpainting" idList={wallArtPainting.movableLinks} objName={wallArtPainting.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {
                                wallArtPainting.eventLinkExist ?
                                    <Row className="accardionPadding">
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Card.Header className="TogglePanelHeader">
                                                    <CustomToggle eventKey="1"><span className="headerText">ქმედება</span></CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body className="accardionBodyPadding">
                                                        {
                                                            <EventObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={wallArtPainting.id} type="event" objType="wallArtPainting" idList={wallArtPainting.eventLinks} objName={wallArtPainting.objectName}/>
                                                        }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Row>
                                    : <></>
                            }
                            {wallArtPainting.docLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">დოკუმენტაცია</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <DocObjectListForLinksTable caption={{ type: "დოკუმენტის ტიპი", docNum: "ავტორი" }} objID={wallArtPainting.id} type="document" objType="wallartpainting" idList={wallArtPainting.docLinks} objName={wallArtPainting.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        </div>

                        <Footer>
                            <div className="containerDiv" style={{ paddingTop: '40px', paddingLeft: '20px' }}>
                                <Row >
                                    <Col md={5}>
                                        <div style={{ fontFamily: 'HelveticaBold', fontSize: '16px' }}>ინფორმაციის შემდგენელი</div>
                                        <div style={{ marginBottom: '20px' }}>თარიღი: <span className="headerText">{wallArtPainting.cardCreationDate}</span></div>
                                        <div style={{ display: 'inline-flex' }}>
                                            <Avatar title={wallArtPainting.cardCreator[0].name} src={wallArtPainting.cardCreator[0].photo} size={50} round="50px" />
                                            <span className="avatarText" data-tip data-for="allAuthors">{wallArtPainting.cardCreator.length > 1 ? `${wallArtPainting.cardCreator[0].name} \n\r(+ ${wallArtPainting.cardCreator.length - 1} ავტორი)`
                                                : wallArtPainting.cardCreator[0].name}</span>
                                        </div>
                                        {wallArtPainting.cardCreator.length > 1 ?
                                            <ReactTooltip className="customZIndex" id="allAuthors" effect='solid' backgroundColor="#FFFFFF" textColor="#000000" place="bottom" border={true} offset={{ top: 70, left: -35 }}>
                                                <span>
                                                    <ul className="periodList">{
                                                        wallArtPainting.cardCreator.map((item, i) => {
                                                            return <div style={{ marginTop: '5px' }} key={i}> <Avatar title={item.name} src={item.photo} size={30} round="30px" />
                                                                <span style={{ marginLeft: '10px' }}>{item.name}</span></div>
                                                                ;
                                                        })
                                                    }</ul>
                                                </span>
                                            </ReactTooltip>
                                            : <></>
                                        }
                                    </Col>
                                    <Col md={7}>
                                        <p style={{ borderBottom: '1px solid #ECECEC' }} className="facebookCOmmentP" />
                                        <div className="fb-comments" data-href={window.location.href} data-numposts="5" data-width="" data-mobile="true"></div>
                                    </Col>
                                </Row>
                            </div>
                        </Footer>
                    </div>
                    : <div style={{
                        marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center'
                    }}>
                        <PulseLoader
                            color={"#E57959"}
                            loading={true}
                        />
                    </div>
                }
            </div>
        );
    }

}