import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {  Row } from 'react-bootstrap'

export class HistoryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };        
    }

    handleClose = () => this.setState({ show: false });
    handleShow = () => this.setState({ show: true });

    render() {
       
        return (
            <>
                <div className="showModal">
                    <button onClick={this.handleShow} className="MainButton" disabled={(this.props.desc === '' || this.props.desc == null)}>ისტორიული ცნობები</button>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose} className="FullDescDialog">
                    <Modal.Header closeButton>
                        <Modal.Title className="ModalHeader">{this.props.objname}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <div className="ObjectHeader">შექმნის ისტორია</div>
                            {this.props.desc}                           
                        </Row>
                    </Modal.Body>
                   
                </Modal>
            </>
        );
    }
}

