import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'

import { Formik, Field, Form, FieldArray } from 'formik';
import { authenticationService } from '../_services';
import { authHeader } from '../_helpers';

function GetModal(props) {
    const [show, setShow] = useState(false);

    var [collection, setCollection] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true) };

    if (collection === null) {
        getFolders();
    }

    function getFolders() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        fetch(`api/Users/GetUserFolders`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setCollection(data);
            });
    }

    function addFolder() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        fetch(`api/Users/AddUserFolder`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setCollection(data);
            });
    }

    function updateFolder(id, name) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        fetch(`api/Users/UpdateUserFolder?id=${id}&name=${name}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setCollection(data);
            });
    }

    function deleteFolder(id) {
        var result = window.confirm("გსურთ წაშლა?");
        if (result) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            fetch(`api/Users/DeleteUserFolder?id=${id}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setCollection(data);
                });
        }
    }

    function addToFavorite(id) {
        if (props.favorite === false) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            var folderID = collection.filter(x => x.isActive == true)[0].id;
            fetch(`api/Users/AddToFvorite?folderID=${folderID}&objectType=immovable&objectID=${id}`, requestOptions)
                .then(data => {
                    props.setStatus(true);
                    handleClose();
                });
        }
        else {
            var result = window.confirm("გსურთ წაშლა?");
            if (result) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                return fetch(`api/Users/DeleteFromFvorite?objectType=immovable&objectID=${id}`, requestOptions)
                    .then(data => {
                        props.setStatus(false);
                        getFolders();
                    });
            }
        }
    }


    return (

        <>
            <div onClick={props.favorite ? () => addToFavorite(props.objID) : handleShow} className="showModal" style={{ float: 'right', cursor: 'pointer' }}>
                {
                    //<Button id="mapButton"><img width="20" alt="" src="/images/heart.svg" style={{ marginRight: '10px' }} />{' '}</Button>{' '}
                }
                <div ><img width="20" alt="" src={props.favorite ? "/images/heartfill.svg" : "/images/heart.svg"} style={{ marginRight: '10px' }} />{' '}</div>

            </div>

            <Modal show={show} onHide={handleClose} className="categoryDialog">
                <Modal.Header closeButton>
                    <Modal.Title className="ModalHeader">კოლექციაში დამატება</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            collections: collection,
                        }}

                        onSubmit={({ folders }, { setStatus, setSubmitting }) => {
                            setStatus();
                            addToFavorite(props.objID).then(
                                user => {

                                },
                                error => {
                                    setSubmitting(false);
                                    setStatus(error);
                                }
                            );                          
                        }}
                        render={({
                            values, errors, status, touched, isSubmitting, setFieldValue }) => (
                                <Form >
                                    {status &&
                                        <div className={'alert alert-danger'}>{status}</div>
                                    }

                                    <div className="form-group" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <FieldArray
                                            name="collections"
                                            render={arrayHelpers => (
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {collection && collection.length > 0 ? (
                                                        collection.map((folder, index) => (
                                                            <div style={{ display: 'inline-flex' }} key={index}>
                                                                <div style={{ textAlign: 'center', width: '227px' }} className={`categoryButton ${folder.isActive ? 'active' : ''}`} onClick={() => {
                                                                    for (var i = 0; i < collection.length; i++) {
                                                                        setFieldValue(`collections.${i}.isActive`, false)
                                                                        collection[i].isActive = false;
                                                                    }
                                                                    setFieldValue(`collections.${index}.isActive`, true)
                                                                    collection[index].isActive = true;
                                                                }}>
                                                                    <Field
                                                                        autoFocus={collection[index].isActive === true && collection[index].name == null ? true : false}
                                                                        autoComplete="off" name={`collections.${index}.name`} type="text" className={'form-control collectionText'}
                                                                        onBlur={(event) => updateFolder(folder.id, event.target.value)} onKeyPress={(e) => {
                                                                            if (e.key === 'Enter') { e.preventDefault(); e.target.blur() }
                                                                        }}
                                                                    />

                                                                    <label>
                                                                        {
                                                                            folder.count
                                                                        }

                                                                    </label>
                                                                    {
                                                                        //<button id={`removeimage_${index}`} className={`deleteImage`}
                                                                        //    type="button"
                                                                        //    onClick={() => arrayHelpers.remove(index)}
                                                                        //>x</button>
                                                                    }
                                                                </div>
                                                                <div id={`removefolder_${index}`} className={`deleteFolder`}

                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                        deleteFolder(folder.id)
                                                                    }
                                                                    }
                                                                >x</div>

                                                            </div>
                                                        ))
                                                    ) : (
                                                            <>
                                                            </>
                                                        )}

                                                    <div>
                                                        <div >
                                                            <div className="newCollection" onClick={(event) => {
                                                                //arrayHelpers.push("");
                                                                addFolder()
                                                            }}>ახლის შექმნა</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )}
                                        />

                                    </div>
                                    <div className="form-group" style={{ display: 'flex', float: 'right' }}>

                                        <div className="NoFillSearchButton">გაუქმება</div>
                                        <button type="submit" className="MainButton" disabled={isSubmitting}>დამატება</button>
                                        {
                                            //    isSubmitting &&
                                            //<img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        }

                                    </div>
                                </Form>
                            )}
                    />
                </Modal.Body>

            </Modal>
        </>
    );
}


export class AddToCollection extends React.Component {

    render() {

        return (
            <GetModal favorite={this.props.favorite} objID={this.props.objID} setStatus={this.props.setStatus} />

        );
    }
}