import React, { Component } from 'react';
import './Object.css';
import { Row, Col, Accordion, Card } from 'react-bootstrap'
import { WebMapView } from '../WebMapView';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { CustomToggle } from '../custom/CustomToggle'
import { ObjectListForLinks } from '../ObjectListForLinks';
import { FullDescModal } from './FullDescModal';
import { DocObjectListForLinksTable } from '../DocObjectListForLinksTable';
import { EventObjectListForLinksTable } from '../EventObjectListForLinksTable';
import PulseLoader from "react-spinners/PulseLoader";
import { Gallery } from './Gallery';
import Avatar from 'react-avatar';
import { Footer } from '../Footer';
import ReactTooltip from "react-tooltip";
import { authHeader, handleResponse } from '../../_helpers';
import { authenticationService } from '../../_services';

import { AddToCollection } from '../AddToCollection';
import { Share } from '../Share';

export class MuseumObject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            isAdmin: false,
            favorite: false,
            museum: {},
            objectID: null,
            objToZoom: null,
            fullScreeenIcon: "esri-icon-zoom-out-fixed",
            mapClass: '',
            loading: true
        };

        this.fullScreenMode = this.fullScreenMode.bind(this)
    }
    componentDidMount() {
        var el = document.querySelector('.searchComponentButton');
        el.textContent = "მუზეუმ-ნაკრძალი/მუზეუმი";

        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x, isAdmin: x && x.role === "Admin"
        }));
        this.loadPage();
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const id = parseInt(params.get('id'));
        if (id !== null) {
            var type = `api/Museum/GetMuseumObject?id=${id}`;

            if (this.state.currentUser != null) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                const response = await fetch(type, requestOptions);
                const data = await response.json();
                this.setState({ museum: data, loading: false, favorite: data.isFavorite, objectID: data.id });

            }
            else {
                const response = await fetch(type);
                const data = await response.json();
                this.setState({ museum: data, loading: false, favorite: data.isFavorite, objectID: data.id });
            }
        }
    }

    toogleFullDesc() {
        if (document.getElementsByClassName('DescTextFull')[0].classList.contains('displayNone')) {
            document.getElementsByClassName('DescTextFull')[0].classList.remove('displayNone');
            document.getElementsByClassName('DescText')[0].classList.add('displayNone');
        }
        else {
            document.getElementsByClassName('DescTextFull')[0].classList.add('displayNone');
            document.getElementsByClassName('DescText')[0].classList.remove('displayNone');
        }

    }

    static displayName = MuseumObject.name;

    addToFavorite(id) {
        if (this.state.favorite === false) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            fetch(`api/Users/AddToFvorite?folderID=0&objectType=museum&objectID=${id}`, requestOptions)
                .then(data => {
                    this.setState({ favorite: true });
                });
        }
        else {
            var result = window.confirm("გსურთ წაშლა?");
            if (result) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                return fetch(`api/Users/DeleteFromFvorite?objectType=museum&objectID=${id}`, requestOptions)
                    .then(data => {
                        this.setState({ favorite: false });
                    });
            }
        }
    }
    hadleFavoriteStatus(value) {
        this.setState({ favorite: value });
    }

    fullScreenMode() {
        if (this.state.mapClass === '') {
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-in-fixed', mapClass: 'objectFullScreentMap', objectID: null, objToZoom: this.state.museum.id })
        }
        else {

            document.getElementById('toolbarDiv').classList.add('displayNone');
            document.getElementById('menuDiv').classList.add('displayNone')
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-out-fixed', mapClass: '', objectID: this.state.museum.id, objToZoom: null })
        }
    }

    render() {
        const museum = this.state.museum;

        window.FB = null;
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/ka_GE/sdk.js#xfbml=1&autoLogAppEvents=1&version=v8.0&appId=1607074352807965";
            js.nonce = "q90Xb87m";

            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        return (
            <div className="immObjDiv">
                {museum && museum.id > 0 ?
                    <div>
                        <div className="containerDiv">
                            <Row className="rowPadding">
                                <Col md={5}>
                                    <Gallery photo={museum.photoIds} video={museum.videoIds} vid3D={museum._3dobjects} mainphoto={museum.photoAddress} type="museum" authors={museum.docAuthors} />
                                </Col>
                                <Col md={7}>

                                    <div className="ObjectType">მუზეუმი/მუზეუმ-ნაკრძალი</div>
                                    <div className="ObjectMainHeader">

                                        <span className="ObjectMainHeaderSpan">{museum.objectName} - #{museum.id}</span>
                                        <Share header="გაზიარება" customClass="objectShare" />
                                        {this.state.currentUser && this.state.isAdmin === false &&
                                            //<div style={{ float: 'right', cursor: 'pointer' }}><img width="20" alt="" src={this.state.favorite ? "/images/heartfill.svg" : "/images/heart.svg"} style={{ marginRight: '10px' }} onClick={() => this.addToFavorite(immovable.id)} />{' '}</div>
                                            <AddToCollection favorite={this.state.favorite} objID={museum.id} setStatus={this.hadleFavoriteStatus.bind(this)}></AddToCollection>
                                        }
                                    </div>
                                    {
                                        museum.sSSM == null || museum.sSSM === '' ?
                                            <></> :
                                            <div> <img style={{ width: '25px', height: '25px' }} alt="" src="/images/disabled.svg" />{museum.sSSM}</div>

                                    }
                                    <div><span className="headerText">ფართობი: </span>{museum.area}</div>
                                    <div className="DescText">
                                        {museum.shortDesc}
                                        <div onClick={this.toogleFullDesc} className="toggleFullDesc">
                                            <svg className="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 01.708 0L8 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 01.708 0L8 8.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="DescTextFull displayNone">{museum.shortDescFull}
                                        <div onClick={this.toogleFullDesc} style={{ textAlign: "center", padding: "20px" }}>
                                            <svg className="bi bi-chevron-double-up" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M7.646 2.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 3.707 2.354 9.354a.5.5 0 11-.708-.708l6-6z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M7.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                        <FullDescModal className="FullDescModal" buttonDesc="დეტალური ინფორმაცია" objname={museum.objectName} disabled={(museum.shortDescFull === '' && museum.functions === '' && museum.events === '' && museum.services === '')}>
                                            {museum.shortDescFull ?
                                                <div>
                                                    <div className="PopupHeader">ზოგადი ინფორმაცია</div>
                                                    {museum.shortDescFull}
                                                </div>
                                                : <></>
                                            }
                                            {museum.functions ?
                                                <div>
                                                    <div className="PopupHeader">ფუნქცია და მოვალეობა</div>
                                                    {museum.functions}
                                                </div>
                                                : <></>
                                            }
                                            {museum.events ?
                                                <div>
                                                    <div className="PopupHeader">ღონისძიებები</div>
                                                    {museum.events}
                                                </div>
                                                : <></>
                                            }
                                            {museum.services ?
                                                <div>
                                                    <div className="PopupHeader">სერვისები</div>
                                                    {museum.services}
                                                </div>
                                                : <></>
                                            }
                                        </FullDescModal>
                                    </div>
                                </Col>
                            </Row>
                            <div className="ObjectHeaderNoTop" style={{ marginLeft: '35px', marginTop: '20px' }}>ადგილმდებარეობა</div>
                            <Row style={{ padding: '20px', width: '100%' }}>
                                <Col md={5}>
                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                        <div>
                                            <div>რეგიონი: <span className="headerText">{museum.region}</span></div>
                                            <div>მუნიციპალიტეტი: <span className="headerText">{museum.municipality}</span></div>
                                            <div>დასახლებული პუნქტი: <span className="headerText">{museum.settlement}</span></div>
                                            <div>მისამართი: <span className="headerText">{museum.street}</span></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={7} style={{ minHeight: '250px' }} className="ObjectMap">
                                    <WebMapView type="museum" objectID={this.state.objectID} objToZoom={this.state.objToZoom} right="30px" fullScreeenIcon={this.state.fullScreeenIcon} fullClick={this.fullScreenMode.bind(this)} mapClass={this.state.mapClass} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5}>

                                </Col>
                                <Col md={7}>
                                    <div style={{ display: 'inline-flex', marginBottom: '30px' }}>
                                        <img style={{ width: '31px', height: '22px', marginRight: '20px' }} alt="" src="/images/wind.svg" />{' '}
                                სიმაღლე ზღვის დონიდან: {museum.elevetion}მ.
                                 <img style={{ width: '23px', height: '23px', margin: '0 20px' }} alt="" src="/images/navigation.svg" />{' '}
                                        <div>
                                            <div>
                                                <span className="headerText">X: </span>{museum.x}
                                                <span className="headerText"> Y: </span>{museum.y}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="orangeRow">
                            <div className="containerDiv" style={{ paddingLeft: '30px' }}>
                                <Table className="objectTable ">
                                    <Thead>
                                        <Tr className="tableColHeader">
                                            <Th style={{ width: '35%', paddingLeft: '10px' }}>საკონტაქტო ინფორმაცია</Th>
                                            <Th style={{ width: '35%', paddingLeft: '10px' }}>სამუშაო განრიგი</Th>
                                            <Th style={{ width: '30%', paddingLeft: '10px' }}>ბილეთის ფასი</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr className="tableRow" >
                                            <Td className="headerText" style={{ padding: '0 10px 10px 10px' }}>
                                                <div style={{ minHeight: '30px' }}>{museum.email}</div>
                                                <div>{museum.phone}</div>
                                                <div>{museum.Mobile}</div>
                                            </Td>
                                            <Td className="headerText" style={{ padding: '0 10px 10px 10px' }}>
                                                <span>
                                                    <ul className="periodList">
                                                        <div>{museum.workDays}</div>
                                                    </ul>
                                                </span>
                                            </Td>
                                            <Td className="headerText" style={{ padding: '0 10px 10px 10px' }}>
                                                <span>
                                                    <ul className="periodList">
                                                        <div>{museum.ticketPrice}</div>
                                                    </ul>
                                                </span>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </div>
                        </div>

                        {
                            museum.linksDisplay ? <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '80px' }} /> : <></>

                        }
                        <div className="containerDiv">
                            <div className="accardionPadding ObjectHeaderNoTop">{museum.linksDisplay ? 'დაკავშირებული კომპონენტები' : ''}</div>
                            {museum.zoneLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1">ზოგადი დამცავი ზონა {museum.zoneLinksCount}</CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={museum.id} type="zone" objType="museum" idList={museum.zoneLinks} objName={museum.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {museum.immovableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">უძრავი ძეგლი/ობიექტი {museum.immovableLinksCount} </span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={museum.id} type="immovable" objType="museum" idList={museum.immovableLinks} objName={museum.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {museum.movableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მოძრავი ძეგლი/ობიექტი {museum.movableLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={museum.id} type="movable" objType="museum" idList={museum.movableLinks} objName={museum.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {museum.museumLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მუზეუმ-ნაკრძალი/მუზეუმი {museum.museumLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={museum.id} type="museum" objType="museum" idList={museum.museumLinks} objName={museum.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {
                                museum.eventLinkExist ?
                                    <Row className="accardionPadding">
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Card.Header className="TogglePanelHeader">
                                                    <CustomToggle eventKey="1"><span className="headerText">ქმედება {museum.eventLinksCount}</span></CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body className="accardionBodyPadding">
                                                        {
                                                            <EventObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={museum.id} type="event"
                                                                objType="museum" idList={museum.eventLinks} objName={museum.objectName} />
                                                        }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Row>
                                    : <></>
                            }
                            {museum.docLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">დოკუმენტაცია {museum.docLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <DocObjectListForLinksTable caption={{ type: "დოკუმენტის ტიპი", docNum: "ავტორი" }} objID={museum.id} type="document" 
                                                        objType="museum" idList={museum.docLinks} objName={museum.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }

                        </div>
                        <Footer>
                            <div className="containerDiv" style={{ paddingTop: '40px', paddingLeft: '20px' }}>
                                <Row >
                                    <Col md={5}>
                                        <div style={{ fontFamily: 'HelveticaBold', fontSize: '16px' }}>ინფორმაციის შემდგენელი</div>
                                        <div style={{ marginBottom: '20px' }}>თარიღი: <span className="headerText">{museum.cardCreationDate}</span></div>
                                        <div style={{ display: 'inline-flex' }}>
                                            <Avatar title={museum.cardCreator[0].name} src={museum.cardCreator[0].photo} size={50} round="50px" />
                                            <span className="avatarText" data-tip data-for="allAuthors">{museum.cardCreator.length > 1 ? `${museum.cardCreator[0].name} \n\r(+ ${museum.cardCreator.length - 1} ავტორი)`
                                                : museum.cardCreator[0].name}</span>
                                        </div>
                                        {museum.cardCreator.length > 1 ?
                                            <ReactTooltip className="customZIndex" id="allAuthors" effect='solid' backgroundColor="#FFFFFF" textColor="#000000" place="bottom" border={true} offset={{ top: 70, left: -35 }}>
                                                <span>
                                                    <ul className="periodList">{
                                                        museum.cardCreator.map((item, i) => {
                                                            return <div style={{ marginTop: '5px' }} key={i}> <Avatar title={item.name} src={item.photo} size={30} round="30px" />
                                                                <span style={{ marginLeft: '10px' }}>{item.name}</span></div>
                                                                ;
                                                        })
                                                    }</ul>
                                                </span>
                                            </ReactTooltip>
                                            : <></>
                                        }
                                    </Col>
                                    <Col md={7}>
                                        <p style={{ borderBottom: '1px solid #ECECEC' }} className="facebookCOmmentP" />
                                        <div className="fb-comments" data-href={window.location.href} data-numposts="5" data-width="" data-mobile="true"></div>
                                    </Col>
                                </Row>
                            </div>
                        </Footer>
                    </div>
                    : <div style={{
                        marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center'
                    }}>
                        <PulseLoader
                            color={"#E57959"}
                            loading={true}
                        />
                    </div>
                }
            </div>
        );
    }

}