import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table'
//import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Card from 'react-bootstrap/Card'
import './CheckBox.css';
import { SearchTemplate } from './SearchTemplate';
import ReactTooltip from "react-tooltip";
import { Footer } from './Footer';
import { DocumentDetailSearchModal } from './Immovable/DocumentDetailSearchModal';
import { ClearSearch } from './Immovable/ClearSearch';
import PulseLoader from "react-spinners/PulseLoader";

class DocListView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pager: {},
            pageOfItems: [],
            loading: true,
            allChecked: false,
            checkedList: [],
            newSearch: false,
            type: this.props.type,
            data: null
        };

        this.filterResulthandle = this.filterResulthandle.bind(this);
        this.GetSearch = this.GetSearch.bind(this);
        this.detailSearch = React.createRef();
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const objType = params.get('type') || '';
        const objID = parseInt(params.get('objID')) || '';
        const objName = params.get('objName') || '';

        if (objType !== '' && objID !== '') {
            sessionStorage.setItem('objType', objType);
            sessionStorage.setItem('objID', objID);
            sessionStorage.setItem('objName', objName);
            sessionStorage.setItem('isLink', "true");

            this.getLinkedIds(objType, objID);
        }
        else {
            if (sessionStorage.getItem('sessionType') !== "document") {
                sessionStorage.removeItem('filterData');

                if (sessionStorage.getItem('isLink') !== "true") {
                    sessionStorage.clear();
                }
                else {
                    sessionStorage.removeItem('isLink');
                }
                sessionStorage.setItem('sessionType', "document");
            }

            window.scrollTo(0, 0)
            this.loadPage();
        }
    }

    async getLinkedIds(objType, objID) {
        var type;
        switch (this.props.type) {
            case "document":
                type = `api/Document/GetLinkedIDs?type=${objType}&obj=${objID}`;
                break;
            default: type = '';
                break;
        }
        const response = await fetch(type);
        const data = await response.json();
        sessionStorage.setItem('filterResult', data.join());
        window.scrollTo(0, 0)
        this.loadPage();
    }
    componentDidUpdate() {
        this.loadPage();
    }

    createDataElement() {
        var data;
        switch (this.props.type) {
            case "document":
                data = {
                    id: '', name: '', org: '', docStartDate: '', docEndDate: '',
                    documentType: [], tempselectedDocumentType: [], linkedObj: {}, fullText: ''
                };
                break;
            default:
                return "";
        }

        return data;
    }

    async loadPage() {

        const params = new URLSearchParams(window.location.search);
        const page = parseInt(params.get('page')) || 1;

        var fileter = params.get('filterText') || '';
        var fileterStr = fileter == null || fileter === '' ? '' : '&filterText=' + fileter;

        var dataFilter = JSON.parse(JSON.parse(sessionStorage.getItem("filterData")));
        if (sessionStorage.getItem('objID') != null) {
            if (dataFilter == null) dataFilter = this.createDataElement();
            dataFilter.linkedObj = {};
            dataFilter.linkedObj.type = sessionStorage.getItem('objType');
            dataFilter.linkedObj.objID = sessionStorage.getItem('objID');
        }
        if (fileter !== '') {
            if (dataFilter == null) dataFilter = this.createDataElement();
            dataFilter.fullText = fileter;
        }

        var type;
        switch (this.props.type) {
            case "document":
                type = `api/document/GetDocumentList?count=10&page=${page}` + fileterStr;
                break;

            default: type = '';
                break;
        }

        var res = sessionStorage.getItem('filterResult') || '';

        if (page !== this.state.pager.currentPage || this.state.newSearch === true || this.props.type !== this.state.type) {
            const response = await fetch(type, {
                method: "POST",
                body: JSON.stringify(res),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            });


            const data = await response.json();
            this.setState({ pager: data, pageOfItems: data.aaData, loading: false, newSearch: false, type: this.props.type, data: dataFilter });
        }
    }

    handleChange = e => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { pageOfItems, allChecked } = prevState;
            if (itemName === "checkAll") {
                allChecked = checked;

                pageOfItems = pageOfItems.map(item => {
                    if (item.isPublic === true) {
                        return { ...item, isChecked: checked };
                    }
                    return item;
                });


                // pageOfItems = pageOfItems.map(item => ({ ...item, isChecked: item.isPublic === true ? checked : item.isChecked }));

            } else {
                pageOfItems = pageOfItems.map(item =>
                    `${item.id}` === itemName ? { ...item, isChecked: checked } : item
                );


                allChecked = pageOfItems.every(item => item.isChecked);
            }
            return { pageOfItems, allChecked };
        });
    };

    async downloadZIp(ids) {

        fetch(`api/document/DownloadZip?ids=${ids}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
            function (myBlob) {
                myBlob.blob().then(
                    function (res) {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(res);
                        link.download = `documents.zip`;
                        link.click();
                    }
                );
            });


    }


    download = () => {
        var idsArray = this.state.pageOfItems.map(function (v) {
            return v.isChecked ? v.id : '';
        });

        var ids = idsArray.filter(item => item !== '').join(',');
        var str = `api/document/Download?ids=${ids}`;

        if (ids != null && ids !== "") {
            //   window.location.href = str;

            this.downloadZIp(ids);

        }
        else {
            alert("მონიშნეთ ობიექტები");
        }

    }
    goToObject(link) {
        window.location = "../" + link;
    }
    drawImovableList(forecasts, className) {

        return (
            <Table className="docTable">
                <Tbody>
                    {
                        forecasts.map(forecast =>
                            <Tr key={forecast.id} className="mainCard">
                                <Td style={{ width: '35%', paddingBottom: '15px' }} className="colWidth">
                                    <a href={forecast.linkPath}>
                                        <Card key={forecast.id} className="cardHorizonalDocument">
                                            <div className="checkbox checkbox-success checkboxlist checkboxlistTable checkInCard"
                                                style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                                <input
                                                    id={"check_" + forecast.id}
                                                    type="checkbox"
                                                    name={forecast.id}
                                                    value={forecast.id}
                                                    checked={forecast.isChecked}
                                                    onChange={this.handleChange}
                                                    disabled={!forecast.isPublic}
                                                />
                                                <label htmlFor={"check_" + forecast.id}></label>
                                            </div>
                                            <div className={className}>
                                                {
                                                    forecast.photoAddress == null || forecast.photoAddress === '' ?
                                                        <></> :
                                                        <img alt="" variant="top" src={forecast.photoAddress} className="docImg" />
                                                }

                                            </div>
                                            <Card.Body className="doc_card-body">
                                                <div className="doc_card-text card-link">{forecast.objectName}</div>
                                                <div className="objectTypeList">{forecast.id}</div>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </Td>
                                <Td style={{ width: '40%' }} className="colWidth">
                                    <a href={forecast.linkPath}>
                                        <div className="docContent">
                                            <div style={{ width: '50%' }}>{forecast.variety}</div>
                                            <div style={{ width: '50%' }}>{forecast.period} </div>
                                        </div>
                                    </a>
                                </Td>

                                <Td style={{ width: '25%' }} className="colWidth">
                                    <a href={forecast.linkPath}>
                                        <div className="DocAuthorDiv"><div className='authorCaption'>ავტორი: </div><div style={{ textAlign: 'left' }} data-tip data-for={"sadFace_" + forecast.id}> {forecast.author}</div></div>
                                        {forecast.authorAll !== "" ?
                                            <ReactTooltip id={"sadFace_" + forecast.id} backgroundColor="#E57959" effect='solid'>
                                                <span>
                                                    <ul className="periodList">{
                                                        forecast.authorAll.split(';').map((item, i) => {
                                                            return <li key={i}>{item}</li>;
                                                        })
                                                    }</ul>
                                                </span>
                                            </ReactTooltip>
                                            : <></>
                                        }
                                    </a>
                                </Td>
                                <Td>
                                    <div className="checkbox checkbox-success checkboxlist checkboxlistTable checkInTable"
                                        style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                                        <input
                                            id={"check_" + forecast.id}
                                            type="checkbox"
                                            name={forecast.id}
                                            value={forecast.id}
                                            checked={forecast.isChecked}
                                            disabled={!forecast.isPublic}
                                            onChange={this.handleChange}
                                        />
                                        <label htmlFor={"check_" + forecast.id}></label>
                                    </div>
                                </Td>
                            </Tr>
                        )
                    }

                </Tbody>
            </Table>

        );
    }


    clearFilter() {
        this.filterResulthandle('');
        sessionStorage.clear();
    }

    filterResulthandle(result) {
        window.history.pushState({}, document.title, window.location.pathname)
        sessionStorage.setItem('filterResult', result);
        this.setState({
            newSearch: true, loading: true
        });
        // this.loadPage();
    }

    GetSearch(isMob) {
        switch (this.state.type) {
            case "document":
                if (isMob === 1) return <DocumentDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator} />;
                return <DocumentDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator}>
                    <span className="FilterButtonText">ფილტრი</span></DocumentDetailSearchModal>;
            default:
                return "";
        }
    }

    updatedataState(data) {
        this.setState({ data: data });
    }


    render() {
        const { pager, pageOfItems } = this.state;
        return (
            <div className="card text-center containerDiv">

                <div className="ButtonsMenu">
                    <div className="MobileObjectSearch">
                        <SearchTemplate type={this.props.type}></SearchTemplate>
                        {pager.classificator != null ?
                            this.GetSearch(1)
                            : <></>
                        }
                    </div>
                    <div className="TotalButtons">
                        <button className="SecondaryButtonOrange" style={{ marginRight: "10px" }} onClick={this.download}> <img width="16" style={{ marginTop: '-5px', marginRight: '15px' }}
                            alt="" src="images/downloadRed.svg" />{' '}გადმოწერა</button>
                        {pager.classificator != null ?
                            this.GetSearch()
                            : <></>
                        }
                    </div>

                    {pager.pages &&
                        <div className="TotalText">
                            <div style={{ display: 'inline-block' }}> <span style={{ fontFamily: 'HelveticaBold' }}>სულ:</span> {pager.iTotalRecords.toLocaleString()}  ჩანაწერი</div>

                            <div className="checkbox checkbox-success checkboxlist checkkAllInline">
                                <input id="checkAll" type="checkbox"
                                    name="checkAll"
                                    checked={this.state.allChecked}
                                    onChange={this.handleChange} />
                                <label htmlFor="checkAll">ყველას მონიშვნა</label>
                            </div>

                        </div>
                    }

                </div>

                {
                    pager.classificator != null && this.state.type === this.props.type ?
                        <ClearSearch type={this.state.type} data={this.state.data} updatedataState={this.updatedataState.bind(this)} detailSearch={this.detailSearch} classificator={pager.classificator} filterResulthandle={this.filterResulthandle.bind(this)} />
                        :
                        <></>
                }

                <div className="card-body">
                    {
                        this.state.loading
                            ? <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center' }}>
                                <PulseLoader
                                    color={"#E57959"}
                                    loading={true}
                                />
                            </div>
                            : this.drawImovableList(pageOfItems, this.props.imgClassName)
                    }


                    {pager.pages &&
                        <div className="TotalText">
                            {`${pager.currentPage * 10 - 9}-${pager.currentPage * 10 > pager.iTotalRecords ? pager.iTotalRecords : pager.currentPage * 10} ობიექტი `}
                            <span style={{ fontFamily: 'HelveticaBold' }}>{pager.iTotalRecords.toLocaleString()} - დან</span>
                        </div>
                    }
                </div>
                <div className="pb-0 pt-3 automargin docPages">
                    {pager.pages && pager.pages.length &&
                        <ul className="pagination mainPagination">
                            <li className={`page-item first-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=1` }} className="page-link">
                                    <svg className="bi bi-chevron-double-left" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M8.354 1.646a.5.5 0 010 .708L2.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z" clipRule="evenodd" />
                                        <path fillRule="evenodd" d="M12.354 1.646a.5.5 0 010 .708L6.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z" clipRule="evenodd" />
                                    </svg>
                                </Link>
                            </li>
                            <li className={`page-item previous-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=${pager.currentPage - 1}` }} className="page-link">
                                    <svg className="bi bi-chevron-left" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z" clipRule="evenodd" />
                                    </svg>
                                </Link>
                            </li>
                            {pager.pages.map(page =>
                                <li key={page} className={`page-item number-item ${pager.currentPage === page ? 'active' : ''}`}>
                                    <Link to={{ search: `?page=${page}` }} className="page-link">{page}</Link>
                                </li>
                            )}
                            <li className={`page-item next-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=${pager.currentPage + 1}` }} className="page-link">
                                    <svg className="bi bi-chevron-right" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />
                                    </svg>
                                </Link>
                            </li>
                            <li className={`page-item last-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=${pager.totalPages}` }} className="page-link">
                                    <svg className="bi bi-chevron-double-right" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L9.293 8 3.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />
                                        <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L13.293 8 7.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                    }
                </div>

                <div className="docFooter">
                    <div className="MobileObjectSearch">
                        <div className="docFooterContent">
                            <div className="checkbox checkbox-success checkboxlist" style={{ display: 'inline-block' }}>
                                <input id="checkAll" type="checkbox"
                                    name="checkAll"
                                    checked={this.state.allChecked}
                                    onChange={this.handleChange} />
                                <label htmlFor="checkAll">ყველას მონიშვნა</label>
                            </div>
                        </div>
                        <button className="MainButton docIcoFont-download" onClick={this.download}></button>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export { DocListView };