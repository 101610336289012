import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap'
import * as Yup from 'yup';
import { Footer } from '../Footer';
import { authenticationService } from '../../_services';
import { UserObjectListView } from './UserObjectListView';

import { UserAlertListView } from './UserAlertListView';
import { Favorits } from './Favorits';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import './UserProfile.css';

function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            var el = document.getElementById('photoView');
            el.setAttribute('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
    }
}




const Schema = Yup.object().shape({
    passwordrepeat: Yup.string().oneOf([Yup.ref('password')], 'პაროლი არ ემთხვევა')
});

export class UserProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            viewState: 0,
            currentUser: null,
            isAdmin: false,
            activeTab: props.match.params.type,
        };


    }
    static getDerivedStateFromProps(props, state) {
        if (props.match.params.type !== state.activeTab) {
            return {
                activeTab: props.match.params.type,
            };
        }
        return null;
    }

    setCustomValidity(event) {
        if (event.target.name === "password")
            event.target.setCustomValidity("შეიყვანეთ პაროლი!");
        else if (event.target.name === "confirmpassword")
            event.target.setCustomValidity("გაიმეორეთ პაროლი!");
    }

    removeCustomValidity(event) {
        event.target.setCustomValidity("");
        event.target.placeholder = '';
    }

    addPlaceHolder(event) {
        if (event.target.name === "password")
            event.target.placeholder = "ახალი პაროლი";
        else if (event.target.name === "confirmpassword")
            event.target.placeholder = "გაიმეორეთ პაროლი";
    }

    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x,
            isAdmin: x && x.role === "Admin"
        }));
    }

    componentDidUpdate() {


    }

    changeActiveTab(value, button) {
        //document.getElementById('privateButton').classList.remove('active');
        //document.getElementById('favoriteButton').classList.remove('active');
        //document.getElementById(button).classList.add('active');
        //this.setState({
        //    activeTab: value
        //});
    }



    render() {
        const params = new URLSearchParams(window.location.search);
        var type = params.get('t');

        var currentUser = this.state.currentUser;
        return (
            currentUser &&
            <div >
                <div style={{ display: 'flex', width: '100%', minHeight: 'calc(100vh - 210px)' }}>
                    {
                        type !== '0' ?
                            <div className='userobjectslist aboutUsRowPadding' style={{}} >

                                {
                                    type == null || type === '2' ?
                                        <div className="userButtons" >
                                            <div className="boldHeade">
                                                ობიექტები
                                            </div>
                                            <div style={{ marginLeft: 'auto' }}>
                                                <a className="MainButton" href="/addobject">ობიექტის დამატება</a>

                                            </div>
                                        </div>
                                        :
                                        <div className="boldHeade">
                                            ფავორიტები
                                            </div>
                                }
                                {
                                    type == null ?
                                        <div className={`userButtons ${type == null ? 'userButtonsHidden userButtonsHiddenFlex' : ''}`}>

                                            <a href="/userprofile/userObjects" id="privateButton" className={`UserProfileButton ${this.state.activeTab === "userObjects" ? 'active' : ''}`} onClick={() => this.changeActiveTab('private', 'privateButton')}>ჩემი დამატებული</a>
                                            <a href="/userprofile/favorite" id="favoriteButton" className={`UserProfileButton ${this.state.activeTab === "favorite" ? 'active' : ''}`} onClick={() => this.changeActiveTab('favorite', 'favoriteButton')}><img width="20" alt="" src="/images/heart.svg" style={{ marginRight: '10px' }} />ფავორიტები</a>
                                            {(this.state.currentUser == null ? "" : this.state.currentUser.role) == "Filler" || (this.state.currentUser == null ? "" : this.state.currentUser.role) == "AdminG"?
                                                <a href="/userprofile/userAlerts" id="userAlertButton" className={`UserProfileButton ${this.state.activeTab === "userAlerts" ? 'active' : ''}`} onClick={() => this.changeActiveTab('alert', 'favoriteButton')}><img width="20" alt="" src="/images/file-text.png" style={{ marginRight: '10px' }} />ჩემი განცხადებები</a>
                                                : ""
                                            }
                                        </div>
                                        :
                                        <></>
                                }

                                <div style={{ marginTop: '50px' }}>

                                    {this.state.activeTab === "favorite" ?
                                        <Favorits type="favorite" currentUser={currentUser} />
                                        : this.state.activeTab === "userObjects" ? <UserObjectListView type="private" currentUser={currentUser} />
                                            : <UserAlertListView type="userAlert" currentUser={currentUser} />}
                                </div>
                            </div>
                            : <></>
                    }
                    {
                        type == null || type === '0' ?
                            <div style={{ backgroundColor: "#FDFDFD", minWidth: '25%' }} className={`aboutUsRowPadding ${type == null ? 'userButtonsHidden' : ''}`}>
                                <div style={{ maxWidth: "460px", marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div className="boldHeade">
                                        ჩემი პროფილი
                            </div>

                                    <Formik
                                        initialValues={{
                                            photo: currentUser.photo,
                                            photoChanged: false,
                                            password: '',
                                            passwordrepeat: ''
                                        }}
                                        validationSchema={Schema}
                                        onSubmit={({ photo, photoChanged, password, passwordrepeat }, { setStatus, setSubmitting }) => {
                                            setStatus();
                                            authenticationService.updateprofile({ photo, photoChanged, password, passwordrepeat })
                                                .then(
                                                    message => {

                                                        authenticationService.currentUser.subscribe(x => this.setState({
                                                            currentUser: x,
                                                            isAdmin: x && x.role === "Admin"
                                                        }));

                                                        setSubmitting(false);
                                                        setStatus(message);
                                                    },
                                                    error => {
                                                        setSubmitting(false);
                                                        setStatus(error);
                                                    }
                                                );
                                        }}
                                        render={({ values, errors, status, touched, isSubmitting, setFieldValue }) => (

                                            <Form id="profileUpdate-form" style={{ marginTop: '30px' }}>
                                                {status &&
                                                    <div className={'alert alert-danger'}>{status}</div>
                                                }
                                                <div className="form-group">
                                                    <div style={{ display: "inline-flex" }}>
                                                        <div className="userImageDiv"
                                                            onClick={() => {
                                                                if (values.photo == null || values.photo === "") {
                                                                    document.getElementById("photo").click()
                                                                }
                                                            }}>
                                                            <img alt="" src={values.photo} style={{ width: '100%', height: values.photo != null && values.photo !== "" ? '100%' : '0' }} id="photoView" />{' '}

                                                            <input id="photo" name="photo" type="file" accept="image/*" style={{ display: 'none' }}
                                                                onChange={(event) => {

                                                                    setFieldValue("photo", event.currentTarget.files[0]);
                                                                    setFieldValue("photoChanged", true);
                                                                    readURL(event.currentTarget);
                                                                }}
                                                            />
                                                        </div>
                                                        <div id="addUser" className={values.photo != null && values.photo !== "" ? "userremoveImage" : "useraddImage"}
                                                            onClick={() => {
                                                                if (values.photo != null && values.photo !== "") {
                                                                    setFieldValue("photo", null);
                                                                    setFieldValue("photoChanged", true);
                                                                }
                                                                else {
                                                                    document.getElementById("photo").click()
                                                                }


                                                            }}></div>
                                                        <span className="avatarText" data-tip data-for="allAuthors">{currentUser.userName}</span>
                                                    </div>
                                                </div>
                                                <div style={{ fontFamily: 'HelveticaBold', fontSize: '16px', marginTop: '50px', marginBottom: '30px' }}>პაროლის შეცვლა</div>

                                                <div className="form-group">
                                                    <Field autoComplete="new-password" name="password" type="password" className={'form-control contactText' + (errors.password && touched.password ? ' is-invalid' : '')} placeholder="ახალი პაროლი" />
                                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                </div>
                                                <div className="form-group">
                                                    <Field autoComplete="new-password" name="passwordrepeat" type="password" className={'form-control contactText' + (errors.passwordrepeat && touched.passwordrepeat ? ' is-invalid' : '')} placeholder="გაიმეორეთ პაროლი" />
                                                    <ErrorMessage name="passwordrepeat" component="div" className="invalid-feedback" />
                                                </div>
                                                <div style={{ padding: '0.375rem 0.75rem' }}>
                                                    <button type="submit" className="SecondaryButtonOrange" disabled={isSubmitting}>დამახსოვრება</button>
                                                    {isSubmitting &&
                                                        <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                </div>

                                            </Form>
                                        )}
                                    />
                                </div>
                            </div>
                            : <></>
                    }
                </div>
                <Footer></Footer>
            </div>


        );
    }

}