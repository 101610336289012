import React, { Component } from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import { authenticationService } from '../../_services';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { Row, Col, Modal, Card } from 'react-bootstrap';
import PropTypes from "prop-types";
import './AdminebisMartva.css';
import { authHeader, handleResponse } from '../../_helpers';
const Schema = Yup.object().shape({
    friends: Yup.array()
        .of(
            Yup.object().shape({
                firstName: Yup.string().min(11, 'too short').required('Required'), // these constraints take precedence
                lastName: Yup.string().min(1, 'cmon').required('Required'), // these constraints take precedence
            })
        )
        .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
        //.min(3, 'Minimum of 3 friends'),
});
export class AdminebisMartvaModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            error: '',
            damxmareNags: [],
            sportTypes: [],
            show: false,
            objId: '7',
            LKPSxvaShenobaNagebobebi: [], //ბაზის ლუქაფი კონსტრუქციის ტიპი
        };
        //this.incrementCounter = this.incrementCounter.bind(this);
    }
    componentDidMount() {
        this.loadPage();
    }
    async loadPage() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        let initialUserAlertMail = [];
        fetch('api/Users/getUserAdminebiG', requestOptions)
            .then(response => {
                return response.json();
            }).then(data => {
                initialUserAlertMail = data;
                // console.log(initialSportTypes);
                this.setState({
                    friends: initialUserAlertMail,
                });
            });
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <Modal
                dialogClassName="modalAdminebisMartva"
                /*size="lg"*/
                show={true}
                backdrop="static"
                centered>
                <Modal.Header>
                    <Modal.Title>ადმინების მართვა</Modal.Title>

                </Modal.Header>
                <Modal.Body>

                    <Formik
                        initialValues={{
                            Objectid: 0,
                            BuildingName: '',
                            Kons_Tipi: '',
                            friends: this.state.friends
                        }}
 
                       // validationSchema={Schema}
                        onSubmit={({ friends }, { setStatus, setSubmitting, setFieldValue }) => {
                            setStatus();
                            authenticationService.registerGancxadebisAdmini({ friends })
                                .then(
                                    user => {
                                        setStatus(user);
                                        //setFieldValue('friends', [""])
                                        this.loadPage();
                                    },
                                    error => {
                                        setSubmitting(false);
                                        setStatus(error);
                                    }
                                );
                        }}
                    >

                        {({ values, errors, status, touched, isSubmitting, setFieldValue }) => {
                            useEffect(() => {
                                const id = this.props.ObjId;
                                if (id) {
                                    setFieldValue('ObjectId', id.objectId)
                                    setFieldValue('Dasaxeleba', id.dasaxeleba)
                                    setFieldValue('Danishnuleba', id.danishnuleba)
                                    setFieldValue('OtaxebisRaodenoba', id.otaxebisRaodenoba)
                                    setFieldValue('SasargebloFartobi', id.sasargebloFartobi)
                                    setFieldValue('SartulebisRaodenoba', id.sartulebisRaodenoba)
                                }


                            }, []);

                             return(

                                <Form>
                                    {status &&
                                        <div className={'alert alert-danger'}>{status}</div>
                                    }

                                    <FieldArray
                                        name="friends"
                                        render={arrayHelpers => (
                                            <div>
                                                <div><span>ქვემოთ მიუთითეთ ადმინისტრატორების მონაცემები</span>
                                                    <button type="button" className="SecondaryButtonMartvaRight" style={{ marginRight: "10px" }} onClick={() => arrayHelpers.push('')}>
                                                        <img style={{ "marginRight": "12px", "width": "11px" }} src="images/vectoradminebi.png"></img>დამატება</button>
                                                </div>
                                                {values.friends && values.friends.length > -1 ? (
                                                    values.friends.map((friend, index) => (

                                                        <div key={index}>

                                                            <Row className="form-group">
                                                                <Col style={{ marginTop: '1rem', display:'none' }} md={2}>
                                                                    <label htmlFor="Danishnuleba">id</label>
                                                                    <Field autoComplete="off" name={`friends.${index}.id`} type="text" className={'form-control contactTextAdmin' + (errors.id && touched.id ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />
                                                                    <ErrorMessage name="id" component="div" className="invalid-feedback" />
                                                                </Col>
                                                                <Col style={{ marginTop: '1rem' }} md={2}>
                                                                    <label htmlFor="Danishnuleba">სახელი</label>
                                                                    <Field autoComplete="off" name={`friends.${index}.firstName`} type="text" className={'form-control contactTextAdmin' + (errors.Raodenoba && touched.Raodenoba ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />
                                                                    <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                                                                </Col>
                                                                <Col style={{ marginTop: '1rem' }} md={2}>
                                                                    <label htmlFor="Danishnuleba">გვარი</label>
                                                                    <Field autoComplete="off" name={`friends.${index}.lastName`} type="text" className={'form-control contactTextAdmin' + (errors.Raodenoba && touched.Raodenoba ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />
                                                                    <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                                                                </Col>
                                                                <Col style={{ marginTop: '1rem' }} md={2}>
                                                                    <label htmlFor="Danishnuleba">იუზერი</label>
                                                                    <Field autoComplete="off" name={`friends.${index}.userName`} type="text" className={'form-control contactTextAdmin' + (errors.Raodenoba && touched.Raodenoba ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />
                                                                    <ErrorMessage name="userName" component="div" className="invalid-feedback" />
                                                                </Col>
                                                                <Col style={{ marginTop: '1rem' }} md={2}>
                                                                    <label htmlFor="Danishnuleba">პაროლი</label>
                                                                    <Field autoComplete="off" name={`friends.${index}.password`} type="text" className={'form-control contactTextAdmin' + (errors.Raodenoba && touched.Raodenoba ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />
                                                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                                                </Col>
                                                                <Col style={{ marginTop: '1rem' }} md={2}>
                                                                    <label htmlFor="Danishnuleba">ელ.ფოსტა</label>
                                                                    <Field autoComplete="off" name={`friends.${index}.email`} type="text" className={'form-control contactTextAdmin' + (errors.Raodenoba && touched.Raodenoba ? ' is-invalid' : '')} placeholder="ჩაწერეთ" />
                                                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                                </Col>


                                                                <Col style={{ marginTop: '1rem' }} md={2}>
                                                                    <div>
                                                                        <label></label>
                                                                        <br></br>
                                                                        <button style={{ "background": "white", "border": "none" }} type="button"
                                                                            onClick={() => arrayHelpers.remove(index)}>
                                                                            <img src="images/fi-rr-trash.png"></img></button>
                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        )
                                        }
                                    />


                                    <div className="content">{this.props.children}</div>


                                    <button style={{ "float": "right" }} type="submit" className="toggle-button-martva"
                                    >
                                        შენახვა
                                        </button>
                                    <button style={{ "float": "right", "background": "white", "border": "none", "marginTop": '1%', "color": "#696D6F" }}
                                        data-value="cllose" type="button" className="toggle-button"
                                         onClick={this.props.cancelDamxmareModal
                                         }
                                    >
                                        დახურვა
                                        </button>


                                </Form>

                            );
                        }}



                    </Formik>
                </Modal.Body>
            </Modal >
        );
    }
}
AdminebisMartvaModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

export default AdminebisMartvaModal;