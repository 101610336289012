import React from 'react';
import CreatableSelect from 'react-select';

export class CustomCreatableMultipleSelect extends React.Component {
    //constructor(props) {
    //    super(props);
    //    this.state = {

    //    };
    //}


    formatCreateLabel = (inputValue) => {
        return (<span>{inputValue}</span>);
    };

    getValue() {

    }
    render() {
        let res = Math.max.apply(Math, this.props.items.map(function (o) { return o.id; }))

        const increment = () => {
            res++;
        };

        var options = [];
        this.props.items.map(item =>
            options.push({ value: item.id, label: item.name })
        );

        var selectedValues = [];
        selectedValues = this.props.selectedOption.map(item => {
            var t = {};
            t.label = item;
            if (this.props.items.filter(el => el.name === item).length > 0)
                t.value = this.props.items.filter(el => el.name === item)[0].id
            else {
                increment()
                t.value = res;
            }
            return t;
        });

        const customStyles = {
            option: (styles, state) => ({
                ...styles,
                color: state.isSelected ? "#E57959" : styles.color,
                backgroundColor: state.isSelected ? "transparent" : styles.color,
                //borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
                "&:hover": {
                    color: "#E57959",
                    backgroundColor: "transparent"
                }
            }),
            control: (styles, state) => ({
                ...styles,
                boxShadow: 0,
                borderColor: state.isFocused ? "#ced4da" : "#ced4da",
                "&:hover": {
                    borderColor: state.isFocused ? "#ced4da" : "#ced4da"
                }
            })
        };

        
        return (
            <div className="MultiSelectLocationControl searchDivRowInput">
                <CreatableSelect
                    isMulti
                    blurInputOnSelect={false}
                    onChange={this.props.changeHandle}
                    //onKeyDown={this.props.onKeyDown}
                    placeholder=""
                    value={selectedValues}
                    options={options}
                    noOptionsMessage={() => " "}
                    isClearable={true}
                    allowCreateWhileLoading={false}
                    formatCreateLabel={this.formatCreateLabel}
                    styles={customStyles}
                />
            </div>
        );
    }
}
