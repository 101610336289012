import React, { Component } from 'react';
import './Object.css';
import { Row, Col, Accordion, Card } from 'react-bootstrap'
import { WebMapView } from '../WebMapView';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { CustomToggle } from '../custom/CustomToggle'
import { ObjectListForLinks } from '../ObjectListForLinks';
import { ObjectListForLinksTable } from '../ObjectListForLinksTable';
import { DocObjectListForLinksTable } from '../DocObjectListForLinksTable';
import { EventObjectListForLinksTable } from '../EventObjectListForLinksTable';
import { FullDescModal } from './FullDescModal';
import { HistoryModal } from './HistoryModal';

import { Gallery } from './Gallery';


import PulseLoader from "react-spinners/PulseLoader";
import Avatar from 'react-avatar';
import { Footer } from '../Footer';
import ReactTooltip from "react-tooltip";

import { authHeader, handleResponse } from '../../_helpers';
import { authenticationService } from '../../_services';
import { AddToCollection } from '../AddToCollection';
import { Share } from '../Share';
import { QuickAlertModal } from '../User/QuickAlertModal';

export class ImmovableObject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            isAdmin: false,
            favorite: false,
            immovable: {},
            objectID: null,
            objToZoom: null,
            fullScreeenIcon: "esri-icon-zoom-out-fixed",
            mapClass: '',
            loading: true
/*            RegistryNum: ImmovableObject.RegistryNum*/
        };

        this.fullScreenMode = this.fullScreenMode.bind(this)
        this.quickAlert = React.createRef();
    }
    componentDidMount() {
        var el = document.querySelector('.searchComponentButton');
        el.textContent = "უძრავი ძეგლი";

        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x, loading: true, isAdmin: x && x.role === "Admin"
        }));

        this.loadPage();
    }
    componentDidUpdate() {
        if (this.state.loading) {
            this.loadPage();
        }
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const id = parseInt(params.get('id'));
        if (id !== null) {
            var type = `api/Immovable/GetImmovableObject?id=${id}`;
            if (this.state.currentUser != null) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                const response = await fetch(type, requestOptions);

                const data = await response.json();
                this.setState({ immovable: data, loading: false, favorite: data.isFavorite, objectID: data.id });
            }
            else {

                const response = await fetch(type);

                const data = await response.json();
                this.setState({ immovable: data, loading: false, favorite: data.isFavorite, objectID: data.id });
            }
        }
    }

    toogleFullDesc() {
        if (document.getElementsByClassName('DescTextFull')[0].classList.contains('displayNone')) {
            document.getElementsByClassName('DescTextFull')[0].classList.remove('displayNone');
            document.getElementsByClassName('DescText')[0].classList.add('displayNone');
        }
        else {
            document.getElementsByClassName('DescTextFull')[0].classList.add('displayNone');
            document.getElementsByClassName('DescText')[0].classList.remove('displayNone');
        }

    }


    static displayName = ImmovableObject.name;

    addToFavorite(id) {
        if (this.state.favorite === false) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            fetch(`api/Users/AddToFvorite?folderID=0&objectType=immovable&objectID=${id}`, requestOptions)
                .then(data => {
                    this.setState({ favorite: true });
                });
        }
        else {
            var result = window.confirm("გსურთ წაშლა?");
            if (result) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                return fetch(`api/Users/DeleteFromFvorite?objectType=immovable&objectID=${id}`, requestOptions)
                    .then(data => {
                        this.setState({ favorite: false });
                    });
            }
        }
    }

    hadleFavoriteStatus(value) {
        this.setState({ favorite: value });
    }

    fullScreenMode() {
        if (this.state.mapClass === '') {
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-in-fixed', mapClass: 'objectFullScreentMap', objectID: null, objToZoom: this.state.immovable.id })
        }
        else {

            document.getElementById('toolbarDiv').classList.add('displayNone');
            document.getElementById('menuDiv').classList.add('displayNone')
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-out-fixed', mapClass: '', objectID: this.state.immovable.id, objToZoom: null })
        }
    }

    render() {
        const immovable = this.state.immovable;

        let images = [];
        if (immovable.photoIds != null) {
            immovable.photoIds.map(item =>
                images.push({
                    original: item,
                    thumbnail: item
                })
            );
        }
        else {
            images.push({
                original: '/immovable.svg',
                thumbnail: '/immovable.svg'
            });
        }


        window.FB = null;
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/ka_GE/sdk.js#xfbml=1&autoLogAppEvents=1&version=v8.0&appId=1607074352807965";
            js.nonce = "q90Xb87m";

            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));


        return (
            <div className="immObjDiv">
                {immovable && immovable.id > 0 ?
                    <div>
                        <div className="containerDiv">
                            <Row className="rowPadding">
                                <Col md={5}>
                                    <Gallery photo={immovable.photoIds} video={immovable.videoIds} vid3D={immovable._3dobjects} mainphoto={immovable.photoAddress} type="immovable" authors={immovable.docAuthors} />
                                </Col>
                                <Col md={7}>
                                    <div className="ObjectType">უძრავი ძეგლი</div>
                                    <div className="ObjectMainHeader">

                                        <span className="ObjectMainHeaderSpan" alt={immovable.objectName}>{immovable.objectName} - #{immovable.id}</span>
                                        <Share header="გაზიარება" customClass="objectShare" />
                                        {this.state.currentUser && this.state.isAdmin === false &&
                                            //<div style={{ float: 'right', cursor: 'pointer' }}><img width="20" alt="" src={this.state.favorite ? "/images/heartfill.svg" : "/images/heart.svg"} style={{ marginRight: '10px' }} onClick={() => this.addToFavorite(immovable.id)} />{' '}</div>
                                            <AddToCollection favorite={this.state.favorite} objID={immovable.id} setStatus={this.hadleFavoriteStatus.bind(this)}></AddToCollection>
                                        }
                                    </div>
                                    <div style={{ marginBottom: '20px' }}>{immovable.variety}</div>
                                    <div><span className="headerText">კომპლექსური ძეგლის/ობიექტის სახელწოდება: </span>{immovable.complexName}</div>
                                    <div><span className="headerText">პერიოდი: </span>
                                        <ul className="periodList">{
                                            immovable.period.split(';').map((item, i) => {
                                                return <li key={i}>{item}</li>;
                                            })
                                        }</ul></div>
                                    <div><span className="headerText">თარიღების დიაპაზონი: </span>{immovable.dateDiapazon}</div>
                                    <div><span className="headerText">თარიღი: </span>{immovable.date}</div>
                                    <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '20px' }} />
                                    <div className="DescText">
                                        {immovable.shortDesc}
                                        <div onClick={this.toogleFullDesc} className="toggleFullDesc">
                                            <svg className="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 01.708 0L8 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 01.708 0L8 8.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="DescTextFull displayNone">{immovable.shortDescFull}
                                        <div onClick={this.toogleFullDesc} style={{ textAlign: "center", padding: "20px" }}>
                                            <svg className="bi bi-chevron-double-up" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M7.646 2.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 3.707 2.354 9.354a.5.5 0 11-.708-.708l6-6z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M7.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="immovableObjectButtons" style={{ display: "inline-flex" }}>
                                        <HistoryModal desc={immovable.creationHistory} objname={immovable.objectName} />
                                        <FullDescModal className="FullDescModal" buttonDesc="დეტალური ინფორმაცია" objname={immovable.objectName} >
                                            <div className="PopupHeader">მოკლე დახასიათება</div>
                                            {immovable.shortDescFull}
                                            {immovable.garemo ?
                                                <>
                                                    <div className="ObjectHeaderMarginTop" style={{ marginTop: '20px' }}>გარემო</div>
                                                    {immovable.garemo}
                                                </>
                                                : <></>
                                            }
                                            {(immovable.archRirebulebaSefaseba || immovable.archRirebulebaAgwera) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0" className="accordionNoshadow">
                                                        <Card className="modelCard">
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">არქიტექტურულ-მხატვრული შეფასება</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {immovable.archRirebulebaSefaseba ?
                                                                        <> <div className="ObjectHeaderMarginTop">არქიტექტურულ-მხატვრული შეფასება</div>
                                                                            {immovable.archRirebulebaSefaseba}</>
                                                                        : <></>
                                                                    }
                                                                    {immovable.archRirebulebaAgwera ?
                                                                        <> <div className="ObjectHeaderMarginTop">არქიტექტურულ-მხატვრული აღწერა</div>
                                                                            {immovable.archRirebulebaAgwera}</>
                                                                        : <></>
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {(immovable.fasadElements || immovable.interierisElements || immovable.rezume) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0" className="accordionNoshadow">
                                                        <Card className="modelCard">
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">ინტერიერისა და ფასადის აღწერა</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {immovable.fasadElements ?
                                                                        <>
                                                                            <div className="ObjectHeaderMarginTop">ფასადის არქიტექტურულ-მხატვრული თვალსაზრისით ღირებული ელემენტები</div>
                                                                            {immovable.fasadElements}</>
                                                                        : <></>
                                                                    }
                                                                    {immovable.interierisElements ?
                                                                        <>
                                                                            <div className="ObjectHeaderMarginTop">ინტერიერის არქიტექტურულ-მხატვრული თვალსაზრისით ღირებული ელემენტები</div>
                                                                            {immovable.interierisElements}</>
                                                                        : <></>
                                                                    }
                                                                    {immovable.rezume ?
                                                                        <>
                                                                            <div className="ObjectHeaderMarginTop">მხატვრულ-ისტორიული რეზიუმე</div>
                                                                            {immovable.rezume}</>
                                                                        : <></>
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {(immovable.samshenobloMasala || immovable.gadaxurvisMasala) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0" className="accordionNoshadow">
                                                        <Card className="modelCard">
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">მასალა</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {immovable.samshenobloMasala ?
                                                                        <>
                                                                            <div>სამშენებლო მასალა</div>
                                                                            <div style={{ display: 'inline-block' }}><ul className="ulNoPoint">
                                                                                {
                                                                                    immovable.samshenobloMasala.split(';').map((item, i) => {
                                                                                        return <li key={i} className="headerText" style={{ float: 'left', marginRight: '20px', fontSize: '14px' }}>{item}</li>;
                                                                                    })
                                                                                }
                                                                            </ul></div>
                                                                        </>
                                                                        : <></>
                                                                    }
                                                                    {immovable.gadaxurvisMasala ?
                                                                        <>
                                                                            <div>გადახურვის მასალა</div>

                                                                            <div style={{ display: 'inline-block' }}><ul className="ulNoPoint">
                                                                                {
                                                                                    immovable.gadaxurvisMasala.split(';').map((item, i) => {
                                                                                        return <li key={i} className="headerText" style={{ float: 'left', marginRight: '20px', fontSize: '14px' }}>{item}</li>;
                                                                                    })
                                                                                }
                                                                            </ul></div>
                                                                        </>
                                                                        : <></>
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                        </FullDescModal>

                                    </div>
                                    <br className="displayBr" />
                                    {(this.state.currentUser == null ? "" : this.state.currentUser.role) == "Filler" || (this.state.currentUser == null ? "" : this.state.currentUser.role) == "AdminG" ?


                                        /*immovable.msoflioCategoryTypeID == null || immovable.msoflioCategoryTypeID === '' ?*/
                                        !immovable.objectName.includes("გელათის მონასტერი") ?
                                            <></> :
                                            <QuickAlertModal
                                                ref={this.quickAlert}
                                                immovableObject={true}
                                                ObjectId={immovable.id}
                                                ObjectName={immovable.objectName}
                                                PhotoAddress={immovable.photoAddress}
                                                MainPhoto={immovable.photoAddress}
                                            >
                                            </QuickAlertModal>
                                        : ""
                                    }
                                </Col>
                            </Row>
                            <div className="ObjectHeaderNoTop" style={{ marginLeft: '35px', marginTop: '20px' }}>ადგილმდებარეობა</div>
                            <Row style={{ padding: '20px 20px 0 20px', width: '100%' }} className="ObjectMapRow">

                                <Col md={5}>

                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                        <div>
                                            <div>ქვეყანა: <span className="headerText">{immovable.country}</span></div>
                                            {immovable.region !== '' ? <div>რეგიონი: <span className="headerText">{immovable.region}</span></div> : <></>}
                                            {immovable.municipality !== '' ? <div>მუნიციპალიტეტი: <span className="headerText">{immovable.municipality}</span></div> : <></>}
                                            {immovable.settlement !== '' ? <div>დასახლებული პუნქტი: <span className="headerText">{immovable.settlement}</span></div> : <></>}
                                            {immovable.street !== '' ? <div>მისამართი: <span className="headerText">{immovable.street}</span></div> : <></>}
                                        </div>
                                    </div>
                                    {(immovable.directionHistoricalName !== '' || immovable.historicalSide !== '' || immovable.settlementHistoricalName !== '') ?
                                        <div style={{ display: 'inline-flex', marginTop: '20px', width: '100%' }}>
                                            <img style={{ margin: '5px 11px 0 0', width: '27px', height: '27px' }} alt="" src="/images/ancient-scroll.svg" />{' '}
                                            <div>
                                                {immovable.directionHistoricalName !== '' ? <div>უბნის ისტორიული სახელწოდება: <span className="headerText">{immovable.directionHistoricalName}</span></div> : <></>}
                                                {immovable.historicalSide !== '' ? <div>ისტორიული მხარე: <span className="headerText">{immovable.historicalSide}</span></div> : <></>}
                                                {immovable.settlementHistoricalName !== '' ? <div>დასახლების ისტორიული სახელწოდება: <span className="headerText">{immovable.settlementHistoricalName}</span></div> : <></>}
                                            </div>
                                        </div>
                                        : <></>
                                    }
                                </Col>
                                <Col md={7} style={{ minHeight: '250px' }} className="ObjectMap">

                                    <WebMapView type="immovable" objectID={this.state.objectID} objToZoom={this.state.objToZoom} right="30px" fullScreeenIcon={this.state.fullScreeenIcon} fullClick={this.fullScreenMode.bind(this)} mapClass={this.state.mapClass} />

                                </Col>
                            </Row>
                            <Row>
                                <Col md={5}>

                                </Col>
                                <Col md={7}>
                                    <Row >
                                        <Col md={5} style={{ marginBottom: '20px' }}>
                                            <div className="icon-waves">
                                                სიმაღლე ზღვის დონიდან: {immovable.elevetion}მ.
                                        </div>
                                        </Col>
                                        <Col md={6} style={{ marginBottom: '20px' }}>
                                            <div className="icon-direction-outline">კოორდინატები {immovable.sizuste === 'სწორია' ? <img style={{ width: '17px', height: '17px' }} alt="" src="/images/OK.svg" /> : <></>}
                                            </div>
                                            {immovable.sizuste === 'სწორია' ?
                                                <div>
                                                    <span className="headerText">X: </span>{immovable.x}
                                                    <span className="headerText"> Y: </span>{immovable.y}
                                                </div> :
                                                <div>
                                                    კოორდინატები დასაზუსტებელია
                                                </div>
                                            }
                                        </Col>
                                    </Row>



                                </Col>
                            </Row>
                        </div>
                        <div className="orangeRow">
                            <div className="containerDiv" style={{ paddingLeft: '30px' }}>
                                <Table className="objectTable ">
                                    <Thead>
                                        <Tr className="tableColHeader">
                                            <Th style={{ width: '30%', paddingLeft: '10px' }}>სახეობა</Th>
                                            <Th style={{ width: '35%', paddingLeft: '10px' }}>თავდაპირებლი ფუნქცია/ტიპი</Th>
                                            <Th style={{ width: '35%', paddingLeft: '10px' }}>ამჟამინდელი ფუნქცია/ტიპი</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr className="tableRow" >
                                            <Td className="headerText" style={{ padding: '0 10px 10px 10px' }}>
                                                <span> {immovable.variety}</span>
                                            </Td>
                                            <Td className="headerText" style={{ padding: '0 10px 10px 10px' }}>
                                                <span>
                                                    <ul className="periodList">{
                                                        immovable.initialFunction.split(';').map((item, i) => {
                                                            return <li key={i}>{item}</li>;
                                                        })
                                                    }</ul>
                                                </span>
                                            </Td>
                                            <Td className="headerText" style={{ padding: '0 10px 10px 10px' }}>
                                                <span>
                                                    <ul className="periodList">{
                                                        immovable.currentFunction.split(';').map((item, i) => {
                                                            return <li key={i}>{item}</li>;
                                                        })
                                                    }
                                                    </ul>
                                                </span>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="containerDiv">
                            <Row className="accardionPadding">
                                <div className="ObjectHeader">სტატუსი</div>
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey={immovable.statusID === 1 ? '0' : '1'} className={immovable.statusID === 1 ? 'not-active' : ''} >
                                                ამჟამინდელი: <span className="headerText">{immovable.currentStatus}</span>
                                            </CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>

                                                <Table className="objectTable">
                                                    <Thead>
                                                        <Tr className="tableColHeaderBlue">
                                                            <Th style={{ width: '30%', padding: '10px' }}>თარიღი</Th>
                                                            <Th style={{ width: '30%', padding: '10px' }}>დოკუმენტის ნომერი</Th>
                                                            <Th style={{ width: '40%', padding: '10px' }}>მიმნიჭებელი ორგანიზაცია</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <Tr className="tableRow">
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{immovable.statusDate}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{immovable.statusDocNum}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{immovable.statusOrg}</span>
                                                            </Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>

                                                <Table className="objectTable">
                                                    <Thead>
                                                        <Tr className="tableColHeaderBlue">
                                                            <Th style={{ width: '30%', padding: '10px' }}>რეესტრში შეტანის თარიღი</Th>
                                                            <Th style={{ width: '30%', padding: '10px' }}>რეესტრის ნომერი</Th>
                                                            <Th style={{ width: '40%', padding: '10px' }}></Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <Tr className="tableRow">
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{immovable.registryDate}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{immovable.registryNum}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ height: '0px' }}>

                                                            </Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>

                                                <Row style={{ padding: '20px', width: '100%' }}>
                                                    <div><a href={immovable.statusDocLink} target="_blank" rel="noopener noreferrer" className={`DocLink ${(immovable.statusDocLink === "" || immovable.statusDocLink === null) ? "not-active" : ""}`} onClick={(immovable.statusDocLink === "" || immovable.statusDocLink === null) ? (event) => event.preventDefault() : null} >დოკუმენტის ნახვა</a></div>
                                                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Row>

                            {immovable.statusChange ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">სტატუსის ცვლილების ისტორია</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={immovable.id} type="event" objType="immovableStatus" idList={immovable.statusChangeLinks} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }

                            <Row className="accardionPadding">
                                <div className="ObjectHeader">კატეგორია</div>
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey={immovable.categoryID === 1 ? '0' : '1'} className={immovable.categoryID === 1 ? 'not-active' : ''} >
                                                ამჟამინდელი: <span className="headerText">{immovable.currentCategory}</span>
                                            </CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <Table className="objectTable">
                                                    <Thead>
                                                        <Tr className="tableColHeaderBlue">
                                                            <Th style={{ width: '30%', padding: '10px' }}>თარიღი</Th>
                                                            <Th style={{ width: '30%', padding: '10px' }}>დოკუმენტის ნომერი</Th>
                                                            <Th style={{ width: '40%', padding: '10px' }}>მიმნიჭებელი ორგანიზაცია</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <Tr className="tableRow" >
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{immovable.categoryDate}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{immovable.categoryDocNum}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{immovable.categoryOrg}</span>
                                                            </Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>

                                                <Row style={{ padding: '20px', width: '100%' }}>
                                                    <div><a href={immovable.categoryDocLink} rel="noopener noreferrer" target="_blank"
                                                        className={`DocLink ${(immovable.categoryDocLink === "" || immovable.categoryDocLink === null) ? "not-active" : ""}`}
                                                        onClick={(immovable.categoryDocLink === "" || immovable.categoryDocLink === null) ? (event) => event.preventDefault() : null} >დოკუმენტის ნახვა</a></div>
                                                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Row>

                            {immovable.categoryChange ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">კატეგორიის ცვლილების ისტორია</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={immovable.id} type="event" objType="immovableCategory" idList={immovable.categoryChangeLinks} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }


                            {/*msoflio memkvidreobistvis*/}
                            <Row className="accardionPadding">
                                <div className="ObjectHeader">მსოფლიო მემკვიდრეობა</div>
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey={immovable.msoflioCategoryID === 1 ? '0' : '1'} className={immovable.msoflioCategoryID === 1 ? 'not-active' : ''} >
                                                ამჟამინდელი: <span className="headerText">{immovable.curMsoflioCategory}</span>
                                            </CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <Table className="objectTable">
                                                    <Thead>
                                                        <Tr className="tableColHeaderBlue">
                                                            <Th style={{ width: '30%', padding: '10px' }}>თარიღი</Th>
                                                            <Th style={{ width: '30%', padding: '10px' }}>დოკუმენტის ნომერი</Th>
                                                            <Th style={{ width: '40%', padding: '10px' }}>მიმნიჭებელი ორგანიზაცია</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <Tr className="tableRow" >
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{immovable.curMsoflioCategory}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{immovable.msofliocategoryDocNum}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{immovable.msofliocategoryOrg}</span>
                                                            </Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>

                                                <Row style={{ padding: '20px', width: '100%' }}>
                                                    <div><a href={immovable.msoflioCategoryDocLink} rel="noopener noreferrer" target="_blank"
                                                        className={`DocLink ${(immovable.msoflioCategoryDocLink === "" || immovable.msoflioCategoryDocLink === null) ? "not-active" : ""}`}
                                                        onClick={(immovable.msoflioCategoryDocLink === "" || immovable.msoflioCategoryDocLink === null) ?
                                                            (event) => event.preventDefault() : null} >დოკუმენტის ნახვა</a></div>
                                                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Row>

                            {immovable.msoflioCategoryChange ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">კატეგორიის ცვლილების ისტორია</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }}
                                                        objID={immovable.id} type="event" objType="immovableCategory" idList={immovable.msoflioCategoryChangeLinks} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }

                        </div>
                        {
                            immovable.linksDisplay ? <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '80px' }} /> : <></>

                        }
                        <div className="containerDiv">

                            <div className="accardionPadding ObjectHeaderNoTop">{immovable.linksDisplay ? 'დაკავშირებული კომპონენტები' : ''}</div>

                            {immovable.zoneLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1">ზოგადი დამცავი ზონა</CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={immovable.id} type="zone" objType="immovable" idList={immovable.zoneLinks} objName={immovable.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }

                            {immovable.userAlertLinkExist && (this.state.currentUser != null ? (this.state.currentUser.role == "AdminG" || this.state.currentUser.role == "Admin") : false) ?

                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">განცხადებები</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={immovable.id} type="userAlert" objType="userAlert" idList={immovable.userAlertLinks} objName={immovable.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }

                            {immovable.immovableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">უძრავი ძეგლი/ობიექტი {immovable.immovableLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={immovable.id} type="immovable" objType="immovable" idList={immovable.immovableLinks} objName={immovable.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }

                            {immovable.movableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მოძრავი ძეგლი/ობიექტი {immovable.movableLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={immovable.id} type="movable" objType="immovable" idList={immovable.movableLinks} objName={immovable.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {immovable.museumLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მუზეუმ-ნაკრძალი/მუზეუმი {immovable.museumLinksCount} </span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={immovable.id} type="museum" objType="immovable" idList={immovable.museumLinks} objName={immovable.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {immovable.wallLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">კედლის მხატვრობა {immovable.wallLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={immovable.id} type="wallart" objType="immovable" idList={immovable.wallLinks} objName={immovable.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {immovable.stoneLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">ქვაზე კვეთილობა {immovable.stoneLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={immovable.id} type="stoneengrave" objType="immovable" idList={immovable.stoneLinks} objName={immovable.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {
                                immovable.eventLinkExist ?
                                    <Row className="accardionPadding">
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Card.Header className="TogglePanelHeader">
                                                    <CustomToggle eventKey="1"><span className="headerText">ქმედება</span></CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body className="accardionBodyPadding">
                                                        {
                                                            <EventObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={immovable.id} type="event"
                                                                objType="immovable" idList={immovable.eventLinks} objName={immovable.objectName} />
                                                        }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Row>
                                    : <></>
                            }
                            {immovable.docLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">დოკუმენტაცია {immovable.doclinksCount}</span></CustomToggle>
                                                
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <DocObjectListForLinksTable caption={{ type: "დოკუმენტის ტიპი", docNum: "ავტორი" }} objID={immovable.id} type="document"
                                                        objType="immovable" idList={immovable.docLinks} objName={immovable.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        </div>

                        <Footer>
                            <div className="containerDiv" style={{ paddingTop: '40px', paddingLeft: '20px' }}>
                                <Row >
                                    <Col md={5}>
                                        <div style={{ fontFamily: 'HelveticaBold', fontSize: '16px' }}>ინფორმაციის შემდგენელი</div>
                                        <div style={{ marginBottom: '20px' }}>თარიღი: <span className="headerText">{immovable.cardCreationDate}</span></div>
                                        <div style={{ display: 'inline-flex' }}>
                                            <Avatar title={immovable.cardCreator[0].name} src={immovable.cardCreator[0].photo} size={50} round="50px" />
                                            <span className="avatarText" data-tip data-for="allAuthors">{immovable.cardCreator.length > 1 ? `${immovable.cardCreator[0].name} \n\r(+ ${immovable.cardCreator.length - 1} ავტორი)`
                                                : immovable.cardCreator[0].name}</span>
                                        </div>
                                        {immovable.cardCreator.length > 1 ?
                                            <ReactTooltip className="customZIndex" id="allAuthors" effect='solid' backgroundColor="#FFFFFF" textColor="#000000" place="bottom" border={true} offset={{ top: 70, left: -35 }}>
                                                <span>
                                                    <ul className="periodList">{
                                                        immovable.cardCreator.map((item, i) => {
                                                            return <div style={{ marginTop: '5px' }} key={i}> <Avatar title={item.name} src={item.photo} size={30} round="30px" />
                                                                <span style={{ marginLeft: '10px' }}>{item.name}</span></div>
                                                                ;
                                                        })
                                                    }</ul>
                                                </span>
                                            </ReactTooltip>
                                            : <></>
                                        }
                                    </Col>
                                    <Col md={7}>
                                        <p style={{ borderBottom: '1px solid #ECECEC' }} className="facebookCOmmentP" />
                                        <div className="fb-comments" data-href={window.location.href} data-numposts="5" data-width="" data-mobile="true"></div>
                                    </Col>
                                </Row>
                            </div>
                        </Footer>
                    </div>
                    : <div style={{
                        marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center'
                    }}>
                        <PulseLoader
                            color={"#E57959"}
                            loading={true}
                        />
                    </div>

                }
            </div>
        );
    }

}