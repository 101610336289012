import React from 'react';
import { Modal, Accordion, Card } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import './Search.css';
import { CustomTreeSelect } from '../custom/CustomTreeSelect';
import { CustomMultipleSelect } from '../custom/CustomMultipleSelect';
import { CustomToggle } from '../custom/CustomToggle'

import { CustomCreatableMultipleSelect } from '../custom/CustomCreatableMultipleSelect';
import { CustomCreatableMultipleSelectSearch } from '../custom/CustomCreatableMultipleSelectSearch';
import Autosuggest from 'react-autosuggest';


const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = suggestion => (
    <div>
        {suggestion}
    </div>
);

const orggetSuggestionValue = orgsuggestions => orgsuggestions;
const orgrenderSuggestion = orgsuggestions => (
    <div>
        {orgsuggestions}
    </div>
);

export class EventDetailSearchModal extends React.Component {
    selectedEventType = [];
    tempselectedEventType = [];
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data:
            {
                id: '', name: '', org: '', eventStartDate: '', eventEndDate: '',
                eventType: [], eventStatus: [],
                country: [], region: [], municipality: [], settlement: [], street: [],
                tempselectedEventType: [], linkedObj: {}, fullText: ''
            },
            suggestions: [],
            orgsuggestions: []
        };

        this.handleClearClick = this.handleClearClick.bind(this);
        this.handleOrgClearClick = this.handleOrgClearClick.bind(this);
    }


    handleClose = () => this.setState({ show: false });
    handleShow = () => {
        this.setState({ show: true })

        if (sessionStorage.getItem("filterData") != null && sessionStorage.getItem("filterData") !== '') {
            var data = JSON.parse(JSON.parse(sessionStorage.getItem("filterData")));

            this.tempselectedEventType = data.tempselectedEventType;
            this.selectedEventType = data.eventType;

            this.setState({ data: data })
        }

    };

    onChange(event) {
        var data = this.state.data;
        switch (event.target.name) {
            case "id":
                data.id = parseInt(event.target.value) || ''
                break;
            case "eventStartDate":
                data.eventStartDate = parseInt(event.target.value) || ''
                break;
            case "eventEndDate":
                data.eventEndDate = parseInt(event.target.value) || ''
                break;
            default:
                break;
        }

        this.setState({ data: data });
    }
    handleSubmit(e) {
        e.preventDefault();

        this.prepareSubmit();
    }
    prepareSubmit() {
        var data = this.state.data;
        data.eventType = this.selectedEventType;
        data.tempselectedEventType = this.tempselectedEventType;

        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));

        this.searchData(data);
    }
    checkData(data) {
        if (data.id !== 0 || data.name !== '' || data.org !== '' || data.eventStartDate !== 0 || data.eventEndDate !== 0 ||
            data.eventType.length > 0 || data.tempselectedEventType.length > 0 ||
             data.eventStatus.length > 0 ||
            data.country.length > 0 || data.region.length > 0 || data.municipality.length > 0 || data.settlement.length > 0 || data.street.length > 0) {
            return true;
        }
        return false
    }

    searchData(data) {
        if (data.id === '') data.id = 0;
        if (data.eventStartDate === '') data.eventStartDate = 0;
        if (data.eventEndDate === '') data.eventEndDate = 0;

        if (this.checkData(data)) {
            fetch('api/Event', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response) => {
                this.props.filterResulthandle(response);
                this.handleClose();
            })
        }
        else {
            this.props.filterResulthandle('');
            this.handleClose();
        }
    }

    handleClearData() {
        var data = {
            id: '', name: '', org: '', eventStartDate: '', eventEndDate: '',
            eventType: [], eventStatus: [],
            country: [], region: [], municipality: [], settlement: [], street: [],
            tempselectedEventType: [], linkedObj: {}, fullText: ''
        };
        this.setState({ data: data, show: false })
        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));
        this.props.filterResulthandle('');
    }

    loopChild(item, tempList) {
        tempList.push(item.value);
        if (item.tempchild != null && item.tempchild.length > 0) {
            item.tempchild.map(i => this.loopChild(i, tempList))
        }
    }
    changeeventStatusHandle(items) {
        var data = this.state.data;
        data.eventStatus = [];
        if (items != null) {
            items.map(item =>
                data.eventStatus.push(item.value)
            );
        }
        this.setState({ data: data });
    }

    changeCountryHandle(items) {
        var data = this.state.data;
        data.country = [];
        if (items != null) {
            items.map(item =>
                data.country.push(item.label)
            );
        }
        this.setState({ data: data });
    }

    changeRegionHandle(items) {
        var data = this.state.data;
        data.region = [];
        if (items != null) {
            items.map(item =>
                data.region.push(item.label)
            );
        }
        this.setState({ data: data });
    }

    changeMunicipalityHandle(items) {
        var data = this.state.data;
        data.municipality = [];
        if (items != null) {
            items.map(item =>
                data.municipality.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeSettlementHandle(items) {
        var data = this.state.data;
        data.settlement = [];
        if (items != null) {
            items.map(item =>
                data.settlement.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeStreetHandle(items) {
        var data = this.state.data;
        data.street = [];
        if (items != null) {
            items.map(item =>
                data.street.push(item.label)
            );
        }
        this.setState({ data: data });
    }

    changeEventTypeHandle(currentNode, selectedNodes) {
        this.selectedEventType = [];
        this.tempselectedEventType = [];

        if (selectedNodes != null) {
            selectedNodes.forEach(item => {
                this.selectedEventType.push(item.value)
                this.loopChild(item, this.tempselectedEventType)
            });
        }
    }
    onKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.prepareSubmit();
        }
    }
    onNameChange = (event, { newValue }) => {
        var data = this.state.data;
        data.name = newValue;
        this.setState({
            data: data
        });
    };
    handleClearClick() {
        var data = this.state.data;
        data.name = '';
        this.setState({
            data: data
        });
    }

    onOrgChange = (event, { newValue }) => {
        var data = this.state.data;
        data.org = newValue;
        this.setState({
            data: data
        });
    };
    handleOrgClearClick() {
        var data = this.state.data;
        data.org = '';
        this.setState({
            data: data
        });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                suggestions: []
            }) :

            fetch('api/AutoComplete/Getrecord?value=' + value + "&component=event")
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        suggestions: data
                    })
                })
                .catch(error => console.error(error));
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onOrgSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                orgsuggestions: []
            }) :

            fetch('api/AutoComplete/GetEventOrg?value=' + value)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        orgsuggestions: data
                    })
                })
                .catch(error => console.error(error));
    };
    onOrgSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };


    render() {
        const suggestions = this.state.suggestions;
        const value = this.state.data.name

        const orgsuggestions = this.state.orgsuggestions;
        const org = this.state.data.org

        const inputProps = {
            value,
            onChange: this.onNameChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        let clearButton;
        if (value.length >= 1) {
            clearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleClearClick}
                />
            );
        }

        const orginputProps = {
            value: org,
            onChange: this.onOrgChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        let orgclearButton;
        if (value.length >= 1) {
            orgclearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleOrgClearClick}
                />
            );
        }

        var locationOpen = (this.state.data.country.length > 0 || this.state.data.region.length > 0 || this.state.data.municipality.length > 0 || this.state.data.settlement.length > 0 || this.state.data.street.length > 0);

        return (
            <>
                <div className="showModal">
                    <button onClick={this.handleShow} className="MainButton icon-equalizer" >{this.props.children}</button>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose} className="SearchDialog">
                    <Modal.Header closeButton className="searchCloseModal">
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit.bind(this)} method="POST" style={{ height: '100%', paddingTop: '0px' }}>
                            <div className="searchDivContent">
                                <div className="FullGalleryHeader">სარეგისტრაციო ნომერი</div>
                                <input type="number" className="form-control searchDivRowInput" name="id" id="id" value={this.state.data.id} onChange={this.onChange.bind(this)} autoComplete="off" />
                                <div className="FullGalleryHeader searchDivRow">სახელწოდება</div>
                                <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        renderSuggestion={renderSuggestion}
                                        getSuggestionValue={getSuggestionValue}
                                        inputProps={inputProps} />
                                    <div className="close-icon-container">
                                        {clearButton}
                                    </div>
                                </div>
                                <div className="FullGalleryHeader searchDivRow" >ქმედების ტიპი</div>
                                <CustomTreeSelect items={this.props.classificators.type} onChange={this.changeEventTypeHandle.bind(this)} selectedOption={this.state.data.eventType} />
                                <div className="FullGalleryHeader searchDivRow" >ქმედების სტატუსი</div>
                                <CustomMultipleSelect onKeyDown={this.onKeyPress.bind(this)} selectedOption={this.state.data.eventStatus} items={this.props.classificators.eventStatus} changeHandle={this.changeeventStatusHandle.bind(this)}></CustomMultipleSelect>

                                <div className="FullGalleryHeader searchDivRow" >თარიღი (წელი)</div>
                                <div style={{ display: 'inline-flex' }} className="searchDivRowInput">
                                    <input autoComplete="off" type="number" className="form-control " name="eventStartDate" id="eventStartDate" value={this.state.data.eventStartDate} onChange={this.onChange.bind(this)} />
                                    <span style={{ padding: '5px 10px' }}>-</span>
                                    <input autoComplete="off" type="number" className="form-control " name="eventEndDate" id="eventEndDate" value={this.state.data.eventEndDate} onChange={this.onChange.bind(this)} />
                                </div>
                                <div className="FullGalleryHeader searchDivRow">ორგანიზაცია/პიროვნება</div>
                                <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                    <Autosuggest
                                        suggestions={orgsuggestions}
                                        onSuggestionsFetchRequested={this.onOrgSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onOrgSuggestionsClearRequested}
                                        renderSuggestion={orgrenderSuggestion}
                                        getSuggestionValue={orggetSuggestionValue}
                                        inputProps={orginputProps} />
                                    <div className="close-icon-container">
                                        {orgclearButton}
                                    </div>
                                </div>
                                <Accordion defaultActiveKey={locationOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                                    <Card className="modelCard">
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey="1" className="FullGalleryHeader" isOpen={locationOpen}>ადგილმდებარეობა</CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <div className="searchDivRow" >სახელმწიფო</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={this.props.classificators.country} changeHandle={this.changeCountryHandle.bind(this)} selectedOption={this.state.data.country} />
                                                <div className="searchDivRow" >რეგიონი</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={
                                                    this.props.classificators.region.filter(i =>
                                                        this.state.data.country.indexOf(i.parentName) > -1
                                                        || (this.state.data.country.length === 0 && (i.other !== true || i.parentName === this.props.classificators.country[0].name)))
                                                } changeHandle={this.changeRegionHandle.bind(this)} selectedOption={this.state.data.region} />
                                                <div className="searchDivRow" >რაიონი/მუნიციპალიტეტი</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={
                                                    this.props.classificators.municipality.filter(i =>
                                                        this.state.data.region.indexOf(i.parentName) > -1
                                                        || (
                                                            this.state.data.region.length === 0 && (this.state.data.country.length === 0 || this.state.data.country.indexOf(this.props.classificators.country[0].name) > -1)
                                                            && (i.other !== true || i.parentName === this.props.classificators.country[0].name)
                                                        )
                                                        || this.state.data.country.indexOf(i.parentName) > -1)
                                                } changeHandle={this.changeMunicipalityHandle.bind(this)} selectedOption={this.state.data.municipality} />
                                                <div className="searchDivRow" >დასახლებული პუნქტი</div>
                                                <CustomCreatableMultipleSelectSearch onKeyDown={this.onKeyPress.bind(this)} isDisabled={false} items={
                                                    this.props.classificators.settlement.filter(i =>
                                                        this.state.data.municipality.indexOf(i.parentName) > -1
                                                        || (this.state.data.municipality.length === 0
                                                            && (i.other !== true || i.parentName === this.props.classificators.country[0].name)
                                                            && (this.state.data.country.length === 0 || this.state.data.country.indexOf(this.props.classificators.country[0].name) > -1))
                                                        || this.state.data.country.indexOf(i.parentName) > -1)}
                                                    changeHandle={this.changeSettlementHandle.bind(this)} selectedOption={this.state.data.settlement} />
                                                <div className="searchDivRow" >მისამართი</div>
                                                <CustomCreatableMultipleSelectSearch onKeyDown={this.onKeyPress.bind(this)} isDisabled={this.state.data.settlement.length === 0} items={
                                                    this.props.classificators.street.filter(i => this.state.data.settlement.indexOf(i.parentName) > -1)}
                                                    changeHandle={this.changeStreetHandle.bind(this)} selectedOption={this.state.data.street}

                                                //buildingChange={this.onChange.bind(this)} buildingNum={this.state.data.buildingNum} displayNum={true}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="searchDiv">
                                <div onClick={this.handleClearData.bind(this)} className="NoFillSearchButton">გაუქმება</div>
                                <button type="submit" className="MainButton">ძიება</button>
                            </div>
                        </Form>
                    </Modal.Body>

                </Modal>
            </>
        );
    }
}

