import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { slide as Menu } from 'react-burger-menu'
import { authenticationService } from '../_services';
export class BurgerMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentUser: null
        };
    }
    componentDidMount() {
        this.setState({ show: false })
        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x
        }));
    }

    handleStateChange(state) {
        this.setState({ show: state.isOpen })
    }


    render() {

        return (
            <Menu right isOpen={this.state.show}
                customBurgerIcon={<img alt="" src="images/icon.svg" />}
                onStateChange={(state) => this.handleStateChange(state)} >
                <LinkContainer to="/objects/immovable">
                    <Nav.Link onClick={() => this.closeMenu()}>
                        უძრავი ძეგლი
                            </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/objects/movable">
                    <Nav.Link onClick={() => this.closeMenu()}>
                        მოძრავი ძეგლი
                            </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/objects/wallart">
                    <Nav.Link onClick={() => this.closeMenu()}>
                        კედლის მხატვრობა
                            </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/objects/stoneengrave">
                    <Nav.Link onClick={() => this.closeMenu()}>
                        ქვაზე კვეთილობა
                            </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/objects/museum">
                    <Nav.Link onClick={() => this.closeMenu()}>
                        მუზეუმ-ნაკრძალი/მუზეუმი
                            </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/objects/zone">
                    <Nav.Link onClick={() => this.closeMenu()}>
                        ზოგადი დამცავი ზონა
                            </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/objects/event">
                    <Nav.Link onClick={() => this.closeMenu()}>
                        ქმედება
                            </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/list/document">
                    <Nav.Link onClick={() => this.closeMenu()}>
                        დოკუმენტაცია
                            </Nav.Link>
                </LinkContainer>
                <hr className="divider" />

                <LinkContainer to="/about">
                    <Nav.Link onClick={() => this.closeMenu()}>
                        საიტის შესახებ
                            </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/aboutUs">
                    <Nav.Link onClick={() => this.closeMenu()}>
                        ჩვენ შესახებ
                            </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/contact">
                    <Nav.Link onClick={() => this.closeMenu()}>
                        კონტაქტი
                            </Nav.Link>
                </LinkContainer>
                {this.state.currentUser != null ?
                    < LinkContainer to="/siterules">
                        <Nav.Link onClick={() => this.closeMenu()}>
                            საიტის წესები
                            </Nav.Link>
                    </LinkContainer>
                    : <></>
                }
                {(this.state.currentUser == null ? "" : this.state.currentUser.role) == "Filler" || (this.state.currentUser == null ? "" : this.state.currentUser.role) == "AdminG" ?
                    <LinkContainer to="/manual">
                        <Nav.Link onClick={() => this.closeMenu()}>
                            სწრაფი შეტყობინების ფორმის სახელმძღვანელო
                            </Nav.Link>
                    </LinkContainer>
                    :""
                }
            </Menu>
        );
    }
}

