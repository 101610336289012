import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Card from 'react-bootstrap/Card';


export class ObjectList extends Component {
    static displayName = ObjectList.name;

    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true };

        this.GetObjectData(this.props.value, this.props.count);

    }

    static drawImovableList(forecasts, className) {
        return (

            forecasts.map(forecast =>
                <a key={forecast.id} href={'../' + forecast.linkPath} className="mainCard">
                    <Card key={forecast.id} className="sliderCard">
                        <div className={`${forecast.photoAddress === '' ? className : 'imgDivObjList'} cardImg`}>
                            {
                                forecast.photoAddress == null || forecast.photoAddress === '' ?
                                    <></> :
                                    <img variant="top" src={forecast.photoAddress === '' ? "" : forecast.photoAddress} alt="" style={{ maxHeight: '210px' }} />
                            }


                        </div>
                        <Card.Body>
                            <div className="cardHeader">{forecast.variety}</div>
                            <Card.Text className="card-link">
                                {forecast.objectName} - #{forecast.id}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </a>
            )


        );
    }


    render() {
        //    responsive = {{
        //        desktop: {
        //            breakpoint: {
        //                max: 3000,
        //                    min: 1366
        //            },
        //            items: 7,
        //                partialVisibilityGutter: 40,
        //                    slidesToSlide: 7
        //        },
        //        mobile: {
        //            breakpoint: {
        //                max: 464,
        //                    min: 0
        //            },
        //            items: 1,
        //                partialVisibilityGutter: 0,
        //                    slidesToSlide: 1
        //        },
        //        tablet: {
        //            breakpoint: {
        //                max: 1366,
        //                    min: 464
        //            },
        //            items: 4,
        //                partialVisibilityGutter: 60,
        //                    slidesToSlide: 4
        //        }
        //    }
        //}

        return (

            this.state.loading
                ? <p><em>Loading...</em></p>
                :
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    partialVisible
                    className=""
                    containerClass="container"
                    dotListClass="multiCaruselDots"
                    showDots
                    draggable
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={70}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={true}
                    removeArrowOnDeviceType={["mobile", "tablet"]}

                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 7,
                            partialVisibilityGutter:30,
                            slidesToSlide:6
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 50,
                            slidesToSlide: 1
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 3,
                            partialVisibilityGutter: -30,
                            slidesToSlide:3
                           
                        }
                    }}                  
                    sliderClass=""
                    //slidesToSlide={6}
                    swipeable
                >
                    {ObjectList.drawImovableList(this.state.forecasts, this.props.imgClassName)}

                </Carousel>


        );
    }

    async GetObjectData(value, count) {
        var type;
        switch (value) {
            case "immovable":
                type = 'api/Immovable/GetImmovables?count=' + count;
                break;
            case "movable":
                type = 'api/Movable/GetMovables?count=' + count;
                break;
            case "zone":
                type = 'api/SafetyZone/GetSafetyZone?count=' + count;
                break;
            default: type = '';
                break;
        }

        const response = await fetch(type);
        const data = await response.json();
        this.setState({ forecasts: data, loading: false });

    }
}


