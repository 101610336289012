import React from 'react';
import Select from 'react-select';

export class CustomMultipleSelect extends React.Component {
    //constructor(props) {
    //    super(props);
    //    this.state = {
            
    //    };
    //}
   

    //onKeyDown(e) {
    //    if (e.key === 'Enter') {
    //        e.preventDefault();
    //    }
    //}
    render() {
       
        var options = [];
        this.props.items.map(item => 
            options.push({ value: item.id, label: item.name })
        );

        const customStyles = {
            option: (styles, state) => ({
                ...styles,
                color: state.isSelected ? "#E57959" : styles.color,
                backgroundColor: state.isSelected ? "transparent" : styles.color,
                //borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
                "&:hover": {
                    color: "#E57959",
                    backgroundColor: "transparent"
                }
            }),
            control: (styles, state) => ({
                ...styles,
                boxShadow: 0,
                borderColor: state.isFocused ? "#ced4da" : "#ced4da",
                "&:hover": {
                    borderColor: state.isFocused ? "#ced4da" : "#ced4da"
                }
            })
        };


        
        return (
            <div className="MultiSelectControl searchDivRowInput">
            <Select
                isMulti                
                blurInputOnSelect={false}
                onChange={this.props.changeHandle}
                //onKeyDown={this.props.onKeyDown}
                placeholder=""
                value={this.props.selectedOption? options.filter(el => this.props.selectedOption.includes(el.value)):""}
                options={options}
                noOptionsMessage={() => " "}
                isClearable={true}                 
                styles={customStyles}
                />
            </div>
        );
    }
}
