import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import './NavMenu.css';
import { BurgerMenu } from './BurgerMenu';
import { SearchTemplate } from './SearchTemplate';
import Avatar from 'react-avatar';

import { history, Role } from '../_helpers';

import { LoginMenu } from './api-authorization/LoginMenu';
import authService from './api-authorization/AuthorizeService'
import { UserRoles } from './api-authorization/ApiAuthorizationConstants';
import { authenticationService } from '../_services';

import { UserBurgerMenu } from './UserBurgerMenu';

export class NavMenu extends Component {
    static displayName = NavMenu.name;
    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            isAdmin: false,
            photo: null,
            selectedType: 'all',
            displayComponentSuggestion: false,
            displayUserMenu: false
        }
        this.closeDropdown = this.closeDropdown.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        authenticationService.currentUser.subscribe(x =>
            this.setState({
                currentUser: x,
                isAdmin: x && x.role === "Admin",
                photo: x && x.photo ? x.photo : null
            }));


    }

    logout() {
        authenticationService.logout();
        history.push('/login');
    }


    componentDidUpdate() {
        if (this.state.displayComponentSuggestion) {
            document.addEventListener("click", this.closeDropdown);
        } else {
            document.removeEventListener("click", this.closeDropdown);
        }
    }

    closeDropdown() {
        this.setState({ displayComponentSuggestion: false })

    }

    setdisplayContent() {
        this.setState({ displayComponentSuggestion: !this.state.displayComponentSuggestion })
    }

    displayUserMenu() {
        this.setState({ displayUserMenu: !this.state.displayUserMenu })
    }



    setComponentButtonContent(e) {
        var el = document.querySelector('.searchComponentButton');
        el.textContent = e.target.innerHTML;
        this.setState({ selectedType: e.target.innerHTML });
    }
    redirectToLogin() {
        window.location.href = "/login";
    }

    render() {




        let items = ["ყველა კომპონენტი",
            "უძრავი ძეგლი", "მოძრავი ძეგლი", "კედლის მხატვრობა", "ქვაზე კვეთილობა", "მუზეუმ-ნაკრძალი/მუზეუმი", "ზოგადი დამცავი ზონა",
            "ქმედება", "დოკუმენტაცია"
        ];

        const { currentUser, isAdmin, photo } = this.state;





        //var imgSrc = String.Format("data:image/jpg;base64,{0}", photo);

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow">
                    <div className="navbar-header">
                        <Navbar.Brand>
                            <div className="logoDiv">
                                <a href="/" style={{ color: 'black' }}>
                                    <div style={{ display: 'inline-flex' }}>
                                        <div className="logoImgDiv">
                                            <img className="logoImg" alt="" src="/images/logo.png" />
                                        </div>
                                        <div className="header">კულტურული მემკვიდრეობის პორტალი </div>
                                    </div>
                                </a>
                            </div>
                            <div className="searchForm">
                                <div className="searchComponentButton icon-down-open-big" onClick={() => this.setdisplayContent()}>კომპონენტი</div>
                                <div className={`componentSuggestion ${this.state.displayComponentSuggestion ? '' : 'displayNone'}`}>
                                    {
                                        items.map((item, i) => {
                                            return <div key={i} className="suggestionItem" onClick={(e) => this.setComponentButtonContent(e)}>{item}</div>;
                                        })

                                    }
                                </div>
                                <SearchTemplate forHeader="true" type={this.state.selectedType}></SearchTemplate>
                            </div>
                            {
                                //<button className="MainButton LoginBtn"><span className="LoginText">ავტორიზაცია</span></button>
                                //<LoginMenu>
                                //</LoginMenu>


                                currentUser != null ?

                                    <div className="UserAvatar" >
                                        <div className="UserAvatar useropenmenu" onClick={() => this.displayUserMenu()}>
                                            <span className="avatar" ><Avatar title={currentUser.userName} src={photo} size={50} round="50px" /></span>
                                            <img id="av" alt="" src="" />
                                            <span className="avatarText" >გამარჯობა, {currentUser.userName}</span>
                                            <span className="icon-down-open-big" style={{ marginTop: 'auto', marginBottom: 'auto', paddingRight: '10px' }}></span>
                                        </div>

                                        {
                                            isAdmin !== true ?
                                                <div className={`UserMenu ${this.state.displayUserMenu ? '' : 'displayNone'}`}>
                                                    <a style={{ display: 'block' }} className="suggestionItem" href="/userprofile/userObjects">ჩემი პროფილი</a>
                                                    <a style={{ display: 'block' }} className="suggestionItem" href="/addobject">მემკვიდრეობის დამატება</a>
                                                    <div className="suggestionItem" onClick={() => this.logout()}>გასვლა</div>
                                                </div>
                                                :
                                                <div className={`UserMenu ${this.state.displayUserMenu ? '' : 'displayNone'}`}>
                                                    <a style={{ display: 'block' }} className="suggestionItem" href="/AdminUsers">მომხმარებლები</a>
                                                    <a style={{ display: 'block' }} className="suggestionItem" href="/objects/userObject">დამატებული მემკვიდრეობა</a>
                                                    <div className="suggestionItem" onClick={() => this.logout()}>გასვლა</div>
                                                </div>
                                            }

                                       
                                        <div className="usermobileMenu">
                                            <UserBurgerMenu logout={() => this.logout()} currentUser={currentUser} photo={photo} isAdmin={isAdmin} />
                                        </div>
                                    </div>


                                    :
                                    <button className="MainButton LoginBtn" onClick={() => this.redirectToLogin()} ><span className="LoginText">ავტორიზაცია</span></button>



                            }

                        </Navbar.Brand>

                    </div>
                    <div className="mobileMenu">
                        <BurgerMenu />
                    </div>

                    <Navbar.Collapse>
                        <Nav>
                            <LinkContainer to="/objects/immovable">
                                <Nav.Link>
                                    უძრავი ძეგლი
                            </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/objects/movable">
                                <Nav.Link>
                                    მოძრავი ძეგლი
                            </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/objects/wallart">
                                <Nav.Link>
                                    კედლის მხატვრობა
                            </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/objects/stoneengrave">
                                <Nav.Link>
                                    ქვაზე კვეთილობა
                            </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/objects/museum">
                                <Nav.Link>
                                    მუზეუმ-ნაკრძალი/მუზეუმი
                            </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/objects/zone">
                                <Nav.Link>
                                    ზოგადი დამცავი ზონა
                            </Nav.Link>
                            </LinkContainer>

                            <NavDropdown title=". . ." id="menuDrop" active={(window.location.href.indexOf("/objects/event") > -1 || window.location.href.indexOf("/list/document") > -1) ? true : false}>
                                <LinkContainer to="/objects/event">
                                    <Nav.Link>
                                        ქმედება
                                    </Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/list/document">
                                    <Nav.Link>
                                        დოკუმენტაცია
                                    </Nav.Link>
                                </LinkContainer>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>

                </Navbar>
            </header>

        );
    }
}
