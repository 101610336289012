import React from 'react';
import DatePicker from "react-datepicker";
import { Card, Table } from "react-bootstrap";
import CardGroup from 'react-bootstrap/CardGroup';
import { format } from "date-fns";

import PulseLoader from "react-spinners/PulseLoader";
import { authenticationService } from '../../_services';
import { authHeader, handleResponse } from '../../_helpers';
import { QuickAlertModal } from '../User/QuickAlertModal';
import './userobjectlistviewStyle.css';
import "react-datepicker/dist/react-datepicker.css";
class UserAlertListView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //currentUser: null,
            //isAdmin: false,
            //photo: null,
            startDate: null,
            setStartDate: null,
            dateRange: null,
            setDateRange: null,
            endDate: null,
            setEndDate: null,
            pager: {},
            pageOfItems: [],
            loading: true,
            reload: false,
            type: this.props.type,
        };

    }

    componentDidMount() {

        //authenticationService.currentUser.subscribe(x =>
        //    this.setState({
        //        currentUser: x,
        //        isAdmin: x && x.role === "Admin",
        //        photo: x && x.photo ? x.photo : null
        //    }));




        if (this.props.currentUser != null) {
            window.scrollTo(0, 0)
            this.loadPage();
        }

    }

    componentDidUpdate() {
        if (this.props.currentUser != null) {
            this.loadPage();
        }
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const page = parseInt(params.get('page')) || 1;

        var type;
        switch (this.props.type) {

            case "userAlert":
                type = `api/Users/GetAlertObjectList?count=10&page=${page}&filterText=`;
                break;
            default: type = '';
                break;
        }


        if (page !== this.state.pager.currentPage || this.state.reload === true) {

            const requestOptions = { method: 'GET', headers: authHeader() };
            const response = await fetch(type, requestOptions);

            const data = await response.json();
            this.setState({ pager: data, pageOfItems: data.aaData, loading: false, type: this.props.type });
        }
    }
    async goTo(filterText, dateTime) {
        const params = new URLSearchParams(window.location.search);
        const page = parseInt(params.get('page')) || 1;
        var type;
        switch (this.props.type) {

            case "userAlert":
                type = `api/Users/GetAlertObjectList?count=10&page=${page}&filterText=` + filterText + '&dateTime=' + dateTime;
                break;
            default: type = '';
                break;
        }

        const requestOptions = { method: 'GET', headers: authHeader() };
        const response = await fetch(type, requestOptions);

        const data = await response.json();
        this.setState({ pager: data, pageOfItems: data.aaData, loading: false, type: this.props.type });

    }
    handleInputDateChange = (valueDate) => {
        var searchdate = "";
        if (valueDate[1] == null && valueDate[0] != null) {
            var month = valueDate[0].getMonth() + 1;
            var sawyisi = valueDate[0].getDate() + "/" + month + "/" + valueDate[0].getFullYear();
            //var sawyisi = valueDate[0].toLocaleDateString();
            searchdate = sawyisi;
        }
        else if (valueDate[0] != null) {
            var month0 = valueDate[0].getMonth() + 1;
            var month1 = valueDate[1].getMonth() + 1;
            var sawyisi = valueDate[0].getDate() + "/" + month0 + "/" + valueDate[0].getFullYear();
            var saboloo = valueDate[1].getDate() + "/" + month1 + "/" + valueDate[1].getFullYear();
            searchdate = sawyisi + "-" + saboloo;
        }
        else {
            searchdate = "";
        }

        this.setState({
            dateRange: valueDate,
            startDate: valueDate[0],
            endDate: valueDate[1],
            valueDate: searchdate
        }, () => {
            if (this.state.valueDate && (this.state.valueDate.length >= 0 || !isNaN(this.state.valueDate)) || this.state.valueDate == "" ) {
                this.goTo(this.state.value, this.state.valueDate)
            }
        })
    }
    handleInputChange = (event) => {
        this.setState({
            value: event.target.value
        }, () => {
            if (this.state.value && (this.state.value.length >= 0 || !isNaN(this.state.value))) {
                this.goTo(this.state.value, this.state.valueDate)
            }
        })
    }
    getImgDivClass(type) {
        switch (type) {
            case "immovable":
                return "imgDivImmovable";
            case "movable":
                return "imgDivMovable";
            case "wallart":
                return "imgDivWallArt";
            case "stoneengrave":
                return "imgDivStoneEngrave";
            case "zone":
                return "imgDivZone";
            case "museum":
                return "imgDivMuseum";
            case "event":
                return "imgDivEvent";
            case "document":
                return "imgDivDocumentBig";
            case "all":
                return "";
            default:
                return "imgDivImmovableAlert";
        }
    }

    deleteObject(id) {

        var result = window.confirm("გსურთ წაშლა?");
        if (result) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            return fetch(`api/Users/DeleteObject?id=${id}`, requestOptions)
                .then(data => {
                    this.setState({ reload: true });
                    this.loadPage();
                });
        }



    }
    parseDate(date) {
        var formattedDate = "";
        if (date != "undefined") {
            formattedDate = (new Date(date)).toISOString().split('T')[0];
            // formattedDate = format(expiryDate, 'yyyy-MM-dd');
            var fsdf = "fdsf";
        }

        return formattedDate;
    }


    onDateChange = (dates) => {
        const [start, end] = dates;
        this.setState({
            setStartDate: start,
            setEndDate: end
        })
    };

    drawImovableList(forecasts) {
        window.scrollTo(0, 0)
        var el = document.getElementsByClassName('immListDiv');
        if (el != null && el.length > 0) el[0].scrollTo(0, 0);

        return (
            <div>
                <div className="searchandSumDiv">
                    <div >სულ: {forecasts.length} განცხადება</div>
                    <div style={{ paddingRight: "18px", "marginLeft": "auto" }}><input type="text" className="sadzieboSityvaAlert" id="sadzieboSityva" name="sadzieboSityva" onChange={this.handleInputChange} placeholder="საძიებო სიტყვა"></input></div>

                    <div style={{  }}>
                        <DatePicker
                            style={{ "marginRight": "18px" }}
                            id="sadzieboSityvaAlert"
                            className="sadzieboSityvaAlert"
                            placeholderText="განცხადების თარიღი"
                            selectsRange={true}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleInputDateChange}
                            isClearable={true}

                        />
                        {/*<input type="date" style={{ "marginLeft": "18px" }} */}
                        {/*    onChange={this.handleInputDateChange} placeholder="განცხადების თარიღი"></input>*/}
                    </div>
                   
                </div>
                <CardGroup>
                    {forecasts.map(forecast =>
                        <div key={forecast.objType + '_' + forecast.id} dataid={forecast.id} >

                            <Card className="cardHorizonalListView AlertListView">
                                <div className="AlertViewImgContainer">
                                    <div className={`${((forecast.photoAddress === '' || (forecast.photoAddress == null ? true : (forecast.photoAddress.indexOf('.jpg') < 0 && forecast.photoAddress.indexOf('.png') < 0) == true ? true : false))) ? this.getImgDivClass(forecast.objType) : 'imgDivObjListView'}`}>
                                        {
                                            forecast.photoAddress == null || forecast.photoAddress === '' ?
                                                <></> :
                                                <img className="list_card-img" variant="top" src={forecast.photoAddress} alt="" />
                                        }

                                    </div>
                                </div>

                                <Card.Body className="objectList_card-body">

                                    <Table className="alertCardTable">
                                        <tbody>
                                            <tr>
                                                <td className="width60alertCardTable">
                                                    <Card.Title className="objectList_card-title alertcard" >{forecast.objectName}</Card.Title>
                                                    <div className="objectTypeList">{forecast.variety}</div>
                                                    <Card.Text className="alertList_card-text marginLeft6" dataid={forecast.id}>
                                                        <span className="reasonTextAlert">მიზანი: </span>
                                                        <span className="dazianebaTextAlert">{forecast.statementReason} </span>
                                                        <br></br>
                                                        <div className="icon-location-outline icon-location-alert"> {forecast.address}</div>
                                                    </Card.Text>
                                                </td>
                                                <td>
                                                    <Card.Text className="alertList_card-text marginLeft6 minwidth130" dataid={forecast.id}>
                                                        <span className="gancxadebisTarigiLabel">{this.parseDate(forecast.createDate)}</span> <br></br>განცხადების თარიღი
                                                    </Card.Text>

                                                </td>
                                                <td style={{ "verticalAlign": "middle" }}>
                                                    <div style={{ marginLeft: 'auto' }}>
                                                        <div onClick={(e) => e.preventDefault()}>


                                                            <QuickAlertModal ref={this.quickAlert}
                                                                filterResulthandle={this.filterResulthandle}
                                                                classificators={this.state.pager.classificator}
                                                                ObjectId={forecast.id}
                                                                ObjectName={forecast.objectName}
                                                                PhotoAddress={forecast.photoAddress}
                                                                immovableAlert={true}
                                                                id={forecast.id}
                                                            >
                                                            </QuickAlertModal>


                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>


                                    {
                                        this.props.type === "private" ?
                                            <div style={{ textAlign: "left", display: 'flex' }}>
                                                <a href={"/addobject?id=" + forecast.id} className="TransparentButton" style={{ color: '#6A8E7F' }}><img width="20" alt="" src="/images/edit.svg" style={{ marginRight: '10px' }} />{' '}რედაქტირება</a>
                                                <div className="TransparentButton" style={{ color: '#FF647C' }} onClick={(e) => { e.preventDefault(); this.deleteObject(forecast.id) }} ><img width="20" alt="" src="/images/trash.svg" style={{ marginRight: '10px' }} />{' '}წაშლა</div>
                                            </div>
                                            : <></>
                                    }
                                </Card.Body>
                            </Card>



                        </div>
                    )}
                </CardGroup>
            </div>
        );
    }


    render() {

        const params = new URLSearchParams(window.location.search);

        const { pager, pageOfItems } = this.state;
        return (
            <div className="card text-center">
                <div className="card-body">
                    {
                        this.state.loading || this.props.type !== this.state.type
                            ?
                            //<p><em>Loading...</em></p>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center' }}>
                                <PulseLoader
                                    color={"#E57959"}
                                    loading={true}
                                />
                            </div>
                            : this.drawImovableList(pageOfItems)
                    }

                </div>

            </div>
        );
    }
}

export { UserAlertListView };