import React, { Component } from 'react';
import './UserObject.css';
import { AddObjectMap } from './AddObjectMap';
import { Row, Col } from 'react-bootstrap';
import { authenticationService } from '../../_services';




function getImageUrl(base64ImageData) {
    const contentType = 'image/jpg';

    const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
}

export class ViewObject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            isAdmin:false,
            coordinatebi: null,
            object: null,
            error: ''
        }
        this.setCoordinates = this.setCoordinates.bind(this);

    }

    componentDidMount() {

        authenticationService.currentUser.subscribe(x =>
            this.setState({
                currentUser: x, isAdmin: x && x.role === "Admin"
            }));


        this.loadObject();
    }

    async loadObject() {
        const params = new URLSearchParams(window.location.search);
        if (params.get('id')) {
            const id = parseInt(params.get('id'));
            if (id !== null) {
                authenticationService.getObject(id).then(
                    object => {
                        this.setState({ object: object })
                    },
                    error => {
                        this.setState({ error: error })
                    }
                );
            }
        }
    }

    setCoordinates(x, y) {
        var el = document.getElementById('x');
        el.value = x;

        document.getElementById('y').value = y;

    }
    render() {
        var currentUser = this.state.currentUser;
        var object = this.state.object;
        return (
            currentUser && object &&
            <div className="immListDiv userObject" style={{
                backgroundColor: 'white'
            }}>
                <div style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                    <div className="objectContent">
                        <div style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className="boldHeade">
                                კულტურული მემკვიდრეობა
                            </div>
                        </div>
                    </div>
                    <div className="mapContent">
                        <AddObjectMap view={true} coordinatebi={{ x: object.x, y: object.y }} />
                    </div>
                    <div className="objectContent">
                        <div style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Row className="form-group">
                                <Col style={{ marginTop: '1rem' }} >
                                    <input name="x" type="hidden" id="x" />
                                    <input name="y" type="hidden" id="y" />
                                </Col>
                            </Row>



                            <Row className="form-group">
                                <Col style={{ marginTop: '1rem' }} md={6}>
                                    <div className="headerText">სახელწოდება</div>
                                    <div>{object.name}</div>
                                </Col>
                                <Col style={{ marginTop: '1rem' }} md={6}>
                                    <div className="headerText">რეგიონი</div>
                                    <div>{object.region}</div>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col style={{ marginTop: '1rem' }} md={6}>
                                    <div className="headerText">მუნიციპალიტეტი</div>
                                    <div>{object.municipality}</div>
                                </Col>
                                <Col style={{ marginTop: '1rem' }} md={6}>
                                    <div className="headerText">დასახლებული პუნქტი</div>
                                    <div>{object.settlement}</div>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col style={{ marginTop: '1rem' }} md={6}>
                                    <div className="headerText">ქუჩა</div>
                                    <div>{object.street}</div>
                                </Col>
                                <Col style={{ marginTop: '1rem' }} md={6}>
                                    <div className="headerText">შენობის ნომერი</div>
                                    <div>{object.streetNum}</div>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col style={{ marginTop: '1rem' }}>
                                    <div className="headerText">მოკლე აღწერა</div>
                                    <div>{object.description}</div>
                                </Col>
                            </Row>

                            <Row className="form-group">
                                <Col style={{ marginTop: '1rem' }} >

                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {object.images && object.images.length > 0 ? (
                                            object.images.map((image, index) => (
                                            
                                               

                                                <div id={`image_${index}`} key={index}>
                                                    <div className="imageDiv" >
                                                        <a href={getImageUrl(image)} rel="noopener noreferrer" target="_blank"> <img src={image} alt=""
                                                            style={{ width: '100%', height: '100%' }} /></a>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                                <></>
                                            )}
                                    </div>

                                </Col>
                            </Row>
                            {
                                this.state.isAdmin != true?
                                <Row className="form-group">
                                    <Col style={{ marginTop: '1rem' }} >
                                        <a href={"/addobject?id=" + object.id} className="MainButton">რედაქტირება</a>
                                    </Col>
                                    </Row>
                                    :
                                    <></>

                            }


                        </div>
                    </div>

                </div>
            </div>
        );
    }

}