import React, { Component } from 'react';
import './Object.css';
import { Row, Col, Accordion, Card } from 'react-bootstrap'
import { WebMapView } from '../WebMapView';


import { CustomToggle } from '../custom/CustomToggle'
import { ObjectListForLinks } from '../ObjectListForLinks';
import { FullDescModal } from './FullDescModal';
import Avatar from 'react-avatar';
import { Footer } from '../Footer';
import PulseLoader from "react-spinners/PulseLoader";
import { Gallery } from './Gallery';
import ReactTooltip from "react-tooltip";
import { DocObjectListForLinksTable } from '../DocObjectListForLinksTable';
import { EventObjectListForLinksTable } from '../EventObjectListForLinksTable';
import { authHeader, handleResponse } from '../../_helpers';
import { authenticationService } from '../../_services';

import { AddToCollection } from '../AddToCollection';
import { Share } from '../Share';

export class ZoneObject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            isAdmin: false,
            favorite: false,
            zone: {},
            objectID: null,
            objToZoom: null,
            fullScreeenIcon: "esri-icon-zoom-out-fixed",
            mapClass: '',
            loading: true
        };

        this.fullScreenMode = this.fullScreenMode.bind(this)
    }
    componentDidMount() {
        var el = document.querySelector('.searchComponentButton');
        el.textContent = "ზოგადი დამცავი ზონა";

        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x, isAdmin: x && x.role === "Admin"
        }));
        this.loadPage();
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const id = parseInt(params.get('id'));
        if (id !== null) {
            var type = `api/SafetyZone/GetZoneObject?id=${id}`;
           

            if (this.state.currentUser != null) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                const response = await fetch(type, requestOptions);
                const data = await response.json();
                this.setState({ zone: data, loading: false, favorite: data.isFavorite, objectID: data.id});
            }
            else {
                const response = await fetch(type);
                const data = await response.json();
                this.setState({ zone: data, loading: false, favorite: data.isFavorite, objectID: data.id});
            }
        }
    }

    toogleFullDesc() {
        if (document.getElementsByClassName('DescTextFull')[0].classList.contains('displayNone')) {
            document.getElementsByClassName('DescTextFull')[0].classList.remove('displayNone');
            document.getElementsByClassName('DescText')[0].classList.add('displayNone');
        }
        else {
            document.getElementsByClassName('DescTextFull')[0].classList.add('displayNone');
            document.getElementsByClassName('DescText')[0].classList.remove('displayNone');
        }

    }


    static displayName = ZoneObject.name;

    addToFavorite(id) {
        if (this.state.favorite === false) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            fetch(`api/Users/AddToFvorite?folderID=0&objectType=zone&objectID=${id}`, requestOptions)
                .then(data => {
                    this.setState({ favorite: true });
                });
        }
        else {
            var result = window.confirm("გსურთ წაშლა?");
            if (result) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                return fetch(`api/Users/DeleteFromFvorite?objectType=zone&objectID=${id}`, requestOptions)
                    .then(data => {
                        this.setState({ favorite: false });
                    });
            }
        }
    }
    hadleFavoriteStatus(value) {
        this.setState({ favorite: value });
    }

    fullScreenMode() {
        if (this.state.mapClass === '') {
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-in-fixed', mapClass: 'objectFullScreentMap', objectID: null, objToZoom: this.state.zone.id })
        }
        else {

            document.getElementById('toolbarDiv').classList.add('displayNone');
            document.getElementById('menuDiv').classList.add('displayNone')
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-out-fixed', mapClass: '', objectID: this.state.zone.id, objToZoom: null })
        }
    }

    render() {
        const zone = this.state.zone;

        window.FB = null;
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/ka_GE/sdk.js#xfbml=1&autoLogAppEvents=1&version=v8.0&appId=1607074352807965";
            js.nonce = "q90Xb87m";

            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        return (
            <div className="immObjDiv">
                {zone && zone.id > 0 ?
                    <div>
                        <div className="containerDiv">
                            <Row className="rowPadding">
                            <Col md={5}>

                                    <Gallery photo={zone.photoIds} video={zone.videoIds} vid3D={zone._3dobjects} mainphoto={zone.photoAddress} type="zone" authors={zone.docAuthors} />
                                </Col>
                                <Col md={7}>
                                   
                                    <div className="ObjectType">ზოგადი დამცავი ზონი</div>
                                    <div className="ObjectMainHeader">
                                        
                                        <span className="ObjectMainHeaderSpan">{zone.objectName} - #{zone.id}</span>
                                        <Share header="გაზიარება" customClass="objectShare" />
                                        {this.state.currentUser && this.state.isAdmin === false &&
                                            //<div style={{ float: 'right', cursor: 'pointer' }}><img width="20" alt="" src={this.state.favorite ? "/images/heartfill.svg" : "/images/heart.svg"} style={{ marginRight: '10px' }} onClick={() => this.addToFavorite(immovable.id)} />{' '}</div>
                                            <AddToCollection favorite={this.state.favorite} objID={zone.id} setStatus={this.hadleFavoriteStatus.bind(this)}></AddToCollection>
                                        }
                                    </div>
                                    <div><span className="headerText">ტიპი: </span>{zone.tipi}</div>
                                    <div><span className="headerText">ფართობი: </span>{zone.fartobi}</div>
                                    <div><span className="headerText">სტატუსი: </span>{zone.statusi}</div>
                                    <div><span className="headerText">დოკუმენტის სახელწოდება: </span>{zone.docName}</div>
                                    <div><span className="headerText">დოკუმენტის ნომერი: </span>{zone.docNum}</div>
                                    <div><span className="headerText">დოკუმენტის თარიღი: </span>{zone.docDate}</div>
                                    <div className="DescText">
                                        {zone.shortDesc}
                                        <div onClick={this.toogleFullDesc} className="toggleFullDesc">
                                            <svg className="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 01.708 0L8 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 01.708 0L8 8.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="DescTextFull displayNone">{zone.shortDescFull}
                                        <div onClick={this.toogleFullDesc} style={{ textAlign: "center", padding: "20px" }}>
                                            <svg className="bi bi-chevron-double-up" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M7.646 2.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 3.707 2.354 9.354a.5.5 0 11-.708-.708l6-6z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M7.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>

                                <FullDescModal className="FullDescModal" buttonDesc="დეტალური აღწერა" objname={zone.objectName} disabled={(document.shortDescFull === '' && document.rejimi === '' )}>
                                        <div className="PopupHeader">საზღვრის აღწერილობა</div>
                                    {zone.shortDescFull}

                                    {(zone.rejimi) ?
                                        <Row style={{ padding: '10px' }}>
                                            <Accordion defaultActiveKey="0" className="accordionNoshadow">
                                                <Card className="modelCard">
                                                    <Card.Header className="TogglePanelHeader">
                                                        <CustomToggle eventKey="1">დამცავი ზონის რეჟიმი</CustomToggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            {zone.rejimi}
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Row>
                                        : <></>
                                    }
                                    </FullDescModal>

                                </Col>
                        </Row>
                        <div className="ObjectHeaderNoTop" style={{ marginLeft: '35px', marginTop: '20px' }}>ადგილმდებარეობა</div>
                            <Row style={{ padding: '20px', width: '100%' }}>
                            <Col md={5}>
                                     <div style={{ display: 'inline-flex', width: '100%'}}>
                                        <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                        <div>
                                            <div>რეგიონი: <span className="headerText">{zone.region}</span></div>
                                            <div>მუნიციპალიტეტი: <span className="headerText">{zone.municipality}</span></div>
                                            <div>დასახლებული პუნქტი: <span className="headerText">{zone.settlement}</span></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={7} style={{ minHeight: '250px' }} className="ObjectMap">
                                    <WebMapView type="zone" objectID={this.state.objectID} objToZoom={this.state.objToZoom} right="30px" fullScreeenIcon={this.state.fullScreeenIcon} fullClick={this.fullScreenMode.bind(this)} mapClass={this.state.mapClass} />

                                </Col>
                            </Row>
                        </div>
                        {
                            zone.linksDisplay ? <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '80px' }} /> : <></>

                        }
                        <div className="containerDiv">
                            <div className="accardionPadding ObjectHeaderNoTop">{zone.linksDisplay ? 'დაკავშირებული კომპონენტები' : ''}</div>

                            {zone.zoneLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">ზოგადი დამცავი ზონა {zone.zoneLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={zone.id} type="zone" objType="zone" idList={zone.zoneLinks} objName={zone.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {zone.immovableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">უძრავი ძეგლი/ობიექტი {zone.immovableLinksCount} </span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={zone.id} type="immovable" objType="zone" idList={zone.immovableLinks} objName={zone.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {zone.museumLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მუზეუმ-ნაკრძალი/მუზეუმი {zone.museumLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={zone.id} type="museum" objType="zone" idList={zone.museumLinks} objName={zone.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {
                                zone.eventLinkExist ?
                                    <Row className="accardionPadding">
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Card.Header className="TogglePanelHeader">
                                                    <CustomToggle eventKey="1"><span className="headerText">ქმედება {zone.eventLinksCount}</span></CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body className="accardionBodyPadding">
                                                        {
                                                            <EventObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={zone.id} type="event"
                                                                objType="zone" idList={zone.eventLinks} objName={zone.objectName} />
                                                        }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Row>
                                    : <></>
                            }
                            {zone.docLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">დოკუმენტაცია {zone.docLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <DocObjectListForLinksTable caption={{ type: "დოკუმენტის ტიპი", docNum: "ავტორი" }} objID={zone.id} type="document"
                                                        objType="zone" idList={zone.docLinks} objName={zone.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                    </div>

                    <Footer>
                        <div className="containerDiv" style={{ paddingTop: '40px', paddingLeft: '20px' }}>
                            <Row >
                                <Col md={5}>
                                    <div style={{ fontFamily: 'HelveticaBold', fontSize: '16px' }}>ინფორმაციის შემდგენელი</div>
                                    <div style={{ marginBottom: '20px' }}>თარიღი: <span className="headerText">{zone.cardCreationDate}</span></div>
                                    <div style={{ display: 'inline-flex' }}>
                                        <Avatar title={zone.cardCreator[0].name} src={zone.cardCreator[0].photo} size={50} round="50px" />
                                        <span className="avatarText" data-tip data-for="allAuthors">{zone.cardCreator.length > 1 ? `${zone.cardCreator[0].name} \n\r(+ ${zone.cardCreator.length - 1} ავტორი)`
                                            : zone.cardCreator[0].name}</span>
                                    </div>
                                    {zone.cardCreator.length > 1 ?
                                        <ReactTooltip className="customZIndex" id="allAuthors" effect='solid' backgroundColor="#FFFFFF" textColor="#000000" place="bottom" border={true} offset={{ top: 70, left: -35 }}>
                                            <span>
                                                <ul className="periodList">{
                                                    zone.cardCreator.map((item, i) => {
                                                        return <div style={{ marginTop: '5px' }} key={i}> <Avatar title={item.name} src={item.photo} size={30} round="30px" />
                                                            <span style={{ marginLeft: '10px' }}>{item.name}</span></div>
                                                            ;
                                                    })
                                                }</ul>
                                            </span>
                                        </ReactTooltip>
                                        : <></>
                                    }
                                </Col>
                                    <Col md={7}>
                                        <p style={{ borderBottom: '1px solid #ECECEC' }} className="facebookCOmmentP" />
                                        <div className="fb-comments" data-href={window.location.href} data-numposts="5" data-width="" data-mobile="true"></div>
                                </Col>
                            </Row>
                        </div>
                    </Footer>
                    </div>
                     : <div style={{
                    marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center'
                }}>
                    <PulseLoader
                        color={"#E57959"}
                        loading={true}
                    />
                </div>
                }
            </div>
        );
    }

}