import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Recaptcha } from '../components/Recaptcha';
import { authenticationService } from '../_services';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // redirect to home if already logged in
        if (authenticationService.currentUserValue) {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <div className="immListDiv" style={{
                backgroundColor: 'white'
            }}>

                <div style={{ backgroundColor: 'white', width: '100%', height: '100%', padding: '30px' }}>
                    <Recaptcha />
                    <div style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="boldHeade" style={{ padding: '30px' }}>
                            სისტემაში შესვლა
                        </div>
                        <Formik
                            initialValues={{
                                username: '',
                                password: ''
                            }}
                            validationSchema={Yup.object().shape({
                                username: Yup.string().required('შეიყვანეთ ელ.ფოსტა'),
                                password: Yup.string().required('შეიყვანეთ პაროლი')
                            })}
                            onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
                                setStatus();
                                authenticationService.login(username, password)
                                    .then(
                                        user => {
                                            const { from } = this.props.location.state || { from: { pathname: "/" } };
                                            this.props.history.push(from);
                                        },
                                        error => {
                                            setSubmitting(false);
                                            setStatus(error.indexOf('Bad') > 0 ? "შეცდომა" : error);
                                        }
                                    );
                            }}
                            render={({ errors, status, touched, isSubmitting }) => (
                                <Form>
                                    {status &&
                                        <div className={'alert alert-danger'}>{status}</div>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="username">ელ.ფოსტა</label>
                                        <Field name="username" type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
                                        <ErrorMessage name="username" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">პაროლი</label>
                                        <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                    </div>
                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <a href="/recovery" style={{ width: '100%', marginTop: 'auto', marginBottom: 'auto', height: '46px' }}>დაგავიწყდათ პაროლი?</a>
                                        <div className="form-group">
                                            <button type="submit" className="MainButton" disabled={isSubmitting}>შესვლა</button>
                                            {isSubmitting &&
                                                <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                        </div>
                                       
                                    </div>
                                    <a href="/register" style={{ width: '100%', marginBottom: 'auto', height: '46px', marginTop: '40px', color:'#6A8E7F' }}>სისტემაში რეგისტრაცია</a>
                                    <div style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                                        <small><span>ეს საიტი დაცულია reCAPTCHA-ს ინსტრუმენტით და მასზე ვრცელდება Google-ის </span>
                                            <a href="https://policies.google.com/privacy" style={{ color: "#007bff" }}>კონფიდენციალურობის პოლისი</a><span> და </span>
                                            <a href="https://policies.google.com/terms" style={{ color: "#007bff" }}>სერვისების წესები.</a>
                                        </small>
                                    </div>
                                </Form>
                            )}
                        />
                    </div>

                </div>
            </div>

        )
    }
}

export { LoginPage }; 