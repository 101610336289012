import React from 'react'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'

const Suggestions = (props) => {
    var suggestion = props.results[0]

    const options = (
        suggestion ?
            <div>
                <div style={{ maxHeight: '350px', overflow: 'auto' }}>
                    {props.type === "all" ?
                        <div>
                            <div className="searchTypesInMenu">კომპონენტები</div>
                            <ul className="ResultContentUl">
                                <li className={`ResultRectangle ${props.forType === 'all' ? 'selected' : ''}`} onClick={() => props.handleSelected("all")} data-content="all">ყველა ობიქტი ({suggestion.allCount})</li>
                                {suggestion.immovableCount > 0 ? <li className={`ResultRectangle ${props.forType === 'immovable' ? 'selected' : ''}`} onClick={() => props.handleSelected("immovable")} data-content="immovable">უძრავი ძეგლი ({suggestion.immovableCount})</li> : <></>}
                                {suggestion.movableCount > 0 ? <li className={`ResultRectangle ${props.forType === 'movable' ? 'selected' : ''}`} onClick={() => props.handleSelected("movable")} data-content="movable">მოძრავი ძეგლი ({suggestion.movableCount})</li> : <></>}
                                {suggestion.wallCount > 0 ? <li className={`ResultRectangle ${props.forType === 'wallart' ? 'selected' : ''}`} onClick={() => props.handleSelected("wallart")} data-content="wallart">კედლის მხატვრობა ({suggestion.wallCount})</li> : <></>}
                                {suggestion.stoneCount > 0 ? <li className={`ResultRectangle ${props.forType === 'stoneengrave' ? 'selected' : ''}`} onClick={() => props.handleSelected("stoneengrave")} data-content="stoneengrave">ქვაზე კვეთილობა ({suggestion.stoneCount})</li> : <></>}
                                {suggestion.museumCount > 0 ? <li className={`ResultRectangle ${props.forType === 'museum' ? 'selected' : ''}`} onClick={() => props.handleSelected("museum")} data-content="museum">მუზეუმ-ნაკრძალი/მუზეუმი ({suggestion.museumCount})</li> : <></>}
                                {suggestion.zoneCount > 0 ? <li className={`ResultRectangle ${props.forType === 'zone' ? 'selected' : ''}`} onClick={() => props.handleSelected("zone")} data-content="zone">ზოგადი დამცავი ზონა ({suggestion.zoneCount})</li> : <></>}
                                {suggestion.eventCount > 0 ? <li className={`ResultRectangle ${props.forType === 'event' ? 'selected' : ''}`} onClick={() => props.handleSelected("event")} data-content="event">ქმედება ({suggestion.eventCount})</li> : <></>}
                                {suggestion.documentCount > 0 ? <li className={`ResultRectangle ${props.forType === 'document' ? 'selected' : ''}`} onClick={() => props.handleSelected("document")} data-content="document" >დოკუმენტაცია ({suggestion.documentCount})</li> : <></>}
                            </ul>
                        </div>
                        : <></>
                    }
                    <CardGroup className="horizontalCrdStyle" style={{ padding: '10px', marginTop: '10px' }}>
                        {suggestion.data.map(forecast =>
                            <a key={forecast.objType + '_' + forecast.id} href={'../' + forecast.linkPath} className="mainCard">
                                <Card className="cardHorizonalSearch">
                                    <div className={`${forecast.photoAddress === '' ? getImgClassName(forecast.objType) : 'imgDivObjListHorizontal'} cardImg`}>
                                        {
                                            forecast.photoAddress == null || forecast.photoAddress === '' ?
                                                <></> :
                                                <img variant="top" src={forecast.photoAddress} alt="" style={{ width: '100%', height: '100%' }} />
                                        }
                                      
                                    </div>
                                    <Card.Body>
                                        <div className="cardboldHeader">{forecast.objectName!=null && forecast.objectName.length > 30 ? forecast.objectName.substring(0, 30) + "..." : forecast.objectName} - #{forecast.id}</div>
                                        <div className="cardsmalltext">
                                            {forecast.address}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </a>
                        )}
                    </CardGroup>
                </div>
                <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '20px' }} />
                <div className="showAll" style={{ textAlign: "center" }} onClick={() => props.goTo(props.type === 'all' ? props.forType : props.type, suggestion.filterText)} >
                    ყველა შედეგი ({getTotalCount(props.forType, suggestion)})
                </div>
            </div>
            : <></>
    )

    return <div>{options}</div>
}

function getTotalCount(value, suggestion) {
    switch (value) {
        case "immovable":
            return suggestion.immovableCount;
        case "movable":
            return suggestion.movableCount;
        case "zone":
            return suggestion.zoneCount;
        case "museum":
            return suggestion.museumCount;
        case "wallart":
            return suggestion.wallCount;
        case "stoneengrave":
            return suggestion.stoneCount;
        case "document":
            return suggestion.documentCount;
        case "event":
            return suggestion.eventCount;
        default: return suggestion.allCount;
    }
}

function getImgClassName(param) {
    var type = "";
    switch (param) {
        case "immovable":
            type = 'ImageDivImmovableSmall';
            break;
        case "movable":
            type = 'ImageDivMovableSmall';
            break;
        case "zone":
            type = 'ImageDivZoneSmall';
            break;
        case "museum":
            type = 'ImageDivMuseum';
            break;
        case "wallart":
            type = 'ImageDivWallArt';
            break;
        case "wallartPainting":
            type = 'ImageDivWallArt';
            break;
        case "stoneengrave":
            type = 'ImageDivStoneEngrave';
            break;
        case "stoneengraveMembership":
            type = 'ImageDivStoneEngrave';
            break;
        case "document":
            type = 'ImageDivDocumentSmall';
            break;
        case "event":
            type = 'ImageDivEventSmall';
            break;
        default: type = '';
            break;
    }

    return type;
}



export default Suggestions