import React from 'react';
import './User.css';
import './Radio.css';
import { authenticationService } from '../_services';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';


const Schema = Yup.object().shape({
    password: Yup.string().required('შეიყვანეთ პაროლი'),
    passwordrepeat: Yup.string().oneOf([Yup.ref('password')], 'პაროლი არ ემთხვევა').required('გაიმეორეთ პაროლი')
});

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmed: false,email:'',code:''
        }
    }
    componentDidMount() {
        this.load();
    }

    async load() {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code') || '';
        const email = params.get('email') || '';

        const response = await fetch(`api/Users/CheckCode?email=${email}&code=${code}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        const data = await response.json();
        this.setState({ confirmed: data, email: email, code: code });

    }
    render() {
        return (
            <div className="immListDiv" style={{
                backgroundColor: 'white'
            }}>

                <div style={{ backgroundColor: 'white', width: '100%', height: '100%', padding: '30px' }}>
                    {
                        this.state.confirmed ?
                            <div style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div className="boldHeade" style={{ padding: '30px' }}>
                                    პაროლის აღდგენა
                        </div>

                                <Formik
                                    initialValues={{
                                        password: '',
                                        passwordrepeat: ''
                                    }}
                                    validationSchema={Schema}
                                    onSubmit={({ password, passwordrepeat }, { setStatus, setSubmitting }) => {
                                        setStatus();
                                        authenticationService.updatePassword(this.state.email,this.state.code,{ password, passwordrepeat })
                                            .then(
                                                user => {
                                                    //setSubmitting(false);
                                                   
                                                    const { from } = this.props.location.state || { from: { pathname: "/Login" } };
                                                    this.props.history.push(from);
                                                    
                                                },
                                                error => {
                                                    setSubmitting(false);
                                                    setStatus(error);
                                                }
                                            );
                                    }}
                                    render={({ values, errors, status, touched, isSubmitting, setFieldValue }) => (
                                        <Form >
                                            {status &&
                                                <div className={'alert alert-danger'}>{status}</div>
                                            }

                                            <div className="form-group">
                                                <Field autoComplete="new-password" name="password" type="password" className={'form-control contactText' + (errors.password && touched.password ? ' is-invalid' : '')} placeholder="პაროლი" />
                                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <Field autoComplete="new-password" name="passwordrepeat" type="password" className={'form-control contactText' + (errors.passwordrepeat && touched.passwordrepeat ? ' is-invalid' : '')} placeholder="გაიმეორეთ პაროლი" />
                                                <ErrorMessage name="passwordrepeat" component="div" className="invalid-feedback" />
                                            </div>
                                            <div style={{ padding: '0.375rem 0.75rem' }}>
                                                <button type="submit" className="MainButton" disabled={isSubmitting}>შეცვლა</button>
                                                {isSubmitting &&
                                                    <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                }
                                            </div>

                                        </Form>
                                    )}
                                />
                            </div> :
                            <></>
                    }


                </div>
            </div>

        )
    }
}

export { ResetPassword }; 