import React, { Component } from 'react';
import { Button, Col, Modal, Card } from 'react-bootstrap';
import SiteRules from './SiteRules';
import ReactDOM from 'react-dom'
export class SiteRulesModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            show: false,
            scrollTop: 0
            , disabledButton: true
        };
        this.myRef = React.createRef()
    }

    componentDidMount() {

    }
    onScroll = () => {
        const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const scrollTop = this.myRef.current.scrollTop

        if (scrollTop > 1330) {
            this.setState({
                scrollTop: 1,
                disabledButton: false
            })
        }


    }
    render() {
        if (!this.props.show) {
            return null;
        }
        const {
            scrollTop
        } = this.state

        return (
            <Modal
                dialogClassName="modalSiteRules"
                /*size="lg"*/
                show={true}
                backdrop="static"
                centered>
                <Modal.Body>

                    <div
                        ref={this.myRef}
                        onScroll={this.onScroll}
                        style={{
                            height: '500px',
                            overflow: 'scroll',
                        }} >
                        <SiteRules
                            fromRegister={true}
                        > </SiteRules>
                    </div>



                </Modal.Body>
                <Modal.Footer>
                    <button className="daxurvaButton">დახურვა</button>
                    <button className={this.state.scrollTop == 1 ? "vetanxmebiButton" : "vetanxmebiButtonDisabled"}
                        onClick={this.props.cancelDamxmareModal}
                        disabled={this.state.disabledButton}
                    >ვეთანხმები</button>
                </Modal.Footer>
            </Modal >
        );
    }
}

export default SiteRulesModal;