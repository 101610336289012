import React, { Component } from 'react';
import { Button, Row, Col } from 'react-bootstrap'
//import './Home.css';
import { Footer } from './Footer';

export class AboutUs extends Component {
    static displayName = AboutUs.name;


    render() {
        return (
            <div >
                <Row style={{ minHeight: 'calc(100vh - 210px)' }}>
                    <Col md={6} style={{ backgroundColor: "#F4F4F4" }} className="aboutUsRowPadding">
                        <div style={{ width: "100%" }}>
                            <div style={{ maxWidth: "760px", marginLeft: "auto" }}>
                                <div className="boldHeade">
                                    ჩვენ შესახებ
                                </div>
                                <div className="lightText">
                                    საქართველოს კულტურული მემკვიდრეობის დაცვის ეროვნული სააგენტო საქართველოში კულტურული მემკვიდრეობის სფეროში სახელმწიფო სტრატეგიის ერთ-ერთი მთავარი გამტარებელი ორგანოა, რომელიც უფლებამოსილია კულტურული მემკვიდრეობის სახელმწიფო პროგრამის განხორციელებაზე. სააგენტო აერთიანებს ქვეყნის მასშტაბით არსებულ 12 მუზეუმ-ნაკრძალსა და 7 მუზეუმს, სახლ-მუზეუმს.
                                    <p />
                                    მუზეუმ-ნაკრძალები, მათში შემავალი სამოქმედო არეალებით, ქვეყნის ტერიტორიის დიდ ნაწილს მოიცავენ და თავის მხრივ, აერთიანებენ კულტურული მემკვიდრეობის მრავალფეროვან სპექტრს: არქეოლოგიურ, არქიტექტურულ, ეთნოგრაფიულ და სხვ. უძრავ/მოძრავ ძეგლებსა და ობიექტებს, რომელთა დაცვა, შესწავლა, პრეზენტირება და ა.შ. მუზეუმ–ნაკრძალების უმთავრესი ფუნქციაა.
                                    <p />
                                    ისინი ახორციელებენ მუზეუმ-ნაკრძალის სამოქმედო არეალში არსებული ძეგლების/ობიექტების ინვენტარიზაციას, დოკუმენტირებას, მდგომარეობის მუდმივ მონიტორინგს და შეფასებას. კულტურული მემკვიდრეობის ძეგლების/ობიექტების შესახებ ინფორმაცია საბოლოო სახით თავს იყრის საქართველოს კულტურული მემკვიდრეობის დაცვის ეროვნული სააგენტოს საინფორმაციო სისტემების სამსახურის მონაცემთა ბაზებში, საიდანაც აღნიშნული ინფორმაცია სხვადასხვა საინფორმაციო სივრცეში, საზოგადოებისათვის ხელმისაწვდომი ფორმით არის განთავსებული.
                                    <p />
                                    კულტურული მემკვიდრეობის მონაცემთა მართვის გეოსაინფორმაციო სისტემას ადმინისტრირებას უწევს სააგენტოს საინფორმაციო სისტემების სამსახური - გის კომპლექსური მონაცემთა ბაზის მართვის ჯგუფი.
                                    </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} style={{ backgroundColor: "#EDEDED" }} className="aboutUsRowPadding">
                        <div style={{ maxWidth: "760px" }}>
                            <div className="mwdiumBoldHeader">
                                მუზეუმ-ნაკრძალები
                            </div>
                            <div className="lightText">
                                1.	დიდი მცხეთის არქეოლოგიური სახელმწიფო მუზეუმ-ნაკრძალი <br />
                                2.	ვარძიის ისტორიულ-არქიტექტურული მუზეუმ-ნაკრძალი  <br />
                                3.	პარმენ ზაქარაიას სახელობის ნოქალაქევის არქიტექტურულ-არქეოლოგიური მუზეუმ-ნაკრძალი  <br />
                                4.	ექვთიმე თაყაიშვილის სახელობის გურიის მხარის არქეოლოგიური მუზეუმ-ნაკრძალი <br />
                                5.	სამშვილდისა და კლდეკარის მუზეუმ-ნაკრძალი <br />
                                6.	უფლისციხის ისტორიულ-არქიტექტურული მუზეუმ-ნაკრძალი  <br />
                                7.	ქსნის ხეობის ისტორიულ-არქიტექტურული მუზეუმ-ნაკრძალი <br />
                                8.	დიდი ლიახვის ხეობის სახელმწიფო მუზეუმ-ნაკრძალი <br />
                                9.	ქუთაისის ისტორიულ-არქიტექტურული მუზეუმ-ნაკრძალი  <br />
                                10.	უჯარმისა და დავით-გარეჯის მუზეუმ-ნაკრძალი  <br />
                                11.	მუცოს მუზეუმ-ნაკრძალი <br />
                                12.	ჩაჟაშის მუზეუმ-ნაკრძალი  <br />

                        </div>

                            <div className="mwdiumBoldHeader">
                                მუზეუმები და სახლ-მუზეუმები
                        </div>
                            <div className="lightText">                               
                                1.	ბორჯომის მხარეთმცოდნეობის მუზეუმი  <br />
                                2.	გრემის მუზეუმი  <br />
                                3.	ნიკო ფიროსმანაშვილის სახელმწიფო მუზეუმი თბილისში <br />
                                4.	ნიკო ფიროსმანაშვილის სახელმწიფო მუზეუმი მირზაანში <br />
                                5.	სტეფანწმინდის ისტორიული მუზეუმი  <br />
                                6.	ნიკო ნიკოლაძის სახლ-მუზეუმი  <br />
                                7.	გიორგი და დავით ერისთავების სახლ-მუზეუმი <br />
                            </div>
                        </div>
                    </Col>
                </Row>
                
                <Footer noMargin={true}></Footer>
            </div>
        );
    }

}
