import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { Row, Col, Accordion, Card } from 'react-bootstrap'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { CustomToggle } from '../custom/CustomToggle'
import { CustomSelect } from '../custom/CustomSelect'
import './GalleryFull.css';
import context from '../../../../../node_modules/react-bootstrap/esm/AccordionContext';

var photoItems;
export class GalleryFull extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            show: this.props.showMod,
            showBullets: true,
            showFullscreenButton: true,
            showGalleryFullscreenButton: false,
            showPlayButton: false,
            showGalleryPlayButton: true,
            showNav: true,
            showIndex: true,
            isRTL: false,
            showVideo: {},
            images: [],
            downloadUrl: ""
        };


        this._onSlide = this._onSlide.bind(this);
        this._onClose = this._onClose.bind(this);
        this._selectChange = this._selectChange.bind(this);
        this._selectChangeMobile = this._selectChangeMobile.bind(this);
        this.downloadJpg = this.downloadJpg.bind(this);
    }

    componentDidMount() {
        this.toogleGalleryActiveLink(0);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.slideInterval !== prevState.slideInterval ||
            this.state.slideDuration !== prevState.slideDuration) {

            this._imageGallery.pause();
            this._imageGallery.play();
        }


    }

    _getImages(fromDate = '', toDate = '', author = '') {

        var type;
        switch (this.props.type) {
            case "immovable":
                type = '/immovable.svg';
                break;
            case "movable":
                type = '/movable.svg';;
                break;
            case "zone":
                type = '/zone.svg';
                break;
            case "museum":
                type = '/museum.svg';
                break;
            case "wallart":
                type = '/wallart.svg';
                break;
            case "stoneengrave":
                type = '/stoneengrave.svg';
                break;
            case "event":
                type = '/event.svg';
                break;
            case "document":
                type = '/document.svg';
                break;
            default: type = '';
                break;
        }


        let images = [];
        if (this.props.photo != null) {
            photoItems = this.props.photo
                .filter(el => ((el.author.includes(author) && el.author !== '') || author === '')
                    && ((el.date && Number(el.date.split('/')[2]) >= Number(fromDate)) || fromDate === '')
                    && ((el.date && Number(el.date.split('/')[2]) <= Number(toDate)) || toDate === '')
                );
            if (photoItems.length > 0) {
                photoItems.slice(0, 50).map(item =>
                    images.push({
                        original: item.docFile,
                        thumbnail: item.docFile
                    })
                );
            }
            else {
                images.push({
                    original: type,
                    // thumbnail: type
                });
            }
        }
        else {

            images.push({
                original: type,

            });
        }

        return images;
    }
    toogleGalleryActiveLink(val) {

        switch (val) {
            case 0:
                this.setState({ images: this._getImages() });
                break;
            case 1:
                this.setState({ images: this._getVideos() });
                break;
            case 2:
                this.setState({ images: this._get3DModels() });
                break;
                this.setState({ images: this._getImages() });
            default:
                break;
        }

    }

    _onSlide(currentIndex) {
        if (typeof currentIndex !== 'number') {
            currentIndex = 0;
        }
        else {
            this.setState({ downloadUrl: this.state.images[currentIndex] });
        }

        var test = this.state.showIndex;
        if (currentIndex > -1) {
            document.querySelector('#itemAuthor').innerHTML = photoItems[currentIndex].author;
            document.querySelector('#itemDate').innerHTML = photoItems[currentIndex].date !== '' ?
                photoItems[currentIndex].date.split('/')[0] + " " + this._getMonth(photoItems[currentIndex].date.split('/')[1]) + ", " + photoItems[currentIndex].date.split('/')[2] + " წ."
                : "";
            document.querySelector('#itemComment').innerHTML = photoItems[currentIndex].comment;

        }
        else {
            document.querySelector('#itemAuthor').innerHTML = photoItems[0].author;
            document.querySelector('#itemDate').innerHTML = photoItems[0].date !== '' ?
                photoItems[0].date.split('/')[0] + " " + this._getMonth(photoItems[0].date.split('/')[1]) + ", " + photoItems[0].date.split('/')[2] + " წ."
                : "";
            document.querySelector('#itemComment').innerHTML = photoItems[0].comment;
        }

        if (document.querySelector(".image-gallery-index-total").innerHTML == '50') {
            document.querySelector(".image-gallery-index-total").innerHTML = "50+";
        }
    }

    _getMonth(index) {
        var value = "";
        switch (index) {
            case '01':
                value = "იანვარი";
                break;
            case '02':
                value = "თებერვალი";
                break;
            case '03':
                value = "მარტი";
                break;
            case '04':
                value = "აპრილი";
                break;
            case '05':
                value = "მაისი";
                break;
            case '06':
                value = "ივნისი";
                break;
            case '07':
                value = "ივლისი";
                break;
            case '08':
                value = "აგვისტო";
                break;
            case '09':
                value = "სექტემბერი";
                break;
            case '10':
                value = "ოქტომბერი";
                break;
            case '11':
                value = "ნოემბერი";
                break;
            case '12':
                value = "დეკემბერი";
                break;

            default:
                value = "";
                break;
        }

        return value;
    }

    _renderLeftNav(onClick, disabled) {
        return (
            <button
                type="button"
                className='image-gallery-icon image-gallery-left-nav fullGalleryLeftIcon'
                disabled={disabled}
                onClick={onClick}>
                { /* <svg className="bi bi-arrow-left image-gallery-svg" width="1em" height="1em" viewBox="0 0 16 16" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M5.854 4.646a.5.5 0 0 1 0 .708L3.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z" />
                    <path fillRule="evenodd" d="M2.5 8a.5.5 0 0 1 .5-.5h10.5a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                </svg> */ }
            </button>
        )
    }
    _renderRightNav(onClick, disabled) {
        return (
            <button
                type="button"
                className='image-gallery-icon image-gallery-right-nav fullGalleryRightIcon'
                disabled={disabled}
                onClick={onClick}>
                { /*  <svg className="bi bi-arrow-right image-gallery-svg" width="1em" height="1em" viewBox="0 0 16 16" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L12.793 8l-2.647-2.646a.5.5 0 0 1 0-.708z" />
                    <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5H13a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8z" />
                </svg> */ }
            </button>
        )
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            var fromDate = document.querySelector('#fromDate').value;
            var toDate = document.querySelector('#toDate').value;
            var author = this.state.selectedOption.value;
            author = (author !== "აირჩიეთ ავტორი" ? author : '');
            this.setState({ images: this._getImages(fromDate, toDate, author) });
        }
    }

    _selectChange(index) {
        this.setState({ selectedOption: index });
        var fromDate = document.querySelector('#fromDate').value;
        var toDate = document.querySelector('#toDate').value;
        var author = index != null ? index.value : '';
        author = (author !== "აირჩიეთ ავტორი" ? author : '');
        this.setState({ images: this._getImages(fromDate, toDate, author) });

        document.querySelector('#itemAuthor').innerHTML = photoItems[0].author;
        document.querySelector('#itemDate').innerHTML = photoItems[0].date !== '' ? photoItems[0].date.split('/')[0] + " " + this._getMonth(photoItems[0].date.split('/')[1]) + ", " + photoItems[0].date.split('/')[2] + " წ." : "";
        document.querySelector('#itemComment').innerHTML = photoItems[0].comment;
    }

    _handleKeyDownMobile = (e) => {
        if (e.key === 'Enter') {
            var fromDate = document.querySelector('#fromDate2').value;
            var toDate = document.querySelector('#toDate2').value;
            var author = this.state.selectedOption.value;
            author = (author !== "აირჩიეთ ავტორი" ? author : '');
            this.setState({ images: this._getImages(fromDate, toDate, author) });
        }
    }

    _selectChangeMobile(index) {
        this.setState({ selectedOption: index });
        var fromDate = document.querySelector('#fromDate2').value;
        var toDate = document.querySelector('#toDate2').value;
        var author = index != null ? index.value : '';
        author = (author !== "აირჩიეთ ავტორი" ? author : '');
        this.setState({ images: this._getImages(fromDate, toDate, author) });


        document.querySelector('#itemAuthor').innerHTML = photoItems[0].author;
        document.querySelector('#itemDate').innerHTML = photoItems[0].date !== '' ? photoItems[0].date.split('/')[0] + " " + this._getMonth(photoItems[0].date.split('/')[1]) + ", " + photoItems[0].date.split('/')[2] + " წ." : "";
        document.querySelector('#itemComment').innerHTML = photoItems[0].comment;
    }

    _onClose() {
        this.setState({ images: this._getImages() });
    }

    removeCustomValidity(event) {
        event.target.setCustomValidity("");
        event.target.placeholder = '';
    }

    addPlaceHolder(event) {
        if (event.target.id === "fromDate" || event.target.id === "fromDate2")
            event.target.placeholder = "დან";
        else if (event.target.id === "toDate" || event.target.id === "toDate2")
            event.target.placeholder = "მდე";
    }
    //handleDownload = () => {
    //    const imageUrl = this.state.downloadUrl.original;
    //    console.log('Image URL:', imageUrl); // Log the image URL to check if it's correct

    //    // Create an anchor element
    //    const link = document.createElement('a');
    //    // Set the anchor's href attribute to the image source
    //    link.href = imageUrl;
    //    // Set the anchor's download attribute to force download
    //    link.download = 'image.jpg';
    //    // Append the anchor to the document body
    //    document.body.appendChild(link);
    //    // Trigger a click event on the anchor
    //    link.click();
    //    const downloadImage = () => {
    //        saveAs(imageUrl, 'image.jpg') // Put your image URL here.
    //    }
    //    downloadImage();
    //    // Remove the anchor from the document body
    //    document.body.removeChild(link);
    //    //window.open(imageUrl, '_blank');

    //    //// აქ ჩასამატებელია პირდაპირ გადმოწერა

    //}
    async downloadJpg(ids) {
        const imageUrl = this.state.downloadUrl.original;
        fetch(`api/Immovable/DownloadFromGallery?ids=${imageUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
            function (myBlob) {
                myBlob.blob().then(
                    function (res) {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(res);
                        const lastSlashIndex = link.href.lastIndexOf('/');
                        const lastPart = link.href.slice(lastSlashIndex + 1);
                        link.download = lastPart + `.jpg`;
                        link.click();
                    }
                );
            });


    }


    handleDownload = () => {

        const imageUrl = "http://www.geographic.ge/images/layout/logo_ge.png";

        fetch(imageUrl)
            .then(response => {
                return response.blob();
            })
            .then(blob => {
                // Create a temporary anchor element
                const link = document.createElement('a');
                // Set the anchor's href attribute to a blob URL
                const url = window.URL.createObjectURL(blob);
                link.href = url;
                // Set the anchor's download attribute to force download
                link.download = 'image.jpg';
                // Append the anchor to the document body
                document.body.appendChild(link);
                // Trigger a click event on the anchor
                link.click();
                // Remove the anchor from the document body after a delay
                setTimeout(() => {
                    document.body.removeChild(link);
                    // Revoke the blob URL to release resources
                    window.URL.revokeObjectURL(url);
                }, 100);
            })
            .catch(error => {
                console.error('Error downloading image:', error);
            });
    }

    render() {

        return (
            <>
                <Modal show={this.props.show} onHide={this.props.close} className="FullGallery" onExit={this._onClose}>
                    <div style={{ height: '100%' }}>
                        <Row style={{ height: '100%' }}>
                            <Col lg={7} className="sliderPart">
                                <Modal.Header closeButton style={{ color: 'white' }} >
                                    <Modal.Title className="ModalHeader"></Modal.Title>
                                </Modal.Header>
                                <div>
                                    <ImageGallery
                                        ref={i => this._imageGallery = i}
                                        items={this.state.images}
                                        lazyLoad={false}
                                        //infinite={this.state.infinite}                                      
                                        showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
                                        showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
                                        showThumbnails={this.state.showThumbnails}
                                        showIndex={this.state.showIndex}
                                        showNav={this.state.showNav} fullGalleryRightIcon
                                        isRTL={this.state.isRTL}
                                        additionalClass="app-image-gallery"
                                        onSlide={this._onSlide}
                                        onImageLoad={this._onSlide}
                                        renderLeftNav={this._renderLeftNav}
                                        renderRightNav={this._renderRightNav}
                                    />



                                </div>
                            </Col>
                            <Col lg={5} className="contentPart">
                                <div className="filterLarge">
                                    <div style={{ padding: '30px', display: "flex", justifyContent: "space-between" }} className="FullGalleryHeader">ფილტრი
                                        <img width="16" alt=""
                                            src="images/downloadRed.svg"
                                            onClick={this.downloadJpg}
                                            style={{
                                                cursor: 'pointer',
                                                float: 'right',
                                                marginTop: '5px',
                                                marginRight: '15px'
                                            }} />
                                    </div>
                                    <div style={{ padding: '0 30px' }}>
                                        <Row>
                                            <Col>
                                                <CustomSelect items={this.props.authors} onChange={this._selectChange} selectedOption={this.state.selectedOption} placeHolder="აირჩიეთ ავტორი"></CustomSelect>
                                            </Col>
                                        </Row>
                                        <div className="headerText" style={{ padding: '30px 20px 20px 20px', fontSize: '14px' }}>გადაღების წელი
                                        </div>
                                        <Row>
                                            <Col>
                                                <input id="fromDate" className="customForm-control" placeholder="დან" onKeyDown={this._handleKeyDown}
                                                    onFocus={this.removeCustomValidity.bind(this)}
                                                    onBlur={this.addPlaceHolder.bind(this)} />
                                            </Col>
                                            <Col>
                                                <input id="toDate" className="customForm-control" placeholder="მდე" onKeyDown={this._handleKeyDown}
                                                    onFocus={this.removeCustomValidity.bind(this)}
                                                    onBlur={this.addPlaceHolder.bind(this)} />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="SlideContent">
                                    <div>ავტორი: <span className="headerText" id="itemAuthor"></span></div>
                                    <div style={{ marginTop: '10px' }}>გადაღების თარიღი: <span className="headerText" id="itemDate"></span></div>
                                    <div style={{ marginTop: '50px' }} ><span id="itemComment"></span>
                                    </div>
                                </div>
                                <div className="filterMobile">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">ფილტრი</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <Row>
                                                        <Col>
                                                            <CustomSelect items={this.props.authors} onChange={this._selectChangeMobile} selectedOption={this.state.selectedOption} placeHolder="აირჩიეთ ავტორი"></CustomSelect>

                                                        </Col>
                                                    </Row>
                                                    <div className="headerText" style={{ padding: '30px 20px 20px 20px', fontSize: '14px' }}>გადაღების წელი
                                                    </div>
                                                    <Row style={{ paddingBottom: '20px' }}>
                                                        <Col>
                                                            <input id="fromDate2" className="customForm-control" placeholder="დან" onKeyDown={this._handleKeyDownMobile}
                                                                onFocus={this.removeCustomValidity.bind(this)}
                                                                onBlur={this.addPlaceHolder.bind(this)} />
                                                        </Col>
                                                        <Col>
                                                            <input id="toDate2" className="customForm-control" placeholder="მდე" onKeyDown={this._handleKeyDownMobile}
                                                                onFocus={this.removeCustomValidity.bind(this)}
                                                                onBlur={this.addPlaceHolder.bind(this)} />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </Modal>
            </>
        );
    }
}