import React from 'react';
import { Modal } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import '../../components/Immovable/Search.css'
import Autosuggest from 'react-autosuggest';


const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = suggestion => (
    <div>
        {suggestion}
    </div>
);


export class UserListDetailSearchModal extends React.Component {
    selectedDocumentType = [];
    tempselectedDocumentType = [];
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data:
            {
                email: '', name: ''
            },
            suggestions: []
        };

        this.handleClearClick = this.handleClearClick.bind(this);

    }


    handleClose = () => this.setState({ show: false });
    handleShow = () => {
        this.setState({ show: true })

        if (sessionStorage.getItem("filterData") != null && sessionStorage.getItem("filterData") !== '') {
            var data = JSON.parse(JSON.parse(sessionStorage.getItem("filterData")));
            this.setState({ data: data })
        }

    };

    handleSubmit(e) {
        e.preventDefault();

        this.prepareSubmit();

    }
    prepareSubmit() {
        var data = this.state.data;
        data.documentType = this.selectedDocumentType;
        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));

        this.searchData(data);
    }

    checkData(data) {
        if (data.email !== 0
            //|| data.name !== ''
        ) {
            return true;
        }
        return false
    }

    searchData(data) {
        if (this.checkData(data)) {
            this.props.filterResulthandle(data);
            this.handleClose();
        }
        else {
            this.props.filterResulthandle('');
            this.handleClose();
        }
    }
    handleClearData() {
        var data = {
            email: '', name: ''
        };
        this.setState({ data: data, show: false })
        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));
        this.props.filterResulthandle('');
    }


    onKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.prepareSubmit();
        }
    }

     handleClearClick() {
        var data = this.state.data;
        data.name = '';
        this.setState({
            data: data
        });
    }



    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                suggestions: []
            }) :

            fetch('api/AutoComplete/Getrecord?value=' + value + "&component=userList")
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        suggestions: data
                    })
                })
                .catch(error => console.error(error));
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    onChange(event) {
        var data = this.state.data;
        switch (event.target.name) {
            case "email":
                data.email = event.target.value
                break;
            default:
                break;
        }

        this.setState({ data: data });
    }
    onNameChange = (event, { newValue }) => {
        var data = this.state.data;
        data.name = newValue;
        this.setState({
            data: data
        });
    };

    render() {
        const suggestions = this.state.suggestions;
        const value = this.state.data.name

        const inputProps = {
            value,
            onChange: this.onNameChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        let clearButton;
        if (value.length >= 1) {
            clearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleClearClick}
                />
            );
        }


        return (
            <>
                <div className="showModal">
                    <button onClick={this.handleShow} className="MainButton icon-equalizer" >{this.props.children}</button>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose} className="SearchDialog">
                    <Modal.Header closeButton className="searchCloseModal">
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit.bind(this)} method="POST" style={{ height: '100%', paddingTop: '0px' }}>
                            <div className="searchDivContent">
                                <div className="FullGalleryHeader">სარეგისტრაციო ნომერი</div>
                                <input type="text" className="form-control searchDivRowInput" name="email" id="email"
                                    value={this.state.data.email}
                                    onChange={this.onChange.bind(this)} autoComplete="off" />
                                <div className="FullGalleryHeader searchDivRow">სახელწოდება</div>
                                <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        renderSuggestion={renderSuggestion}
                                        getSuggestionValue={getSuggestionValue}
                                        inputProps={inputProps} />
                                    <div className="close-icon-container">
                                        {clearButton}
                                    </div>
                                </div>
                            </div>
                            <div className="searchDiv">
                                <div onClick={this.handleClearData.bind(this)} className="NoFillSearchButton">გაუქმება</div>
                                <button type="submit" className="MainButton">ძიება</button>
                            </div>
                        </Form>
                    </Modal.Body>

                </Modal>
            </>
        );
    }
}

