import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            displayUserMenu: false
        };

        this.redirectToLogin = this.redirectToLogin.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    displayUserMenu() {      
        this.setState({ displayUserMenu: !this.state.displayUserMenu })      
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <div style={{ display:'flex' }}>
                <NavLink tag={Link} className="text-dark icon-down-open-big" to={profilePath} onClick={() => this.displayUserMenu()}>გამარჯობა, {userName}</NavLink>
                <div className={`componentSuggestion ${this.state.displayComponentSuggestion ? '' : 'displayNone'}`}>
                    <div className="suggestionItem" onClick={(e) => this.setComponentButtonContent(e)}>ჩემი პროფილი</div>
                    <div className="suggestionItem" onClick={(e) => this.setComponentButtonContent(e)}>მემკვიდრეობის დამატება</div>
                    <div className="suggestionItem" onClick={(e) => this.setComponentButtonContent(e)}><NavLink tag={Link} className="text-dark" to={logoutPath}>გასვლა</NavLink></div>
                </div>
            </div>          
        </Fragment>);

    }
    redirectToLogin(loginPath) {
        window.location.href = "/login?id=" + loginPath;
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            {
                //<NavItem>
                //    <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
                //</NavItem>
            }
            {
                //<NavItem>
                //<NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink> 
                // </NavItem>
            }
            <button className="MainButton LoginBtn" onClick={() => this.redirectToLogin(loginPath)} to={loginPath}><span className="LoginText">ავტორიზაცია</span></button>


        </Fragment>);
    }
}
