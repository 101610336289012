import React from 'react';
import Select from 'react-select';

export class CustomSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            placeholder: this.props.placeHolder
        };
    }
   
    removePlaceHolder(event) {
        this.setState({ placeholder: "" });    
    }

    addPlaceHolder(event) {
        this.setState({ placeholder: this.props.placeHolder }); 
    }
    
    render() {
       
        var options = [];
        this.props.items.map(item => 
            options.push({ value: item, label: item })
        );

        const customStyles = {
            option: (styles, state) => ({
                ...styles,
                color: state.isSelected ? "#E57959" : styles.color,
                backgroundColor: state.isSelected ? "transparent" : styles.color,
                //borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
                "&:hover": {
                    color: "#E57959",
                    backgroundColor: "transparent"
                }
            }),
            control: (styles, state) => ({
                ...styles,
                boxShadow: 0,
                borderColor: state.isFocused ? "#ced4da" : "#ced4da",
                "&:hover": {
                    borderColor: state.isFocused ? "#ced4da" : "#ced4da"
                }
            })
        };
        return (
            <Select
                blurInputOnSelect={false}
                value={this.props.selectedOption}
                onChange={this.props.onChange}
                options={options}
                noOptionsMessage={() => " "}
                isClearable={true}
                placeholder={this.state.placeholder}
                onBlur={() => this.addPlaceHolder(this)}
                onFocus={() => this.removePlaceHolder(this)}               
                styles={customStyles}
            />
        );
    }
}
