import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav'
import { authenticationService } from '../_services';

export class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showGoTo: false,
            currentUser: null
        };
    }


    componentDidMount() {

        window.addEventListener('scroll', this.handleScroll, true);

        var el = document.getElementsByClassName('immListDiv');
        if (el != null && el.length > 0) {
            el[0].addEventListener('scroll', this.handleScroll, true);
        }
        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x
        }));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);

        var el = document.getElementsByClassName('immListDiv');
        if (el != null && el.length > 0) {
            el[0].removeEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll = () => {
        var el = document.getElementsByClassName('immListDiv');
        window.scrollY > 0 || (el != null && el.length > 0 && el[0].scrollTop > 0) ? this.setState({ showGoTo: true }) : this.setState({ showGoTo: false })
    };

    goToTop() {
        window.scrollTo(0, 0);
        var el = document.getElementsByClassName('immListDiv');
        if (el != null && el.length > 0) {
            el[0].scrollTo(0, 0);
        }
    }



    render() {


        return (
            <div style={this.props.noMargin === true ? {} : { marginTop: '50px' }}>

                {this.props.children ?
                    <div style={{ backgroundColor: 'white', marginBottom: '50px', paddingBottom: '20px' }}> {this.props.children}</div>
                    : <></>
                }
                <div style={{ backgroundColor: 'white' }}>
                    <div className={`goToTop icon-up-open-big ${this.state.showGoTo === true ? '' : 'displayNone'}`} onClick={this.goToTop}></div>

                    <footer className="footer">
                        <div className="footerMenu">
                            <Nav as="ul">
                                <Nav.Item as="li">
                                    <Nav.Link href="/about" className="card-link" style={{ paddingLeft: '0' }}>საიტის შესახებ</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link href="/aboutUs" className="card-link">ჩვენ შესახებ</Nav.Link>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <Nav.Link href="/contact" className="card-link">კონტაქტი</Nav.Link>
                                </Nav.Item>
                                {this.state.currentUser != null ?
                                    <Nav.Item as="li">
                                        <Nav.Link href="/siterules" className="card-link">საიტის წესები</Nav.Link>
                                    </Nav.Item>
                                    : <></>
                                }

                            </Nav>
                        </div>
                        <div className="footer-copyright">© 2020 ყველა უფლება დაცულია.
                    </div>

                    </footer>
                </div>
            </div>

        );
    }
}
