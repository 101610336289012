import React from 'react';
import { Accordion, Card, CardGroup } from 'react-bootstrap'

import { CustomToggle } from '../custom/CustomToggle'

import { ObjectListViewPart } from '../ObjectListViewPart';

import PulseLoader from "react-spinners/PulseLoader";

import { authHeader, handleResponse } from '../../_helpers';
import { Formik, Field, Form, FieldArray } from 'formik';
class Favorits extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pager: {},
            pageOfItems: [],
            loadData: true,
            loading: true,
            reload: false,
            type: this.props.type,
            collection: []
        };

    }

    componentDidMount() {
        if (this.props.currentUser != null) {
            window.scrollTo(0, 0)
            this.loadPage();
        }

    }

    componentDidUpdate() {
        if (this.props.currentUser != null && this.state.loading) {
            this.loadPage();
        }
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const page = parseInt(params.get('page')) || 1;

        var type;
        switch (this.props.type) {
            case "favorite":
                //type = `api/Users/GetFavoriteObjectList?count=10&page=${page}`;
                type = `api/Users/GetFavorites?count=10&page=${page}`;               
                break;
            default: type = '';
                break;
        }


        if (page !== this.state.pager.currentPage || this.state.reload === true) {
            const requestOptions = { method: 'GET', headers: authHeader() };
            const response = await fetch(type, requestOptions);

            const data = await response.json();
            //this.setState({ pager: data, pageOfItems: data.aaData, loading: false, type: this.props.type });
            this.setState({ collection: data, loading: false, type: this.props.type });
        }
    }

    async loadData(folderID) {
        const params = new URLSearchParams(window.location.search);
        const page = parseInt(params.get('page')) || 1;

        var type;
        switch (this.props.type) {
            case "favorite":
                type = `api/Users/GetFavoriteObjectList?count=10&page=${page}&folder=${folderID}`;                
                break;
            default: type = '';
                break;
        }


        if (page !== this.state.pager.currentPage || this.state.reload === true) {
            const requestOptions = { method: 'GET', headers: authHeader() };
            const response = await fetch(type, requestOptions);

            const data = await response.json();
            this.setState({ pager: data, pageOfItems: data.aaData, loadData: false, type: this.props.type });           
        }
    }
    getImgDivClass(type) {
        switch (type) {
            case "immovable":
                return "imgDivImmovable";
            case "movable":
                return "imgDivMovable";
            case "wallart":
                return "imgDivWallArt";
            case "stoneengrave":
                return "imgDivStoneEngrave";
            case "zone":
                return "imgDivZone";
            case "museum":
                return "imgDivMuseum";
            case "event":
                return "imgDivEvent";
            case "document":
                return "imgDivDocumentBig";
            case "all":
                return "";
            default:
                return "imgDivImmovable";
        }
    }

    DeleteFromCollection(type, id) {

        var result = window.confirm("გსურთ წაშლა?");
        if (result) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };
            return fetch(`api/Users/DeleteFromFvorite?objectType=${type}&objectID=${id}`, requestOptions)
                .then(data => {
                    this.setState({ reload: true });
                    this.loadPage();
                });
        }



    }

    updateFolder(id, name) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        fetch(`api/Users/UpdateUserFolder?id=${id}&name=${name}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                this.loadPage();
            });
    }

    deleteFolder(id) {
        var result = window.confirm("გსურთ წაშლა?");
        if (result) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            fetch(`api/Users/DeleteUserFolder?id=${id}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.loadPage();
                });
        }
    }
   

    drawCollectionList(collection) {
        window.scrollTo(0, 0)
        var el = document.getElementsByClassName('immListDiv');
        if (el != null && el.length > 0) el[0].scrollTo(0, 0);

        return (
            <Formik
                initialValues={{
                    collections: collection,
                }}

                render={({
                    values, errors, status, touched, isSubmitting, setFieldValue }) => (
                        <Form >                          
                            <div className="form-group" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FieldArray
                                    name="collections"
                                    render={arrayHelpers => (
                                        <div style={{ width:'100%' }}>
                                            {collection && collection.length > 0 ? (
                                                collection.map((folder, index) => (
                                                    <div key={index}>

                                                        <Accordion defaultActiveKey="0" style={{ marginTop: '10px' }}>
                                                            <Card >
                                                                <Card.Header className="TogglePanelHeader" style={{ textAlign: 'left' }}>
                                                                    <CustomToggle eventKey="1">
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div className="TransparentButton" style={{ color: '#FF647C', minWidth: '30px' }} onClick={(e) => {
                                                                                e.preventDefault();
                                                                                arrayHelpers.remove(index);
                                                                                this.deleteFolder(folder.id)
                                                                            }} ><img width="20" alt="" src="/images/trash.svg" style={{ marginRight: '10px' }} />{' '}</div>

                                                                        <Field
                                                                            autoFocus={collection[index].isActive === true && collection[index].name == null ? true : false}
                                                                            autoComplete="off" name={`collections.${index}.name`} type="text" className={'favoriteCollectionText'} 
                                                                            onBlur={(event) => this.updateFolder(folder.id, event.target.value)} onKeyPress={(e) => {
                                                                                if (e.key === 'Enter') { e.preventDefault(); e.target.blur() }
                                                                            }}
                                                                        />

                                                                            <div style={{ marginBottom: 'auto', marginTop: 'auto' }}>
                                                                            {
                                                                                ' ('+folder.count+') '
                                                                            }

                                                                            </div>
                                                                        </div>
                                                                    </CustomToggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="1">
                                                                    <Card.Body>
                                                                        <CardGroup>
                                                                            {folder.data.aaData.map(forecast =>
                                                                                <div key={forecast.objType + '_' + forecast.id} dataid={forecast.id} >
                                                                                    <a href={'../' + forecast.linkPath} className="mainCard">
                                                                                        <Card className="cardHorizonalListView">
                                                                                            <div className="ObjectListViewImgContainer">
                                                                                                <div className={`${(forecast.photoAddress === '' || (forecast.photoAddress.indexOf('.jpg') < 0 && forecast.photoAddress.indexOf('.png') < 0)) ? this.getImgDivClass(forecast.objType) : 'imgDivObjListView'}`}>
                                                                                                    

                                                                                                    {
                                                                                                        forecast.photoAddress == null || forecast.photoAddress === '' ?
                                                                                                            <></> :
                                                                                                            <img className="list_card-img" variant="top" src={forecast.photoAddress} alt="" />
                                                                                                    }



                                                                                                </div>
                                                                                            </div>
                                                                                            <Card.Body className="objectList_card-body">
                                                                                                <Card.Title className="objectList_card-title card-link" >{forecast.objectName}</Card.Title>
                                                                                                <div className="objectTypeList">{forecast.variety}</div>
                                                                                                <Card.Text className="objectList_card-text icon-location-outline" dataid={forecast.id}>
                                                                                                    {forecast.address}
                                                                                                </Card.Text>
                                                                                                {
                                                                                                    this.props.type === "favorite" ?
                                                                                                        <div style={{ textAlign: "left" }}>
                                                                                                            <div className="TransparentButton" style={{ color: '#FF647C', display: 'flex' }} onClick={(e) => { e.preventDefault(); this.DeleteFromCollection(forecast.objType, forecast.id) }}><img width="20" alt="" src="/images/heart.svg" style={{ marginRight: '10px' }} />{' '}ფავორიტებიდან წაშლა</div>
                                                                                                        </div>
                                                                                                        : <></>
                                                                                                }
                                                                                            </Card.Body>
                                                                                        </Card>
                                                                                    </a>

                                                                                    {
                                                                                        forecast.objType === "wallart"
                                                                                            ? <div >
                                                                                                <div style={{ textAlign: "left", padding: '0 10px 20px 10px', fontSize: '15px' }}>შემადგენელი მოხატულობები</div>
                                                                                                <ObjectListViewPart objID={forecast.id} imgClassName="ImageDivWallArt" type="wallart" />
                                                                                            </div>
                                                                                            : forecast.objType === "stoneengrave" ? <div >
                                                                                                <div style={{ textAlign: "left", padding: '0 10px 20px 10px', fontSize: '15px' }}>შემადგენელი კვეთილობები</div>
                                                                                                <ObjectListViewPart objID={forecast.id} imgClassName="ImageDivWallArt" type="stoneengrave" />
                                                                                            </div>
                                                                                                : <></>
                                                                                    }

                                                                                </div>
                                                                            )}
                                                                        </CardGroup>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </Accordion>
                                                            
                                                          
                                                        </div>
                                                     
                                                ))
                                            ) : (
                                                    <>
                                                    </>
                                                )}

                                        </div>

                                    )}
                                />

                            </div>
                            
                        </Form>
                    )}
            />

);

            {


            //    (collection && collection.length > 0) ?
            //        <div>
            //            {
            //                this.state.collection.map((folder, index) =>
            //                    <Accordion defaultActiveKey="0" style={{ marginTop: '10px' }}>
            //                        <Card >
            //                            <Card.Header className="TogglePanelHeader" style={{ textAlign: 'left' }}>
            //                                <CustomToggle eventKey="1">
            //                                    {
            //                                        folder.name + ' (' + folder.count + ') '
            //                                    }
            //                                </CustomToggle>
            //                            </Card.Header>
            //                            <Accordion.Collapse eventKey="1">
            //                                <Card.Body>
            //                                    <CardGroup>
            //                                        {folder.data.aaData.map(forecast =>
            //                                            <div key={forecast.objType + '_' + forecast.id} dataid={forecast.id} >
            //                                                <a href={'../' + forecast.linkPath} className="mainCard">
            //                                                    <Card className="cardHorizonalListView">
            //                                                        <div className="ObjectListViewImgContainer">
            //                                                            <div className={`${(forecast.photoAddress === '' || (forecast.photoAddress.indexOf('.jpg') < 0 && forecast.photoAddress.indexOf('.png') < 0)) ? this.getImgDivClass(forecast.objType) : 'imgDivObjListView'}`}>
            //                                                                <img className="list_card-img" variant="top" src={forecast.photoAddress} alt="" />
            //                                                            </div>
            //                                                        </div>
            //                                                        <Card.Body className="objectList_card-body">
            //                                                            <Card.Title className="objectList_card-title card-link" >{forecast.objectName}</Card.Title>
            //                                                            <div className="objectTypeList">{forecast.variety}</div>
            //                                                            <Card.Text className="objectList_card-text icon-location-outline" dataid={forecast.id}>
            //                                                                {forecast.address}
            //                                                            </Card.Text>
            //                                                            {
            //                                                                this.props.type === "favorite" ?
            //                                                                    <div style={{ textAlign: "left" }}>
            //                                                                        <div className="TransparentButton" style={{ color: '#FF647C', display: 'flex' }} onClick={(e) => { e.preventDefault(); this.DeleteFromCollection(forecast.objType, forecast.id) }}><img width="20" alt="" src="/images/heart.svg" style={{ marginRight: '10px' }} />{' '}ფავორიტებიდან წაშლა</div>
            //                                                                    </div>
            //                                                                    : <></>
            //                                                            }
            //                                                        </Card.Body>
            //                                                    </Card>
            //                                                </a>

            //                                                {
            //                                                    forecast.objType === "wallart"
            //                                                        ? <div >
            //                                                            <div style={{ textAlign: "left", padding: '0 10px 20px 10px', fontSize: '15px' }}>შემადგენელი მოხატულობები</div>
            //                                                            <ObjectListViewPart objID={forecast.id} imgClassName="ImageDivWallArt" type="wallart" />
            //                                                        </div>
            //                                                        : forecast.objType === "stoneengrave" ? <div >
            //                                                            <div style={{ textAlign: "left", padding: '0 10px 20px 10px', fontSize: '15px' }}>შემადგენელი კვეთილობები</div>
            //                                                            <ObjectListViewPart objID={forecast.id} imgClassName="ImageDivWallArt" type="stoneengrave" />
            //                                                        </div>
            //                                                            : <></>
            //                                                }

            //                                            </div>
            //                                        )}
            //                                    </CardGroup>
            //                                </Card.Body>
            //                            </Accordion.Collapse>
            //                        </Card>
            //                    </Accordion>
            //                )}
            //        </div> :
            //        <></>
            //
        }

        
    }

    drawImovableList(forecasts) {
        window.scrollTo(0, 0)
        var el = document.getElementsByClassName('immListDiv');
        if (el != null && el.length > 0) el[0].scrollTo(0, 0);

        return (
            <Accordion defaultActiveKey="0" className="accordionNoshadow">
                <Card >
                    <Card.Header className="TogglePanelHeader" style={{ textAlign: 'left' }}>
                        <CustomToggle eventKey="1">კოლექცია</CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <CardGroup>
                                {forecasts.map(forecast =>
                                    <div key={forecast.objType + '_' + forecast.id} dataid={forecast.id} >
                                        <a href={'../' + forecast.linkPath} className="mainCard">
                                            <Card className="cardHorizonalListView">
                                                <div className="ObjectListViewImgContainer">
                                                    <div className={`${(forecast.photoAddress === '' || (forecast.photoAddress.indexOf('.jpg') < 0 && forecast.photoAddress.indexOf('.png') < 0)) ? this.getImgDivClass(forecast.objType) : 'imgDivObjListView'}`}>
                                                        <img className="list_card-img" variant="top" src={forecast.photoAddress} alt="" />
                                                    </div>
                                                </div>
                                                <Card.Body className="objectList_card-body">
                                                    <Card.Title className="objectList_card-title card-link" >{forecast.objectName}</Card.Title>
                                                    <div className="objectTypeList">{forecast.variety}</div>
                                                    <Card.Text className="objectList_card-text icon-location-outline" dataid={forecast.id}>
                                                        {forecast.address}
                                                    </Card.Text>
                                                    {
                                                        this.props.type === "favorite" ?
                                                            <div style={{ textAlign: "left" }}>
                                                                <div className="TransparentButton" style={{ color: '#FF647C', display: 'flex' }} onClick={(e) => { e.preventDefault(); this.DeleteFromCollection(forecast.objType, forecast.id) }}><img width="20" alt="" src="/images/heart.svg" style={{ marginRight: '10px' }} />{' '}ფავორიტებიდან წაშლა</div>
                                                            </div>
                                                            : <></>
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </a>

                                        {
                                            forecast.objType === "wallart"
                                                ? <div >
                                                    <div style={{ textAlign: "left", padding: '0 10px 20px 10px', fontSize: '15px' }}>შემადგენელი მოხატულობები</div>
                                                    <ObjectListViewPart objID={forecast.id} imgClassName="ImageDivWallArt" type="wallart" />
                                                </div>
                                                : forecast.objType === "stoneengrave" ? <div >
                                                    <div style={{ textAlign: "left", padding: '0 10px 20px 10px', fontSize: '15px' }}>შემადგენელი კვეთილობები</div>
                                                    <ObjectListViewPart objID={forecast.id} imgClassName="ImageDivWallArt" type="stoneengrave" />
                                                </div>
                                                    : <></>
                                        }

                                    </div>
                                )}
                            </CardGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>


        );
    }


    render() {

        const params = new URLSearchParams(window.location.search);

        const { pager, pageOfItems } = this.state;
        return (
            <div className="card text-center">
                <div className="card-body">
                    {
                        this.state.loading || this.props.type !== this.state.type
                            ?
                            //<p><em>Loading...</em></p>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center' }}>
                                <PulseLoader
                                    color={"#E57959"}
                                    loading={true}
                                />
                            </div>
                            : this.drawCollectionList(this.state.collection)
                    }

                </div>

            </div>
        );
    }
}

export { Favorits };