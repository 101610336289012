import React, { Component } from 'react';
import './Object.css';
import { Row, Col, Accordion, Card } from 'react-bootstrap'
import { WebMapView } from '../WebMapView';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { CustomToggle } from '../custom/CustomToggle'
import { ObjectListForLinks } from '../ObjectListForLinks';
import { ObjectListForLinksTable } from '../ObjectListForLinksTable';
import { DocObjectListForLinksTable } from '../DocObjectListForLinksTable';
import { EventObjectListForLinksTable } from '../EventObjectListForLinksTable';
import { FullDescModal } from './FullDescModal';


import { Gallery } from './Gallery';
import PulseLoader from "react-spinners/PulseLoader";
import Avatar from 'react-avatar';
import { Footer } from '../Footer';
import ReactTooltip from "react-tooltip";

import { authHeader, handleResponse } from '../../_helpers';
import { authenticationService } from '../../_services';

import { AddToCollection } from '../AddToCollection';
import { Share } from '../Share';

export class MovableObject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            isAdmin: false,
            favorite: false,
            movable: {},
            objectID: null,
            objToZoom: null,
            fullScreeenIcon: "esri-icon-zoom-out-fixed",
            mapClass: '',
            maptype: '',
            mapObjectID: '',
            loading: true
        };

        this.toogleMapActiveLink = this.toogleMapActiveLink.bind(this)
        this.fullScreenMode = this.fullScreenMode.bind(this)
    }
    componentDidMount() {
        var el = document.querySelector('.searchComponentButton');
        el.textContent = "მოძრავი ძეგლი";

        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x, isAdmin: x && x.role === "Admin"
        }));
        this.loadPage();

    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const id = parseInt(params.get('id'));
        if (id !== null) {
            var type = `api/Movable/GetMovableObject?id=${id}`;
           
            if (this.state.currentUser != null) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                const response = await fetch(type, requestOptions);
                const data = await response.json();
                this.setState({ movable: data, loading: false, favorite: data.isFavorite, maptype: data.curLocId !== "" ? data.curLocType : data.oldLocType, mapObjectID: data.curLocId !== "" ? data.curLocId : data.oldLocId, objectID: data.curLocId !== "" ? data.curLocId : data.oldLocId });

            }
            else {
                const response = await fetch(type);
                const data = await response.json();
                this.setState({ movable: data, loading: false, favorite: data.isFavorite, maptype: data.curLocId !== "" ? data.curLocType : data.oldLocType, mapObjectID: data.curLocId !== "" ? data.curLocId : data.oldLocId, objectID: data.curLocId !== "" ? data.curLocId : data.oldLocId });
            }
        }
    }

    toogleFullDesc() {
        if (document.getElementsByClassName('DescTextFull')[0].classList.contains('displayNone')) {
            document.getElementsByClassName('DescTextFull')[0].classList.remove('displayNone');
            document.getElementsByClassName('DescText')[0].classList.add('displayNone');
        }
        else {
            document.getElementsByClassName('DescTextFull')[0].classList.add('displayNone');
            document.getElementsByClassName('DescText')[0].classList.remove('displayNone');
        }

    }


    toogleMapActiveLink(val) {
        document.getElementsByClassName('mapLink')[0].classList.remove('active');
        document.getElementsByClassName('mapLink')[1].classList.remove('active');

        document.getElementsByClassName('mapLink')[val].classList.add('active');


        if (val === 0)
            this.setState({
                "maptype": this.state.movable.curLocType, "mapObjectID": this.state.movable.curLocId
            })
        else this.setState({
            "maptype": this.state.movable.oldLocType, "mapObjectID": this.state.movable.oldLocId
        })

        document.getElementsByClassName('mapCoord')[0].classList.add('displayNone');
        document.getElementsByClassName('mapCoord')[1].classList.add('displayNone');
        document.getElementsByClassName('mapCoord')[val].classList.remove('displayNone');
    }
    static displayName = MovableObject.name;

    addToFavorite(id) {
        if (this.state.favorite === false) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            fetch(`api/Users/AddToFvorite?folderID=0&objectType=movable&objectID=${id}`, requestOptions)
                .then(data => {
                    this.setState({ favorite: true });
                });
        }
        else {
            var result = window.confirm("გსურთ წაშლა?");
            if (result) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                return fetch(`api/Users/DeleteFromFvorite?objectType=movable&objectID=${id}`, requestOptions)
                    .then(data => {
                        this.setState({ favorite: false });
                    });
            }
        }
    }
    hadleFavoriteStatus(value) {
        this.setState({ favorite: value });
    }

    fullScreenMode() {
        if (this.state.mapClass === '') {
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-in-fixed', mapClass: 'objectFullScreentMap', objectID: null, objToZoom: this.state.mapObjectID })
        }
        else {

            document.getElementById('toolbarDiv').classList.add('displayNone');
            document.getElementById('menuDiv').classList.add('displayNone')
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-out-fixed', mapClass: '', objectID: this.state.mapObjectID, objToZoom: null })
        }
    }

    render() {
        const movable = this.state.movable;

        let images = [];
        if (movable.photoIds != null) {
            movable.photoIds.map(item =>
                images.push({
                    original: item,
                    thumbnail: item
                })
            );
        }
        else {
            images.push({
                original: '/movable.svg',
                thumbnail: '/movable.svg'
            });
        }

        window.FB = null;
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/ka_GE/sdk.js#xfbml=1&autoLogAppEvents=1&version=v8.0&appId=1607074352807965";
            js.nonce = "q90Xb87m";

            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));


        return (
            <div className="immObjDiv">
                {movable && movable.id > 0 ?
                    <div>
                        <div className="containerDiv">
                            <Row className="rowPadding">
                                <Col md={5}>
                                    <Gallery photo={movable.photoIds} video={movable.videoIds} vid3D={movable._3dobjects} mainphoto={movable.photoAddress} type="movable" authors={movable.docAuthors} />
                                </Col>
                                <Col md={7}>
                                   
                                    <div className="ObjectType">მოძრავი ძეგლი</div>
                                    <div className="ObjectMainHeader">
                                       
                                        <span className="ObjectMainHeaderSpan">{movable.objectName}{movable.originali ? '(' + movable.originali + ')' : ''} - #{movable.id}</span>
                                        <Share header="გაზიარება" customClass="objectShare" />
                                        {this.state.currentUser && this.state.isAdmin === false &&
                                            //<div style={{ float: 'right', cursor: 'pointer' }}><img width="20" alt="" src={this.state.favorite ? "/images/heartfill.svg" : "/images/heart.svg"} style={{ marginRight: '10px' }} onClick={() => this.addToFavorite(immovable.id)} />{' '}</div>
                                            <AddToCollection favorite={this.state.favorite} objID={movable.id} setStatus={this.hadleFavoriteStatus.bind(this)}></AddToCollection>
                                        }
                                    </div>
                                    <div style={{ marginBottom: '20px' }}>{movable.variety}</div>
                                    {
                                        movable.adgilmdebareobaType !== '' ?
                                            <div><span className="headerText">ადგილმდებარეობა: </span>{movable.adgilmdebareobaType}</div>
                                            : <></>
                                    }
                                    <div><span className="headerText">პერიოდი: </span>
                                        <ul className="periodList">
                                            {
                                                movable.period.split(';').map((item, i) => {
                                                    return <li key={i}>{item}</li>;
                                                })
                                            }</ul></div>
                                    <div><span className="headerText">თარიღი: </span>{movable.date}</div>
                                    <div><span className="headerText">შექმნის ადგილი: </span>{movable.creationPlace}</div>
                                    <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '20px' }} />
                                    <div className="DescText">
                                        {movable.shortDesc}
                                        <div onClick={this.toogleFullDesc} className="toggleFullDesc">
                                            <svg className="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 01.708 0L8 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 01.708 0L8 8.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="DescTextFull displayNone">{movable.shortDescFull}
                                        <div onClick={this.toogleFullDesc} style={{ textAlign: "center", padding: "20px" }}>
                                            <svg className="bi bi-chevron-double-up" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M7.646 2.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 3.707 2.354 9.354a.5.5 0 11-.708-.708l6-6z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M7.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div style={{ display: "inline-flex" }}>
                                        <FullDescModal className="FullDescModal" buttonDesc="დეტალური აღწერა" objname={movable.objectName} >
                                            <div style={{ marginBottom: '20px' }}>{movable.variety}</div>
                                            <div className="PopupHeader">მოკლე დახასიათება</div>
                                            {movable.shortDescFull}
                                            {(movable.forma || movable.istoriuliCnobebi || movable.style || movable.janri || movable.size) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0" className="accordionNoshadow">
                                                        <Card className="modelCard">
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">აღწერა</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {movable.forma ?
                                                                        <><div>ფორმა</div><div className="headerText">{movable.forma}</div></>
                                                                        : <></>
                                                                    }
                                                                    {movable.istoriuliCnobebi ?
                                                                        <><div>ნივთთან დაკავშირებული ისტორიული ცნობები</div><div className="headerText">{movable.istoriuliCnobebi}</div></>
                                                                        : <></>
                                                                    }
                                                                    {movable.style ?
                                                                        <><div>სტილი</div><div className="headerText">{movable.style}</div></>
                                                                        : <></>
                                                                    }
                                                                    {movable.janri ?
                                                                        <><div>ჟანრი</div><div className="headerText">{movable.janri}</div></>
                                                                        : <></>
                                                                    }
                                                                    {movable.size ?
                                                                        <><div>ზომა</div><div className="headerText">{movable.size}</div></>
                                                                        : <></>
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {
                                                (movable.masala || movable.technica) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0" className="accordionNoshadow">
                                                        <Card className="modelCard">
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">მასალა და ტექნიკა</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {movable.masala ?
                                                                        <>
                                                                            <div>მასალა</div>
                                                                            <div style={{ display: 'inline-block' }}><ul className="ulNoPoint">
                                                                                {
                                                                                    movable.masala.split(';').map((item, i) => {
                                                                                        return <li key={i} className="headerText" style={{ float: 'left', marginRight: '20px', fontSize: '14px' }}>{item}</li>;
                                                                                    })
                                                                                }

                                                                            </ul></div>
                                                                        </>
                                                                        : <></>
                                                                    }
                                                                    {movable.technica ?
                                                                        <>
                                                                            <div>ტექნიკა</div>
                                                                            <div style={{ display: 'inline-block' }}><ul className="ulNoPoint">
                                                                                {
                                                                                    movable.technica.split(';').map((item, i) => {
                                                                                        return <li key={i} className="headerText" style={{ float: 'left', marginRight: '20px', fontSize: '14px' }}>{item}</li>;
                                                                                    })
                                                                                }
                                                                            </ul></div>
                                                                        </>
                                                                        : <></>
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {(movable.author) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0" className="accordionNoshadow">
                                                        <Card className="modelCard">
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">ავტორი, თანავტორი, ავტორთა ჯგუფი</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {movable.author ?
                                                                        <div style={{ display: 'inline-block' }}><ul className="ulNoPoint">
                                                                            {
                                                                                movable.author.split(';').map((item, i) => {
                                                                                    return <li key={i} className="headerText" style={{ fontSize: '14px' }}>{item}</li>;
                                                                                })
                                                                            }
                                                                        </ul></div>
                                                                        : <></>
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {
                                                (movable.warwera) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Card>
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">წარწერა</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {
                                                                        movable.warwera.map((item, i) =>
                                                                            <Row key={i}>
                                                                                <Col>
                                                                                    <div>ენა: </div><div className="headerText">{item.split(';')[0]}</div>
                                                                                    <div>თარიღი: </div><div className="headerText">{item.split(';')[1]}</div>
                                                                                    <div>ტექსტი: </div><div className="headerText">{item.split(';')[2]}</div>
                                                                                    <p style={{ borderBottom: '1px solid #DBDBDB', marginTop: '20px' }} />
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {(movable.symbol) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Card>
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">ნიშნები/სიმბოლოები</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {
                                                                        movable.symbol.map((item, i) =>
                                                                            <Row key={i}>
                                                                                <Col>
                                                                                    <div>შესრულების ტექნიკა: </div><div className="headerText">{item.split(';')[0]}</div>
                                                                                    <div>მდებარეობა: </div><div className="headerText">{item.split(';')[1]}</div>
                                                                                    <div>აღწერა: </div><div className="headerText">{item.split(';')[2]}</div>
                                                                                    <p style={{ borderBottom: '1px solid #DBDBDB', marginTop: '20px' }} />
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {(movable.decor) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Card>
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">შემკულობა/დეკორი</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {
                                                                        movable.decor.map((item, i) =>
                                                                            <Row key={i}>
                                                                                <Col>
                                                                                    <div>შემკულობის ტიპი/ტექნიკა: </div><div className="headerText">{item.split(';')[0]}</div>
                                                                                    <div>შემკულობის მასალა: </div><div className="headerText">{item.split(';')[1]}</div>
                                                                                    <div>აღწერა: </div><div className="headerText">{item.split(';')[2]}</div>
                                                                                    <p style={{ borderBottom: '1px solid #DBDBDB', marginTop: '20px' }} />
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                            {
                                                (movable.gaformeba) ?
                                                <Row style={{ padding: '10px' }}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Card>
                                                            <Card.Header className="TogglePanelHeader">
                                                                <CustomToggle eventKey="1">"გაფორმება/კონსტრუციულდეკორატიული ელემენტები</CustomToggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    {
                                                                        movable.gaformeba.map((item, i) =>
                                                                            <Row key={i}>
                                                                                <Col>
                                                                                    <div>გაფორმება/კონსტრუციულდეკორატიული ელემენტები: </div><div className="headerText">{item.split(';')[0]}</div>
                                                                                    <div>გაფორმების მასალა: </div><div className="headerText">{item.split(';')[1]}</div>
                                                                                    <div>გაფორმების ტექნიკა: </div><div className="headerText">{item.split(';')[2]}</div>
                                                                                    <div>აღწერა: </div><div className="headerText">{item.split(';')[3]}</div>
                                                                                    <p style={{ borderBottom: '1px solid #DBDBDB', marginTop: '20px' }} />
                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    }
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Row>
                                                : <></>
                                            }
                                        </FullDescModal>
                                    </div>
                                </Col>
                            </Row>
                            <div className="ObjectHeaderNoTop" style={{ marginLeft: '35px', marginTop: '20px' }}>ადგილმდებარეობა</div>
                            <Row style={{ padding: '20px 20px 0 20px', width: '100%' }} className="ObjectMapRow">
                                <Col md={5}>
                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                        <div>
                                            <div>ამჟამინდელი ადგილმდებარეობა: <span className="headerText">{movable.adgilmdebareoba}</span></div>
                                            <div>ქვეყანა: <span className="headerText">{movable.country}</span></div>
                                            {movable.region !== '' ? <div>რეგიონი: <span className="headerText">{movable.region}</span></div> : <></>}
                                            {movable.municipality !== '' ? <div>მუნიციპალიტეტი: <span className="headerText">{movable.municipality}</span></div> : <></>}
                                            {movable.settlement !== '' ? <div>დასახლებული პუნქტი: <span className="headerText">{movable.settlement}</span></div> : <></>}
                                            {movable.street !== '' ? <div>მისამართი: <span className="headerText">{movable.street}</span></div> : <></>}
                                        </div>
                                    </div>

                                    {(movable.orgcountry !== '' || movable.orgregion !== '' || movable.orgmunicipality !== '' || movable.orgsettlement !== '' || movable.orgstreet !== '' || movable.discoverPlace !== '') ?
                                        <div style={{ display: 'inline-flex', marginTop: '20px', width: '100%' }}>
                                            <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                            <div>
                                                <div>წარმომავლობა:</div>
                                                {movable.orgcountry !== '' ? <div>ქვეყანა: <span className="headerText">{movable.orgcountry}</span></div> : <></>}
                                                {movable.orgregion !== '' ? <div>რეგიონი: <span className="headerText">{movable.orgregion}</span></div> : <></>}
                                                {movable.orgmunicipality !== '' ? <div>მუნიციპალიტეტი: <span className="headerText">{movable.orgmunicipality}</span></div> : <></>}
                                                {movable.orgsettlement !== '' ? <div>დასახლებული პუნქტი: <span className="headerText">{movable.orgsettlement}</span></div> : <></>}
                                                {movable.orgstreet !== '' ? <div>მისამართი: <span className="headerText">{movable.orgstreet}</span></div> : <></>}
                                                {movable.discoverPlace !== '' ? <div>აღმოჩენის ადგილი: <span className="headerText">{movable.discoverPlace}</span></div> : <></>}
                                            </div>
                                        </div>
                                        : <></>
                                    }

                                </Col>
                                <Col md={7} style={{ minHeight: '300px' }} className="ObjectMap movableMap">
                                    <div style={{ display: "inline-block", margin: '0 10px 10px 0px' }}>
                                        <button className={movable.curLocId !== "" ? 'mapLink active' : 'mapLink not-active'} onClick={() => this.toogleMapActiveLink(0)} disabled={(movable.curLocId === "")}>ამჟამინდელი</button>
                                        <button className={movable.curLocId !== "" ? 'mapLink' : movable.oldLocId !== "" ? 'mapLink active' : 'mapLink not-active'} onClick={() => this.toogleMapActiveLink(1)} style={{ marginLeft: "20px" }} disabled={(movable.oldLocId === "")}>წარმომავლობა</button>
                                    </div>
                                    <WebMapView type={this.state.maptype} objectID={this.state.objectID} objToZoom={this.state.objToZoom} right="30px" fullScreeenIcon={this.state.fullScreeenIcon} fullClick={this.fullScreenMode.bind(this)} mapClass={this.state.mapClass} />

                                </Col>
                            </Row>
                            <Row>
                                <Col md={5}>
                                    
                                </Col>
                                <Col md={7}>
                                    <Row className={movable.curLocId !== "" ? 'mapCoord' : 'mapCoord displayNone'}>
                                        <Col md={5} style={{ marginBottom: '20px' }}>
                                            <div className="icon-waves">
                                                სიმაღლე ზღვის დონიდან: {movable.elevetion}მ.
                                        </div>
                                        </Col>
                                        <Col md={6} style={{ marginBottom: '20px' }}>
                                            <div className="icon-direction-outline">კოორდინატები {movable.sizuste === 'სწორია' ? <img style={{ width: '17px', height: '17px' }} alt="" src="/images/OK.svg" /> : <></>}
                                            </div>
                                            {movable.sizuste === 'სწორია' ?
                                                <div>
                                                    <span className="headerText">X: </span>{movable.x}
                                                    <span className="headerText"> Y: </span>{movable.y}
                                                </div> :
                                                <div>
                                                    კოორდინატები დასაზუსტებელია
                                                </div>
                                            }
                                        </Col>
                                    </Row>

                                    <Row className={movable.curLocId !== "" ? 'mapCoord displayNone' : 'mapCoord'}>
                                        <Col md={5} style={{ marginBottom: '20px' }}>
                                            <div className="icon-waves">
                                                სიმაღლე ზღვის დონიდან: {movable.elevetionOld}მ.
                                        </div>
                                        </Col>
                                        <Col md={6} style={{ marginBottom: '20px' }}>
                                            <div className="icon-direction-outline">კოორდინატები {movable.sizusteOld === 'სწორია' ? <img style={{ width: '17px', height: '17px' }} alt="" src="/images/OK.svg" /> : <></>}
                                            </div>
                                            {movable.sizusteOld === 'სწორია' ?
                                                <div>
                                                    <span className="headerText">X: </span>{movable.xOld}
                                                    <span className="headerText"> Y: </span>{movable.yOld}
                                                </div> :
                                                <div>
                                                    კოორდინატები დასაზუსტებელია
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="orangeRow">
                            <div className="containerDiv" style={{ paddingLeft: '30px' }}>
                                <Table className="objectTable">
                                    <Thead>
                                        <Tr className="tableColHeader">
                                            <Th style={{ width: '40%', paddingLeft: '10px' }}>ფუნქცია, ტიპი</Th>
                                            <Th style={{ width: '30%', paddingLeft: '10px' }}>ავტორი</Th>
                                            <Th style={{ width: '30%', paddingLeft: '10px' }}>საინვენტარო ნომერი</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr className="tableRow" >
                                            <Td className="headerText" style={{ padding: '0 10px 10px 10px' }}>
                                                <span>
                                                    <ul className="periodList">{
                                                        movable.function.split(';').map((item, i) => {
                                                            return <li key={i}>{item}</li>;
                                                        })
                                                    }</ul>
                                                </span>
                                            </Td>
                                            <Td className="headerText" style={{ padding: '0 10px 10px 10px' }}>
                                                <span> {movable.author}</span>
                                            </Td>
                                            <Td className="headerText" style={{ padding: '0 10px 10px 10px' }}>
                                                <span> {movable.inventoryNum}</span>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="containerDiv">
                            <Row className="accardionPadding">
                                <div className="ObjectHeader">სტატუსი</div>
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey={movable.statusID === 1 ? '0' : '1'} className={movable.statusID === 1 ? 'not-active' : ''} >
                                                <span className="headerText">{movable.status}</span>
                                            </CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>

                                                <Table className="objectTable">
                                                    <Thead>
                                                        <Tr className="tableColHeaderBlue">
                                                            <Th style={{ width: '30%', padding: '10px' }}>თარიღი</Th>
                                                            <Th style={{ width: '30%', padding: '10px' }}>დოკუმენტის ნომერი</Th>
                                                            <Th style={{ width: '40%', padding: '10px' }}>მიმნიჭებელი ორგანიზაცია</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <Tr className="tableRow">
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{movable.statusDate}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{movable.statusDocNum}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{movable.statusOrg}</span>
                                                            </Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>
                                                <Row style={{ padding: '20px', width: '100%' }}>
                                                    <div><a href={movable.statusDocLink} target="_blank" rel="noopener noreferrer" className={`DocLink ${(movable.statusDocLink === "" || movable.statusDocLink === null) ? "not-active" : ""}`} onClick={(movable.statusDocLink === "" || movable.statusDocLink === null) ? (event) => event.preventDefault() : null} >დოკუმენტის ნახვა</a></div>
                                                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Row>

                            {movable.statusChange ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">სტატუსის ცვლილების ისტორია</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={movable.id} type="event" objType="movableStatus" idList={movable.statusChangeLinks} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }

                            <Row className="accardionPadding">
                                <div className="ObjectHeader">კატეგორია</div>
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey={movable.categoryID === 1 ? '0' : '1'} className={movable.categoryID === 1 ? 'not-active' : ''} >
                                                <span className="headerText">{movable.category}</span>
                                            </CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <Table className="objectTable">
                                                    <Thead>
                                                        <Tr className="tableColHeaderBlue">
                                                            <Th style={{ width: '30%', padding: '10px' }}>თარიღი</Th>
                                                            <Th style={{ width: '30%', padding: '10px' }}>დოკუმენტის ნომერი</Th>
                                                            <Th style={{ width: '40%', padding: '10px' }}>მიმნიჭებელი ორგანიზაცია</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <Tr className="tableRow" >
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{movable.categoryDate}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{movable.categoryDocNum}</span>
                                                            </Td>
                                                            <Td className="headerText" style={{ padding: '10px' }}>
                                                                <span>{movable.categoryOrg}</span>
                                                            </Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>

                                                <Row style={{ padding: '20px', width: '100%' }}>
                                                    <div><a href={movable.categoryDocLink} rel="noopener noreferrer" target="_blank" className={`DocLink ${(movable.categoryDocLink === "" || movable.categoryDocLink === null) ? "not-active" : ""}`} onClick={(movable.categoryDocLink === "" || movable.categoryDocLink === null) ? (event) => event.preventDefault() : null} >დოკუმენტის ნახვა</a></div>
                                                </Row>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Row>

                            {movable.categoryChange ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">კატეგორიის ცვლილების ისტორია</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={movable.id} type="event" objType="movableCategory" idList={movable.categoryLinks} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        </div>
                        {
                            movable.linksDisplay ? <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '80px' }} /> : <></>

                        }
                        <div className="containerDiv">
                            <div className="accardionPadding ObjectHeaderNoTop">{movable.linksDisplay ? 'დაკავშირებული კომპონენტები' : ''}</div>
                            {movable.movableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მოძრავი ძეგლი/ობიექტი {movable.movableLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={movable.id} type="movable" objType="movable" idList={movable.movableLinks} objName={movable.objectName}/>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {movable.immovableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">უძრავი ძეგლი/ობიექტი {movable.immovableLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={movable.id} type="immovable" objType="movable" idList={movable.immovableLinks} objName={movable.objectName}/>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {movable.museumLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მუზეუმ-ნაკრძალი/მუზეუმი idList={movable.museumLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={movable.id} type="museum" objType="movable" idList={movable.museumLinks} objName={movable.objectName}/>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {
                                movable.eventLinkExist ?
                                    <Row className="accardionPadding">
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Card.Header className="TogglePanelHeader">
                                                    <CustomToggle eventKey="1"><span className="headerText">ქმედება idList={movable.eventLinksCount}</span></CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body className="accardionBodyPadding">
                                                        {
                                                            <EventObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={movable.id} type="event"
                                                                objType="movable" idList={movable.eventLinks} objName={movable.objectName} />
                                                        }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Row>
                                    : <></>
                            }
                            {movable.docLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">დოკუმენტაცია {movable.docLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <DocObjectListForLinksTable caption={{ type: "დოკუმენტის ტიპი", docNum: "ავტორი" }} objID={movable.id} type="document"
                                                        objType="movable" idList={movable.docLinks} objName={movable.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        </div>

                        <Footer>
                            <div className="containerDiv" style={{ paddingTop: '40px', paddingLeft: '20px' }}>
                                <Row >
                                    <Col md={5}>
                                        <div style={{ fontFamily: 'HelveticaBold', fontSize: '16px' }}>ინფორმაციის შემდგენელი</div>
                                        <div style={{ marginBottom: '20px' }}>თარიღი: <span className="headerText">{movable.cardCreationDate}</span></div>
                                        <div style={{ display: 'inline-flex' }}>
                                            <Avatar title={movable.cardCreator[0].name} src={movable.cardCreator[0].photo} size={50} round="50px" />
                                            <span className="avatarText" data-tip data-for="allAuthors">{movable.cardCreator.length > 1 ? `${movable.cardCreator[0].name} \n\r(+ ${movable.cardCreator.length - 1} ავტორი)`
                                                : movable.cardCreator[0].name}</span>
                                        </div>
                                        {movable.cardCreator.length > 1 ?
                                            <ReactTooltip className="customZIndex" id="allAuthors" effect='solid' backgroundColor="#FFFFFF" textColor="#000000" place="bottom" border={true} offset={{ top: 70, left: -35 }}>
                                                <span>
                                                    <ul className="periodList">{
                                                        movable.cardCreator.map((item, i) => {
                                                            return <div style={{ marginTop: '5px' }} key={i}> <Avatar title={item.name} src={item.photo} size={30} round="30px" />
                                                                <span style={{ marginLeft: '10px' }}>{item.name}</span></div>
                                                                ;
                                                        })
                                                    }</ul>
                                                </span>
                                            </ReactTooltip>
                                            : <></>
                                        }
                                    </Col>
                                    <Col md={7}>
                                        <p style={{ borderBottom: '1px solid #ECECEC' }} className="facebookCOmmentP" />
                                        <div className="fb-comments" data-href={window.location.href} data-numposts="5" data-width="" data-mobile="true"></div>
                                    </Col>
                                </Row>
                            </div>
                        </Footer>
                    </div>
                    : <div style={{
                        marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center'
                    }}>
                        <PulseLoader
                            color={"#E57959"}
                            loading={true}
                        />
                    </div>

                }
            </div>
        );
    }

}