import React, { Component } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'



export class ObjectListTable extends Component {
    static displayName = ObjectListTable.name;

    constructor(props) {
        super(props);
        this.state = { forecasts: [], loading: true };

        this.GetObjectData(this.props.value, this.props.count);


    }

    goToObject(link) {
        window.location = "../" + link;
    }

    drawImovableList(forecasts) {
        return (
            <Table className="TableList">
                <Thead>
                    <Tr className="tableColHeader" style={{ textAlign: 'left' }}>
                        <Th style={{ width: '45%', paddingLeft: '20px' }}>სახელწოდება</Th>
                        <Th style={{ width: '35%', paddingLeft: '15px' }}>ქმედების ტიპი</Th>
                        <Th style={{ width: '25%', paddingLeft: '15px' }}>ნომერი</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        forecasts.map(forecast =>
                            <Tr key={forecast.id} className="tableRow mainCard">
                                <Td className="tableCol">
                                    <a href={forecast.linkPath}>
                                        <span>{forecast.objectName} - #{forecast.id}</span>
                                    </a>
                                </Td>
                                <Td className="tableCol lightTD">
                                    <a href={forecast.linkPath}>
                                        <span>{forecast.variety}</span>
                                    </a>
                                </Td>
                                <Td className="tableCol">
                                    <a href={forecast.linkPath}>
                                        <span>{forecast.docNum}</span>
                                    </a>
                                    <a target='_blank' rel="noopener noreferrer" download href={forecast.fileAddress} style={{ float: 'right' }} className={`downloadImg IcoFont-download ${(forecast.fileAddress === "" || forecast.fileAddress === null) ? "not-active" : ""}`} onClick={(forecast.fileAddress === "" || forecast.fileAddress === null) ? (event) => event.preventDefault() : null}>  </a>
                                </Td>
                            </Tr>

                        )
                    }

                </Tbody>
            </Table>

        );
    }


    render() {

        return (

            this.state.loading
                ? <p><em>Loading...</em></p>
                : this.drawImovableList(this.state.forecasts)

        );
    }

    async GetObjectData(value, count) {
        var type;
        switch (value) {
            case "event":
                type = 'api/Event/GetEvent?count=' + count;
                break;
            default: type = '';
                break;
        }

        const response = await fetch(type);
        const data = await response.json();
        this.setState({ forecasts: data, loading: false });

    }
}
