import './CustomToggle.css';
import React, { useState } from 'react'
import { useAccordionToggle } from 'react-bootstrap'

function GetModal(props) {

    const [panelIndex, setPanelIndex] = useState()   

    const customOnClick = useAccordionToggle(props.eventKey, () => {
        setPanelIndex(props.eventKey === panelIndex ? null : props.eventKey)
    })

    const customClass = (props.className === null ? '' : props.className) + ((props.eventKey === panelIndex || props.isOpen) ? " panel-opened panel-title" : " panel-title")

    return (
        <div className={customClass} onClick = { customOnClick } >
            <div style={{ width: '85%', display:'inline-block' }}> {props.children}</div>
        </div>
    );
}

export class CustomToggle extends React.Component {
    render() {
        return (
            <GetModal eventKey={this.props.eventKey} className={this.props.className} isOpen={ this.props.isOpen }>
                {this.props.children}
            </GetModal>

        );
    }
}


