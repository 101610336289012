
import React, { Component } from 'react';
import './ImmovableList.css';
import Suggestions from './SearchSuggestions';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export class SearchTemplate extends Component {

    targetRef = React.createRef();
    targetElement = null;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            //forType: "all", 
            forType: "all",
            value: '',
            suggestions: []
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);

        this.handleClearClick = this.handleClearClick.bind(this);
        this.handlebodyClick = this.handlebodyClick.bind(this);
        this.handleSelected = this.handleSelected.bind(this);
        this.goTo = this.goTo.bind(this);
        this.checkType = this.checkType.bind(this);
    }
    componentDidMount() {
        this.targetElement = this.targetRef.current;
    }
    componentDidUpdate() {
        if (this.state.open) {
            document.addEventListener("click", this.handlebodyClick);
            document.addEventListener("keypress", this.onKeyPress);
        } else {
            document.removeEventListener("click", this.handlebodyClick);
            document.removeEventListener("keypress", this.onKeyPress);
        }

       
    }
    checkType() {
        var el = document.querySelector('.searchComponentButton');

        switch (el.textContent) {
            case "უძრავი ძეგლი":
                return "immovable";
            case "მოძრავი ძეგლი":
                return "movable";
            case "კედლის მხატვრობა":
                return "wallart";
            case "ქვაზე კვეთილობა":
                return "stoneengrave";
            case "ზოგადი დამცავი ზონა":
                return "zone";
            case "მუზეუმ-ნაკრძალი/მუზეუმი":
                return "museum";
            case "ქმედება":
                return "event";
            case "დოკუმენტაცია":
                return "document";
            default:
                return "all";

        }

    }

    getInfo = (_forType = 'all') => {

        fetch('api/Home/MainSearch?findText=' + this.state.value + '&objectType=' + this.checkType() + '&forType=' + _forType)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    suggestions: data, open: true, forType: _forType
                })

               // document.body.style.overflow = "hidden";
                disableBodyScroll(this.targetElement);


            })
            .catch(error => console.error(error));
    }

    handleInputChange = (event) => {
        this.setState({
            value: event.target.value
        }, () => {
            if (this.state.value && (this.state.value.length >= 3 || !isNaN(this.state.value))) {

                this.getInfo()

            } else {
                this.setState({
                    suggestion: [], open: false
                });
               // document.body.style.overflow = "auto";
                enableBodyScroll(this.targetElement);
            }
        })
    }

    onMouseDown = (event) => {
        var index = this.props.forHeader == null ? 1 : 0;
        event.target.placeholder = '';
        document.getElementsByClassName('autosugDiv')[index].classList.remove('autosugDivSearch')

        if (this.state.value && (this.state.value.length >= 3 || !isNaN(this.state.value))) {
            this.getInfo()
        }
        document.removeEventListener("click", this.handlebodyClick);
    }

    onBlur = (event) => {
        var index = this.props.forHeader == null ? 1 : 0;
        event.target.placeholder = 'საძიებო სიტყვა...';
        if (this.state.value === "")
            document.getElementsByClassName('autosugDiv')[index].classList.add('autosugDivSearch')
    };

    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.goTo(this.checkType() === 'all' ? this.state.forType : this.checkType(), this.state.value);
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handlebodyClick(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.open) {
                this.setState({
                    suggestion: [], open: false
                });
               // document.body.style.overflow = "auto";
                enableBodyScroll(this.targetElement);
            }
        }

    }

    handleClearClick() {
        var index = this.props.forHeader == null ? 1 : 0;
        document.getElementsByClassName('autosugDiv')[index].classList.add('autosugDivSearch')
        this.setState({
            value: '', suggestion: [], open: false
        });
       // document.body.style.overflow = "auto";
        enableBodyScroll(this.targetElement);
    }

    handleSelected(value) {
        this.getInfo(value)
    }

    onSubmit(e) {
        e.preventDefault();
        this.goTo(this.checkType() === 'all' ? this.state.forType : this.checkType(), this.state.value);
    }

    goTo(value, filterText) {

        var link = '';
        switch (this.checkType()) {
            case "immovable":
                link = '/objects/immovable?type=immovable&filterText=' + filterText
                
                break;
            case "movable":
                link = '/objects/movable?type=movable&filterText=' + filterText;
                break;
            case "zone":
                link = '/objects/zone?type=zone&filterText=' + filterText;
                break;
            case "museum":
                link = '/objects/museum?type=museum&filterText=' + filterText;
                break;
            case "wallart":
                link = '/objects/wallart?type=wallart&filterText=' + filterText;
                break;
            case "stoneengrave":
                link = '/objects/stoneengrave?type=stoneengrave&filterText=' + filterText;
                break;
            case "document":
                link = '/list/document?type=document&filterText=' + filterText;
                break;
            case "event":
                link = '/objects/event?type=event&filterText=' + filterText;
                break;
            default: link = '/objects/all?type=all&filterText=' + filterText + "&t=" + value;
                break;
        }
        //link = '/objects/all?type=all&filterText=' + filterText + "&t=" + value;
        window.location.href = link;
    }



    render() {

        let clearButton;
        if (this.state.value.length >= 1) {
            clearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleClearClick}
                />
            );
        }

        return (
            <div className="SearchContainer" ref={this.setWrapperRef}>
                <form className={this.props.forHeader == null ? 'ComponentSearchForm' : ''} onSubmit={this.onSubmit}>
                    <div className={`autosugDiv autosugDivSearch ${this.props.forHeader == null ? 'objectsuggestion' : 'headersuggestion'}`} style={{ display: "inline-flex", width: '100%', lineHeight: '30px' }}>
                        <input className="react-autosuggest__input"
                            placeholder="საძიებო სიტყვა..."
                            value={this.state.value}
                            onChange={this.handleInputChange}
                            onBlur={this.onBlur.bind(this)}
                            onClick={this.onMouseDown.bind(this)}
                            onKeyPress={this.onKeyPress.bind(this)}
                        />
                        <div className="close-icon-container">
                            {
                                clearButton
                            }
                        </div>

                        <div className={`react-autosuggest__suggestions-container ${this.state.open ? '' : 'displayNone'}`} >
                            {
                                this.state.suggestions.length > 0 ?
                                    <Suggestions results={this.state.suggestions} type={this.checkType()} handleSelected={this.handleSelected} forType={this.state.forType} goTo={this.goTo} ></Suggestions>
                                    : <></>
                            }
                        </div>
                    </div>
                </form>
            </div>
        );
    }

}