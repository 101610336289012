import React from 'react';
import { Modal } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import './Search.css';
import { CustomTreeSelect } from '../custom/CustomTreeSelect';
import Autosuggest from 'react-autosuggest';


const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = suggestion => (
    <div>
        {suggestion}
    </div>
);

const orggetSuggestionValue = orgsuggestions => orgsuggestions;
const orgrenderSuggestion = orgsuggestions => (
    <div>
        {orgsuggestions}
    </div>
);

export class DocumentDetailSearchModal extends React.Component {
    selectedDocumentType = [];
    tempselectedDocumentType = [];
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data:
            {
                id: '', name: '', org: '', docStartDate: '', docEndDate: '',
                documentType: [], tempselectedDocumentType: [], linkedObj: {}, fullText: ''
            },
            suggestions: [],
            orgsuggestions: []
        };

        this.handleClearClick = this.handleClearClick.bind(this);
        this.handleOrgClearClick = this.handleOrgClearClick.bind(this);
    }


    handleClose = () => this.setState({ show: false });
    handleShow = () => {
        this.setState({ show: true })

        if (sessionStorage.getItem("filterData") != null && sessionStorage.getItem("filterData") !== '') {
            var data = JSON.parse(JSON.parse(sessionStorage.getItem("filterData")));
            this.tempselectedDocumentType = data.tempselectedDocumentType;
            this.selectedDocumentType = data.documentType;
            this.setState({ data: data })
        }

    };

    onChange(event) {
        var data = this.state.data;
        switch (event.target.name) {
            case "id":
                data.id = parseInt(event.target.value) || ''
                break;
            case "docStartDate":
                data.docStartDate = parseInt(event.target.value) || ''
                break;
            case "docEndDate":
                data.docEndDate = parseInt(event.target.value) || ''
                break;
            default:
                break;
        }

        this.setState({ data: data });
    }
    handleSubmit(e) {
        e.preventDefault();

        this.prepareSubmit();

    }
    prepareSubmit() {
        var data = this.state.data;
        data.documentType = this.selectedDocumentType;
        data.tempselectedDocumentType = this.tempselectedDocumentType;

        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));

        this.searchData(data);
    }

    checkData(data) {
        if (data.id !== 0 || data.name !== '' || data.org !== '' || data.docStartDate !== 0 || data.docEndDate !== 0 ||
            data.documentType.length > 0 || data.tempselectedDocumentType.length > 0) {
            return true;
        }
        return false
    }

    searchData(data) {
        if (data.id === '') data.id = 0;
        if (data.docStartDate === '') data.docStartDate = 0;
        if (data.docEndDate === '') data.docEndDate = 0;
        if (this.checkData(data)) {
            fetch('api/Document', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response) => {
                this.props.filterResulthandle(response);
                this.handleClose();
            })
        }
        else {
            this.props.filterResulthandle('');
            this.handleClose();
        }
    }
    handleClearData() {
        var data = {
            id: '', name: '', org: '', docStartDate: '', docEndDate: '',
            documentType: [], tempselectedDocumentType: []
        };
        this.setState({ data: data, show: false })
        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));
        this.props.filterResulthandle('');
    }

    loopChild(item, tempList) {
        tempList.push(item.value);
        if (item.tempchild != null && item.tempchild.length > 0) {
            item.tempchild.map(i => this.loopChild(i, tempList))
        }
    }

    changeDocumentTypeHandle(currentNode, selectedNodes) {
        this.selectedDocumentType = [];
        this.tempselectedDocumentType = [];

        if (selectedNodes != null) {
            selectedNodes.forEach(item => {
                this.selectedDocumentType.push(item.value)
                this.loopChild(item, this.tempselectedDocumentType)
            });
        }
    }
    onKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.prepareSubmit();
        }
    }

    onNameChange = (event, { newValue }) => {
        var data = this.state.data;
        data.name = newValue;
        this.setState({
            data: data
        });
    };
    handleClearClick() {
        var data = this.state.data;
        data.name = '';
        this.setState({
            data: data
        });
    }

    onOrgChange = (event, { newValue }) => {
        var data = this.state.data;
        data.org = newValue;
        this.setState({
            data: data
        });
    };
    handleOrgClearClick() {
        var data = this.state.data;
        data.org = '';
        this.setState({
            data: data
        });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                suggestions: []
            }) :

            fetch('api/AutoComplete/Getrecord?value=' + value + "&component=document")
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        suggestions: data
                    })
                })
                .catch(error => console.error(error));
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onOrgSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                orgsuggestions: []
            }) :

            fetch('api/AutoComplete/GetDocOrg?value=' + value)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        orgsuggestions: data
                    })
                })
                .catch(error => console.error(error));
    };
    onOrgSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };


    render() {
        const suggestions = this.state.suggestions;
        const value = this.state.data.name

        const orgsuggestions = this.state.orgsuggestions;
        const org = this.state.data.org

        const inputProps = {
            value,
            onChange: this.onNameChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        let clearButton;
        if (value.length >= 1) {
            clearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleClearClick}
                />
            );
        }

        const orginputProps = {
            value: org,
            onChange: this.onOrgChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        let orgclearButton;
        if (value.length >= 1) {
            orgclearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleOrgClearClick}
                />
            );
        }

        return (
            <>
                <div className="showModal">
                    <button onClick={this.handleShow} className="MainButton icon-equalizer" >{this.props.children}</button>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose} className="SearchDialog">
                    <Modal.Header closeButton className="searchCloseModal">
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit.bind(this)} method="POST" style={{ height: '100%', paddingTop: '0px' }}>
                            <div className="searchDivContent">
                                <div className="FullGalleryHeader">სარეგისტრაციო ნომერი</div>
                                <input type="number" className="form-control searchDivRowInput" name="id" id="id" value={this.state.data.id} onChange={this.onChange.bind(this)} autoComplete="off" />
                                <div className="FullGalleryHeader searchDivRow">სახელწოდება</div>
                                <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        renderSuggestion={renderSuggestion}
                                        getSuggestionValue={getSuggestionValue}
                                        inputProps={inputProps} />
                                    <div className="close-icon-container">
                                        {clearButton}
                                    </div>
                                </div>
                                <div className="FullGalleryHeader searchDivRow" >დოკუმენტის ტიპი</div>
                                <CustomTreeSelect items={this.props.classificators.type} onChange={this.changeDocumentTypeHandle.bind(this)} selectedOption={this.state.data.documentType} />
                                <div className="FullGalleryHeader searchDivRow" >თარიღი (წელი)</div>
                                <div style={{ display: 'inline-flex' }} className="searchDivRowInput">
                                    <input autoComplete="off" type="number" className="form-control " name="docStartDate" id="docStartDate" value={this.state.data.docStartDate} onChange={this.onChange.bind(this)} />
                                    <span style={{ padding: '5px 10px' }}>-</span>
                                    <input autoComplete="off" type="number" className="form-control " name="docEndDate" id="docEndDate" value={this.state.data.docEndDate} onChange={this.onChange.bind(this)} />
                                </div>
                                <div className="FullGalleryHeader searchDivRow">ავტორი</div>
                                <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                    <Autosuggest
                                        suggestions={orgsuggestions}
                                        onSuggestionsFetchRequested={this.onOrgSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onOrgSuggestionsClearRequested}
                                        renderSuggestion={orgrenderSuggestion}
                                        getSuggestionValue={orggetSuggestionValue}
                                        inputProps={orginputProps} />
                                    <div className="close-icon-container">
                                        {orgclearButton}
                                    </div>
                                </div>
                            </div>
                            <div className="searchDiv">
                                <div onClick={this.handleClearData.bind(this)} className="NoFillSearchButton">გაუქმება</div>
                                <button type="submit" className="MainButton">ძიება</button>
                            </div>
                        </Form>
                    </Modal.Body>

                </Modal>
            </>
        );
    }
}

