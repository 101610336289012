import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { SearchTemplate } from './SearchTemplate';
import { ObjectListViewPart } from './ObjectListViewPart';

import { DetailSearchModal } from './Immovable/DetailSearchModal';
import { QuickAlertModal } from './User/QuickAlertModal';
import { MovableDetailSearchModal } from './Immovable/MovableDetailSearchModal';
import { MuseumDetailSearchModal } from './Immovable/MuseumDetailSearchModal';
import { ZoneDetailSearchModal } from './Immovable/ZoneDetailSearchModal';
import { WallDetailSearchModal } from './Immovable/WallDetailSearchModal';
import { StoneDetailSearchModal } from './Immovable/StoneDetailSearchModal';
import { EventDetailSearchModal } from './Immovable/EventDetailSearchModal';
import { DocumentDetailSearchModal } from './Immovable/DocumentDetailSearchModal';

import { Location } from './Location';

import { authenticationService } from '../_services';
import { authHeader } from '../_helpers';

//import './Home.css';
import PulseLoader from "react-spinners/PulseLoader";
import { ClearSearch } from './Immovable/ClearSearch';

import { drawPinOnMap, zoomToMapObject, filterMap, filterLoc, stopLoc, setPoint } from './WebMapView';
import $ from 'jquery';

class ObjectListView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pager: {},
            pageOfItems: [],
            loading: true,
            forType: 'all',
            newSearch: false,
            type: this.props.type,
            data: {},
            currentUser: null,
            isAdmin: false,
            isAdminG: false,
            showLocation: false
            // ,showOpacity : false
        };
        this.handleSelected = this.handleSelected.bind(this);
        this.filterResulthandle = this.filterResulthandle.bind(this);

        this.GetSearch = this.GetSearch.bind(this);
        this.chanegPageSession = this.chanegPageSession.bind(this);
        this.detailSearch = React.createRef();
        this.quickAlert = React.createRef();
    }

    componentDidMount() {

        const params = new URLSearchParams(window.location.search);

        const objType = params.get('type') || '';
        const objID = parseInt(params.get('objID')) || '';
        const objName = params.get('objName') || '';

        if (objType !== '' && objID !== '') {
            sessionStorage.setItem('objType', objType);
            sessionStorage.setItem('objID', objID);
            sessionStorage.setItem('objName', objName);
            sessionStorage.setItem('isLink', "true");
            sessionStorage.setItem('page', 1);
            this.getLinkedIds(objType, objID);
        }
        else {
            if (sessionStorage.getItem('sessionType') !== this.props.type) {
                sessionStorage.removeItem('filterData');
                sessionStorage.removeItem('locationfilterData');

                sessionStorage.setItem('page', 1);

                if (sessionStorage.getItem('isLink') !== "true") {
                    sessionStorage.clear();
                }
                else {
                    sessionStorage.removeItem('isLink');
                }
                sessionStorage.setItem('sessionType', this.props.type);
            }

            window.scrollTo(0, 0)

            authenticationService.currentUser.subscribe(x => this.setState({
                currentUser: x, isAdmin: x && x.role === "Admin", isAdminG: x && x.role === "AdminG",
            }));


            this.loadPage();
        }
    }

    async getLinkedIds(objType, objID) {
        var type;
        switch (this.props.type) {
            case "immovable":
                type = `api/Immovable/GetLinkedIDs?type=${objType}&obj=${objID}`;
                break;
            case "userAlert":
                type = `api/Immovable/GetLinkedUserAlertIDImmovable?obj=${objID}`;
                break;
            case "movable":
                type = `api/Movable/GetLinkedIDs?type=${objType}&obj=${objID}`;
                break;
            case "zone":
                type = `api/SafetyZone/GetLinkedIDs?type=${objType}&obj=${objID}`;
                break;
            case "museum":
                type = `api/Museum/GetLinkedIDs?type=${objType}&obj=${objID}`;
                break;
            case "wallart":
                type = `api/WallArt/GetLinkedIDs?type=${objType}&obj=${objID}`;
                break;
            case "stoneengrave":
                type = `api/StoneEngrave/GetLinkedIDs?type=${objType}&obj=${objID}`;
                break;
            case "event":
                type = `api/Event/GetLinkedIDs?type=${objType}&obj=${objID}`;
                break;
            default: type = '';
                break;
        }
        const response = await fetch(type);
        const data = await response.json();
        sessionStorage.setItem('filterResult', data.join());

        if (filterMap != null) {
            filterMap(this.props.type, data.join());
        }

        window.scrollTo(0, 0)
        this.loadPage();
    }

    componentDidUpdate() {
        this.loadPage();
    }

    createDataElement() {
        var data;
        switch (this.props.type) {
            case "immovable":
                data = {
                    id: '', name: '', eventStartDate: '', eventEndDate: '', docStartDate: '', docEndDate: '',
                    variety: [], status: [], category: [], worldHeritage: [], period: [], function: [], eventType: [], documentType: [], sizuste: [],
                    country: [], region: [], municipality: [], settlement: [], street: [],
                    tempselectedPeriods: [], tempselectedFunction: [], tempselectedEventType: [], tempselectedDocumentType: [], linkedObj: {}, fullText: ''
                    , gancxadebisMizaniArsebuli: false, gancxadebisMizaniMosalodneli: false, dazianebisAdgilmdebareoba: [], dazianebisXarisxi: [], dazianebisTipi: [], ganmcxadebeli: '', gancxadebisTariRi: ''
                };
                break;
            case "userAlert":
                data = {
                    id: '', name: '', eventStartDate: '', eventEndDate: '', docStartDate: '', docEndDate: '',
                    variety: [], status: [], category: [], worldHeritage: [], period: [], function: [], eventType: [], documentType: [], sizuste: [],
                    country: [], region: [], municipality: [], settlement: [], street: [],
                    tempselectedPeriods: [], tempselectedFunction: [], tempselectedEventType: [], tempselectedDocumentType: [], linkedObj: {}, fullText: ''
                };
                break;
            case "movable":
                data = {
                    id: '', name: '',
                    variety: [], status: [], category: [], period: [], function: [],
                    locationName: '',
                    country: [], region: [], municipality: [], settlement: [], street: [],
                    oldcountry: [], oldregion: [], oldmunicipality: [], oldsettlement: [], oldstreet: [],
                    author: '', tempselectedPeriods: [], tempselectedFunction: [], linkedObj: {}, fullText: ''
                };
                break;
            case "wallart":
                data = {
                    id: '', name: '', period: [],
                    araidentificirebuli: '',
                    iconografia: [],
                    ciuriZalni: [],
                    diakvnebi: [],
                    gamosaxuleba: [],
                    mamebi: [],
                    mociqulni: [],
                    saeroPirebi: [],
                    sqemebi: [],
                    scenebi: [],
                    wmindanebi: [],
                    winaswarmetyvelebi: [],
                    ornamenti: [],
                    tempselectedPeriods: [],
                    tempselectedsaeroPirebi: [],
                    tempselectedscenebi: [], linkedObj: {}, fullText: ''
                };
                break;
            case "stoneengrave":
                data = {
                    id: '', name: '', period: [],
                    araidentificirebuli: '',
                    geometrornamenti: [],
                    ciuriZalni: [],
                    cxovelebi: [],
                    gamosaxuleba: [],
                    mamebi: [],
                    mociqulni: [],
                    saeroPirebi: [],
                    frinvelebi: [],
                    scenebi: [],
                    wmindanebi: [],
                    winaswarmetyvelebi: [],
                    ornamenti: [],
                    tempselectedPeriods: [],
                    tempselectedsaeroPirebi: [],
                    tempselectedscenebi: [], linkedObj: {}, fullText: ''
                };
                break;
            case "zone":
                data = {
                    id: '', name: '',
                    variety: [],
                    region: [], municipality: [], settlement: [], linkedObj: {}, fullText: ''
                };
                break;
            case "museum":
                data = {
                    id: '', name: '',
                    region: [], municipality: [], settlement: [], street: [], linkedObj: {}, fullText: ''
                };
                break;
            case "event":
                data = {
                    id: '', name: '', org: '', eventStartDate: '', eventEndDate: '',
                    eventType: [], eventStatus: [],
                    country: [], region: [], municipality: [], settlement: [], street: [],
                    tempselectedEventType: [], linkedObj: {}, fullText: ''
                };
                break;
            case "document":
                data = {
                    id: '', name: '', org: '', docStartDate: '', docEndDate: '',
                    documentType: [], tempselectedDocumentType: [], linkedObj: {}, fullText: ''
                };
                break;
            default:
                return "";
        }

        return data;
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);

        var page = parseInt(sessionStorage.getItem('page')) || 1;
        page = (page === 0 ? 1 : page);

        var fileter = params.get('filterText') || '';
        var fileterStr = fileter == null || fileter === '' ? '' : '&filterText=' + fileter;

        var forType = params.get('t') || '';
        var forTypeStr = (forType == null || forType === '') ? '' : '&forType=' + forType;

        var ft = (forType == null || forType === '') ? 'all' : forType;

        var dataFilter = JSON.parse(JSON.parse(sessionStorage.getItem("filterData")));
        if (sessionStorage.getItem('objID') != null) {
            if (dataFilter == null) dataFilter = this.createDataElement();
            dataFilter.linkedObj = {};
            dataFilter.linkedObj.type = sessionStorage.getItem('objType');
            dataFilter.linkedObj.objID = sessionStorage.getItem('objID');
        }
        if (fileter !== '' && this.props.type !== 'all') {
            if (dataFilter == null) dataFilter = this.createDataElement();
            dataFilter.fullText = fileter;
        }

        if (sessionStorage.getItem('locationFilter') != null && sessionStorage.getItem('locationFilter') === 'on') {
            if (dataFilter == null) dataFilter = this.createDataElement();
        }
        var type;
        switch (this.props.type) {
            case "immovable":
                type = `api/Immovable/GetImmovableList?count=10&page=${page}` + fileterStr;
                break;
            case "userAlert":
                type = `api/Immovable/GetImmovableList?count=10&page=${page}` + fileterStr;
                break;
            case "movable":
                type = `api/Movable/GetMovableList?count=10&page=${page}` + fileterStr;
                break;
            case "zone":
                type = `api/SafetyZone/GetSafetyZoneList?count=10&page=${page}` + fileterStr;
                break;
            case "museum":
                type = `api/Museum/GetMuseumsList?count=10&page=${page}` + fileterStr;
                break;
            case "wallart":
                type = `api/WallArt/GetWallArtList?count=10&page=${page}` + fileterStr;
                break;
            case "stoneengrave":
                type = `api/StoneEngrave/GetStoneEngraveList?count=10&page=${page}` + fileterStr;
                break;
            case "event":
                type = `api/Event/GetEventList?count=10&page=${page}` + fileterStr;
                break;
            case "all":
                type = `api/Home/GetAllObject?count=10&page=${page}` + fileterStr + forTypeStr;
                break;
            case "userObject":
                const userid = params.get('id') || null;
                type = `api/Users/GetUserObjectList?count=10&page=${page}&user=${userid}`;
                break;

            default: type = '';
                break;
        }

        //var res = sessionStorage.getItem('filterResult') || '';

        var r = sessionStorage.getItem('filterResult') || '';
        var loc = sessionStorage.getItem('locationfilterResult') || '';

        var array1 = r !== '' ? r.split(',') : [];
        var array2 = loc !== '' ? loc.split(',') : [];

        var res = array1.length > 0 ? array1.filter(value => array2.includes(value) || array2.length === 0) : array2
        if (array2.length > 0 && res.length === 0) res = ['0'];

        res = res.join(',');

        if (page !== this.state.pager.currentPage || ft !== this.state.forType || this.state.newSearch === true || this.props.type !== this.state.type) {
            if (this.props.type !== 'all' && this.props.type !== 'userObject') {
                const response = await fetch(type, {
                    method: "POST",
                    body: JSON.stringify(res),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });

                const data = await response.json();
                this.setState({ pager: data, pageOfItems: data.aaData, loading: false, forType: ft, newSearch: false, type: this.props.type, data: dataFilter });

                const params = new URLSearchParams(window.location.search);
                const view = params.get('view') || '';
                if (view === 'map') {
                    window.history.pushState({}, document.title, window.location.pathname);
                    this.props.mapfullClick();
                }

            }
            else {
                if (this.props.type === 'all') {
                    const response = await fetch(type);
                    const data = await response.json();
                    this.setState({ pager: data, pageOfItems: data.aaData, loading: false, forType: ft, type: this.props.type });

                    const params = new URLSearchParams(window.location.search);
                    const view = params.get('view') || '';
                    if (view === 'map') {
                        window.history.pushState({}, document.title, window.location.pathname);
                        this.props.mapfullClick();
                    }
                }
                else {
                    if (this.state.isAdmin) {
                        const requestOptions = { method: 'GET', headers: authHeader() };
                        const response = await fetch(type, requestOptions);

                        const data = await response.json();
                        this.setState({ pager: data, pageOfItems: data.aaData, loading: false, forType: ft, type: this.props.type });

                    }

                }
            }
        }
    }


    getImgDivClass(type) {
        switch (type) {
            case "immovable": case "userObject":
                return "imgDivImmovable";
            case "movable":
                return "imgDivMovable";
            case "wallart":
                return "imgDivWallArt";
            case "stoneengrave":
                return "imgDivStoneEngrave";
            case "zone":
                return "imgDivZone";
            case "museum":
                return "imgDivMuseum";
            case "event":
                return "imgDivEvent";
            case "document":
                return "imgDivDocumentBig";
            case "all":
                return "";
            default:
                return "imgDivImmovable";
        }
    }

    setMapPoint(el) {
        if ((this.props.type === "all" && (this.state.forType === "all" || this.state.forType === "event")) || this.props.type === "event" || this.props.type === "userObject") {
        }
        else {
            //var id = el.currentTarget.attributes['dataid'].value;
            var mapid = el.currentTarget.attributes['mapid'].value;
            var maptype = el.currentTarget.attributes['maptype'].value;
            if (drawPinOnMap != null) {
                //drawPinOnMap(this.props.type, mapid);
                drawPinOnMap(maptype, mapid);
            }
        }
    }

    zoomToObject(el) {
        el.preventDefault();
        var mapid = el.currentTarget.attributes['mapid'].value;
        var maptype = el.currentTarget.attributes['maptype'].value;
        if (zoomToMapObject != null) {
            //zoomToMapObject(this.props.type !== "all" ? this.props.type : this.state.forType, mapID);
            zoomToMapObject(maptype, mapid);
        }
    }

    removeMapPoint(el) {
        var id = null;
        if (drawPinOnMap != null) {
            drawPinOnMap(this.props.type, id);
        }
    }
    //setShowOpacity(mapid) {
    //   // showOpacity = true;
    //    var item = mapid;
    //    $("#td_id").attr('class', 'newClass');

    //}
    setShowOpacity = (mapid) => {
        var item = mapid;
        $(".opacity10").toggleClass('opacity10 opacity05');
        $("[mapid=" + mapid + "]").toggleClass('opacity05 opacity10');
        //showOpacity = false;
        //this.setState({ showOpacity: false })
    }
    //setShowOpacity = () => {
    //    var item = "fd";
    //    //showOpacity = false;
    //    //this.setState({ showOpacity: false })
    //}
    setHideOpacity = (mapid) => {
        $(".opacity05").toggleClass('opacity05 opacity10');
        //$("[mapid=" + mapid + "]").toggleClass('opacity05 opacity10');

        //showOpacity = false;
        //this.setState({ showOpacity: false })
    }


    drawImovableList(forecasts) {
        window.scrollTo(0, 0)
        var el = document.getElementsByClassName('immListDiv');
        if (el != null && el.length > 0) el[0].scrollTo(0, 0);




        return (

            <CardGroup>
                {forecasts.map(forecast =>
                    <div id="opacityChange" key={forecast.objType + '_' + forecast.id} mapid={forecast.mapID} maptype={forecast.mapType}
                        onMouseEnter={this.setMapPoint.bind(this)}
                        className="opacity10"
                    >
                        <a href={'../' + forecast.linkPath} className="mainCard">
                            <Card className="cardHorizonalListView">
                                <div className="ObjectListViewImgContainer">
                                    <div className={`${(forecast.photoAddress === '' || (forecast.photoAddress.indexOf('.jpg') < 0 && forecast.photoAddress.indexOf('.png') < 0)) ? this.getImgDivClass(forecast.objType) : 'imgDivObjListView'}`}>
                                        {
                                            forecast.photoAddress == null || forecast.photoAddress === '' ?
                                                <></> :
                                                <img className="list_card-img" variant="top" src={forecast.photoAddress} alt="" />
                                        }
                                    </div>
                                </div>
                                <Card.Body className="objectList_card-body">

                                    <Card.Title className="objectList_card-title card-link" >{forecast.objectName} - #{forecast.id}</Card.Title>

                                    <div className="objectTypeList">{forecast.variety}</div>
                                    <Card.Text className="objectList_card-text icon-location-outline" >
                                        {forecast.address}
                                    </Card.Text>
                                    {
                                        ((this.props.type === "all" && (this.state.forType === "all" || this.state.forType === "event")) || this.props.type === "event" || forecast.mapID === 0 || forecast.mapID == null) ?
                                            <>
                                            </>
                                            :
                                            <>

                                                <div className="row">
                                                    <div className="viewOnMap" mapid={this.props.type !== 'userObject' ? forecast.mapID : forecast.fileAddress} maptype={forecast.mapType} onClick={this.zoomToObject.bind(this)}>რუკაზე ნახვა</div>



                                                    <div className="viewOnMapMobile" mapid={this.props.type !== 'userObject' ? forecast.mapID : forecast.fileAddress} maptype={forecast.mapType} onClick={(e) => { e.preventDefault(); this.props.handler(forecast.id) }}>რუკაზე ნახვა</div>
                                                </div>
                                            </>

                                    }
                                </Card.Body>
                            </Card>
                        </a>
                        <div >
                            {(this.state.currentUser == null ? "" : this.state.currentUser.role) == "Filler" || (this.state.currentUser == null ? "" : this.state.currentUser.role) == "AdminG"?
                                /*forecast.msoflioCategoryTypeID == null || forecast.msoflioCategoryTypeID === '' ?*/
                                forecast.objectName.includes("გელათის მონასტერი") ?
                                    
                                    <QuickAlertModal ref={this.quickAlert}
                                        filterResulthandle={this.filterResulthandle}
                                        classificators={this.state.pager.classificator}
                                        ObjectId={forecast.id}
                                        ObjectName={forecast.objectName}
                                        PhotoAddress={forecast.photoAddress}
                                        AdminG={this.state.currentUser == null ? "" : this.state.currentUser.role}
                                        AdminGancxadebebi={true}
                                        statementCount={forecast.userAlertCount}
                                        cancelDamxmareModal={this.cancelDamxmareModal}
                                        showOpacity={this.setShowOpacity}
                                        hideOpacity={this.setHideOpacity}
                                        MapID={forecast.mapID}
                                        MainPhoto={forecast.photoAddress}
                                        UserAlertList={forecast.userAlertObjList}
                                        kukushka={forecast.photoAddress}
                                    >
                                    </QuickAlertModal>
                                    : <></> 
                                :
                                ""
                            }
                        </div>
                        {
                            forecast.objType === "wallart"
                                ? <div >
                                    <div style={{ textAlign: "left", padding: '0 10px 20px 10px', fontSize: '15px' }}>შემადგენელი მოხატულობები</div>
                                    <ObjectListViewPart objID={forecast.id} imgClassName="ImageDivWallArt" type="wallart" />
                                </div>
                                : forecast.objType === "stoneengrave" ? <div >
                                    <div style={{ textAlign: "left", padding: '0 10px 20px 10px', fontSize: '15px' }}>შემადგენელი კვეთილობები</div>
                                    <ObjectListViewPart objID={forecast.id} imgClassName="ImageDivStoneEngrave" type="stoneengrave" />
                                </div>
                                    : <></>
                        }

                    </div>
                )}
            </CardGroup>
        );
    }

    showMap() {
        document.getElementsByClassName('listView')[0].classList.remove('PanelWidth');
        document.getElementsByClassName('mapView')[0].classList.remove('displayNone');
        if (document.getElementsByClassName('gotoLocButton').length > 0) {

            document.getElementsByClassName('gotoLocButton')[0].classList.remove('displayNone');
        }
        document.getElementsByClassName('openMapButton')[0].classList.add('displayNone');
        document.getElementsByClassName('ChangeStateButton')[0].classList.remove('displayNone');
    }

    showOnlyMap() {
        document.querySelector('.SplitPane').classList.add('CustomSplitPane');

        document.getElementsByClassName('gotoLocButton')[0].classList.add('displayNone');
        document.getElementsByClassName('ChangeStateButton')[0].classList.add('displayNone');
        // 
    }

    handleSelected(value) {
        const params = new URLSearchParams(window.location.search);
        var fileter = params.get('filterText') || '';
        var fileterStr = fileter === '' ? '' : '&filterText=' + fileter;
        var forTypeStr = '&t=' + value;

        window.location.search = "/?page=1&type=all" + fileterStr + forTypeStr;

    }



    filterResulthandle(result, forLoc) {
        if (this.props.type !== 'all') {

        }

        // if (sessionStorage.getItem('locationFilter') === 'on') {
        //if (this.props.type === 'immovable' || this.props.type === 'museum') {
        //    if (sessionStorage.getItem('filterResult') && sessionStorage.getItem('filterResult') !== '0') {
        //        sessionStorage.setItem('PrevfilterResult', sessionStorage.getItem('filterResult'));
        //    }
        //    sessionStorage.setItem('filterResult', result);
        //}

        //   }
        if (forLoc === true) {
            sessionStorage.setItem('locationfilterResult', result);
        }
        else {
            sessionStorage.setItem('filterResult', result);
        }
        if (filterMap != null) {
            filterMap(this.props.type, result);
        }

        this.setState({
            newSearch: true, loading: true
        });
    }


    GetSearch(isMob) {
        if (this.state.pager.classificator != null) {
            switch (this.state.type) {
                case "immovable":
                    if (isMob === 1) return <DetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator}></DetailSearchModal>;
                    return <DetailSearchModal ref={this.detailSearch} isAdmin={this.state.isAdminG} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator}><span className="FilterButtonText">ფილტრი</span></DetailSearchModal>;

                case "movable":
                    if (isMob === 1) return <MovableDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator} />;
                    return <MovableDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator}><span className="FilterButtonText">ფილტრი</span></MovableDetailSearchModal>;

                case "wallart":
                    if (isMob === 1) return <WallDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator} />;
                    return <WallDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator}><span className="FilterButtonText">ფილტრი</span></WallDetailSearchModal>;
                case "stoneengrave":
                    if (isMob === 1) return <StoneDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator} />;
                    return <StoneDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator}><span className="FilterButtonText">ფილტრი</span></StoneDetailSearchModal>;
                case "zone":
                    if (isMob === 1) return <ZoneDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator} />;
                    return <ZoneDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator}><span className="FilterButtonText">ფილტრი</span></ZoneDetailSearchModal>;
                case "museum":
                    if (isMob === 1) return <MuseumDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator} />;
                    return <MuseumDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator}><span className="FilterButtonText">ფილტრი</span></MuseumDetailSearchModal>;
                case "event":
                    if (isMob === 1) return <EventDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator} />;
                    return <EventDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator}><span className="FilterButtonText">ფილტრი</span></EventDetailSearchModal>;
                case "document":
                    if (isMob === 1) return <DocumentDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator} />;
                    return <DocumentDetailSearchModal ref={this.detailSearch} filterResulthandle={this.filterResulthandle} classificators={this.state.pager.classificator}><span className="FilterButtonText">ფილტრი</span></DocumentDetailSearchModal>;
                default:
                    return "";
            }
        }
    }

    updatedataState(data) {
        this.setState({ data: data });
    }


    chanegPageSession(el) {
        var page = el.currentTarget.attributes['page'].value;
        var p = (page == null ? 1 : page);
        sessionStorage.setItem("page", p);
        this.loadPage();
    }

    location(el) {
        if (sessionStorage.getItem('locationFilter') === 'on') {


            var e = document.getElementById('locationBtn');
            if (e != null) e.classList.remove('active');

            var eM = document.getElementById('locationMobileMapBtn');
            if (eM != null) eM.classList.remove('active');

            // el.target.classList.remove('active');
            stopLoc();
        }
        else {
            if (filterLoc != null) {
                //el.target.classList.add('active');
                //filterLoc();

                this.setState({ showLocation: true })
            }
        }
    }


    hideLocationModal() {
        this.setState({ showLocation: false })
    }

    locationClick() {
        //document.getElementById('locationBtn').classList.add('active');
        if (filterLoc != null) {
            filterLoc();
            this.setState({ showLocation: false })
        }
    }

    render() {
        const { pager, pageOfItems } = this.state;

        if (this.state.isAdmin && this.state.isAdminG && this.state.type === "userObject") {
            if (setPoint != null) {
                //sessionStorage.removeItem('userPoints');
                pageOfItems.map(function (item) {
                    setPoint(item.fileAddress.split(';')[0], item.fileAddress.split(';')[1])
                    return { x: item.fileAddress.split(';')[0], y: item.fileAddress.split(';')[1] };
                });
            }
            else {

                var userPoints = [];
                userPoints = pageOfItems.map(function (item) {
                    return item.fileAddress;
                });

                sessionStorage.setItem('userPoints', userPoints.join(','));
            }
        }



        const params = new URLSearchParams(window.location.search);

        var fileter = params.get('filterText') || '';
        var fileterStr = fileter === '' ? '' : '&filterText=' + fileter;


        return (

            <div className="card text-center">
                <div className="ButtonsMenu">
                    <div className="MobileObjectSearch">
                        <SearchTemplate type={this.state.type}></SearchTemplate>
                        {
                            this.state.type !== "all" && pager.classificator != null && this.state.type === this.props.type && this.state.type !== "userObject" ?

                                this.GetSearch(1)

                                : <></>
                        }
                        {
                            <div>
                                <button id="locationBtn" className={`SecondaryButtonOrange ${sessionStorage.getItem('locationFilter') === 'on' ? 'active' : ''} icon-location-outline`} onClick={this.location.bind(this)} style={{ padding: '5px 0px 5px 15px', position: 'absolute', right: '30px' }}></button>
                                <Location showLocation={this.state.showLocation} onClick={this.locationClick.bind(this)} hideLocationModal={this.hideLocationModal.bind(this)} />
                            </div>
                        }
                    </div>

                    <div className="TotalButtons">
                        {
                            this.props.type === "immovable" || this.props.type === "museum" ?
                                <>
                                    <button id="locationBtn" className={`gotoLocButton SecondaryButtonOrange ${sessionStorage.getItem('locationFilter') === 'on' ? 'active' : ''} icon-location-outline`} onClick={this.location.bind(this)}>ჩემთან ახლოს</button>
                                    <Location showLocation={this.state.showLocation} onClick={this.locationClick.bind(this)} hideLocationModal={this.hideLocationModal.bind(this)} />
                                </>
                                : <></>
                        }
                        {
                            this.state.type !== "all" && pager.classificator != null && this.state.type === this.props.type && this.state.type !== "userObject" ?
                                this.GetSearch()
                                : <></>
                        }

                        <button className="openMapButton SecondaryButtonOrange displayNone" onClick={this.showMap}>რუკის ჩართვა</button>
                    </div>

                    {this.state.type === "all" ?
                        <div style={{ padding: '15px' }}>
                            <div className="searchTypes">კომპონენტები</div>
                            <div style={{ width: '100%', display: 'flex' }}>
                                <ul className="ResultContentUl">
                                    {pager.allCount > 0 ?
                                        <li >
                                            <Link to={{ search: `?page=1` + fileterStr + `&t=all` }}
                                                className={`page-link ResultRectangle ${this.state.forType === 'all' ? 'selected' : ''}`} >
                                                ყველა ობიქტი ({pager.allCount})
                                            </Link>
                                        </li> : <></>
                                    }
                                    {pager.immovableCount > 0 ?
                                        <li >
                                            <Link to={{ search: `?page=1` + fileterStr + `&t=immovable` }}
                                                className={`page-link ResultRectangle ${this.state.forType === 'immovable' ? 'selected' : ''}`} >
                                                უძრავი ძეგლი ({pager.immovableCount})
                                            </Link>
                                        </li> : <></>
                                    }
                                    {pager.movableCount > 0 ?
                                        <li >
                                            <Link to={{ search: `?page=1` + fileterStr + `&t=movable` }}
                                                className={`page-link ResultRectangle ${this.state.forType === 'movable' ? 'selected' : ''}`} >
                                                მოძრავი ძეგლი ({pager.movableCount})
                                            </Link>
                                        </li> : <></>
                                    }
                                    {pager.wallCount > 0 ?
                                        <li >
                                            <Link to={{ search: `?page=1` + fileterStr + `&t=wallart` }}
                                                className={`page-link ResultRectangle ${this.state.forType === 'wallart' ? 'selected' : ''}`} >
                                                კედლის მხატვრობა ({pager.wallCount})
                                            </Link>
                                        </li> : <></>
                                    }
                                    {pager.stoneCount > 0 ?
                                        <li >
                                            <Link to={{ search: `?page=1` + fileterStr + `&t=stoneengrave` }}
                                                className={`page-link ResultRectangle ${this.state.forType === 'stoneengrave' ? 'selected' : ''}`} >
                                                ქვაზე კვეთილობა ({pager.stoneCount})
                                            </Link>
                                        </li> : <></>
                                    }
                                    {pager.museumCount > 0 ?
                                        <li >
                                            <Link to={{ search: `?page=1` + fileterStr + `&t=museum` }}
                                                className={`page-link ResultRectangle ${this.state.forType === 'museum' ? 'selected' : ''}`} >
                                                მუზეუმ-ნაკრძალი/მუზეუმი ({pager.museumCount})
                                            </Link>
                                        </li> : <></>
                                    }
                                    {pager.zoneCount > 0 ?
                                        <li >
                                            <Link to={{ search: `?page=1` + fileterStr + `&t=zone` }}
                                                className={`page-link ResultRectangle ${this.state.forType === 'zone' ? 'selected' : ''}`} >
                                                ზოგადი დამცავი ზონა ({pager.zoneCount})
                                            </Link>
                                        </li> : <></>
                                    }
                                    {pager.eventCount > 0 ?
                                        <li >
                                            <Link to={{ search: `?page=1` + fileterStr + `&t=event` }}
                                                className={`page-link ResultRectangle ${this.state.forType === 'event' ? 'selected' : ''}`} >
                                                ქმედება ({pager.eventCount})
                                            </Link>
                                        </li> : <></>
                                    }
                                    {pager.documentCount > 0 ?
                                        <li >
                                            <Link to={{ search: `?page=1` + fileterStr + `&t=document` }}
                                                className={`page-link ResultRectangle ${this.state.forType === 'document' ? 'selected' : ''}`} >
                                                დოკუმენტაცია ({pager.documentCount})
                                            </Link>
                                        </li> : <></>
                                    }
                                </ul>
                            </div>
                        </div>
                        : <></>
                    }


                    {pager.pages && this.props.type === this.state.type &&
                        <div className="TotalText"> <span style={{ fontFamily: 'HelveticaBold' }}>სულ:</span> {pager.iTotalRecords.toLocaleString()}  ჩანაწერი</div>
                    }

                </div>
                {
                    this.state.type !== "all" && pager.classificator != null && this.state.type === this.props.type && this.state.type !== "userObject" ?
                        <ClearSearch type={this.state.type} data={this.state.data} updatedataState={this.updatedataState.bind(this)} detailSearch={this.detailSearch} classificator={pager.classificator} filterResulthandle={this.filterResulthandle.bind(this)}
                            locationClick={this.props.type === "immovable" || this.props.type === "museum" ? this.location.bind(this) : null} createDataElement={this.createDataElement.bind(this)}
                        />
                        :
                        <></>
                }
                <div className="card-body" onMouseLeave={this.removeMapPoint.bind(this)}>
                    {
                        this.state.loading || this.props.type !== this.state.type
                            ?
                            //<p><em>Loading...</em></p>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center' }}>
                                <PulseLoader
                                    color={"#E57959"}
                                    loading={true}
                                />
                            </div>
                            : this.drawImovableList(pageOfItems)
                    }


                    {
                        pager.pages != null && pager.pages.length > 0 && this.props.type === this.state.type ?
                            <div className="TotalText">
                                {`${pager.currentPage * 10 - 9}-${pager.currentPage * 10 > pager.iTotalRecords ? pager.iTotalRecords : pager.currentPage * 10} ობიექტი `}
                                <span style={{ fontFamily: 'HelveticaBold' }}>{pager.iTotalRecords.toLocaleString()} - დან</span>
                            </div>
                            : <></>
                    }
                </div>
                <div className="pb-0 pt-3 automargin">
                    {pager.pages != null && pager.pages.length > 0 && this.props.type === this.state.type ?
                        <ul className="pagination mainPagination">
                            <li className={`page-item first-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                                <div page={1} onClick={this.chanegPageSession.bind(this)} className="page-link">
                                    <svg className="bi bi-chevron-double-left" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M8.354 1.646a.5.5 0 010 .708L2.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z" clipRule="evenodd" />
                                        <path fillRule="evenodd" d="M12.354 1.646a.5.5 0 010 .708L6.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </li>
                            <li className={`page-item previous-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                                <div page={pager.currentPage - 1} onClick={this.chanegPageSession.bind(this)} className="page-link">
                                    <svg className="bi bi-chevron-left" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 010 .708L5.707 8l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </li>
                            {pager.pages.map(page =>
                                <li key={page} className={`page-item number-item ${pager.currentPage === page ? 'active' : ''}`}>
                                    <div className="page-link" page={page} onClick={this.chanegPageSession.bind(this)}>{page}</div>
                                </li>
                            )}
                            <li className={`page-item next-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                                <div page={pager.currentPage + 1} onClick={this.chanegPageSession.bind(this)} className="page-link">
                                    <svg className="bi bi-chevron-right" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </li>
                            <li className={`page-item last-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                                <div page={pager.totalPages} onClick={this.chanegPageSession.bind(this)} className="page-link">
                                    <svg className="bi bi-chevron-double-right" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L9.293 8 3.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />
                                        <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L13.293 8 7.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </li>
                        </ul>
                        : <></>
                    }
                </div>

                <button className="MapButton" onClick={this.props.handler}><img width="25" alt="" src="/images/map-solid.svg" />{' '} </button>

            </div>

        );
    }
}

export { ObjectListView };