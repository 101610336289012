import React, { Component } from 'react';
import { Row, Col, Modal } from 'react-bootstrap'
import Jumbotron from 'react-bootstrap/Jumbotron'

import { ObjectList } from './ObjectList';
import { ObjectListHorizontal } from './ObjectListHorizontal';
import { ObjectListTable } from './ObjectListTable';

import { Share } from './Share'

import { AboutObject } from './AboutObject'
import { AboutZone } from './AboutZone'
import { ChooseCategory } from './ChooseCategory'
import { SearchTemplate } from './SearchTemplate';
import { authenticationService } from '../_services';
import { Footer } from './Footer';
const marginRight = {
    marginRight: '10px'
}


export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            currentUser: null,
        };
    }
    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x, isAdmin: x && x.role === "Admin", isAdminG: x && x.role === "AdminG", filler: x && x.role === "Filler"
        }));
    }
    handleShow = () => {
        this.setState({ show: true })

    };
    handleClose = () => {
        this.setState({
            show: false
        })
    }
    quickAlertBtn = () => {
        localStorage.setItem('quickAlertForm', "quickAlertForm");
    }

    render() {
        sessionStorage.clear();


        return (
            <div className="mainDiv">
                <div className="mainDiv containerDiv">
                    <div className="MobileMainSearch">
                        <SearchTemplate type="all"></SearchTemplate>
                    </div>
                    {(this.state.currentUser == null ? "" : this.state.currentUser.role) == "Filler" || (this.state.currentUser == null ? "" : this.state.currentUser.role) == "AdminG" ?
                        <Row>
                            <Col md={4} className="MarginTop">
                                <Jumbotron fluid className="aboutSite">
                                    <div className="aboutSiteText">
                                        თქვენ ესტუმრეთ
                                <div style={{ fontFamily: 'HelveticaBold', fontSize: '18px' }}>კულტურული მემკვიდრეობის ვებ-პორტალს,</div>
                                        <div style={{ marginTop: '20px' }}>რომელიც წარმოადგენს საქართველოს კულტურული მემკვიდრეობის ერთიან მონაცემთა ბაზას</div>
                                    </div>
                                    <div>
                                        <div style={{ float: 'left', paddingTop: '30px' }}>
                                            <Share header="გვერდის გაზიარება"></Share>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <a href="/about"><button className="AboutSiteBtn"></button></a>
                                        </div>
                                    </div>

                                </Jumbotron>
                            </Col>
                            <Col md={5} className="MarginTop">
                                <Jumbotron fluid className="map">
                                    <div className="mapHeader">ინტერაქტიული რუკა</div>
                                    <div className="mapText">
                                        რუკის საშუალებით თქვენ შეგიძლიათ გაიგოთ ნებისმიერი ობიექტის კოორდინატები, ნახოთ სასურველი ტერიტორიის ფართობი ან გაზომოთ მანძილი სხვა და სხვა ძეგლებს შორის.
                     </div>
                                    <div style={{ textAlign: 'right' }}>
                                        <ChooseCategory />
                                    </div>
                                </Jumbotron>
                            </Col>
                            <Col md={3} className="MarginTop">
                                <div className="PopUpUserAlertWeb">
                                    <a href="/objects/immovable" onClick={this.quickAlertBtn}>
                                        <Jumbotron fluid className="quickAlert" >
                                            <div className="file-text-png"><div className="file-text-div"></div></div>
                                            <div className="quickAlertHeader">სწრაფი შეტყობინების ფორმა</div>
                                            <div className="quickAlertText">
                                                მსოფლიო მემკვიდრეობის ძეგლის ფიზიკურ მდგომარეობაზე
                                            </div>
                                            <div style={{ textAlign: 'right' }}>
                                                <button onClick={this.quickAlertBtn} className="quickAlertBtn"></button>
                                            </div>
                                        </Jumbotron>
                                    </a>
                                </div>
                                <div className="PopUpUserAlertMobile">

                                    <a onClick={this.handleShow}>
                                        <Jumbotron fluid className="quickAlert" >
                                            <div className="file-text-png"><div className="file-text-div"></div></div>
                                            <div className="quickAlertHeader">სწრაფი შეტყობინების ფორმა</div>
                                            <div className="quickAlertText">
                                                მსოფლიო მემკვიდრეობის ძეგლის ფიზიკურ მდგომარეობაზე
                                            </div>
                                            <div style={{ textAlign: 'right' }}>
                                                <button onClick={this.handleShow} className="quickAlertBtn"></button>
                                            </div>
                                        </Jumbotron>
                                    </a>


                                    <Modal size="sm" backdrop="static" show={this.state.show} onHide={this.handleClose} className="QuickAlertDialogPopUp"
                                    >
                                        <Modal.Header closeButton className="searchCloseModal">
                                            <div className="PopUpModalTitleDiv">
                                                <div className="PopUpModalTitle">სწრაფი შეტყობინება</div>
                                            </div>

                                        </Modal.Header>
                                        <div className="alertModalTitleLine"> </div>
                                        <Modal.Body>

                                            <div className="PopUpModalBodyText">
                                                მსფოლიო მემკვიდრეობის ძეგლებზე დაზიანება/საფრთხის გამოვლენის შემთხვევაში ინფორმაციის დროულად მიწოდების სერვისი
                                            </div>
                                            <div className="manualPopUpButtonDiv">
                                                <button type="button" className="manualPopUpButton">
                                                    <a href="/manual" >
                                                        <span className="">სახელმძღვანელო</span>
                                                    </a>
                                                </button>
                                            </div>
                                            <br />
                                            <div className="ganacxadiPopUpButtonDiv">
                                                <button type="button" className="ganacxadiPopUpButton">
                                                    <a href="/objects/immovable" onClick={this.quickAlertBtn}>
                                                        <span className="">განაცხადი</span>
                                                    </a>
                                                </button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </Col>
                        </Row>

                        :
                        <Row>
                            <Col md={5} className="MarginTop">
                                <Jumbotron fluid className="aboutSite">
                                    <div className="aboutSiteText">
                                        თქვენ ესტუმრეთ
                                <div style={{ fontFamily: 'HelveticaBold', fontSize: '18px' }}>კულტურული მემკვიდრეობის ვებ-პორტალს,</div>
                                        <div style={{ marginTop: '20px' }}>რომელიც წარმოადგენს საქართველოს კულტურული მემკვიდრეობის ერთიან მონაცემთა ბაზას</div>
                                    </div>
                                    <div>
                                        <div style={{ float: 'left', paddingTop: '30px' }}>
                                            <Share header="გვერდის გაზიარება"></Share>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <a href="/about"><button className="AboutSiteBtn"></button></a>
                                        </div>
                                    </div>

                                </Jumbotron>
                            </Col>
                            <Col md={7} className="MarginTop">
                                <Jumbotron fluid className="map">
                                    <div className="mapHeader">ინტერაქტიული რუკა</div>
                                    <div className="mapText">
                                        რუკის საშუალებით თქვენ შეგიძლიათ გაიგოთ ნებისმიერი ობიექტის კოორდინატები, ნახოთ სასურველი ტერიტორიის ფართობი ან გაზომოთ მანძილი სხვა და სხვა ძეგლებს შორის.
                     </div>
                                    <div style={{ textAlign: 'right' }}>
                                        <ChooseCategory />
                                    </div>
                                </Jumbotron>
                            </Col>

                        </Row>
                    }



                    <Row>
                        <Col className="MarginTop">
                            <Jumbotron fluid className="object">
                                <div className="objectHeader">უძრავი ძეგლები</div>
                                <AboutObject headerText="უძრავი ძეგლები" >მოიცავს ინფორმაციას ისტორიული დასახლებების, ციხე-სიმაგრეების, ტრადიციული თუ ქალაქური ტიპის ნაგებობების , ბაღ-პარკების, მემორიალური, არქეოლოგიური, საინჟინრო, რელიგიური სახეობა/ფუნქციის მქონე და სხვა ობიექტების შესახებ.</AboutObject>
                                <Row>
                                    <Col md={8} className="objectText">
                                        მოიცავს ინფორმაციას ისტორიული დასახლებების, ციხე-სიმაგრეების, ტრადიციული თუ ქალაქური ტიპის ნაგებობების , ბაღ-პარკების, მემორიალური, არქეოლოგიური, საინჟინრო, რელიგიური სახეობა/ფუნქციის მქონე და სხვა ობიექტების შესახებ.
                                </Col>
                                    <Col md={4} className="objectButtonDiv">
                                        <a className="SecondaryNoBorderButton SecondaryButton icon-right" href="/objects/immovable"><span style={marginRight}>ყველა ობიექტი</span></a>
                                    </Col>
                                </Row>
                                <Row>
                                    <ObjectList value="immovable" count="24" imgClassName="ImageDivImmovable" />
                                </Row>
                            </Jumbotron>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="MarginTop">
                            <Jumbotron fluid className="object">
                                <div className="objectHeader">მოძრავი ძეგლები</div>
                                <AboutObject headerText="მოძრავი ძეგლები">აერთიანებს ინფორმაციას არქეოლოგიური, ეთნოგრაფიული, სახვითი ხელოვნების, მემორიალური სახეობის ადამიანის ყოფა-კულტურასთან დაკავშირებულ ნივთებზე. მათ შორის: სამოსის, სამკაულის, ავეჯის, ნუმიზმატიკის, სამეურნეო, სამუსიკო, საბრძოლო-სათავდაცვო  და სხვა ფუნქცია/ტიპის მოძრავი (გადაადგილებადი) ობიექტების შესახებ.</AboutObject>
                                <Row>
                                    <Col md={8} className="objectText">
                                        აერთიანებს ინფორმაციას არქეოლოგიური, ეთნოგრაფიული, სახვითი ხელოვნების, მემორიალური სახეობის ადამიანის ყოფა-კულტურასთან დაკავშირებულ ნივთებზე. მათ შორის: სამოსის, სამკაულის, ავეჯის, ნუმიზმატიკის, სამეურნეო, სამუსიკო, საბრძოლო-სათავდაცვო  და სხვა ფუნქცია/ტიპის მოძრავი (გადაადგილებადი) ობიექტების შესახებ.
                                </Col>
                                    <Col md={4} className="objectButtonDiv">
                                        <a className="SecondaryNoBorderButton SecondaryButton icon-right" href="/objects/movable"><span style={marginRight}>ყველა ობიექტი</span></a>
                                    </Col>
                                </Row>
                                <Row>
                                    <ObjectList value="movable" count="21" imgClassName="ImageDivMovable" />
                                </Row>
                            </Jumbotron>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} className="MarginTop">
                            <Jumbotron fluid className="object">
                                <div className="objectHeader">კედლის მხატვრობა</div>
                                <AboutObject headerText="კედლის მხატვრობა">მოიცავს საერო და რელიგიური დანიშნულების შენობა-ნაგებობების, კლდეში ნაკვეთი გამოქვაბულების და სხვა ობიექტების კედლის მხატვრობის ნიმუშების ინფორმაციას.</AboutObject>
                                <div className="objectText">
                                    მოიცავს საერო და რელიგიური დანიშნულების შენობა-ნაგებობების, კლდეში ნაკვეთი გამოქვაბულების და სხვა ობიექტების კედლის მხატვრობის ნიმუშების ინფორმაციას.
                                </div>
                                <div style={{ textAlign: "left" }}>
                                    <a className="SecondaryNoBorderButton SecondaryButton icon-right" href="/objects/wallart"><span style={marginRight}>ყველა ობიექტი</span></a>
                                </div>
                                <Row>
                                    <ObjectListHorizontal value="wall" count="3" imgClassName="ImageDivWallArt" />
                                </Row>
                            </Jumbotron>
                        </Col>
                        <Col md={6} className="MarginTop">
                            <Jumbotron fluid className="object">
                                <div className="objectHeader">ქვაზე კვეთილობა</div>
                                <AboutObject headerText="ქვაზე კვეთილობა">გაერთიანებულია ინფორმაცია სხვადასხვა სახეობის, ფუნქცია/ტიპის, მათ შორის: არქეოლოგიური, არქიტექტურის, საინჟინრო, საერო, რელიგიური ობიექტების ქვაზე შესახებ.</AboutObject>
                                <div className="objectText">
                                    გაერთიანებულია ინფორმაცია სხვადასხვა სახეობის, ფუნქცია/ტიპის, მათ შორის: არქეოლოგიური, არქიტექტურის, საინჟინრო, საერო, რელიგიური ობიექტების ქვაზე კვეთილობების შესახებ.
                                </div>
                                <div style={{ textAlign: "left" }}>
                                    <a className="SecondaryNoBorderButton SecondaryButton icon-right" href="/objects/stoneengrave"><span style={marginRight}>ყველა ობიექტი</span></a>
                                </div>
                                <Row>
                                    <ObjectListHorizontal value="stone" count="3" imgClassName="ImageDivStoneEngrave" />
                                </Row>
                            </Jumbotron>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="MarginTop">
                            <Jumbotron fluid className="object">
                                <div className="objectHeader">ზოგადი დამცავი ზონა</div>
                                <AboutObject objectType="zone"></AboutObject>
                                <div className="objectAboutZone"><AboutZone /></div>
                                <Row>
                                    <Col md={8} className="objectText">
                                        კულტურული მემკვიდრეობის უძრავი ობიექტების ირგვლივ ან/და მათი გავრცელების ან გავლენის არეალში კანონით დადგენილი წესით განსაზღვრული ტერიტორია, რომლის ფარგლებშიც მოქმედებს ექსპლუატაციის განსაკუთრებული რეჟიმი და რომლის დანიშნულებაა, მის საზღვრებში არსებული კულტურული მემკვიდრეობა დაიცვას არასასურველი ზეგავლენისაგან.
                                </Col>
                                    <Col md={4} className="objectButtonDiv">
                                        <a className="SecondaryNoBorderButton SecondaryButton icon-right" href="/objects/zone"><span style={marginRight}>ყველა ობიექტი</span></a>
                                    </Col>
                                </Row>
                                <Row>
                                    <ObjectList value="zone" count="21" imgClassName="ImageDivMovable" />
                                </Row>

                            </Jumbotron>
                        </Col>
                    </Row>

                    <Row >
                        <Col className="MarginTop" style={{ marginBottom: "30px" }}>
                            <Jumbotron fluid className="object">
                                <Row>
                                    <Col lg={4}>
                                        <div className="objectHeader">ქმედება</div>
                                        <AboutObject headerText="ქმედება">აერთიანებს ინფორმაციას კულტურული მემკვიდრეობის ძეგლების/ობიექტების გამოვლენის, კვლევის, დაცვის, მონიტორინგის, სტატუსისა თუ კატეგორიის მინიჭების, ჩატარებული ან დაგეგმილი ჩარევების და მასთან დაკავშირებული ნებისმიერი ღონისძიების შესახებ.</AboutObject>
                                        <div className="objectText">
                                            აერთიანებს ინფორმაციას კულტურული მემკვიდრეობის ძეგლების/ობიექტების გამოვლენის, კვლევის, დაცვის, მონიტორინგის, სტატუსისა თუ კატეგორიის მინიჭების, ჩატარებული ან დაგეგმილი ჩარევების და მასთან დაკავშირებული ნებისმიერი ღონისძიების შესახებ.
                            </div>
                                        <div style={{ textAlign: 'left' }}>
                                            <a className="SecondaryNoBorderButton SecondaryButton icon-right" href="/objects/event"><span style={marginRight}>ყველა ობიექტი</span></a>
                                        </div>
                                    </Col>
                                    <Col lg={8} style={{ padding: '0' }}>
                                        <ObjectListTable value="event" count="3" />
                                    </Col>
                                </Row>
                            </Jumbotron>

                        </Col>
                    </Row>

                </div>
                <Footer></Footer>
            </div>
        );
    }

}
