import React, { Component } from 'react';
import './ImmovableList.css';

import { DocListView } from './DocListVIew';

export class DocumentList extends Component {
    componentDidMount() {
      
        var el = document.querySelector('.searchComponentButton');
        el.textContent = "დოკუმენტაცია";
    }

    static displayName = DocumentList.name;

    render() {
        return (
            <div className="immListDiv" style={{ backgroundColor: 'white' }}>

                <DocListView type="document" imgClassName="imgDivDocument" />

            </div>
        );
    }

}