import React, { Component } from 'react';
import './UserObject.css';
import { AddObjectMap, setPoint } from './AddObjectMap';
import { Thumb } from './Thumb';
import { Row, Col } from 'react-bootstrap';
import { authenticationService } from '../../_services';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';

function readURL(input, index) {
    if (input) {
        var reader = new FileReader();
        reader.onload = function (e) {
            var el = document.getElementById('photoView_' + index);
            el.setAttribute('src', e.target.result);
        };
        reader.readAsDataURL(input);

        document.getElementById('removeimage_' + index).classList.remove('displayNone');

    }
}

function removeImage() {
    var el = document.getElementById('photoView');
    el.setAttribute('src', null);
    document.getElementById('photo').value = null;
    document.getElementById('addUser').classList.remove('userremoveImage');
    document.getElementById('addUser').classList.add('useraddImage');
}

const Schema = Yup.object().shape({
    name: Yup.string().required('შეიყვანეთ სახელწოდება'),
    region: Yup.string().required('შეიყვანეთ რეგიონი'),
    municipality: Yup.string().required('შეიყვანეთ მუნიციპალიტეტი'),
    settlement: Yup.string().required('შეიყვანეთ დასახლებული პუნქტი'),
    description: Yup.string().required('შეიყვანეთ მოკლე აღწერა')

});

export class AddObject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            coordinatebi: null,
            mapClass: '',
            mobile:false,
            fullScreeenIcon: "esri-icon-zoom-out-fixed",
            error: ''
        }
        this.setCoordinates = this.setCoordinates.bind(this);
        this.fullScreenMode = this.fullScreenMode.bind(this)
    }

    componentDidMount() {

        authenticationService.currentUser.subscribe(x =>
            this.setState({
                currentUser: x
            }));

        //  this.loadPage();
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        if (params.get('id')) {
            const id = parseInt(params.get('id'));
            if (id !== null) {
                authenticationService.getObject(id).then(
                    object => {
                        this.setState({ object: object })
                    },
                    error => {
                        this.setState({ error: error })
                    }
                );
            }
        }
    }

    setCoordinates(x, y) {
        var el = document.getElementById('x');
        el.value = x;

        document.getElementById('y').value = y;

    }

    fullScreenMode() {
        if (this.state.mapClass === '') {
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-in-fixed', mapClass: 'objectFullScreentMap',mobile:true })
        }
        else {

            document.getElementById('toolbarDiv').classList.add('displayNone');
            document.getElementById('menuDiv').classList.add('displayNone')
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-out-fixed', mapClass: '', mobile: false })
        }
    }

    render() {
        var currentUser = this.state.currentUser;
        return (
            currentUser &&
            <div className="immListDiv userObject" style={{
                backgroundColor: 'white'
            }}>
                <div style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                    <div className="objectContent">
                        <div style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className="boldHeade">
                                დაამატე ახალი კულტურული მემკვიდრეობა
                            </div>
                        </div>
                    </div>
                    <div className="mapContent">
                        <AddObjectMap setCoordinates={this.setCoordinates} coordinatebi={this.state.coordinatebi} fullScreeenIcon={this.state.fullScreeenIcon} fullClick={this.fullScreenMode.bind(this)} mapClass={this.state.mapClass} mobile={this.state.mobile} />
                    </div>
                    <div className="objectContent">
                        <div style={{ maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Row className="form-group">
                                <Col style={{ marginTop: '1rem' }} >
                                    <input name="x" type="hidden" id="x" />
                                    <input name="y" type="hidden" id="y" />
                                </Col>
                            </Row>
                            <Formik
                                initialValues={{
                                    id: 0,
                                    photos: [null],
                                    images: [],
                                    name: '',
                                    region: '',
                                    municipality: '',
                                    settlement: '',
                                    street: '',
                                    streetNum: '',
                                    description: '',
                                    x: 0,
                                    y: 0
                                }}
                                validationSchema={Schema}
                                onSubmit={({ id, photos, images, name, region, municipality, settlement, street, streetNum, description }, { setStatus, setSubmitting }) => {
                                    setStatus();
                                    if (document.getElementById('x').value === '' || document.getElementById('y').value === '') {
                                        setSubmitting(false);
                                        setStatus("მონიშნეთ ადგილი რუკაზე");
                                    }
                                    else {
                                        authenticationService.addobject({ id, photos, images, name, region, municipality, settlement, street, streetNum, description }, document.getElementById('x').value, document.getElementById('y').value)
                                            .then(
                                                user => {
                                                    const { from } = this.props.location.state || { from: { pathname: "/UserProfile/userObjects" } };
                                                    this.props.history.push(from);
                                                },
                                                error => {
                                                    setSubmitting(false);
                                                    setStatus(error);
                                                }
                                            );
                                    }
                                }}>

                                {({ values, errors, status, touched, isSubmitting, setFieldValue }) => {

                                    useEffect(() => {
                                        
                                            const params = new URLSearchParams(window.location.search);
                                            if (params.get('id')) {
                                                const id = parseInt(params.get('id'));
                                                if (id !== null) {
                                                    authenticationService.getObject(id).then(
                                                        object => {
                                                            setFieldValue('id', object.id)
                                                            setFieldValue('images', object.images)
                                                            setFieldValue('name', object.name)
                                                            setFieldValue('region', object.region)
                                                            setFieldValue('municipality', object.municipality)
                                                            setFieldValue('settlement', object.settlement)
                                                            setFieldValue('street', object.street)
                                                            setFieldValue('streetNum', object.streetNum)
                                                            setFieldValue('description', object.description)
                                                            setFieldValue('x', object.x)
                                                            setFieldValue('y', object.y)
                                                            if (setPoint != null) {
                                                                setPoint(object.x, object.y)
                                                            }
                                                            else {
                                                                this.setState(
                                                                    { coordinatebi: { x:object.x, y:object.y} })
                                                            }
                                                        }
                                                    );
                                                }
                                            }
                                        
                                       
                                    }, []);


                                    return (
                                        <Form>
                                            {status &&
                                                <div className={'alert alert-danger'}>{status}</div>
                                            }

                                            <Row className="form-group">
                                                <Col style={{ marginTop: '1rem' }} md={6}>
                                                    <Field autoComplete="off" name="id" type="hidden" />

                                                    <Field autoComplete="off" name="name" type="text" className={'form-control contactText' + (errors.name && touched.name ? ' is-invalid' : '')} placeholder="სახელწოდება*" />
                                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                </Col>
                                                <Col style={{ marginTop: '1rem' }} md={6}>
                                                    <Field autoComplete="off" name="region" type="text" className={'form-control contactText' + (errors.region && touched.region ? ' is-invalid' : '')} placeholder="რეგიონი*" />
                                                    <ErrorMessage name="region" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col style={{ marginTop: '1rem' }} md={6}>
                                                    <Field autoComplete="off" name="municipality" type="text" className={'form-control contactText' + (errors.municipality && touched.municipality ? ' is-invalid' : '')} placeholder="მუნიციპალიტეტი*" />
                                                    <ErrorMessage name="municipality" component="div" className="invalid-feedback" />
                                                </Col>
                                                <Col style={{ marginTop: '1rem' }} md={6}>
                                                    <Field autoComplete="off" name="settlement" type="text" className={'form-control contactText' + (errors.settlement && touched.settlement ? ' is-invalid' : '')} placeholder="დასახლებული პუნქტი*" />
                                                    <ErrorMessage name="settlement" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col style={{ marginTop: '1rem' }} md={6}>
                                                    <Field autoComplete="off" name="street" type="text" className={'form-control contactText' + (errors.street && touched.street ? ' is-invalid' : '')} placeholder="ქუჩა" />
                                                    <ErrorMessage name="street" component="div" className="invalid-feedback" />
                                                </Col>
                                                <Col style={{ marginTop: '1rem' }} md={6}>
                                                    <Field autoComplete="off" name="streetNum" type="text" className={'form-control contactText' + (errors.streetNum && touched.streetNum ? ' is-invalid' : '')} placeholder="შენობის ნომერი" />
                                                    <ErrorMessage name="streetNum" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col style={{ marginTop: '1rem' }}>
                                                    <Field component="textarea" rows="3" style={{ resize: 'none' }} autoComplete="off" name="description" type="text" className={'form-control contactText' + (errors.description && touched.description ? ' is-invalid' : '')} placeholder="მოკლე აღწერა*" />
                                                    <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                                </Col>
                                            </Row>

                                            <Row className="form-group">
                                                <Col style={{ marginTop: '1rem' }} >
                                                    <FieldArray
                                                        name="images"
                                                        render={arrayHelpers => (
                                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                {values.images && values.images.length > 0 ? (
                                                                    values.images.map((image, index) => (
                                                                        <div id={`image_${index}`} key={index}>
                                                                            <Field component="div" className="imageDiv" name={`images.${index}`}>
                                                                                <img src={image} alt=""
                                                                                    style={{ width: '100%', height: '100%' }} />

                                                                            </Field>
                                                                            <button id={`removeim_${index}`} className={`deleteImage ${image === "" || image == null ? 'displayNone' : ''}`}
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    arrayHelpers.remove(index);
                                                                                    //document.getElementById(`image_${index}`).remove()
                                                                                }}
                                                                            >x</button>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                        <></>
                                                                    )}
                                                            </div>

                                                        )}
                                                    />

                                                    <FieldArray
                                                        name="photos"
                                                        render={arrayHelpers => (
                                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                {values.photos && values.photos.length > 0 ? (
                                                                    values.photos.map((photo, index) => (
                                                                        <div key={index}>
                                                                            <Field component="div" className="imageDiv" name={`photos.${index}`}>
                                                                                {
                                                                                    <Thumb file={photo} />
                                                                                }

                                                                                <input id={`photo_${index}`} type="file" accept="image/*" className="fileUpload"
                                                                                    onChange={(event) => {
                                                                                        arrayHelpers.insert(index + 1, "");
                                                                                        setFieldValue(`photos.${index}`, event.currentTarget.files[0]);
                                                                                    }}
                                                                                />
                                                                            </Field>
                                                                            <button id={`removeimage_${index}`} className={`deleteImage ${photo === "" || photo == null ? 'displayNone' : ''}`}
                                                                                type="button"
                                                                                onClick={() => arrayHelpers.remove(index)}
                                                                            >x</button>
                                                                        </div>
                                                                    ))) : (                                                                       
                                                                        <></>
                                                                    )
                                                                }


                                                            </div>

                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="form-group">
                                                <Col style={{ marginTop: '1rem' }} >
                                                    <button type="submit" className="MainButton" disabled={isSubmitting}>გაგზავნა</button>
                                                    {isSubmitting &&
                                                        <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                </Col>
                                            </Row>
                                        </Form>

                                    );
                                }}
                            </Formik>


                        </div>
                    </div>

                </div>
            </div>
        );
    }

}