import React from 'react';
import { Modal, Accordion, Card } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import './Search.css';
import { CustomMultipleSelect } from '../custom/CustomMultipleSelect';
import { CustomToggle } from '../custom/CustomToggle'
import { CustomTreeSelect } from '../custom/CustomTreeSelect';
import { CustomCreatableMultipleSelect } from '../custom/CustomCreatableMultipleSelect';
import { CustomCreatableMultipleSelectSearch } from '../custom/CustomCreatableMultipleSelectSearch';
import Autosuggest from 'react-autosuggest';


const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = suggestion => (
    <div>
        {suggestion}
    </div>
);

const locationNamegetSuggestionValue = locationNamesuggestions => locationNamesuggestions;
const locationNamerenderSuggestion = locationNamesuggestions => (
    <div>
        {locationNamesuggestions}
    </div>
);

const authorgetSuggestionValue = authorsuggestions => authorsuggestions;
const authorrenderSuggestion = authorsuggestions => (
    <div>
        {authorsuggestions}
    </div>
);

export class MovableDetailSearchModal extends React.Component {
    selectedPeriods = [];
    selectedFunction = [];
    selectedVarieties = [];

    tempselectedPeriods = [];
    tempselectedFunction = [];


    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data:
            {
                id: '', name: '',
                variety: [], status: [], category: [], period: [], function: [],
                locationName: '',
                country: [], region: [], municipality: [], settlement: [], street: [],
                oldcountry: [], oldregion: [], oldmunicipality: [], oldsettlement: [], oldstreet: [],
                author: '', tempselectedPeriods: [], tempselectedFunction: [], linkedObj: {}, fullText: ''
            },
            suggestions: [],
            locationNamesuggestions: [],
            authorsuggestions: []
        };

        this.handleNameClearClick = this.handleNameClearClick.bind(this);
        this.handleocNameClearClick = this.handleLocationNameClearClick.bind(this);
        this.handleAuthorClearClick = this.handleAuthorClearClick.bind(this);
    }


    handleClose = () => this.setState({ show: false });
    handleShow = () => {
        this.setState({ show: true })

        if (sessionStorage.getItem("filterData") != null && sessionStorage.getItem("filterData") !== '') {
            var data = JSON.parse(JSON.parse(sessionStorage.getItem("filterData")));

            this.tempselectedFunction = data.tempselectedFunction;
            this.tempselectedPeriods = data.tempselectedPeriods;

            this.selectedPeriods = data.period;
            this.selectedFunction = data.function;
            this.selectedVarieties = data.variety;

            this.setState({ data: data })
        }

    };

    onChange(event) {
        var data = this.state.data;
        switch (event.target.name) {
            case "id":
                data.id = parseInt(event.target.value) || ''
                break;
            default:
                break;
        }

        this.setState({ data: data });
    }
    handleSubmit(e) {
        e.preventDefault();

        this.prepareSubmit();
    }
    prepareSubmit() {
        var data = this.state.data;
        data.period = this.selectedPeriods;
        data.function = this.selectedFunction;
        data.variety = this.selectedVarieties;

        data.tempselectedPeriods = this.tempselectedPeriods;
        data.tempselectedFunction = this.tempselectedFunction;

        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));

        this.searchData(data);
    }
    checkData(data) {
        if (data.id !== 0 || data.author !== '' || data.name !== '' || data.locationName !== ''
            || data.variety.length > 0 || data.status.length > 0 || data.category.length > 0 ||
            data.period.length > 0 || data.function.length > 0 ||
            data.country.length > 0 || data.region.length > 0 || data.municipality.length > 0 || data.settlement.length > 0 || data.street.length > 0 ||
            data.oldcountry.length > 0 || data.oldregion.length > 0 || data.oldmunicipality.length > 0 || data.oldsettlement.length > 0 || data.oldstreet.length > 0) {
            return true;
        }
        return false
    }

    searchData(data) {
        if (data.id === '') data.id = 0;
        if (this.checkData(data)) {
            fetch('api/Movable', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response) => {
                this.props.filterResulthandle(response);
                this.handleClose();
            })
        }
        else {
            this.props.filterResulthandle('');
            this.handleClose();
        }
    }
    handleClearData() {
        var data = {
            id: '', name: '',
            variety: [], status: [], category: [], period: [], function: [],
            locationName: '',
            country: [], region: [], municipality: [], settlement: [], street: [],
            oldcountry: [], oldregion: [], oldmunicipality: [], oldsettlement: [], oldstreet: [],
            author: '', tempselectedPeriods: [], tempselectedFunction: [], linkedObj: {}, fullText: ''
        };
        this.setState({ data: data, show: false })
        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));
        this.props.filterResulthandle('');
    }

    loopChild(item, tempList) {
        tempList.push(item.value);
        if (item.tempchild != null && item.tempchild.length > 0) {
            item.tempchild.map(i => this.loopChild(i, tempList))
        }
    }

    changeVarietyHandle(currentNode, selectedNodes) {
        this.selectedVarieties = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectedVarieties.push(item.value)
            );
        }
    }
    changeStatusHandle(items) {
        var data = this.state.data;
        data.status = [];
        if (items != null) {
            items.map(item =>
                data.status.push(item.value)
            );
        }
        this.setState({ data: data });
    }
    changeCategoryHandle(items) {
        var data = this.state.data;
        data.category = [];
        if (items != null) {
            items.map(item =>
                data.category.push(item.value)
            );
        }
        this.setState({ data: data });
    }
    changePeriodHandle(currentNode, selectedNodes) {
        this.selectedPeriods = [];
        this.tempselectedPeriods = [];

        if (selectedNodes != null) {
            selectedNodes.forEach(item => {
                this.selectedPeriods.push(item.value)
                this.loopChild(item, this.tempselectedPeriods)
            });
        }
    }
    changeFunctionHandle(currentNode, selectedNodes) {
        this.selectedFunction = [];
        this.tempselectedFunction = [];

        if (selectedNodes != null) {
            selectedNodes.forEach(item => {
                this.selectedFunction.push(item.value)
                this.loopChild(item, this.tempselectedFunction)
            });
        }
    }

    changeCountryHandle(items) {
        var data = this.state.data;
        data.country = [];
        if (items != null) {
            items.map(item =>
                data.country.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeRegionHandle(items) {
        var data = this.state.data;
        data.region = [];
        if (items != null) {
            items.map(item =>
                data.region.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeMunicipalityHandle(items) {
        var data = this.state.data;
        data.municipality = [];
        if (items != null) {
            items.map(item =>
                data.municipality.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeSettlementHandle(items) {
        var data = this.state.data;
        data.settlement = [];
        if (items != null) {
            items.map(item =>
                data.settlement.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeStreetHandle(items) {
        var data = this.state.data;
        data.street = [];
        if (items != null) {
            items.map(item =>
                data.street.push(item.label)
            );
        }
        this.setState({ data: data });
    }

    changeOldCountryHandle(items) {
        var data = this.state.data;
        data.oldcountry = [];
        if (items != null) {
            items.map(item =>
                data.oldcountry.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeOldRegionHandle(items) {
        var data = this.state.data;
        data.oldregion = [];
        if (items != null) {
            items.map(item =>
                data.oldregion.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeOldMunicipalityHandle(items) {
        var data = this.state.data;
        data.oldmunicipality = [];
        if (items != null) {
            items.map(item =>
                data.oldmunicipality.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeOldSettlementHandle(items) {
        var data = this.state.data;
        data.oldsettlement = [];
        if (items != null) {
            items.map(item =>
                data.oldsettlement.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeOldStreetHandle(items) {
        var data = this.state.data;
        data.oldstreet = [];
        if (items != null) {
            items.map(item =>
                data.oldstreet.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    onKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.prepareSubmit();
        }
    }
    onNameChange = (event, { newValue }) => {
        var data = this.state.data;
        data.name = newValue;
        this.setState({
            data: data
        });
    };
    onLocationNameChange = (event, { newValue }) => {
        var data = this.state.data;
        data.locationName = newValue;
        this.setState({
            data: data
        });
    };
    onAuthorChange = (event, { newValue }) => {
        var data = this.state.data;
        data.author = newValue;
        this.setState({
            data: data
        });
    };

    handleNameClearClick() {
        var data = this.state.data;
        data.name = '';
        this.setState({
            data: data
        });
    }
    handleLocationNameClearClick() {
        var data = this.state.data;
        data.locationName = '';
        this.setState({
            data: data
        });
    }
    handleAuthorClearClick() {
        var data = this.state.data;
        data.author = '';
        this.setState({
            data: data
        });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                suggestions: []
            }) :
            fetch('api/AutoComplete/Getrecord?value=' + value + "&component=movable")
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        suggestions: data
                    })
                })
                .catch(error => console.error(error));
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onLocationNameSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                locationNamesuggestions: []
            }) :
            fetch('api/AutoComplete/GetLocationName?value=' + value)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        locationNamesuggestions: data
                    })
                })
                .catch(error => console.error(error));
    };
    onLocationNameSuggestionsClearRequested = () => {
        this.setState({
            locationNamesuggestions: []
        });
    };

    onAuthorSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                authorsuggestions: []
            }) :
            fetch('api/AutoComplete/GetAuthors?value=' + value)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        authorsuggestions: data
                    })
                })
                .catch(error => console.error(error));
    };
    onAuthorSuggestionsClearRequested = () => {
        this.setState({
            authorsuggestions: []
        });
    };

    render() {
        const suggestions = this.state.suggestions;
        const locationNamesuggestions = this.state.locationNamesuggestions;
        const authorsuggestions = this.state.authorsuggestions;
        const value = this.state.data.name;
        const locationName = this.state.data.locationName;
        const author = this.state.data.author;

        const inputProps = {
            value: value,
            onChange: this.onNameChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        let clearButton;
        if (value.length >= 1) {
            clearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleNameClearClick}
                />
            );
        }

        const locationNameinputProps = {
            value: locationName,
            onChange: this.onLocationNameChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        let locationclearButton;
        if (locationName.length >= 1) {
            locationclearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleLocationNameClearClick}
                />
            );
        }

        const authorinputProps = {
            value: author,
            onChange: this.onAuthorChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        let authorclearButton;
        if (author.length >= 1) {
            authorclearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleAuthorClearClick}
                />
            );
        }

        var locationOpen = (this.state.data.locationName!=='' || this.state.data.country.length > 0 || this.state.data.region.length > 0 || this.state.data.municipality.length > 0 || this.state.data.settlement.length > 0 || this.state.data.street.length > 0);
        var oldlocationOpen = (this.state.data.oldcountry.length > 0 || this.state.data.oldregion.length > 0 || this.state.data.oldmunicipality.length > 0 || this.state.data.oldsettlement.length > 0 || this.state.data.oldstreet.length > 0);

        return (
            <>
                <div className="showModal">
                    <button onClick={this.handleShow} className="MainButton icon-equalizer" >{this.props.children}</button>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose} className="SearchDialog">
                    <Modal.Header closeButton className="searchCloseModal">
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit.bind(this)} method="POST" style={{ height: '100%', paddingTop: '0px' }}>
                            <div className="searchDivContent">
                                <div className="FullGalleryHeader">სარეგისტრაციო ნომერი</div>
                                <input type="number" className="form-control searchDivRowInput" name="id" id="id" value={this.state.data.id} onChange={this.onChange.bind(this)} autoComplete="off" />
                                <div className="FullGalleryHeader searchDivRow">სახელწოდება</div>
                                <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        renderSuggestion={renderSuggestion}
                                        getSuggestionValue={getSuggestionValue}
                                        inputProps={inputProps} />
                                    <div className="close-icon-container">
                                        {clearButton}
                                    </div>
                                </div>
                                <div className="FullGalleryHeader searchDivRow" >სახეობა</div>
                                <CustomTreeSelect items={this.props.classificators.variety} onChange={this.changeVarietyHandle.bind(this)} selectedOption={this.state.data.variety} />
                                <div className="FullGalleryHeader searchDivRow" >ფუნქცია, ტიპი</div>
                                <CustomTreeSelect items={this.props.classificators.function} onChange={this.changeFunctionHandle.bind(this)} selectedOption={this.state.data.function} />
                                <div className="FullGalleryHeader searchDivRow" >პერიოდი</div>
                                <CustomTreeSelect items={this.props.classificators.period} onChange={this.changePeriodHandle.bind(this)} selectedOption={this.state.data.period} />
                                <div className="FullGalleryHeader searchDivRow" >ამჟამინდელი სტატუსი</div>
                                <CustomMultipleSelect onKeyDown={this.onKeyPress.bind(this)} selectedOption={this.state.data.status} items={this.props.classificators.status} changeHandle={this.changeStatusHandle.bind(this)}></CustomMultipleSelect>
                                <div className="FullGalleryHeader searchDivRow" >ამჟამინდელი კატეგორია</div>
                                <CustomMultipleSelect onKeyDown={this.onKeyPress.bind(this)} selectedOption={this.state.data.category} items={this.props.classificators.category} changeHandle={this.changeCategoryHandle.bind(this)}></CustomMultipleSelect>
                                <div className="FullGalleryHeader searchDivRow">ავტორი</div>
                                <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                    <Autosuggest
                                        suggestions={authorsuggestions}
                                        onSuggestionsFetchRequested={this.onAuthorSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onAuthorSuggestionsClearRequested}
                                        renderSuggestion={authorrenderSuggestion}
                                        getSuggestionValue={authorgetSuggestionValue}
                                        inputProps={authorinputProps} />
                                    <div className="close-icon-container">
                                        {authorclearButton}
                                    </div>
                                </div>
                                <Accordion defaultActiveKey={locationOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                                    <Card className="modelCard">
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey="1" className="FullGalleryHeader" isOpen={locationOpen}>ამჟამინდელი ადგილმდებარეობა</CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <div className="searchDivRow">დასახელება</div>
                                                <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                                    <Autosuggest
                                                        suggestions={locationNamesuggestions}
                                                        onSuggestionsFetchRequested={this.onLocationNameSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={this.onLocationNameSuggestionsClearRequested}
                                                        renderSuggestion={locationNamerenderSuggestion}
                                                        getSuggestionValue={locationNamegetSuggestionValue}
                                                        inputProps={locationNameinputProps} />
                                                    <div className="close-icon-container">
                                                        {locationclearButton}
                                                    </div>
                                                </div>

                                                <div className="searchDivRow" >სახელმწიფო</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={this.props.classificators.country} changeHandle={this.changeCountryHandle.bind(this)} selectedOption={this.state.data.country} />
                                                <div className="searchDivRow" >რეგიონი</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={
                                                    this.props.classificators.region.filter(i =>
                                                        this.state.data.country.indexOf(i.parentName) > -1
                                                        || (this.state.data.country.length === 0 && (i.other !== true || i.parentName === this.props.classificators.country[0].name)))
                                                } changeHandle={this.changeRegionHandle.bind(this)} selectedOption={this.state.data.region} />
                                                <div className="searchDivRow" >რაიონი/მუნიციპალიტეტი</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={
                                                    this.props.classificators.municipality.filter(i =>
                                                        this.state.data.region.indexOf(i.parentName) > -1
                                                        || (
                                                            this.state.data.region.length === 0 && (this.state.data.country.length === 0 || this.state.data.country.indexOf(this.props.classificators.country[0].name) > -1)
                                                            && (i.other !== true || i.parentName === this.props.classificators.country[0].name)
                                                        )
                                                        || this.state.data.country.indexOf(i.parentName) > -1)
                                                } changeHandle={this.changeMunicipalityHandle.bind(this)} selectedOption={this.state.data.municipality} />
                                                <div className="searchDivRow" >დასახლებული პუნქტი</div>
                                                <CustomCreatableMultipleSelectSearch onKeyDown={this.onKeyPress.bind(this)} isDisabled={false} items={
                                                    this.props.classificators.settlement.filter(i =>
                                                        this.state.data.municipality.indexOf(i.parentName) > -1
                                                        || (this.state.data.municipality.length === 0
                                                            && (i.other !== true || i.parentName === this.props.classificators.country[0].name)
                                                            && (this.state.data.country.length === 0 || this.state.data.country.indexOf(this.props.classificators.country[0].name) > -1))
                                                        || this.state.data.country.indexOf(i.parentName) > -1)}
                                                    changeHandle={this.changeSettlementHandle.bind(this)} selectedOption={this.state.data.settlement} />
                                                <div className="searchDivRow" >მისამართი</div>
                                                <CustomCreatableMultipleSelectSearch onKeyDown={this.onKeyPress.bind(this)} isDisabled={this.state.data.settlement.length === 0} items={
                                                    this.props.classificators.street.filter(i => this.state.data.settlement.indexOf(i.parentName) > -1)}
                                                    changeHandle={this.changeStreetHandle.bind(this)} selectedOption={this.state.data.street}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                                <Accordion defaultActiveKey={oldlocationOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                                    <Card className="modelCard">
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey="1" className="FullGalleryHeader" isOpen={oldlocationOpen}>წარმომავლობა</CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <div className="searchDivRow" >სახელმწიფო</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={this.props.classificators.country} changeHandle={this.changeOldCountryHandle.bind(this)} selectedOption={this.state.data.oldcountry} />
                                                <div className="searchDivRow" >რეგიონი</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={
                                                    this.props.classificators.region.filter(i =>
                                                        this.state.data.oldcountry.indexOf(i.parentName) > -1
                                                        || (this.state.data.oldcountry.length === 0 && (i.other !== true || i.parentName === this.props.classificators.country[0].name)))
                                                } changeHandle={this.changeOldRegionHandle.bind(this)} selectedOption={this.state.data.oldregion} />
                                                <div className="searchDivRow" >რაიონი/მუნიციპალიტეტი</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={
                                                    this.props.classificators.municipality.filter(i =>
                                                        this.state.data.oldregion.indexOf(i.parentName) > -1
                                                        || (
                                                            this.state.data.oldregion.length === 0 && (this.state.data.oldcountry.length === 0 || this.state.data.oldcountry.indexOf(this.props.classificators.country[0].name) > -1)
                                                            && (i.other !== true || i.parentName === this.props.classificators.country[0].name)
                                                        )
                                                        || this.state.data.oldcountry.indexOf(i.parentName) > -1)
                                                } changeHandle={this.changeOldMunicipalityHandle.bind(this)} selectedOption={this.state.data.oldmunicipality} />
                                                <div className="searchDivRow" >დასახლებული პუნქტი</div>
                                                <CustomCreatableMultipleSelectSearch onKeyDown={this.onKeyPress.bind(this)} isDisabled={false} items={
                                                    this.props.classificators.settlement.filter(i =>
                                                        this.state.data.oldmunicipality.indexOf(i.parentName) > -1
                                                        || (this.state.data.oldmunicipality.length === 0
                                                            && (i.other !== true || i.parentName === this.props.classificators.country[0].name)
                                                            && (this.state.data.oldcountry.length === 0 || this.state.data.oldcountry.indexOf(this.props.classificators.country[0].name) > -1))
                                                        || this.state.data.oldcountry.indexOf(i.parentName) > -1)}
                                                    changeHandle={this.changeOldSettlementHandle.bind(this)} selectedOption={this.state.data.oldsettlement} />
                                                <div className="searchDivRow" >მისამართი</div>
                                                <CustomCreatableMultipleSelectSearch onKeyDown={this.onKeyPress.bind(this)} isDisabled={this.state.data.oldsettlement.length === 0} items={
                                                    this.props.classificators.oldstreet.filter(i => this.state.data.oldsettlement.indexOf(i.parentName) > -1)}
                                                    changeHandle={this.changeOldStreetHandle.bind(this)} selectedOption={this.state.data.oldstreet}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            <div className="searchDiv">
                                <div onClick={this.handleClearData.bind(this)} className="NoFillSearchButton">გაუქმება</div>
                                <button type="submit" className="MainButton">ძიება</button>
                            </div>
                        </Form>
                    </Modal.Body>

                </Modal>
            </>
        );
    }
}

