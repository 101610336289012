import React, { Component } from 'react';
//import SplitPane, { Pane } from 'react-split-pane';
import './ImmovableList.css';
import { WebMapView } from './WebMapView';
import { ObjectListView } from './ObjectListView';
import { Footer } from './Footer';
export class ImmovableList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            viewState: 0,
            objToZoom: null,
            type: props.match.params.type,
            reload: true,
            fullScreeenIcon: "esri-icon-zoom-out-fixed"
        };

        this.handler = this.handler.bind(this)
        this.hideMapState = this.hideMapState.bind(this)
        this.hideMapState2 = this.hideMapState2.bind(this)
        this.openMapState = this.openMapState.bind(this)

        this.tableView = React.createRef()

    }



    componentDidMount() {
      
        this.updatecomponentText();
    }

    componentDidUpdate() {

        if (this.state.viewState === 2) {
            window.scrollTo(0, 0);            
        }
       
        this.updatecomponentText();
    }


    updatecomponentText() {
        var el = document.querySelector('.searchComponentButton');
        el.style.disabled = true;
        switch (this.props.match.params.type) {
            case "immovable":
                el.textContent = "უძრავი ძეგლი";
                break;
            case "movable":
                el.textContent = "მოძრავი ძეგლი";
                break;
            case "wallart":
                el.textContent = "კედლის მხატვრობა";
                break;
            case "stoneengrave":
                el.textContent = "ქვაზე კვეთილობა";
                break;
            case "zone":
                el.textContent = "ზოგადი დამცავი ზონა";
                break;
            case "museum":
                el.textContent = "მუზეუმ-ნაკრძალი/მუზეუმი";
                break;
            case "event":
                el.textContent = "ქმედება";
                break;
            default:
                break;
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match.params.type !== state.type) {
            sessionStorage.clear();

            return {
                type: props.match.params.type,
            };
        }
        return null;
    }


    hideMap() {
        //document.querySelector('.SplitPane').classList.add('CustomSplitPane');
        document.getElementsByClassName('mapView')[0].classList.add('displayNone');
        document.getElementsByClassName('listView')[0].classList.add('PanelWidth');
        if (document.getElementsByClassName('gotoLocButton').length > 0) {
            document.getElementsByClassName('gotoLocButton')[0].classList.add('displayNone');
        }
        document.getElementsByClassName('openMapButton')[0].classList.remove('displayNone');
        document.getElementsByClassName('ChangeStateButton')[0].classList.add('displayNone');

    }

    handler(objToZoom = null) {
        this.setState({
            viewState: 2, fullScreeenIcon: "esri-icon-zoom-in-fixed", objToZoom: objToZoom
        });
    }

    hideMapState() {
        this.setState({
            viewState: 1
        });
    }
    hideMapState2() {
        this.setState({
            viewState: 0, fullScreeenIcon: "esri-icon-zoom-out-fixed"
        });
    }
    openMapState() {
        this.setState({ viewState: 3, fullScreeenIcon: "esri-icon-zoom-in-fixed" })
    }

    getImgDivClass() {
        switch (this.props.match.params.type) {
            case "immovable":
                return "imgDivImmovable";
            case "movable":
                return "imgDivMovable";
            case "wallart":
                return "imgDivWallArt";
            case "stoneengrave":
                return "imgDivStoneEngrave";
            case "zone":
                return "imgDivZone";
            case "museum":
                return "imgDivMuseum";
            case "event":
                return "imgDivEvent";
            case "document":
                return "imgDivDocumentBig";
            case "all":
                return "";
            default:
                return "imgDivImmovable";
        }
    }
    static displayName = ImmovableList.name;


    render() {
       
        window.scrollTo(0, 0);
        return (
            this.state.viewState === 0 ?
                <div >
                    <div className="immListDiv" style={{ display: 'inline-flex', width: '100%' }}>
                        <div className="listView">
                            <ObjectListView type={this.state.type} handler={this.handler} mapfullClick={this.openMapState} ref={this.tableView} />
                        </div>
                        <div className="mapView">
                            <WebMapView type={this.state.type} right="15px" fullScreeenIcon={this.state.fullScreeenIcon} fullClick={this.openMapState} tableView={this.tableView}>
                                <button className="ChangeStateButton icon-right-open-big" onClick={this.hideMap}></button>
                         </WebMapView>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
                :
                this.state.viewState === 1 ?
                    <div className="immListDiv">
                        <div className="listView PanelWidth">
                            {
                                <ObjectListView type={this.props.match.params.type} handler={this.handler} />
                            }
                        </div>
                        <Footer></Footer>
                    </div>
                    :
                    <div className="immListDiv">
                        <div style={{ display: 'none' }}>
                            <ObjectListView type={this.state.type} handler={this.handler} mapfullClick={this.openMapState} ref={this.tableView} />
                        </div>
                        <div className="fullmapview PanelWidth">
                            <WebMapView type={this.state.type} right="15px" docPopup={this.state.viewState === 2 ? true : false} fullClick={this.state.viewState === 2 ? this.hideMapState : this.hideMapState2} fullScreeenIcon={this.state.fullScreeenIcon} objToZoom={this.state.objToZoom} tableView={this.tableView}>

                            </WebMapView>
                        </div>
                    </div>





        );
    }

}