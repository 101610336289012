import React from 'react';
import Modal from 'react-bootstrap/Modal'
import InputRange from 'react-input-range'

export class Location extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: this.props.showLocation,
            radius: 5,
            disabled: false,
            checked: true
        };

    }

    componentDidMount() {
        sessionStorage.setItem('locationRadius', 5);
        sessionStorage.setItem('useLocationFilter', true);
    }

    handleCheckChange = e => {
        this.setState({ disabled: !e.target.checked, checked: e.target.checked })
        sessionStorage.setItem('useLocationFilter', e.target.checked);
    };

    handleRadius = e => {
       
        sessionStorage.setItem('locationRadius', e);

        this.setState({ radius: e })
    }

    render() {

        return (
            <Modal show={this.props.showLocation} onHide={this.props.hideLocationModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="ModalHeader">ჩემთან ახლოს</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {

                    }
                    <div>
                        <div className="checkbox checkbox-success checkboxlist"
                            style={{ display: 'flex', marginBottom: 'auto', marginTop: 'auto' }}>
                            <input id="check_arch"
                                type="checkbox"
                                name="arch"
                                checked={this.state.checked}
                                onChange={this.handleCheckChange} />
                            <label htmlFor={"check_arch"}>ობიექტების დაფილტვრა</label>
                        </div>
                    </div>
                    <div style={{ margin: '10px' }}>
                        რადიუსი (კმ.)
                    </div>
                    <div style={{ margin:'30px 10px' }}>                        
                        <InputRange
                            maxValue={25}
                            minValue={1}
                            step={1}
                            disabled={this.state.disabled}
                            value={this.state.radius}                           
                            onChange={value => this.handleRadius(value)} />
                       
                    </div>
                    <div style={{ margin: '10px' }}>
                        <button className={`SecondaryButtonOrange ${sessionStorage.getItem('locationFilter') === 'on' ? 'active' : ''} icon-location-outline`} onClick={this.props.onClick}>ჩემთან ახლოს</button>
                    </div>
                    <div style={{ margin: '10px' }}>
                        <button className={`SecondaryButtonOrange ${sessionStorage.getItem('locationFilter') === 'on' ? 'active' : ''} icon-location-outline`} onClick={this.props.onClick}>მონიშვნა</button>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        );
    }
}