import React from 'react';

import { authenticationService } from '../_services';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';


class RecoverPassword extends React.Component {
    constructor(props) {
        super(props);

        // redirect to home if already logged in
        if (authenticationService.currentUserValue) {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <div className="immListDiv" style={{
                backgroundColor: 'white'
            }}>

                <div style={{ backgroundColor: 'white', width: '100%', height: '100%', padding: '30px' }}>

                    <div style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="boldHeade" style={{ padding: '30px' }}>
                            პაროლის აღდგენა
                        </div>
                        <Formik
                            initialValues={{
                                email: ''
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().required('შეიყვანეთ ელ.ფოსტა')

                            })}
                            onSubmit={({ email }, { setStatus, setSubmitting }) => {
                                setStatus();
                                authenticationService.resetPassword(email)
                                    .then(
                                        user => {
                                            if (user.indexOf('error:') > -1) {
                                                setSubmitting(false);
                                                setStatus(user.replace('error:',''));
                                            }
                                            else {
                                                const { from } = this.props.location.state || { from: { pathname: "/Confirmation" } };
                                                this.props.history.push(from);
                                            }
                                        },
                                        error => {
                                            setSubmitting(false);
                                            setStatus(error);
                                        }
                                    );
                            }}
                            render={({ errors, status, touched, isSubmitting }) => (
                                <Form>
                                    {status &&
                                        <div className={'alert alert-danger'}>{status}</div>
                                    }
                                    <div className="form-group">
                                        <Field name="email" type="email" className={'form-control contactText' + (errors.email && touched.email ? ' is-invalid' : '')} placeholder="ელ.ფოსტა"/>
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="MainButton" disabled={isSubmitting}>პაროლის აღდგენა</button>
                                        {isSubmitting &&
                                            <img alt="" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        }
                                    </div>

                                </Form>
                            )}
                        />
                    </div>

                </div>
            </div>

        )
    }
}

export { RecoverPassword }; 