import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { GalleryFull } from './GalleryFull';


export class Gallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            showBullets: true,
            showFullscreenButton: true,
            showGalleryFullscreenButton: false,
            showPlayButton: false,
            showGalleryPlayButton: true,
            showNav: true,
            isRTL: false,
            showVideo: {},
            images: [],
            activeType:""
        };
    }

    componentDidMount() {
        if (this.props.photo && this.props.photo.length)
            this.toogleGalleryActiveLink(0);
        else if (this.props.video && this.props.video.length)
            this.toogleGalleryActiveLink(1);
        else if (this.props.vid3D && this.props.vid3D.length)
            this.toogleGalleryActiveLink(2);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.slideInterval !== prevState.slideInterval ||
            this.state.slideDuration !== prevState.slideDuration) {
            // refresh setInterval
            this._imageGallery.pause();
            this._imageGallery.play();
        }
    }

    _onSlide(index) {
        this._resetVideo();
        console.debug('slid to index', index);
    }


    toogleGalleryActiveLink(val) {

        if (document.getElementsByClassName('galleryActiveLink').length > 0) {
            document.getElementsByClassName('galleryActiveLink')[0].classList.remove('active');
            document.getElementsByClassName('galleryActiveLink')[1].classList.remove('active');
            document.getElementsByClassName('galleryActiveLink')[2].classList.remove('active');
            document.getElementsByClassName('galleryActiveLink')[val].classList.add('active');
        }

        switch (val) {
            case 0:
                this.setState({ images: this._getImages(), activeType:"0"});
                break;
            case 1:
                this.setState({ images: this._getVideos(), activeType: "1" });
                break;
            case 2:
                this.setState({ images: this._get3DModels(), activeType: "2" });
                break;
            default:
                this.setState({ images: this._getImages(), activeType: "0" });
                break;
        }

    }

    formatUrl(url) {
        var str = url;
        var regex = /<iframe.*?src='(.*?)'/;
        var src = (regex.exec(str) != null && regex.exec(str).length) > 0 ? regex.exec(str)[1] : url;
        var urlparts = src.split('?');   
        var result = urlparts[0];     
        return result;
    }

    formatUrlThumbnail(url) {
        var str = url;
        var regex = /<iframe.*?src='(.*?)'/;
        var src = (regex.exec(str) != null && regex.exec(str).length) > 0 ? regex.exec(str)[1] : url;
        var urlparts = src.split('?');
        var result = urlparts[0];        
        return result;
    }

    _getVideos() {
        let images = [];
        if (this.props.video != null) {
            this.props.video.map(item =>
                images.push(
                    {
                        thumbnail: this.formatUrl(item).indexOf('youtube') > -1 ? 'https://img.youtube.com/vi/' + this.formatUrl(item).replace('https://www.youtube.com/embed/','') +'/0.jpg':'/video.jpg',
                        original: this.formatUrl(item).indexOf('youtube') > -1 ? 'https://img.youtube.com/vi/' + this.formatUrl(item).replace('https://www.youtube.com/embed/', '') + '/0.jpg' : '/video.jpg',
                        embedUrl: this.formatUrl(item),
                        renderItem: this._renderVideo.bind(this),
                        //renderThumbInner: this._renderVideoThumbInner.bind(this),
                        
                    })
            );
        }

        else {
            images.push({
                original: '/video.jpg',
                thumbnail: '/video.jpg'
            });
        }
        return images;
    }

    _get3DModels() {
        let images = [];
        if (this.props.vid3D != null) {
            this.props.vid3D.map(item =>
                images.push(
                    {
                        thumbnail: item.docFile,
                        original: item.docFile,
                        embedUrl: this.formatUrl(item.comment),
                        renderItem: this._renderVideo.bind(this),
                       // renderThumbInner: this._renderVideoThumbInner.bind(this),
                    })
            );
        }

        else {
            images.push({
                original: '/video.jpg',
                thumbnail: '/video.jpg'
            });
        }
        return images;
    }

    _getClass() {
        var type;
        switch (this.props.type) {
            case "immovable":
                type = 'ImageDivImmovable';
                break;
            case "movable":
                type = 'ImageDivMovable';;
                break;
            case "zone":
                type = 'ImageDivZone';
                break;
            case "museum":
                type = 'ImageDivMuseumMain';
                break;
            case "wallart":
                type = 'ImageDivWallArtMain';
                break;
            case "stoneengrave":
                type = 'ImageDivStoneEngraveMain';
                break;
            case "event":
                type = 'ImageDivEvent';
                break;
            default: type = '';
                break;
        }
        return type;
    }

    _getType() {
        var type;
        switch (this.props.type) {
            case "immovable":
                type = '/immovable.svg';
                break;
            case "movable":
                type = '/movable.svg';;
                break;
            case "zone":
                type = '/zone.svg';
                break;
            case "museum":
                type = '/museum.svg';
                break;
            case "wallart":
                type = '/wallart.svg';
                break;
            case "stoneengrave":
                type = '/stoneengrave.svg';
                break;
            case "event":
                type = '/event.svg';
                break;
            case "document":
                type = '/document.svg';
                break;
            default: type = '';
                break;
        }
        return type;
    }

    _getImages() {

        var type = this._getType();

        let images = [];
        if (this.props.photo != null) {
            this.props.photo.slice(0, 50).map(item =>
                images.push({
                    original: item.docFile,
                    thumbnail: item.docFile
                })
            );
        }
        else {

            images.push({
                original: type,
                thumbnail: type
            });
        }
        return images;
    }

    _resetVideo() {
        this.setState({ showVideo: {} });

        if (this.state.showPlayButton) {
            this.setState({ showGalleryPlayButton: true });
        }

        if (this.state.showFullscreenButton) {
            this.setState({ showGalleryFullscreenButton: true });
        }
    }

    _toggleShowVideo(url) {
        this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
        this.setState({
            showVideo: this.state.showVideo
        });

        if (this.state.showVideo[url]) {
            if (this.state.showPlayButton) {
                this.setState({ showGalleryPlayButton: false });
            }

            if (this.state.showFullscreenButton) {
                this.setState({ showGalleryFullscreenButton: false });
            }
        }
    }

    _renderVideo(item) {
        return (
            <div>
                {
                    //this.state.showVideo[item.embedUrl] ?
                        <div className='video-wrapper'>
                        { //<div
                            //    className='close-video'
                            //    onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                            //>
                            //</div>
                        }
                            <iframe title="temp"
                                className="image-gallery-image"
                                style={{ height: '350px' }}
                                src={item.embedUrl}
                            frameBorder='0'
                            allowFullScreen
                            >
                            </iframe>
                        </div>
                        //:
                        //<div onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
                        //    <div className='play-button'></div>
                        //    <img className='image-gallery-image' src={item.original} alt="" />
                        //    {
                        //        item.description &&
                        //        <span
                        //            className='image-gallery-description'
                        //            style={{ right: '0', left: 'initial' }}
                        //        >
                        //            {item.description}
                        //        </span>
                        //    }
                        //</div>
                }
            </div>
        );
    }


    _renderVideoThumbInner(item) {
        return (
            <div>
                {
                    //this.state.showVideo[item.embedUrl] ?
                    //<div className='video-wrapper'>
                      
                    //    <iframe title="temp"
                    //        className="image-gallery-thumbnail-image"
                    //        src={this.formatUrlThumbnail(item.embedUrl)}
                    //        frameBorder='0'
                    //        allowFullScreen
                    //    >
                    //    </iframe>
                    //</div>
                    //:
                    //<div onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
                    //    <div className='play-button'></div>
                    //    <img className='image-gallery-image' src={item.original} alt="" />
                    //    {
                    //        item.description &&
                    //        <span
                    //            className='image-gallery-description'
                    //            style={{ right: '0', left: 'initial' }}
                    //        >
                    //            {item.description}
                    //        </span>
                    //    }
                    //</div>
                }
            </div>
        );
    }



    handleClose = () => this.setState({ openModal: false });
    handleShow = () => this.setState({ openModal: true });

    render() {

        return (
            <div style={{ height: '100%' }}>
                {
                    ((this.props.video && this.props.video.length > 0) || (this.props.photo && this.props.photo.length > 0) || (this.props.vid3D && this.props.vid3D.length > 0)) ?
                        <div>
                            {
                                (this.props.video && this.props.video.length > 0) || (this.props.vid3D && this.props.vid3D.length > 0) ?
                                    <div style={{ display: "inline-flex", marginBottom: '10px' }}>
                                        <div className={`galleryActiveLink marginLeft ${(this.props.photo && this.props.photo.length > 0) ? 'active' : 'displayNone'}`} onClick={() => this.toogleGalleryActiveLink(0)}>ფოტო გალერია</div>
                                        <div className={`galleryActiveLink marginLeft ${(this.props.video && this.props.video.length > 0) ? ((this.props.photo && this.props.photo.length) > 0 ? '' : 'active') : 'displayNone'}`} onClick={() => this.toogleGalleryActiveLink(1)} >ვიდეო გალერია</div>
                                        <div className={`galleryActiveLink marginLeft ${(this.props.vid3D && this.props.vid3D.length > 0) ? ((this.props.photo && this.props.photo.length > 0) || (this.props.video && this.props.video.length > 0) ? '' : 'active') : 'displayNone'}`} onClick={() => this.toogleGalleryActiveLink(2)} >3D ობიექტები</div>
                                    </div>
                                    : <></>
                            }
                            <GalleryFull show={this.state.openModal} close={this.handleClose} photo={this.props.photo} video={this.props.video} vid3D={this.props.vid3D} mainphoto={this.props.mainphoto} type={this.props.type} authors={this.props.authors}></GalleryFull>
                            <div >
                                <ImageGallery
                                    ref={i => this._imageGallery = i}
                                    items={this.state.images}
                                    lazyLoad={false}
                                    //infinite={this.state.infinite}
                                    onClick={this.state.activeType==="0"? this.handleShow:null}
                                    showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
                                    showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
                                    showThumbnails={this.state.showThumbnails}
                                    showIndex={this.state.showIndex}
                                    showNav={this.state.showNav}
                                    isRTL={this.state.isRTL}
                                    additionalClass="app-image-gallery"
                                />
                            </div>
                        </div>
                        :
                        <div alt="" src="" className={this._getClass()} style={{
                            width: '100%', height: '100%', backgroundColor: '#DBCCBE', backgroundSize: '150px', minHeight: '250px'
                        }}></div>

                }
            </div>
        );
    }
}
