import React, { Component } from 'react';
import './Object.css';
import { Row, Col, Accordion, Card } from 'react-bootstrap'

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { CustomToggle } from '../custom/CustomToggle'
import { ObjectListForLinks } from '../ObjectListForLinks';
import { FullDescModal } from './FullDescModal';
import { DocObjectListForLinksTable } from '../DocObjectListForLinksTable';
import { EventObjectListForLinksTable } from '../EventObjectListForLinksTable';
import { WebMapView, filterMap } from '../WebMapView';
import { Gallery } from './Gallery';
import Avatar from 'react-avatar';
import { Footer } from '../Footer';
import ReactTooltip from "react-tooltip";
import PulseLoader from "react-spinners/PulseLoader";

import { authHeader, handleResponse } from '../../_helpers';
import { authenticationService } from '../../_services';

import { AddToCollection } from '../AddToCollection';
import { Share } from '../Share';

export class EventObject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            isAdmin: false,
            favorite: false,
            event: {}, 
            objectID: null,
            fullScreeenIcon: "esri-icon-zoom-out-fixed",
            mapClass: '',
            loading: true
        };

        this.fullScreenMode = this.fullScreenMode.bind(this)
    }
    componentDidMount() {
        var el = document.querySelector('.searchComponentButton');
        el.textContent = "ქმედება";

        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x, isAdmin: x && x.role === "Admin"
        }));

        this.loadPage();
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const id = parseInt(params.get('id'));
        if (id !== null) {
            var type = `api/Event/GetEventObject?id=${id}`;
            

            if (this.state.currentUser != null) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                const response = await fetch(type, requestOptions);
                const data = await response.json();
                this.setState({ event: data, loading: false, favorite: data.isFavorite, objectID: data.id });
            }
            else {

                const response = await fetch(type);
                const data = await response.json();
                this.setState({ event: data, loading: false, favorite: data.isFavorite, objectID: data.id });
            }

        }
    }

    toogleFullDesc() {
        if (document.getElementsByClassName('DescTextFull')[0].classList.contains('displayNone')) {
            document.getElementsByClassName('DescTextFull')[0].classList.remove('displayNone');
            document.getElementsByClassName('DescText')[0].classList.add('displayNone');
        }
        else {
            document.getElementsByClassName('DescTextFull')[0].classList.add('displayNone');
            document.getElementsByClassName('DescText')[0].classList.remove('displayNone');
        }

    }


    static displayName = EventObject.name;

    addToFavorite(id) {
        if (this.state.favorite === false) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            fetch(`api/Users/AddToFvorite?folderID=0&objectType=event&objectID=${id}`, requestOptions)
                .then(data => {
                    this.setState({ favorite: true });
                });
        }
        else {
            var result = window.confirm("გსურთ წაშლა?");
            if (result) {
                const requestOptions = {
                    method: 'GET',
                    headers: authHeader()
                };
                return fetch(`api/Users/DeleteFromFvorite?objectType=event&objectID=${id}`, requestOptions)
                    .then(data => {
                        this.setState({ favorite: false });
                    });
            }
        }
    }
    hadleFavoriteStatus(value) {
        this.setState({ favorite: value });
    }

    fullScreenMode() {
        if (this.state.mapClass === '') {
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-in-fixed', mapClass: 'objectFullScreentMap', objectID: this.state.event.id})
        }
        else {

            document.getElementById('toolbarDiv').classList.add('displayNone');
            document.getElementById('menuDiv').classList.add('displayNone')
            //this.setState({ fullScreeenIcon: 'esri-icon-zoom-out-fixed', mapClass: '' })
            this.setState({ fullScreeenIcon: 'esri-icon-zoom-out-fixed', mapClass: '', objectID: this.state.event.id })
        }

    }
    render() {
        const event = this.state.event;

        window.FB = null;
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            // if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/ka_GE/sdk.js#xfbml=1&autoLogAppEvents=1&version=v8.0&appId=1607074352807965";
            js.nonce = "q90Xb87m";

            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));


        //if (filterMap != null && event.id!=null) {
        //    filterMap(this.props.type, event.id);
        //}

        return (
            <div className="immObjDiv">
                {event && event.id > 0 ?
                    <div >
                        <div className="containerDiv">
                            <Row className="rowPadding">
                                <Col md={5}>
                                <Gallery photo={event.photoIds} video={event.videoIds} vid3D={event._3dobjects} mainphoto={event.photoAddress} type="event" authors={event.docAuthors}/>
                                </Col>
                                <Col md={7}>
                                  
                                    <div className="ObjectType">ქმედება</div>
                                    <div className="ObjectMainHeader">
                                        <span className="ObjectMainHeaderSpan"> {event.objectName}{event.etapi === 'ქმედების ეტაპი' ? '(' + event.etapi + ')' : ''}  - #{event.id}</span>
                                        <Share header="გაზიარება" customClass="objectShare" />
                                        {this.state.currentUser && this.state.isAdmin === false &&
                                            //<div style={{ float: 'right', cursor: 'pointer' }}><img width="20" alt="" src={this.state.favorite ? "/images/heartfill.svg" : "/images/heart.svg"} style={{ marginRight: '10px' }} onClick={() => this.addToFavorite(immovable.id)} />{' '}</div>
                                            <AddToCollection favorite={this.state.favorite} objID={event.id} setStatus={this.hadleFavoriteStatus.bind(this)}></AddToCollection>
                                        }
                                        
                                    </div>
                                    {event.etapi === 'ქმედების ეტაპი' ? <div><span className="headerText">ქმედება: </span><a style={{ color: "black" }} href={'../event/eventObject?id=' + event.parentEventID}>{event.parentEvent}</a></div> : <></>}
                                    <div><span className="headerText">ქმედების ტიპი: </span>{event.type}</div>
                                    <div style={{ marginBottom: '30px' }}><span className="headerText">დოკუმენტის ნომერი: </span>{event.docNum}</div>
                                    <div ><span className="headerText">განხორციელების თარიღი: </span>{event.date}</div>

                                    <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '30px' }} />

                                    <div className="headerText">საფუძველი/მიზანი</div>
                                    <div className="DescText">
                                        {event.mizani}
                                        <div onClick={this.toogleFullDesc} className="toggleFullDesc">
                                            <svg className="bi bi-chevron-double-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 01.708 0L8 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 01.708 0L8 8.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="DescTextFull displayNone">{event.mizaniFull}
                                        <div onClick={this.toogleFullDesc} style={{ textAlign: "center", padding: "20px" }}>
                                            <svg className="bi bi-chevron-double-up" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M7.646 2.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 3.707 2.354 9.354a.5.5 0 11-.708-.708l6-6z" clipRule="evenodd" />
                                                <path fillRule="evenodd" d="M7.646 6.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 7.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                        <FullDescModal buttonDesc="დეტალური აღწერა" objname={event.objectName} >
                                        <div className="PopupHeader">საფუძველი/მიზანი</div>
                                            {event.mizaniFull}
                                        </FullDescModal>
                                    </div>

                                </Col>
                            </Row>
                        <div className="ObjectHeaderNoTop" style={{ marginLeft: '35px', marginTop: '20px' }}>ადგილმდებარეობა</div>
                        <Row style={{ padding: '20px', width: '100%' }}>
                            <Col md={5}>
                                
                                <div style={{ display: 'inline-flex', width: '100%' }}>
                                    <img style={{ margin: '5px 20px 0 0', width: '18px', height: '22px' }} alt="" src="/images/map-pin.svg" />{' '}
                                    <div>
                                        <div>ქვეყანა: <span className="headerText">{event.country}</span></div>
                                        {event.region !== '' ? <div>რეგიონი: <span className="headerText">{event.region}</span></div> : <></>}
                                        {event.municipality !== '' ? <div>მუნიციპალიტეტი: <span className="headerText">{event.municipality}</span></div> : <></>}
                                        {event.settlement !== '' ? <div>დასახლებული პუნქტი: <span className="headerText">{event.settlement}</span></div> : <></>}
                                        {event.street !== '' ? <div>მისამართი: <span className="headerText">{event.street}</span></div> : <></>}
                                    </div>
                                </div>
                            </Col>
                                <Col md={7} style={{ minHeight: '250px' }} className="ObjectMap">
                                    
                                    <WebMapView type="event" objectID={this.state.objectID}  right="30px" fullScreeenIcon={this.state.fullScreeenIcon} fullClick={this.fullScreenMode.bind(this)} mapClass={this.state.mapClass} />

                            </Col>
                        </Row>

                            <Row className="accardionPadding">
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey="1">ქმედების განხორციელების ადგილი</CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <Table >
                                                    <Thead>
                                                        <Tr className="tableColHeader">
                                                            <Th style={{ padding: '10px' }}>სახელმწიფო</Th>
                                                            <Th style={{ padding: '10px' }}>რეგიონი</Th>
                                                            <Th style={{ padding: '10px' }}>მუნიციპალიტეტი</Th>
                                                            <Th style={{ padding: '10px' }}>დასახლებული პუნქტი</Th>
                                                            <Th style={{ padding: '10px' }}>მისამართი</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            event.eventLocation.map((item, i) =>
                                                                <Tr className="tableRow" key={i}>
                                                                    <Td style={{ padding: '10px' }}>
                                                                        {item.split(';')[0]}
                                                                    </Td>
                                                                    <Td style={{ padding: '10px' }}>
                                                                        {item.split(';')[1]}
                                                                    </Td>
                                                                    <Td style={{ padding: '10px' }}>
                                                                        {item.split(';')[2]}
                                                                    </Td>
                                                                    <Td style={{ padding: '10px' }}>
                                                                        {item.split(';')[3]}
                                                                    </Td>
                                                                    <Td style={{ padding: '10px' }}>
                                                                        {item.split(';')[4]}
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        }
                                                    </Tbody>
                                                </Table>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Row>


                    </div>
                    {
                        event.linksDisplay ? <p style={{ borderBottom: '1px solid #ECECEC', marginTop: '80px' }} /> : <></>

                    }
                    <div className="containerDiv">



                        <div className="accardionPadding ObjectHeaderNoTop">{event.linksDisplay ? 'დაკავშირებული კომპონენტები' : ''}</div>
                        {event.zoneLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1">ზოგადი დამცავი ზონა</CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={event.id} type="zone" objType="event" idList={event.zoneLinks} objName={event.objectName}/>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        {event.immovableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">უძრავი ძეგლი/ობიექტი {event.immovableLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={event.id} type="immovable" objType="event" idList={event.immovableLinks} objName={event.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        {event.movableLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მოძრავი ძეგლი/ობიექტი {event.movableLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={event.id} type="movable" objType="event" idList={event.movableLinks} objName={event.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        {event.museumLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">მუზეუმ-ნაკრძალი/მუზეუმი {event.museumLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={event.id} type="museum" objType="event" idList={event.museumLinks} objName={event.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        {event.wallLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">კედლის მხატვრობა {event.wallLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={event.id} type="wallart" objType="event" idList={event.wallLinks} objName={event.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                        {event.stoneLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">ქვაზე კვეთილობა {event.stoneLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <ObjectListForLinks objID={event.id} type="stoneengrave" objType="event" idList={event.stoneLinks} objName={event.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {event.docLinkExist ?
                                <Row className="accardionPadding">
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1"><span className="headerText">დოკუმენტაცია {event.docLinksCount}</span></CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body className="accardionBodyPadding">
                                                    <DocObjectListForLinksTable caption={{ type: "დოკუმენტის ტიპი", docNum: "ავტორი" }} objID={event.id} type="document"
                                                        objType="event" idList={event.docLinks} objName={event.objectName} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Row>
                                : <></>
                            }
                            {
                                event.eventLinkExist ?
                                    <Row className="accardionPadding">
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Card.Header className="TogglePanelHeader">
                                                    <CustomToggle eventKey="1"><span className="headerText">ქმედება</span></CustomToggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body className="accardionBodyPadding">
                                                        {
                                                            <EventObjectListForLinksTable caption={{ type: "ქმედების ტიპი", docNum: "ნომერი" }} objID={event.id} type="event" objType="event" idList={event.eventLinks} objName={event.objectName}/>
                                                        }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Row>
                                    : <></>
                            }
                        </div>

                        <Footer>
                        <div className="containerDiv" style={{ paddingTop: '40px', paddingLeft: '20px' }}>
                            <Row >
                                <Col md={5}>
                                    <div style={{ fontFamily: 'HelveticaBold', fontSize: '16px' }}>ინფორმაციის შემდგენელი</div>
                                    <div style={{ marginBottom: '20px' }}>თარიღი: <span className="headerText">{event.cardCreationDate}</span></div>
                                    <div style={{ display: 'inline-flex' }}>
                                        <Avatar title={event.cardCreator[0].name} src={event.cardCreator[0].photo} size={50} round="50px" />
                                        <span className="avatarText" data-tip data-for="allAuthors">{event.cardCreator.length > 1 ? `${event.cardCreator[0].name} \n\r(+ ${event.cardCreator.length - 1} ავტორი)`
                                            : event.cardCreator[0].name}</span>
                                    </div>
                                    {event.cardCreator.length > 1 ?
                                        <ReactTooltip className="customZIndex" id="allAuthors" effect='solid' backgroundColor="#FFFFFF" textColor="#000000" place="bottom" border={true} offset={{ top: 70, left: -35 }}>
                                            <span>
                                                <ul className="periodList">{
                                                    event.cardCreator.map((item, i) => {
                                                        return <div style={{ marginTop: '5px' }} key={i}> <Avatar title={item.name} src={item.photo} size={30} round="30px" />
                                                            <span style={{ marginLeft: '10px' }}>{item.name}</span></div>
                                                            ;
                                                    })
                                                }</ul>
                                            </span>
                                        </ReactTooltip>
                                        : <></>
                                    }
                                </Col>
                                    <Col md={7}>
                                        <p style={{ borderBottom: '1px solid #ECECEC' }} className="facebookCOmmentP" />
                                        <div className="fb-comments" data-href={window.location.href} data-numposts="5" data-width="" data-mobile="true"></div>
                                </Col>
                            </Row>
                        </div>
                        </Footer>
                    </div>
                     : <div style={{
                    marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center'
                }}>
                    <PulseLoader
                        color={"#E57959"}
                        loading={true}
                    />
                </div>

                }
            </div>
        );
    }

}