import React from 'react';
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
//import './Home.css';


class ObjectListViewPart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            loading: true
        };
    }

    componentDidMount() {
        this.loadPage();
    }

    componentDidUpdate() {
        this.loadPage();
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        var id = this.props.objID;

        var fileter = params.get('filterText') || '';
        var fileterStr = fileter == null ? '' : '&filterText=' + fileter;

        var type;
        switch (this.props.type) {
            case "wallart":
                type = `api/WallArt/GetWallArtPaintings?objID=${id}` + fileterStr;
                break;
            case "stoneengrave":
                type = `api/StoneEngrave/GetStoneEngraveMemberships?objID=${id}` + fileterStr;
                break;
            default: type = '';
                break;
        }

        var res = sessionStorage.getItem('filterResult') || '';

        const response = await fetch(type, {
            method: "POST",
            body: JSON.stringify(res),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        //const response = await fetch(type);
        const data = await response.json();
        this.setState({ items: data, loading: false });

    }

    drawImovableList(forecasts, className) {

        return (
            <CardGroup>
                {forecasts.map(forecast =>
                    <a key={forecast.id} href={'../' + forecast.linkPath} className="mainCard">
                        <Card className="cardHorizonalListView">
                            <div className={`${forecast.photoAddress === '' ? className : 'imgDivPartObjList'}`} style={{ borderRadius: "5px" }}>
                                {
                                    forecast.photoAddress == null || forecast.photoAddress === '' ?
                                        <></> :
                                        <img alt="" style={{ borderRadius: "5px", width: '100%', height: '100%' }} variant="top" src={forecast.photoAddress} />
                                }
                                
                            </div>
                            <Card.Body className="objectList_card-body" style={{ fontSize: "13px", marginTop: "0" }}>
                                <Card.Title className="objectList_card-title card-link">{forecast.objectName} - #{forecast.id}</Card.Title>
                                <div className="objectTypeList" style={{ fontSize: "13px" }}>{forecast.period}</div>
                            </Card.Body>
                        </Card>
                        <p style={{ borderBottom: '1px solid #EFEFEF', marginTop: '20px', marginBottom:'20px' }} />
                    </a>
                )}
            </CardGroup>
        );
    }


    render() {
        const { items } = this.state;
        return (
            <div className="card text-center">
                <div className="card-body">
                    {
                        this.state.loading
                            ? <p><em>Loading...</em></p>
                            : this.drawImovableList(items, this.props.imgClassName)
                    }

                </div>
            </div>
        );
    }
}

export { ObjectListViewPart };