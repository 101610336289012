import React from 'react';
import { Modal, Accordion, Card } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import './Search.css';
import { CustomMultipleSelect } from '../custom/CustomMultipleSelect';
import { CustomToggle } from '../custom/CustomToggle'
import { CustomTreeSelect } from '../custom/CustomTreeSelect';
import { CustomCreatableMultipleSelect } from '../custom/CustomCreatableMultipleSelect';
import { CustomCreatableMultipleSelectSearch } from '../custom/CustomCreatableMultipleSelectSearch';
import Autosuggest from 'react-autosuggest';
import CheckboxTree from 'react-checkbox-tree-enhanced';
import Select from 'react-select'

const getSuggestionValue = suggestion => suggestion;
const renderSuggestion = suggestion => (
    <div>
        {suggestion}
    </div>
);
const getSuggestionValueGanmcxadebeli = suggestion => suggestion;
const renderSuggestionGanmcxadebeli = suggestion => (
    <div>
        {suggestion}
    </div>
);
const nodesInterieri = [
    {
        value: 'ექსტერიერი',
        label: 'ექსტერიერი',
        children: [
            {
                value: 'არქიტექტურული ელემენტი/ნაწილი (კარი, სარკმელი,აივანი და ა.შ.)',
                label: 'არქიტექტურული ელემენტი/ნაწილი (კარი, სარკმელი,აივანი და ა.შ.)',
            },
            {
                value: 'კონსტრუქციული ელემენტი/ნაწილი (საძირკველი,კედელი, სახურავი და ა.შ.)',
                label: 'კონსტრუქციული ელემენტი/ნაწილი (საძირკველი,კედელი, სახურავი და ა.შ.)',
            },

            {
                value: 'დეკორატიული ელემენტი (რელიეფი, ორნამენტი და ა.შ.)',
                label: 'დეკორატიული ელემენტი (რელიეფი, ორნამენტი და ა.შ.)',
            },
            {
                value: 'კედლის მხატვრობა/ მოზაიკა',
                label: 'კედლის მხატვრობა/მოზაიკა',
            }
        ],
    },
    {

        value: 'ინტერიერი',
        label: 'ინტერიერი',
        children: [
            {
                value: 'არქიტექტურული ელემენტი/ ნაწილი(კარი, სარკმელი დაა.შ.)',
                label: 'არქიტექტურული ელემენტი/ ნაწილი(კარი, სარკმელი დაა.შ.)',


            },
            {
                value: 'კონსტრუქციული ელემენტი/ნაწილი (საძირკველი,კედელი და ა.შ.)',
                label: 'კონსტრუქციული ელემენტი/ნაწილი (საძირკველი,კედელი და ა.შ.)',
            }
            ,
            {
                value: 'დეკორატიული ელემენტი (რელიეფი, ორნამენტი და ა.შ)',
                label: 'დეკორატიული ელემენტი (რელიეფი, ორნამენტი და ა.შ)',
            }
            ,
            {
                value: 'კედლის მხატვრობა/მოზაიკა',
                label: 'კედლის მხატვრობა/მოზაიკა',
            }
        ],
    },
    {
        value: 'მემკვიდრეობის განზრახ დაზიანება',
        label: 'მემკვიდრეობის განზრახ დაზიანება',
    },
    {
        value: 'მიმდებარე არეალი (ეზო, ქუჩა, გზა და ა.შ.)',
        label: 'მემკვიდრეობის შემთხვევითი დაზიანება',
    }
    ,
    {
        value: 'სხვა',
        label: 'სხვა',
    }

];
export class DetailSearchModal extends React.Component {

    selectedPeriods = [];
    selectedFunction = [];
    selectedEventType = [];
    selectedDocumentType = [];
    selectedVarieties = [];

    tempselectedPeriods = [];
    tempselectedFunction = [];
    tempselectedEventType = [];
    tempselectedDocumentType = [];

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data:
            {
                id: '', name: '', eventStartDate: '', eventEndDate: '', docStartDate: '', docEndDate: '',
                variety: [], status: [], category: [], worldHeritage: [], period: [], function: [], eventType: [], documentType: [], sizuste: [],
                country: [], region: [], municipality: [], settlement: [], street: [],
                tempselectedPeriods: [], tempselectedFunction: [], tempselectedEventType: [], tempselectedDocumentType: [], linkedObj: {}, fullText: ''
                , gancxadebisMizaniArsebuli: false, gancxadebisMizaniMosalodneli: false, dazianebisAdgilmdebareoba: [], dazianebisXarisxi: [], dazianebisTipi: [], ganmcxadebeli: '', gancxadebisTariRi: ''
            },
            suggestions: []
            , DazianebisXarisxiList: []
            , DazianebisTipiList: []
            , suggestionsGanmcxadebeli: []
        };

        this.handleClearClick = this.handleClearClick.bind(this);

        this.onCheck1 = this.onCheck1.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onExpand = this.onExpand.bind(this);
        this.myInputRef = React.createRef();

    }

    componentDidMount() {
        //თუ სტორიჯი ცარიელი არაა მაშინ მოხდეს საბმიტი და დაიფილტროს ძეგლები
        if (localStorage.getItem("quickAlertForm") == "quickAlertForm") {
            this.prepareSubmit();
        }
        this.loadPage();
    }

    async loadPage() {
        let initialDazianebisXarisxi = [];
        fetch('api/UserAlert/getLkpDazianebisXarisxi')
            .then(response => {
                return response.json();
            }).then(data => {
                initialDazianebisXarisxi = data;
                this.setState({
                    DazianebisXarisxiList: initialDazianebisXarisxi,
                });
            });

        let initialDazianebisTipi = [];
        fetch('api/UserAlert/getLkpDazianebisTipi')
            .then(response => {
                return response.json();
            }).then(data => {
                initialDazianebisTipi = data;
                this.setState({
                    DazianebisTipiList: initialDazianebisTipi,
                });
            });
    }
    handleClose = () => this.setState({ show: false });
    handleShow = () => {
        this.setState({ show: true })

        if (sessionStorage.getItem("filterData") != null && sessionStorage.getItem("filterData") !== '') {
            var data = JSON.parse(JSON.parse(sessionStorage.getItem("filterData")));

            this.tempselectedPeriods = data.tempselectedPeriods;
            this.tempselectedFunction = data.tempselectedFunction;
            this.tempselectedDocumentType = data.tempselectedDocumentType;
            this.tempselectedEventType = data.tempselectedEventType;

            this.selectedPeriods = data.period;
            this.selectedFunction = data.function;
            this.selectedEventType = data.eventType;
            this.selectedDocumentType = data.documentType;
            this.selectedVarieties = data.variety;

            if (data.id === 0) data.id = '';
            this.setState({ data: data });
        }
    };

    onChange(event) {
        var data = this.state.data;
        switch (event.target.name) {
            case "id":
                data.id = parseInt(event.target.value) || ''
                break;
            case "eventStartDate":
                data.eventStartDate = parseInt(event.target.value) || ''
                break;
            case "eventEndDate":
                data.eventEndDate = parseInt(event.target.value) || ''
                break;
            case "docStartDate":
                data.docStartDate = parseInt(event.target.value) || ''
                break;
            case "docEndDate":
                data.docEndDate = parseInt(event.target.value) || ''
                break;
            case "gancxadebisTariRi":
                data.gancxadebisTariRi = event.target.value
                break;
            case "ganmcxadebeli":
                data.ganmcxadebeli = event.target.value
                break;
            case "gancxadebisMizaniArsebuli":
                if (event.target.checked) {
                    data.gancxadebisMizaniArsebuli = "არსებული დაზიანება"
                }
                else data.gancxadebisMizaniArsebuli = false
                break;
            case "gancxadebisMizaniMosalodneli":
                if (event.target.checked) {
                    data.gancxadebisMizaniMosalodneli = "მოსალოდნელი საფრთხე"
                }
                else data.gancxadebisMizaniMosalodneli = false
                break;

            default:
                break;
        }

        this.setState({ data: data });
    }
    onCheck1(checked1) {
        this.setState({ checked1 });

        var data = this.state.data;
        data.dazianebisAdgilmdebareoba = [];
        if (checked1 != null) {
            checked1.map(item =>
                data.dazianebisAdgilmdebareoba.push(item)
            );
        }
        this.setState({ data: data });

    }

    onClick(clicked) {
        this.setState({ clicked });
    }

    onExpand(expanded) {
        this.setState({ expanded });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.prepareSubmit();
    }
    prepareSubmit() {
        var data = this.state.data;
        data.period = this.selectedPeriods;
        data.function = this.selectedFunction;
        data.eventType = this.selectedEventType;
        data.documentType = this.selectedDocumentType;
        data.variety = this.selectedVarieties;

        data.tempselectedPeriods = this.tempselectedPeriods;
        data.tempselectedFunction = this.tempselectedFunction;
        data.tempselectedEventType = this.tempselectedEventType;
        data.tempselectedDocumentType = this.tempselectedDocumentType;



        //თუ სტორიჯი არაა ცარიელი მოახდინოს დაფილტვრა ძეგლების
        if (localStorage.getItem("quickAlertForm") == "quickAlertForm") {
            var data = this.state.data;
            var items = this.props.classificators.worldHeritage;
            data.name = "გელათის მონასტერი";
            //var data = this.state.data;
            //var items = this.props.classificators.worldHeritage;
            //data.worldHeritage = [];
            //if (items != null) {
            //    items.map(item =>
            //        data.worldHeritage.push(item.id)
            //    );
            //}
        }
        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));
        this.searchData(data);
        localStorage.setItem('quickAlertForm', ""); // სტორიჯის დაცარიელება გვერდის ჩატვირთვის შემდეგ
    }

    checkData(data) {
        if (data.id !== 0 ||
            data.eventStartDate !== 0 || data.eventEndDate !== 0 ||
            data.docStartDate !== 0 || data.docEndDate !== 0 || data.name !== ''
            || data.variety.length > 0 || data.status.length > 0 || data.category.length > 0 || data.worldHeritage.length > 0 || data.sizuste.length > 0 ||
            data.period.length > 0 || data.function.length > 0 || data.eventType.length > 0 || data.documentType.length > 0 ||
            data.country.length > 0 || data.region.length > 0 || data.municipality.length > 0 || data.settlement.length > 0 || data.street.length > 0
            || data.gancxadebisMizaniMosalodneli.length > 0 || data.gancxadebisMizaniArsebuli.length > 0 || data.dazianebisAdgilmdebareoba.length > 0 ||
            data.dazianebisXarisxi.length > 0 || data.dazianebisTipi.length > 0 || data.ganmcxadebeli.length > 0 || data.gancxadebisTariRi.length > 0) {
            return true;
        }
        return false
    }

    searchData(data) {
        if (data.id === '') data.id = 0;
        if (data.eventStartDate === '') data.eventStartDate = 0;
        if (data.eventEndDate === '') data.eventEndDate = 0;
        if (data.eventEndDate === '') data.eventEndDate = 0;
        if (data.docStartDate === '') data.docStartDate = 0;
        if (data.docEndDate === '') data.docEndDate = 0;

        if (this.checkData(data)) {
            fetch('api/Immovable', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response) => {
                this.props.filterResulthandle(response);
                this.handleClose();
            })
        }
        else {
            this.props.filterResulthandle('');
            this.handleClose();
        }
    }

    handleClearData() {
        var data = {
            id: '', name: '', eventStartDate: '', eventEndDate: '', docStartDate: '', docEndDate: '',
            variety: [], status: [], category: [], worldHeritage: [], period: [], function: [], eventType: [], documentType: [], sizuste: [],
            country: [], region: [], municipality: [], settlement: [], street: [],
            tempselectedPeriods: [], tempselectedFunction: [], tempselectedEventType: [], tempselectedDocumentType: [], linkedObj: {}, fullText: ''
            , gancxadebisMizaniArsebuli: false, gancxadebisMizaniMosalodneli: false, dazianebisAdgilmdebareoba: [], dazianebisXarisxi: [], dazianebisTipi: [], ganmcxadebeli: '', gancxadebisTariRi: ''
        };
        this.setState({ data: data, show: false })
        sessionStorage.setItem('filterData', JSON.stringify(JSON.stringify(data)));
        this.props.filterResulthandle('');
        sessionStorage.setItem('locationFilter', 'off');

    }

    TreeonBlur() {
        var data = this.state.data;
        data.period = this.selectedPeriods;
        data.function = this.selectedFunction;
        data.documentType = this.selectedDocumentType;
        data.eventType = this.selectedEventType;
        data.variety = this.selectedVarieties;
        this.setState({ data: data });
    }

    changeVarietyHandle(currentNode, selectedNodes) {
        this.selectedVarieties = [];
        if (selectedNodes != null) {
            selectedNodes.map(item =>
                this.selectedVarieties.push(item.value)
            );
        }
    }

    changeStatusHandle(items) {
        var data = this.state.data;
        data.status = [];
        if (items != null) {
            items.map(item =>
                data.status.push(item.value)
            );
        }
        this.setState({ data: data });
    }

    changeSizusteHandle(items) {
        var data = this.state.data;
        data.sizuste = [];
        if (items != null) {
            items.map(item =>
                data.sizuste.push(item.value)
            );
        }
        this.setState({ data: data });
    }

    changeCategoryHandle(items) {
        var data = this.state.data;
        data.category = [];
        if (items != null) {
            items.map(item =>
                data.category.push(item.value)
            );
        }
        this.setState({ data: data });
    }
    changeWorldHeritageHandle(items) {
        var data = this.state.data;
        data.worldHeritage = [];
        if (items != null) {
            items.map(item =>
                data.worldHeritage.push(item.value)
            );
        }
        this.setState({ data: data });
    }


    loopChild(item, tempList) {
        tempList.push(item.value);
        if (item.tempchild != null && item.tempchild.length > 0) {
            item.tempchild.map(i => this.loopChild(i, tempList))
        }
    }

    changePeriodHandle(currentNode, selectedNodes) {
        this.selectedPeriods = [];
        this.tempselectedPeriods = [];

        if (selectedNodes != null) {
            selectedNodes.forEach(item => {
                this.selectedPeriods.push(item.value)
                this.loopChild(item, this.tempselectedPeriods)
            });
        }
    }

    changeFunctionHandle(currentNode, selectedNodes) {
        this.selectedFunction = [];
        this.tempselectedFunction = [];
        if (selectedNodes != null) {
            selectedNodes.forEach(item => {
                this.selectedFunction.push(item.value)
                this.loopChild(item, this.tempselectedFunction)
            });
        }
    }
    changeEventTypeHandle(currentNode, selectedNodes) {
        this.selectedEventType = [];
        this.tempselectedEventType = [];
        if (selectedNodes != null) {
            selectedNodes.forEach(item => {
                this.selectedEventType.push(item.value)
                this.loopChild(item, this.tempselectedEventType)
            });
        }
    }
    changeDocumentTypeHandle(currentNode, selectedNodes) {
        this.selectedDocumentType = [];
        this.tempselectedDocumentType = [];
        if (selectedNodes != null) {
            selectedNodes.forEach(item => {
                this.selectedDocumentType.push(item.value)
                this.loopChild(item, this.tempselectedDocumentType)
            });
        }
    }

    changeCountryHandle(items) {
        var data = this.state.data;
        data.country = [];
        if (items != null) {
            items.map(item =>
                data.country.push(item.label)
            );
        }
        this.setState({ data: data });
    }

    changeRegionHandle(items) {
        var data = this.state.data;
        data.region = [];
        if (items != null) {
            items.map(item =>
                data.region.push(item.label)
            );
        }
        this.setState({ data: data });
    }

    changeMunicipalityHandle(items) {
        var data = this.state.data;
        data.municipality = [];
        if (items != null) {
            items.map(item =>
                data.municipality.push(item.label)
            );
        }
        this.setState({ data: data });
    }
    changeSettlementHandle(items) {
        var data = this.state.data;
        data.settlement = [];
        if (items != null) {
            items.map(item =>
                data.settlement.push(item.label)
            );
        }
        this.setState({ data: data });
    }

    changeStreetHandle(items) {
        var data = this.state.data;
        data.street = [];
        if (items != null) {
            items.map(item =>
                data.street.push(item.label)
            );
        }
        this.setState({ data: data });
    }

    //changeDazianebisTipiHandle(items) {
    //    var data = this.state.data;

    //    if (items != null) {
    //        data.dazianebisTipi = items.label;
    //    }
    //    this.setState({ data: data });
    //}
    changeDazianebisTipiHandle(items) {
        var data = this.state.data;
        data.dazianebisTipi = [];
        if (items != null) {
            items.map(item =>
                data.dazianebisTipi.push(item.value)
            );
        }
        this.setState({ data: data });
    }
    changeDazianebisXarisxiHandle(items) {
        var data = this.state.data;
        data.dazianebisXarisxi = [];
        if (items != null) {
            items.map(item =>
                data.dazianebisXarisxi.push(item.value)
            );
        }
        this.setState({ data: data });
    }

    onNameChange = (event, { newValue, method }) => {

        var data = this.state.data;
        data.name = newValue;
        this.setState({
            data: data
        });

        if (method === 'enter') {
            this.refs.form.handleSubmit();
        }
    };
    onGanmcxadebeliChange = (event, { newValue, method }) => {

        var data = this.state.data;
        data.ganmcxadebeli = newValue;
        this.setState({
            data: data
        });

        if (method === 'enter') {
            this.refs.form.handleSubmit();
        }
    };

    onKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.prepareSubmit();
        }
    }
    handleClearClick() {
        var data = this.state.data;
        data.name = '';
        this.setState({
            data: data
        });
    }
    handleClearGanmcxadebeliClick() {
        var data = this.state.data;
        data.ganmcxadebeli = '';
        this.setState({
            data: data
        });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                suggestions: []
            }) :

            fetch('api/AutoComplete/Getrecord?value=' + value + "&component=immovable")
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        suggestions: data
                    })
                })
                .catch(error => console.error(error));
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };


    onSuggestionsGanmcxadebeliFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        inputLength < 3 ?
            this.setState({
                suggestionsGanmcxadebeli: []
            }) :

            fetch('api/AutoComplete/getGanmcxadebeli?value=' + value)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        suggestionsGanmcxadebeli: data
                    })
                })
                .catch(error => console.error(error));
    };
    onSuggestionsGanmcxadebeliClearRequested = () => {
        this.setState({
            suggestionsGanmcxadebeli: []
        });
    };




    render() {
        const { checked1, expanded, clicked } = this.state;
        const suggestions = this.state.suggestions;
        const suggestionsGanmcxadebeli = this.state.suggestionsGanmcxadebeli;
        var value = this.state.data.name;
        const inputProps = {
            value:value,
            onChange: this.onNameChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        const valueGanmcxadebeli = this.state.data.ganmcxadebeli;
        const inputGanmcxadebeliProps = {
            value: valueGanmcxadebeli,
            onChange: this.onGanmcxadebeliChange,
            onKeyPress: this.onKeyPress.bind(this)
        };
        

        let DazianebisXarisxi = this.state.DazianebisXarisxiList;
        let dazianebisXarisxiItems = [];
        DazianebisXarisxi.map((item) =>
            /* <option key={item.name} value={item.id}>{item.name} </option>*/
            dazianebisXarisxiItems.push({ label: item.name, value: item.id })
        );

        let DazianebisTipi = this.state.DazianebisTipiList;
        let dazianebisTipiItems = [];
        DazianebisTipi.map((item) =>
            /* <option key={item.name} value={item.id}>{item.name} </option> */
            dazianebisTipiItems.push({ label: item.name, value: item.id })
        );
        let clearButton;
        if (value.length >= 1) {
            clearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleClearClick}
                />
            );
        }

        let clearGanmcxadebeliButton;
        if (valueGanmcxadebeli.length >= 1) {
            clearButton = (
                <div
                    className="autosugDivClearButton icon-cancel"
                    onClick={this.handleClearGanmcxadebeliClick}
                />
            );
        }

        var locationOpen = (this.state.data.country.length > 0 || this.state.data.region.length > 0 || this.state.data.municipality.length > 0 || this.state.data.settlement.length > 0 || this.state.data.street.length > 0 || this.state.data.sizuste.length > 0);
        var eventOpen = (this.state.data.eventType.length > 0 || this.state.data.eventStartDate > 0 || this.state.data.eventEndDate > 0);
        var docOpen = (this.state.data.documentType.length > 0 || this.state.data.docStartDate > 0 || this.state.data.docEndDate > 0);

        return (
            <>
                <div className="showModal">
                    <button onClick={this.handleShow} className="MainButton icon-equalizer" >{this.props.children}</button>
                </div>

                <Modal show={this.state.show} onHide={this.handleClose} className="SearchDialog">
                    <Modal.Header closeButton className="searchCloseModal">
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit.bind(this)} method="POST" style={{ height: '100%', paddingTop: '0px' }}>
                            <div className="searchDivContent">
                                <div className="FullGalleryHeader">სარეგისტრაციო ნომერი</div>
                                <input type="number" className="form-control searchDivRowInput" name="id" id="id" value={this.state.data.id} onChange={this.onChange.bind(this)} autoComplete="off" />
                                <div className="FullGalleryHeader searchDivRow">სახელწოდება</div>
                                <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        renderSuggestion={renderSuggestion}
                                        getSuggestionValue={getSuggestionValue}
                                        inputProps={inputProps}

                                    />
                                    <div className="close-icon-container">
                                        {clearButton}
                                    </div>
                                </div>
                                <div className="FullGalleryHeader searchDivRow" >სახეობა</div>
                                <CustomTreeSelect items={this.props.classificators.variety} onChange={this.changeVarietyHandle.bind(this)} selectedOption={this.state.data.variety} onBlur={this.TreeonBlur.bind(this)} />
                                <div className="FullGalleryHeader searchDivRow" >პერიოდი</div>
                                <CustomTreeSelect items={this.props.classificators.period} onChange={this.changePeriodHandle.bind(this)} selectedOption={this.state.data.period} onBlur={this.TreeonBlur.bind(this)} />
                                <div className="FullGalleryHeader searchDivRow" >ფუნქცია, ტიპი</div>
                                <CustomTreeSelect items={this.props.classificators.function} onChange={this.changeFunctionHandle.bind(this)} selectedOption={this.state.data.function} onBlur={this.TreeonBlur.bind(this)} />
                                <div className="FullGalleryHeader searchDivRow" >ამჟამინდელი სტატუსი</div>
                                <CustomMultipleSelect onKeyDown={this.onKeyPress.bind(this)} selectedOption={this.state.data.status} items={this.props.classificators.status} changeHandle={this.changeStatusHandle.bind(this)}></CustomMultipleSelect>
                                <div className="FullGalleryHeader searchDivRow" >ამჟამინდელი კატეგორია</div>
                                <CustomMultipleSelect onKeyDown={this.onKeyPress.bind(this)} selectedOption={this.state.data.category} items={this.props.classificators.category} changeHandle={this.changeCategoryHandle.bind(this)}></CustomMultipleSelect>
                                <div className="FullGalleryHeader searchDivRow" >მსოფლიო მემკვიდრეობა</div>
                                <CustomMultipleSelect onKeyDown={this.onKeyPress.bind(this)} selectedOption={this.state.data.worldHeritage} items={this.props.classificators.worldHeritage} changeHandle={this.changeWorldHeritageHandle.bind(this)}></CustomMultipleSelect>

                                <Accordion defaultActiveKey={locationOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                                    <Card className="modelCard">
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey="1" className="FullGalleryHeader" isOpen={locationOpen}>ადგილმდებარეობა</CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <div className="searchDivRow" >სახელმწიფო</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={this.props.classificators.country} changeHandle={this.changeCountryHandle.bind(this)} selectedOption={this.state.data.country} />
                                                <div className="searchDivRow" >რეგიონი</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={
                                                    this.props.classificators.region.filter(i =>
                                                        this.state.data.country.indexOf(i.parentName) > -1
                                                        || (this.state.data.country.length === 0 && (i.other !== true || i.parentName === this.props.classificators.country[0].name)))
                                                } changeHandle={this.changeRegionHandle.bind(this)} selectedOption={this.state.data.region} />
                                                <div className="searchDivRow" >რაიონი/მუნიციპალიტეტი</div>
                                                <CustomCreatableMultipleSelect onKeyDown={this.onKeyPress.bind(this)} items={
                                                    this.props.classificators.municipality.filter(i =>
                                                        this.state.data.region.indexOf(i.parentName) > -1
                                                        || (
                                                            this.state.data.region.length === 0 && (this.state.data.country.length === 0 || this.state.data.country.indexOf(this.props.classificators.country[0].name) > -1)
                                                            && (i.other !== true || i.parentName === this.props.classificators.country[0].name)
                                                        )
                                                        || this.state.data.country.indexOf(i.parentName) > -1)
                                                } changeHandle={this.changeMunicipalityHandle.bind(this)} selectedOption={this.state.data.municipality} />
                                                <div className="searchDivRow" >დასახლებული პუნქტი</div>
                                                <CustomCreatableMultipleSelectSearch onKeyDown={this.onKeyPress.bind(this)} isDisabled={false} items={
                                                    this.props.classificators.settlement.filter(i =>
                                                        this.state.data.municipality.indexOf(i.parentName) > -1
                                                        || (this.state.data.municipality.length === 0
                                                            && (i.other !== true || i.parentName === this.props.classificators.country[0].name)
                                                            && (this.state.data.country.length === 0 || this.state.data.country.indexOf(this.props.classificators.country[0].name) > -1))
                                                        || this.state.data.country.indexOf(i.parentName) > -1)}
                                                    changeHandle={this.changeSettlementHandle.bind(this)} selectedOption={this.state.data.settlement} />
                                                <div className="searchDivRow" >მისამართი</div>
                                                <CustomCreatableMultipleSelectSearch onKeyDown={this.onKeyPress.bind(this)} isDisabled={this.state.data.settlement.length === 0} items={
                                                    this.props.classificators.street.filter(i => this.state.data.settlement.indexOf(i.parentName) > -1)}
                                                    changeHandle={this.changeStreetHandle.bind(this)} selectedOption={this.state.data.street} />
                                                <div className="searchDivRow" >ადგილმდებარეობის სიზუსტე</div>
                                                <CustomMultipleSelect onKeyDown={this.onKeyPress.bind(this)} selectedOption={this.state.data.sizuste} items={this.props.classificators.sizuste} changeHandle={this.changeSizusteHandle.bind(this)}></CustomMultipleSelect>




                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                                <Accordion defaultActiveKey={eventOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                                    <Card className="modelCard">
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey="1" className="FullGalleryHeader" isOpen={eventOpen}>ობიექტზე განხორციელებული ქმედებით ძიება</CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <div className="searchDivRow" >ობიექტზე განხორციელებული ქმედების ტიპი</div>
                                                <CustomTreeSelect items={this.props.classificators.eventType} onChange={this.changeEventTypeHandle.bind(this)} selectedOption={this.state.data.eventType} onBlur={this.TreeonBlur.bind(this)} />

                                                <div className="searchDivRow" >ქმედების განხორციელების თარიღი (წელი)</div>
                                                <div style={{ display: 'inline-flex' }} className="searchDivRowInput">
                                                    <input autoComplete="off" type="number" className="form-control " name="eventStartDate" id="eventStartDate" value={this.state.data.eventStartDate} onChange={this.onChange.bind(this)} />
                                                    <span style={{ padding: '5px 10px' }}>-</span>
                                                    <input autoComplete="off" type="number" className="form-control " name="eventEndDate" id="eventEndDate" value={this.state.data.eventEndDate} onChange={this.onChange.bind(this)} />
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>

                                <Accordion defaultActiveKey={docOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                                    <Card className="modelCard">
                                        <Card.Header className="TogglePanelHeader">
                                            <CustomToggle eventKey="1" className="FullGalleryHeader" isOpen={docOpen}>ობიექტზე არსებული დოკუმენტით ძიება</CustomToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <div className="searchDivRow" >დოკუმენტის ტიპი</div>
                                                <CustomTreeSelect items={this.props.classificators.documentType} onChange={this.changeDocumentTypeHandle.bind(this)} selectedOption={this.state.data.documentType} onBlur={this.TreeonBlur.bind(this)} />

                                                <div className="searchDivRow" >თარიღი (წელი)</div>
                                                <div style={{ display: 'inline-flex' }} className="searchDivRowInput">
                                                    <input autoComplete="off" type="number" className="form-control " name="docStartDate" id="docStartDate" value={this.state.data.docStartDate} onChange={this.onChange.bind(this)} />
                                                    <span style={{ padding: '5px 10px' }}>-</span>
                                                    <input autoComplete="off" type="number" className="form-control " name="docEndDate" id="docEndDate" value={this.state.data.docEndDate} onChange={this.onChange.bind(this)} />
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                                {this.props.isAdmin ?
                                    <Accordion defaultActiveKey={docOpen ? "1" : "0"} className="accordionNoshadow searchDivRow">
                                        <Card className="modelCard">
                                            <Card.Header className="TogglePanelHeader">
                                                <CustomToggle eventKey="1" className="FullGalleryHeader" isOpen={docOpen}>ობიექტზე შემოსული განცხადებები</CustomToggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <div className="searchDivRowTItleQuick" >განცხადების მიზანი</div>
                                                    <div className="searchDivRowTItleQuick">
                                                        <input style={{ "verticalAlign": "middle", "marginRight": "12px" }}
                                                            checked={this.state.data.gancxadebisMizaniArsebuli == "არსებული დაზიანება" ? true:false} type="checkbox" value={this.state.data.gancxadebisMizaniArsebuli}
                                                            name="gancxadebisMizaniArsebuli" onChange={this.onChange.bind(this)} />არსებული დაზიანება</div>

                                                    <div className="searchDivRowTItleQuick">
                                                        <input style={{ "verticalAlign": "middle", "marginRight": "12px" }}
                                                            checked={this.state.data.gancxadebisMizaniMosalodneli == "მოსალოდნელი საფრთხე" ? true : false}
                                                            type="checkbox" value="მოსალოდნელი საფრთხე"
                                                            name="gancxadebisMizaniMosalodneli" onChange={this.onChange.bind(this)} />მოსალოდნელი საფრთხე</div>
                                                    <div className="searchDivRowTItleQuick">დაზიანების ადგილმდებარეობა </div>

                                                    <div>
                                                        <CheckboxTree
                                                            checked={checked1}
                                                            expanded={expanded}
                                                            iconsClass="fa4"
                                                            nodes={nodesInterieri}
                                                            onCheck={this.onCheck1}
                                                            onClick={this.onClick}
                                                            onExpand={this.onExpand}
                                                            icons={{
                                                                check: <span className="rct-icon rct-icon-check" />,
                                                                uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                                                halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                                                expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                                                expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                                                expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                                collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                                parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                                                parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                                                leaf: <span className="rct-icon rct-icon-leaf" />,
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="searchDivRowTItleQuick" >დაზიანების ხარისხი</div>
                                                    <CustomMultipleSelect onKeyDown={this.onKeyPress.bind(this)} selectedOption={this.state.data.dazianebisXarisxi} items={this.props.classificators.dazianebisXarisxi}
                                                        changeHandle={this.changeDazianebisXarisxiHandle.bind(this)}></CustomMultipleSelect>
                                                    <div className="searchDivRowTItleQuick" >დაზიანების ტიპი</div>
                                                    <CustomMultipleSelect onKeyDown={this.onKeyPress.bind(this)} selectedOption={this.state.data.dazianebisTipi} items={this.props.classificators.dazianebisTipi}
                                                        changeHandle={this.changeDazianebisTipiHandle.bind(this)}></CustomMultipleSelect>

                                                    {/*<div>*/}
                                                    {/*    <div className="searchDivRowTItleQuick">დაზიანების ტიპი</div>*/}
                                                    {/*    <Select*/}
                                                    {/*        placeholder="აირჩიეთ"*/}
                                                    {/*        options={dazianebisTipiItems}*/}
                                                    {/*        className=""*/}
                                                    {/*        onChange={this.changeDazianebisTipiHandle.bind(this)}*/}
                                                    {/*        value={this.state.data.dazianebistipi}*/}
                                                    {/*    />*/}
                                                    {/*</div>*/}
                                                    <div className="searchDivRowTItleQuick">განმცხადებელი</div>
                                                    <div className="autosugDiv searchDivRow" style={{ display: "inline-flex", width: '100%' }}>
                                                        <Autosuggest
                                                            suggestions={suggestionsGanmcxadebeli}
                                                            onSuggestionsFetchRequested={this.onSuggestionsGanmcxadebeliFetchRequested}
                                                            onSuggestionsClearRequested={this.onSuggestionsGanmcxadebeliClearRequested}
                                                            renderSuggestion={renderSuggestion}
                                                            getSuggestionValue={getSuggestionValue}
                                                            inputProps={inputGanmcxadebeliProps}

                                                        />
                                                        <div className="close-icon-container">
                                                            {clearGanmcxadebeliButton}
                                                        </div>
                                                    </div>
                                                    {/*<div className="searchDivRowTItleQuick">განმცხადებელი </div>*/}
                                                    {/*<input autoComplete="off" style={{ "padding": "8px 14px 12px", "width": "100%" }} type="text" className="form-control " name="ganmcxadebeli" id="ganmcxadebeli" value={ this.state.data.ganmcxadebeli} onChange={this.onChange.bind(this)} placeholder="ჩაწერეთ" />*/}
                                                    <div className="searchDivRowTItleQuick">განცხადების თარიღი   </div>
                                                    <input autoComplete="off" style={{ "padding": "8px 14px 12px", "width": "100%" }} type="date" className="form-control " name="gancxadebisTariRi" id="gancxadebisTariRi" onChange={this.onChange.bind(this)} />
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion> : ""

                                }

                            </div>
                            <div className="searchDiv">
                                <div onClick={this.handleClearData.bind(this)} className="NoFillSearchButton">გაუქმება</div>
                                <button type="submit" className="MainButton">ძიება</button>
                            </div>
                        </Form>
                    </Modal.Body>

                </Modal>
            </>
        );
    }
}

