import React from 'react';

import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';


import PulseLoader from "react-spinners/PulseLoader";
import { authenticationService } from '../../_services';
import { authHeader, handleResponse } from '../../_helpers';


class UserObjectListView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //currentUser: null,
            //isAdmin: false,
            //photo: null,
            pager: {},
            pageOfItems: [],
            loading: true,
            reload: false,
            type: this.props.type,
        };

    }

    componentDidMount() {

        //authenticationService.currentUser.subscribe(x =>
        //    this.setState({
        //        currentUser: x,
        //        isAdmin: x && x.role === "Admin",
        //        photo: x && x.photo ? x.photo : null
        //    }));




        if (this.props.currentUser != null) {
            window.scrollTo(0, 0)
            this.loadPage();
        }

    }

    componentDidUpdate() {
        if (this.props.currentUser != null) {
            this.loadPage();
        }
    }

    async loadPage() {
        const params = new URLSearchParams(window.location.search);
        const page = parseInt(params.get('page')) || 1;

        var type;
        switch (this.props.type) {

            case "private":
                type = `api/Users/GetPrivateObjectList?count=10&page=${page}`;
                break;
            default: type = '';
                break;
        }


        if (page !== this.state.pager.currentPage || this.state.reload === true) {

            const requestOptions = { method: 'GET', headers: authHeader() };
            const response = await fetch(type, requestOptions);

            const data = await response.json();
            this.setState({ pager: data, pageOfItems: data.aaData, loading: false, type: this.props.type });
        }
    }

    getImgDivClass(type) {
        switch (type) {
            case "immovable":
                return "imgDivImmovable";
            case "movable":
                return "imgDivMovable";
            case "wallart":
                return "imgDivWallArt";
            case "stoneengrave":
                return "imgDivStoneEngrave";
            case "zone":
                return "imgDivZone";
            case "museum":
                return "imgDivMuseum";
            case "event":
                return "imgDivEvent";
            case "document":
                return "imgDivDocumentBig";
            case "all":
                return "";
            default:
                return "imgDivImmovable";
        }
    }

    deleteObject(id) {

        var result = window.confirm("გსურთ წაშლა?");
        if (result) {
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            return fetch(`api/Users/DeleteObject?id=${id}`, requestOptions)
                .then(data => {
                    this.setState({ reload: true });
                    this.loadPage();
                });
        }



    }

    drawImovableList(forecasts) {
        window.scrollTo(0, 0)
        var el = document.getElementsByClassName('immListDiv');
        if (el != null && el.length > 0) el[0].scrollTo(0, 0);

        return (
            <CardGroup>
                {forecasts.map(forecast =>
                    <div key={forecast.objType + '_' + forecast.id} dataid={forecast.id} >

                        <a href={'/viewobject?id=' + forecast.id} className="mainCard">
                            <Card className="cardHorizonalListView">
                                <div className="ObjectListViewImgContainer">
                                    <div className={`${(forecast.photoAddress === '' || (forecast.photoAddress.indexOf('.jpg') < 0 && forecast.photoAddress.indexOf('.png') < 0)) ? this.getImgDivClass(forecast.objType) : 'imgDivObjListView'}`}>
                                        {
                                            forecast.photoAddress == null || forecast.photoAddress === '' ?
                                                <></> :
                                                <img className="list_card-img" variant="top" src={forecast.photoAddress} alt="" />
                                        }


                                    </div>
                                </div>
                                <Card.Body className="objectList_card-body">
                                    <Card.Title className="objectList_card-title card-link" >{forecast.objectName}</Card.Title>
                                    <div className="objectTypeList">{forecast.variety}</div>
                                    <Card.Text className="objectList_card-text icon-location-outline" dataid={forecast.id}>
                                        {forecast.address}
                                    </Card.Text>
                                    {
                                        this.props.type === "private" ?
                                            <div style={{ textAlign: "left", display: 'flex' }}>
                                                <a href={"/addobject?id=" + forecast.id} className="TransparentButton" style={{ color: '#6A8E7F' }}><img width="20" alt="" src="/images/edit.svg" style={{ marginRight: '10px' }} />{' '}რედაქტირება</a>
                                                <div className="TransparentButton" style={{ color: '#FF647C' }} onClick={(e) => { e.preventDefault(); this.deleteObject(forecast.id) }} ><img width="20" alt="" src="/images/trash.svg" style={{ marginRight: '10px' }} />{' '}წაშლა</div>
                                            </div>
                                            : <></>
                                    }
                                </Card.Body>
                            </Card>
                        </a>


                    </div>
                )}
            </CardGroup>
        );
    }


    render() {

        const params = new URLSearchParams(window.location.search);

        const { pager, pageOfItems } = this.state;
        return (
            <div className="card text-center">
                <div className="card-body">
                    {
                        this.state.loading || this.props.type !== this.state.type
                            ?
                            //<p><em>Loading...</em></p>
                            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', padding: '50px', textAlign: 'center' }}>
                                <PulseLoader
                                    color={"#E57959"}
                                    loading={true}
                                />
                            </div>
                            : this.drawImovableList(pageOfItems)
                    }

                </div>

            </div>
        );
    }
}

export { UserObjectListView };