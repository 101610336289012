import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';


import { useClearCache } from "react-clear-cache";


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');





function ClearHistory() {
    const { isLatestVersion, latestVersion, emptyCacheStorage } = useClearCache();

    console.log(latestVersion)
    if (!isLatestVersion) {
        emptyCacheStorage();
        localStorage.removeItem('APP_VERSION')
        window.location.reload()
      
    }

    return (
        <App />
    );
}

ReactDOM.render(
   
    <BrowserRouter basename={`${baseUrl}`} >
        <ClearHistory />

  </BrowserRouter>,
  rootElement);

unregister();
registerServiceWorker();

